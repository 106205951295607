export default class Experience {
  constructor(options) {

    this.Level = 0;
    this.ExperienceNeeded = 0;
    
    if(options) {
      this.init(options);
    }

  }

  init(options) {
    
    this.Level = parseInt(options.Level) || this.Level;
    this.ExperienceNeeded = parseInt(options.ExperienceNeeded) || this.ExperienceNeeded;

  }
}