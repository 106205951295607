//---------------------------------------
import Vue from 'vue'
import VueRouter from 'vue-router'
import { setPageBackground } from './code/app/assetManager'

//---------------------------------------  routes
import Login from './ui/routes/Login'
import Concourse from './ui/routes/Concourse'
import CharacterForge from './ui/routes/CharacterForge'
import CharacterSheet from './ui/routes/CharacterSheet'
import _controls from './ui/routes/_controls'
import _dev from './ui/routes/_dev'

//---------------------------------------  
const routes = [
  { path: '/', name: 'Login', component: Login  },
  { path: '/concourse', name: 'Concourse', component: Concourse  },
  { path: '/character-forge', name: 'CharacterForge', component: CharacterForge },
  { path: '/character-sheet/:characterRecordId', name: 'CharacterSheet', component: CharacterSheet },
  { path: '/_controls', name: '_controls', component: _controls },
  { path: '/_dev', name: '_dev', component: _dev }
]

//---------------------------------------  router instance
Vue.use(VueRouter);
const router = new VueRouter({ routes })

//---------------------------------------  route guards
router.beforeEach((to, from, next) => {
  //console.log('leaving: ' + from.name + '  going to: ' + to.name);
  setPageBackground(to.name);
  next();
})

export default router;

// const Login = () => import('./routes/Login') : lazy load syntax