<template>
<section class="stone-frame">
  <header>
    <slot name="header"></slot>
  </header>
  <div>
    <slot name="body"></slot>
  </div>
  <footer>
    <slot name="footer"></slot>
  </footer>
</section>
</template>

<script>
// -----------------------------------------------------
export default {
  name: "StoneFrame"
};
</script>

<style lang="scss" scoped>

$artworkRoot: '/img/artwork/';

.stone-frame {
  
  position: relative;
  border: solid 6px rgb(51, 51, 51);
  border-image-source: url($artworkRoot + 'border-stone-frame.png');
  border-image-slice: 50;
  border-image-width: auto;
  border-image-repeat: repeat;
  padding: 3px 4px;  
  background: url($artworkRoot + 'texture-brown-sandstone.png');
  background-size: 100%;
  color: #fff;
  z-index: 10;
  margin-bottom: 10px;

  header {
    display: flex;
    justify-content: space-between;
    background: url($artworkRoot + 'facia-stone-border.png');
    height: 34px;
    padding: 4px;
  }
  
}
</style>