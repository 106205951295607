//-------------------------------------------------------------
const storageKey = process.env.VUE_APP_DATABASE_KEY;
//-------------------------------------------------------------

export function setProfile(obj) {
  let json = JSON.stringify(obj);
  let cypherText = btoa(json);
	localStorage.setItem(storageKey, cypherText);
}

//-------------------------------

export function getProfile() {
  let cypherText = localStorage.getItem(storageKey);
  if(!cypherText) { return null; }
  let json = atob(cypherText);
  return JSON.parse(json);  
}

//-------------------------------

export function clearProfile() {
	localStorage.removeItem(storageKey);
}

//-------------------------------------------------------------