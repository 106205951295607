
//====================================================
// Game Calculations
//====================================================
import * as g20Utils  from '../utils/g20Utils';
import g20CharacterModels from "../models/g20CharacterModels";

//------------------------------------------------------------------  setAttributeValues
export function setAttributeValues(attribute, attributeBonuses) {

  if(!attribute) {
    console.error('setAttributeValues requires an attribute');
    return;
  }

  if(attribute.Current > 0) {
    let bonus = attributeBonuses.find(ab => ab.Score === attribute.Current);
    attribute.PrimaryBonus = bonus.PrimaryBonus;
    attribute.SecondaryBonus = bonus.SecondaryBonus;    
  } else {
    attribute.PrimaryBonus = 0;
    attribute.SecondaryBonus = 0;  
  }

  return setAttributeTotals(attribute);
}

//------------------------------------------------------------------  setAttributeTotals
export function setAttributeTotals(attribute) {

  if(!attribute) {
    console.error('attributeTotals requires an attribute');
    return;
  }

  attribute.PrimaryTotal = attribute.PrimaryBonus + attribute.RacialBonus + attribute.Misc;
  attribute.SecondaryTotal = attribute.SecondaryBonus;

  return attribute;
}

//------------------------------------------------------------------  startingFate
export function startingFate(race, characterClass, attributes) {
  let fate = parseInt(race.StartingFate);
  fate += attributes[characterClass.Stat1].Potential;
  fate += attributes[characterClass.Stat2].Potential;
  return fate;
}

//------------------------------------------------------------------  spentFate
export function spentFate(characterBackgrounds, characterFeatures) {
  let spentFate = 0;
  if(characterBackgrounds) {
    spentFate += Object.values(characterBackgrounds).reduce((t, {cost}) => t + cost, 0);
  }  
  if(characterFeatures) {
    spentFate += characterFeatures.reduce((t, {cost}) => t + cost, 0);
  }
  return spentFate;
}

//------------------------------------------------------------------  corruption
export function corruption(character) {
  let corruption = 0;
  character.Items.forEach(item => {
    if(item.ApplyCorruption) {
      corruption += item.Corruption;
    }
  });
  return corruption;
}

//------------------------------------------------------------------  setInitialWealth
export function setInitialWealth(inheritance) {

  let currentWealth = new g20CharacterModels.Wealth();
  let array = inheritance.StartingPossessions.split(';').filter(item => { if(item.length>0) { return item;} });

  array.forEach(entry => {
    let wealthParts = entry.split(':');
    let code = wealthParts[0].trim();
    currentWealth.Currency[code].Quantity = g20Utils.rollDice(wealthParts[1].trim(), false);
  });

  return currentWealth;

}

//-------------------------------------------------------------------  setSkillBonus
export function getSkillBonus({character, skill, skillGroup}) {

  console.log('getSkillBonus',{character, skill, skillGroup});

  let primaryTotal = skill.Stat1 ? character.Attributes[skill.Stat1].PrimaryTotal : 0;
  let secondaryTotal = skill.Stat2 ? character.Attributes[skill.Stat2].SecondaryTotal : 0;

  if(skill.SkillGroupName==="Weapons") { console.log(primaryTotal, secondaryTotal, skill)}

  let bonus = 0;
  bonus += primaryTotal + secondaryTotal;       // from character attributes
  bonus += skillGroup.LevelBonus;               // skill group
  bonus += skill.ItemBonus + skill.MiscBonus;   // skill  

  if(skill.Ranks > 20) {
    bonus += 15 + Math.ceil((skill.Ranks - 20) * .25);
  } else if(skill.Ranks < 11) {
    bonus += skill.Ranks;
  } else {
    bonus += 10 + Math.ceil((skill.Ranks - 10) * .5);
  }

  if(skill.SkillGroupName==="Weapons") { console.log(bonus)}

  return bonus;
}

//-------------------------------------------------------------------  tomeCost
export function getTomeCost(character, tome) {

  let currentTomes = character.SpellLists.length;
  let hybridCost = character.SpellAbility.isHybrid ? character.SpellAbility.ExtraHybridCost : 0;
  let listCost = character.SpellAbility.BaseTomeCost + hybridCost;

  if(tome.ClassName === character.Class.ClassName) {
    listCost += 0;
  } else if(tome.ClassName.toLowerCase() === 'none') {
    if(tome.SpellListType.TypeDesc.toLowerCase() === "mundane") {
      listCost += character.SpellAbility.ExtraMundaneCost;
    }
    if(tome.SpellListType.TypeDesc.toLowerCase() === "urbane") {
      listCost += character.SpellAbility.ExtraUrbaneCost;
    }
    if(tome.SpellListType.TypeDesc.toLowerCase() === "arcane") {
      listCost += character.SpellAbility.ExtraArcaneCost;
    }
  } else {
    listCost += character.SpellAbility.ExtraClassCost;
  }
    
  if(character.SpellAbility.PrimaryRealm && character.SpellAbility.SecondaryRealm ) { // hybrid?
    listCost += character.SpellAbility.ExtraHybridCost;
  }
  if(currentTomes > character.SpellAbility.TomesBeforeCostIncrease) { // many tomes?
    listCost += character.SpellAbility.AdditionalTomeCost;
  }

  tome.ListCost = listCost;  // by reference

}

//-------------------------------------------------------------------  defensive bonus
export function getDefensiveBonus(character) {
  return character.Attributes.RE.PrimaryTotal + character.Attributes.RE.SecondaryTotal;
}

//-------------------------------------------------------------------  initiative bonus
export function getInitiativeBonus(character) {
  let IB = 0;
  IB += character.Attributes.RE.PrimaryTotal;
  IB += character.Attributes.LO.SecondaryTotal;
  IB += character.Attributes.IN.SecondaryTotal;
  if(character.CombatInfo.Armor) {
    IB += character.CombatInfo.Armor.InitiativePenalty || 0;
  }
  return IB;
}

//-------------------------------------------------------------------  level bonus points
export function levelBonusPointsPerLevel(level) {
  if(level < 11) {
    return 3;
  } else if(level > 20) {
    return 1;
  } else {
    return 2;
  }
}

//============================================================================================
// SKILL AND SPELL LEDGERS
//============================================================================================

//-------------------------------------------------------------------  blendSkillLedger
export function blendSkillLedger(skillLedger) {

  // copy original as a base
  skillLedger.blended = g20Utils.deepClone(skillLedger.original);  

  // spin through selected skill groups -> skills
  let selectedGroups = Object.values(skillLedger.selected);
  selectedGroups.forEach(selectedGroup => {    
    let selectedSkills = Object.values(selectedGroup.Skill);
    selectedSkills.forEach(selectedSkill => {

      //get skill instances from each list
      let bSkill = skillLedger.blended[selectedGroup.SkillGroupName].Skill[selectedSkill.SkillName];
      let oSkill = skillLedger.original[selectedGroup.SkillGroupName].Skill[selectedSkill.SkillName];
      let sSkill = skillLedger.selected[selectedGroup.SkillGroupName].Skill[selectedSkill.SkillName];
      
      // if blended skill does not exist, clone it into the blended skill
      if(!bSkill) {
        bSkill = g20Utils.deepClone(selectedSkill);
      }

      // if original Skill exists, use its ranks as a base - else, zero it out
      let originalRanks = oSkill?.Ranks || 0;
      bSkill.Ranks = originalRanks + sSkill.AddedRanks;
      bSkill.AddedRanks = sSkill.AddedRanks;

      // set blended property to object
      skillLedger.blended[selectedGroup.SkillGroupName].Skill[selectedSkill.SkillName] =  g20Utils.deepClone(bSkill);

    });
  });

  return skillLedger;
}

//-------------------------------------------------------------------  blendSpellLedger
export function blendSpellLedger(spellLedger) {

  let blendedLists = g20Utils.deepClone(spellLedger.original);
  let selectedLists = g20Utils.deepClone(spellLedger.selected);

  selectedLists.forEach(list => {      
    let destList = blendedLists.find(sl => sl.SpellListID === list.SpellListID);
    if(!destList) {
      destList = list;
      blendedLists.push(destList);
      blendedLists.sort((a, b) => (a.SpellListName > b.SpellListName) ? 1 : -1);
    }
  });

  selectedLists.forEach(list => {
    let destList = blendedLists.find(sl => sl.SpellListID === list.SpellListID);
    list.Spells.forEach(spell => {
      if(!listHasSpell(destList, spell)) {          
        destList.Spells.push(spell);
        destList.Spells.sort((a, b) => (a.SpellName > b.SpellName) ? 1 : -1);
      }
    });
  });

  spellLedger.blended = blendedLists;
  return spellLedger;
}

//--------------------------------------------------------------------  getSpellList
export function getSpellList(spellLedger, spellListId) {
  let spellList = null;
  for(let s = 0; s < spellLedger.selected.length; s++) {
    if(spellLedger.selected[s].SpellListID === spellListId) {
      spellList = spellLedger.selected[s]; 
      return { spellList, selected: true }
    }
  }
  for(let o = 0; o < spellLedger.original.length; o++) {
    if(spellLedger.original[o].SpellListID === spellListId) {
      spellList = spellLedger.original[o]; 
      return { spellList, selected: false }
    }
  }
  return { spellList, selected: false }
}

//--------------------------------------------------------------------  getSpell
export function getSpell(spellLedger, spell) { 
  let spellListObj = getSpellList(spellLedger, spell.SpellListID);
  let spellList = spellListObj.spellList;
  if(spellList) {
    return spellList.Spells.find(sp => sp.Spell_ID === spell.Spell_ID);
  } else {
    return null;
  } 
}

//--------------------------------------------------------------------  tomeUnlocked
export function tomeUnlocked(spellLedger, spellList) {    
  let spellListObj = getSpellList(spellLedger, spellList.SpellListID);
  return (spellListObj.spellList) ? true : false;
}


//============================================================================================
// POINTS MANAGER
//============================================================================================
//-------------------------------------------------------------------  getReservePoints
export function getReservePoints(character) {
  let lvl = character.Level;
  let stat1 = character.Class.Stat1;
  let stat2 = character.Class.Stat2;
  let s1Pb = character.Attributes[stat1].PrimaryTotal;
  let s2Pb = character.Attributes[stat2].SecondaryTotal;
  return lvl + s1Pb + s2Pb
}

//-------------------------------------------------------------------  getInheritancePoints
export function getInheritancePoints(character) {

  let skp = character.Backgrounds.Inheritance.SkillPoints.split(';').filter(item => { if(item.length>0) { return item;} });
  let rpd = character.Backgrounds.Inheritance.RapidPoints.split(';').filter(item => { if(item.length>0) { return item;} });

  let skillPoints = skp.map(item => { 
    let parts = item.split(':').map(item => item.trim());
    return { type: 'Skill', rapid: false, group: parts[0], item: null, value: parseInt(parts[1]) } 
  });
  let rapidPoints = rpd.map(item => { 
    let parts = item.split(':').map(item => item.trim());
    return { type: 'Skill', rapid: true, group: parts[0], item: null, value: parseInt(parts[1]) } 
  });
  return [...skillPoints, ...rapidPoints];
}

export function tallySpentPoints({pointsPool, skills, spells}) {
  //------------------------------------------------------
  // spentItems are a meta-data list of skills and spells: { type, rapid, group, item, value }
  //------------------------------------------------------
  let spentItems = [];

  //------------------------------------------------------  gather spells
  spells.selected.forEach(list => {    
    if(!hasSpellList(spells.original, list.SpellListName)) {  
      spentItems.push({ type: 'spell-list', rapid: false, group: list.SpellListName, item: null, value: list.ListCost });
    }
    list.Spells.forEach(spell => {
      spentItems.push({ type: 'spell', rapid: false, group: list.SpellListName, item: spell.SpellName, value: spell.SpellCost });
    });
  });

  //------------------------------------------------------  gather skills
  for (const [gkey] of Object.entries(skills.selected)) {
    for (const [skey, sObj] of Object.entries(skills.selected[gkey].Skill)) { 
      spentItems.push({ type: 'skill', rapid: false, group: gkey, item: skey, value: sObj.Cost });        
      if(sObj.Ranks==2) {
        spentItems.push({ type: 'skill', rapid: true, group: gkey, item: skey, value: sObj.Cost*2 });
      }
    }
  }

  //------------------------------------------------------  ready points
  let spentPointsPool = g20Utils.deepClone(pointsPool);
  let leveling = spentPointsPool.find(spp => spp.type.toLowerCase() === 'leveling');
  let reserve = spentPointsPool.find(spp => spp.type.toLowerCase() === 'reserve');

  //------------------------------------------------------  process items
  spentItems.forEach(item => {    
    if(item.type.toLowerCase() === 'spell' || item.type.toLowerCase() === 'spell-list') {
      handleDeduction(item, reserve, leveling);   
    } else if (item.type.toLowerCase() === 'skill') {        
      let rapid = spentPointsPool.find(spp => spp.type.toLowerCase() === 'skill' && spp.rapid === true && spp.group === item.group);
      if(rapid && item.rapid) {
        //take from rapid
        handleDeduction(item, rapid, leveling);
      } else {
        //take from skill
        let skill = spentPointsPool.find(spp => spp.type.toLowerCase() === 'skill' && spp.rapid === false && spp.group === item.group);
        if(skill) {
          handleDeduction(item, skill, leveling);
        } else {
          //take from leveling, then from reserve
          leveling.value += -item.value;
          if(leveling.value < 0) {
            reserve.value += leveling.value;
            leveling.value = 0;
          }
        }
      }
    }  
  });
  //------------------------------------------------------  return new pool
  return spentPointsPool;
}

//-------------------------------------------------------
function hasSpellList(spellLists, spellListName) {
  if(spellLists.length===0) { return false }
  for(let i = 0; i < spellLists.length; i++) {
    if(spellLists[i].SpellListName === spellListName) {
      return true;
    }
  }
  return false;
}

//-------------------------------------------------------
export function hasSpell(spellLists, spell) {
  if(spellLists.length===0) { return false }
  for(let i = 0; i < spellLists.length; i++) {
    if(listHasSpell(spellLists[i], spell)){
      return true;
    }
  }
  return false;
}

//-------------------------------------------------------
export function listHasSpell(spellList, spell) {
  if(!spellList.Spells) { return false }
  for(let i = 0; i < spellList.Spells.length; i++) {
    if(spellList.Spells[i].Spell_ID === spell.Spell_ID) {
      return true;
    }
  }   
  return false;
}

//-------------------------------------------------------
function handleDeduction(item, targetObj, leveling) {
  let value = targetObj.value - item.value;
  if(value >= 0) {
    targetObj.value = value;
  } else {
    targetObj.value = 0;
    leveling.value += value;
  }
}
