<template>
<BrassFrame>
  <div class="character-listing" v-if="character">
    <div class="image">
      <img :src="imgSrc" class="thumb-100">
    </div>
    <div class="content">

      <div class="character-info">
        <div class="xp">
          <small>{{character.Xp}} xp</small>
        </div>
        <div class="character-name"> {{character.Info.Name}} </div>
        <div class="character-title"> {{character.Info.Title}} </div>
        <div class="level-race-class">
          <span>{{ $numericWord(level) }} {{character.LevelText}}</span>
          <span>, {{character.Race.PrimaryRaceName}}</span>
          <span v-if="character.Race.SecondaryRaceName"> / {{character.Race.SecondaryRaceName}} </span> 
          <span>, {{character.Class.ClassName}}</span>             
        </div>
      </div>

      <div class="character-demoraphics">
        <div>
          <div><strong>Hair:</strong> {{character.Info.Hair}}</div>
          <div><strong>Eyes:</strong> {{character.Info.Eyes}}</div>
        </div>
        <div>
          <div><strong>Height:</strong> {{character.Info.Height}}</div>
          <div><strong>Weight:</strong> {{character.Info.Weight}} lbs.</div>
        </div>
        <div>
          <div><strong>Age:</strong> {{character.Info.Age}}</div>
        </div>
      </div>

      <div class="character-stat-data" v-if="character.PrimeStats">
        <div>
          <div class="prime-stat-block"><strong>VI:</strong> {{character.PrimeStats.VI.Current}}</div>
          <div class="prime-stat-block"><strong>AG:</strong> {{character.PrimeStats.AG.Current}}</div>
          <div class="prime-stat-block"><strong>WI:</strong> {{character.PrimeStats.WI.Current}}</div>
          <div class="prime-stat-block"><strong>ME:</strong> {{character.PrimeStats.ME.Current}}</div>
          <div class="prime-stat-block"><strong>LO:</strong> {{character.PrimeStats.LO.Current}}</div>
        </div>
        <div>
          <div class="prime-stat-block"><strong>MI:</strong> {{character.PrimeStats.MI.Current}}</div>
          <div class="prime-stat-block"><strong>RE:</strong> {{character.PrimeStats.RE.Current}}</div>
          <div class="prime-stat-block"><strong>CH:</strong> {{character.PrimeStats.CH.Current}}</div>
          <div class="prime-stat-block"><strong>EM:</strong> {{character.PrimeStats.EM.Current}}</div>
          <div class="prime-stat-block"><strong>IN:</strong> {{character.PrimeStats.IN.Current}}</div>
        </div>
      </div>

      <div class="buttons">
        <button class="btn-gem green btn-sm" title="Save Character" @click="$emit('sync-character', characterRecord)">Save to Server</button>
        <button class="btn-gem blue btn-sm" title="Edit Character" v-if="character.Level>0"  @click="$emit('edit-character', characterRecord.CharacterRecordId)">Sheet</button>
        <button class="btn-gem red btn-sm" title="Character Level-Up" v-if="isLevelUp" @click="$emit('level-character', characterRecord)">Level-Up</button>
      </div>

    </div>
  </div>
</BrassFrame>
</template>

<script>
//----------------------------------------------------------
//----------------------------------------------------------
export default {
  name: "CharacterListing",
  props: {
    characterRecord: Object
  },
  computed: {
    character() { return this.characterRecord.CharacterData },
    level() { return this.character.Level },
    isLevelUp() { return this.character.Level===0 || this.character.Xp >= this.nextLevelXp },
    imgSrc() { return this.character.Picture }
  },
  data() {
    return {      
      nextLevelXp: -1
    }
  },
  watch: {
    character: {
      handler(val) {
        if(val) {
          this.getExperienceNeeded(this.character.Level + 1);
        }
      },
      immediate: true      
    }
  },
  methods: {
    async getExperienceNeeded(level) {
      setTimeout(async () => {
        let experience = await this.$store.dispatch('Content/getExperienceNeeded', level);
        this.nextLevelXp = experience.ExperienceNeeded;
      }, 100);
    },
  }
}
</script>


<style lang="scss" scoped>

.character-listing {
  display: flex;
  width: 100%;

  .image {

    width: 75px;
    height: 75px;
    margin-right: 10px;
    border: solid 1px #222;
    
    img {
      width: 100%;
      height: 100%;
    }

  }

  .content {
    position: relative;
    flex:1;
    padding-bottom: 28px;

    .character-name {
      font-size: 16px;
      font-weight: bold;
    }
    .xp {
      float: right;
    }
    .character-title {
      font-size: 12px;
      font-style: italic;
    }
    .level-race-class {
      font-size: 12px;
    }

    .character-demoraphics {
      display: none;
      justify-content: space-between;
      margin-top: 10px;
      font-size: 12px;
    }

    .prime-stat-block{
      display: inline-block;
      font-size: 12px;
      width: 55px;
    }

    .character-stat-data {
      margin-top: 10px;
    }

  }

  .buttons {
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: right;
  }
}
</style>