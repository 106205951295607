export default class SpecialAbilityType {
  constructor(options) {

    this.Cost = 0;
    this.SpecialAbilityType = null;

    if(options) {
      this.init(options);
    }
  }

  init(options) {
    this.Cost = parseInt(options.Cost) || this.Cost;
    this.SpecialAbilityType = options.SpecialAbilityType || this.SpecialAbilityType;
  }
  
}