<template>
<FateFrame class="item-form">

  <div class="form-body" v-if="item">  

    <div class="form-group">
      <label for="name"><strong>Name</strong></label>
      <input name="name" type="text" class="input-trans-sm full" autocomplete="off" v-model="item.Name">
    </div>
    
    <div class="flex even">
      <div class="form-group">
        <label for="bonus"><strong>Item Bonus</strong></label>
        <input name="bonus" type="number" :min="minimumBonus" class="input-trans-sm full" autocomplete="off" v-model.number="item.ItemBonus">    
      </div>      
      <div class="form-group">
        <label for="corruption"><strong>Corruption</strong></label>
        <input name="corruption" type="number" min="0" class="input-trans-sm full" autocomplete="off" v-model.number="item.Corruption">
      </div>
    </div>
        
    <div class="flex even">
      <div class="form-group">
        <label for="itemType"><strong>Item Type</strong></label>
        <select name="itemType" class="input-trans-sm full" v-model="item.ItemType">
          <option v-for="it in itemTypes" :key="it" :value="it">{{it}}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="material"><strong>Material</strong></label>
        <select name="material" class="input-trans-sm full" v-model="item.Material" @change="checkMinimums()">
          <option v-for="m in materials" :key="m.Material_ID" :value="m">{{m.MaterialName}}</option>
        </select>
      </div>      
    </div>

    <div class="weapon-fields" v-if="item.ItemType==='Weapon'">
      <div class="form-group">
        <label for="weaponSkill"><strong>Weapon Skill</strong></label>
        <select name="weaponSkill" class="input-trans-sm full" v-model="item.WeaponSkill">
          <option v-for="ws in weaponSkills" :key="ws.code" :value="ws.name">{{ws.name}}</option>
        </select>
      </div>
    </div>
   
    <div class="weapon-fields" v-if="item.ItemType==='Armor'">

      <div class="flex even">
        <div class="form-group">
          <label for="armorLocation"><strong>Armor Location</strong></label>
          <select name="armorLocation" class="input-trans-sm full" v-model="item.ArmorLocation">
            <option v-for="al in armorLocations" :key="al.code" :value="al.prop">{{al.name}}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="quality"><strong>Quality</strong></label>
          <input name="quality" type="number" class="input-trans-sm full" autocomplete="off" 
            min="0" :max="item.Material.QualityRange" v-model.number="item.Quality" />
        </div> 

      </div>

      <div class="flex even">
        <div class="form-group">
          <label for="armorType"><strong>Armor Type</strong></label>
          <select name="armorType" class="input-trans-sm full" v-model="item.ArmorType" @change="setArmorTypeData()">
            <option v-for="at in armorTypes" :key="at.ArmorCode" :value="at.ArmorCode">{{at.Description}}</option>
          </select>
        </div>

        <div class="form-group" v-show="item.ArmorLocation==='Armor'" @change="setWearsAsData()">
          <label for="armorType"><strong>Wears As</strong></label>
          <select name="armorType" class="input-trans-sm full" v-model="item.WearsAs">
            <option v-for="at in armorTypes" :key="at.ArmorCode" :value="at.ArmorCode">{{at.Description}}</option>
          </select>
        </div> 
      </div> 

    </div>

    <div class="notes" v-if="item.ItemType==='Armor' && item.ArmorLocation==='Armor'">
      <span>Crit Prevent: {{item.CritPrevention}}</span>
      <span>Casting Penalty: {{item.CastingPenalty}}</span>
      <span>Init Penalty: {{item.InitiativePenalty}}</span>
    </div>
    
    <div class="form-group">
      <label for="itemDesc"><strong>Description</strong></label>
      <textarea name="itemDesc" rows="4" class="input-trans-sm full" autocomplete="off" v-model="item.ItemDesc"></textarea>
    </div>
  
    <div class="checkboxes">
      <span>
        <label class="cursor-pointer"><input type="checkbox" v-model="item.IsEquipped" /> Equipped </label>
      </span> 
      <span>
        <label class="cursor-pointer"><input type="checkbox" v-model="item.ApplyCorruption" /> Apply Corruption </label>
      </span> 
    </div>

  </div>

  <footer>
    <div>
      <a class="help-link" href="#" title="show description">
        <img src="/img/svg/question.svg" />
      </a> 
      &nbsp; 
    </div>
    <div>
      <button class="btn-gem red btn" title="Cancel" @click="$emit('close')">Cancel</button>
      <button class="btn-gem green btn" title="New Character" @click="$emit('save', item)">Save</button>
    </div>
  </footer>
  
</FateFrame>
</template>

<script>
//--------------------------------------------------------------------
import Item from '../../../code/models/character-models/Item'
import { weaponSkills, armorLocations } from '../../../code/constants'
//--------------------------------------------------------------------
export default {
  name: "ItemForm",
  props: { controller: Object},
  data() {
    return {
      item: null,
      itemTypes: ["Weapon", "Armor", "Equipment"],
      weaponSkills: weaponSkills,
      armorLocations: armorLocations,
      armorTypes: [],
      materials: []
    }
  },
  computed: {
    minimumBonus() { return this.item.Material?.BonusMin || 0 },
    show() { return this.controller.show }
  },
  watch: {
    show(val) {
      if(val) {
        this.setData();
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {

    async init() {
      this.materials = await this.$store.dispatch('Content/getMaterials');
      this.armorTypes = await this.$store.dispatch('Content/getArmorTypes');
    },

    async setData() {      
      let item = new Item();
      if(this.controller.payload) {
        item = new Item(this.controller.payload);
      } else {
        item.Material = this.materials[0];
        item.ItemType = this.itemTypes[0];
      }    
      this.item = item;
    },

    setArmorTypeData() {
      if(this.item.ItemType === 'Armor' && this.item.ArmorLocation === 'Armor' ) {          
        let armorType = this.armorTypes.find(at => at.ArmorCode === this.item.ArmorType);
        this.item.CritPrevention = armorType.CritPrevention;
        this.item.WearsAs = this.item.ArmorType;
        this.setWearsAsData();
      }
    },

    setWearsAsData() {
      if(this.item.ItemType === 'Armor' && this.item.ArmorLocation === 'Armor' ) {     
        let armorType = this.armorTypes.find(at => at.ArmorCode === this.item.WearsAs);
        this.item.CastingPenalty = armorType.CastingPenalty;
        this.item.InitiativePenalty = armorType.InitiativePenalty;
      }
    },

    checkMinimums() {
      if(this.item.Material && this.item.ItemBonus < this.item.Material.BonusMin) {
        this.item.ItemBonus = this.item.Material.BonusMin;
      }
      if(this.item.Material && this.item.Quality > this.item.Material.QualityRange) {
        this.item.Quality = this.item.Material.QualityRange;
      }
    },

    save() {
      this.$emit('save', this.item);
      this.$emit('close');
    }

  }
}
</script>


<style lang="scss" scoped>
.item-form {
  .form-body {
    padding: 5px;

    .form-group {
      margin: 3px 0;
      label {
        margin-bottom: 2px;;
      }
    }

    .flex {
      display: flex;

      &.even > * {
        flex: 1;
        padding: 0 5px;
      }
      &.even > *:first-child {
        padding: 0;
        padding-right: 5px;
      }
      &.even > *:last-child {
        padding: 0;
        padding-left: 5px;
      }
    }


    .checkboxes {
      text-align: right;
    }
  }

  .notes {
    font-size: 10px;
    background-color: rgba(0,0,255,0.25);
    border: solid 1px rgba(0,0,0,0.25);
    padding: 2px;
    margin: 5px 0;
    &> span {
      padding: 1px 5px;
      &:first-child {
        padding-left: 0;
      }
    }

  }



  //---------------------------------------
  footer {
    display: flex;
    justify-content: space-between;
    padding: 5px;

    .help-link img {
      width: 22px;
      height: auto;
    }
  }
}

@media screen and (min-width: 768px) {
  .item-form {
    .form-body {
      padding: 10px;
    }
  }
}
</style>