export default class Background {
  constructor(options) {

    this.LBB_ID = 0;
    this.LBBName = null;
    this.LBBDescription = null;
    this.SkillGroup_ID = 0;

    this.LevelBonus = null;
    this.pz = null;
    this.g20Roll = 0;

    this.high = 0;
    this.mid = 0;
    this.low =  0;
    this.cost = 0;

    if(options) {
      this.init(options);
    }

  }

  init(options) {
    
    this.LBB_ID = parseInt(options.LBB_ID) || this.LBB_ID;
    this.LBBName = options.LBBName || this.LBBName;
    this.LBBDescription = options.LBBDescription || this.LBBDescription;
    this.SkillGroup_ID = parseInt(options.SkillGroup_ID) || this.SkillGroup_ID;

    this.LevelBonus = options.LevelBonus || this.LevelBonus;
    this.pz = options.pz || this.pz;
    this.g20Roll = options.g20Roll || this.g20Roll;

    this.high = parseInt(options.high) || this.high;
    this.mid = parseInt(options.mid) || this.mid;
    this.low =  parseInt(options.low) || this.low;
    this.cost = parseInt(options.cost) || this.cost;

  }

}