<template>
<StoneFrame class="campaigns">

  <div class='header' slot="header">
    <div class="title">GM Campaigns</div>
    <div v-if="isGm">
      <button class="btn-gem green btn-sm" title="New Campaign" @click="newCampaign()">New</button>
    </div>
  </div>

  <div slot="body">

    <!-- List Campaigns -->
    <div v-if="mode=='list'">
      <AnnotationContainer category="warning" v-if="campaigns.length===0">
        You do not have a campaign yet.
      </AnnotationContainer>
      <div class="list-item" v-for="c in campaigns" :key="c.Id">
        <CampaignListing :campaign="c" :select="true"></CampaignListing>
      </div>
    </div>

    <!-- Search Campaigns -->
    <div class="content" v-if="mode=='search'">

      <div class="search-form">
        <input type="text" class="full" placeholder="campaign or GM name" v-model="searchQuery" />  
        <button class="btn-gem blue btn-sm btn-search" @click="runSearch()">
          <font-awesome-icon class="text-light" icon="search" />
        </button>      
      </div>

      <!-- Campaign Search Results -->
      <AnnotationContainer category="warning" v-if="hasSearched && campaignSearchResults.length==0">
        No Campaigns Found
      </AnnotationContainer>

      <div class="scrolling-list" v-if="campaignSearchResults.length>0">
        <div class="card" v-for="c in campaignSearchResults" :key="c.Id">
          <div class="card-content">
            <campaign-listing :campaign="c"></campaign-listing>
            <div class="text-right">
              <a @click="$emit('send-join-request',c)"><small>Send Join Request</small></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="buttons" slot="footer">
    <button class="btn-capped-red btn-sm" :class="{'highlight':mode=='list'}" @click="mode='list'">My Campaigns</button>
    <button class="btn-capped-red btn-sm" :class="{'highlight':mode=='search'}" @click="mode='search'">Find Campaigns</button>
  </div>

</StoneFrame>
</template>

<script>
// -----------------------------------------------------
import CampaignListing from "./CampaignListing";
// -----------------------------------------------------
export default {
  name: "Campaigns",
  components: { CampaignListing },
  computed: {
    isGm() { return this.$store.getters['App/isGm'] },
    campaigns() { return this.$store.state.App.campaigns }
  },
  data() {
    return {
      mode: "list",
      searchQuery: null,
      hasSearched: false,
      campaignSearchResults: []
    };
  },
  methods: {
    runSearch() {
      this.hasSearched = true;
      console.log('campaign search', this.searchQuery);
      this.searchQuery = null;
    }
  }
};
</script>


<style lang="scss" scoped>
.campaigns {
  width: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .title {
      padding: 1px 2px;
    }
  }

  .campaign-name {
    font-size: 20px;
    font-weight: bold;
  }

  .list-item {
    margin-bottom: 5px;
  }

  .hilight {
    background-color: #eee;
  }

  .buttons {
    margin-top:10px;
    padding: 5px 10px 10px 10px;
    text-align: right;
  }

  .search-form {
    display: flex; 
    padding: 10px;   
  }

}

@media screen and (min-width: 768px) {
  .campaigns {
    min-width: 350px;
  }  
}

</style>
