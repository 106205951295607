export default class Difficulty {
  constructor(options) {

    this.Difficulty = null;
    this.DifficultyDescription = null;
    this.EffectMod = null;
    this.SortOrder = 0;

    if(options) {
      this.init(options);
    }

  }

  init(options) {
        
    this.Difficulty = options.Difficulty || this.Difficulty;
    this.DifficultyDescription = options.DifficultyDescription || this.DifficultyDescription;
    this.EffectMod = options.EffectMod || this.EffectMod;
    this.SortOrder = parseInt(options.SortOrder) || this.SortOrder;
    
  }
}