export default class SkillGroup {
  constructor(options) {

    this.SkillGroupName = null;
    this.SkillGroup_ID = 0;
    this.SortOrder = 0;
    this.Stat1 = null;
    this.Stat2 = null;
    this.UseSkillStats = 0;

    if(options) {
      this.init(options);
    } 

  }

  init(options) {

    this.SkillGroupName = options.SkillGroupName || this.SkillGroupName;
    this.SkillGroup_ID = parseInt(options.SkillGroup_ID) || this.SkillGroup_ID;
    this.SortOrder = parseInt(options.SortOrder) || this.SortOrder;
    this.Stat1 = options.Stat1 || this.Stat1;
    this.Stat2 = options.Stat2 || this.Stat2;
    this.UseSkillStats = parseInt(options.UseSkillStats) || this.UseSkillStats;

  }
}