<template>
<MagicFrame v-if="ready"> 

  <div class="level-up-summary">

    <header>
      <div class="title">
        Summary
      </div>
    </header>

    <div class="warnings">
      <AnnotationContainer category="warning" v-if="points > 0">
        You have {{points}} unspent points. You may want to go back and apply them...
      </AnnotationContainer>
      <AnnotationContainer category="error" v-if="points < 0">
        You have overspent by {{Math.abs(points)}} points. Does the GM agree to this?
      </AnnotationContainer>
    </div>

    <StatGainSummary></StatGainSummary>

    <LevelBonusSummary></LevelBonusSummary>

    <SkillSummary></SkillSummary>

    <SpellSummary></SpellSummary>

    <PossessionSummary></PossessionSummary>

    <div class="finish-button">
      <button class="btn-capped-red btn-sm" @click="saveCharacter()">Finish and Save</button>
    </div>

  </div>

</MagicFrame>
</template>

<script>
//------------------------------------------------------
import swal from 'sweetalert'
//------------------------------------------------------
export default {
  name: "LevelUpSummary",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
    points() { return this.forgeState.ledger.spentPointsPool.reduce((acc, item) => acc += item.value, 0); }
  },
  methods: {
    async saveCharacter() {
      let confirm = await swal({
        title: "Finish Character?", text: `Have you made all of the selections you want for this Characrer?`, buttons: true, dangerMode: true,
      })    
      if (confirm) {
        let res = await this.$store.dispatch('Character/applyLedgerAndSave');
        if(res.success) {          
          this.$router.push({name: 'Concourse'});          
          await this.$store.dispatch('Forge/clearForgeState');
        } else {
          console.error(res.message);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.level-up-summary {
  .finish-button {

    text-align: right;
    padding: 10px;
    margin-bottom: 5px;
  }
}
@media screen and (min-width: 768px) { }
</style>