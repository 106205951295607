<template>
<MagicFrame v-if="ready"> 

  <div class="skills-select">

    <header>
      <div class="title">
        Skills
        <G20Help book="GameCanon.pdf" bookmark="Skills"></G20Help>
      </div>
      <div>
        <select class="input-sm" v-model="filter">
          <option value="All">All</option>
          <option value="My Skills">My Skills</option>
          <option value="Important">Important</option>
          <option value="Cost: 1">Cost: 1</option>
          <option value="Cost: 2">Cost: 2</option>
          <option value="Cost: 3">Cost: 3</option>
          <option value="Cost: 4">Cost: 4</option>
          <option value="Cost: 5">Cost: 5</option>
        </select>
      </div>
    </header>
  
    <div class="skill-points">
      <div class="points-pool">
        <div class="points-pool-item" v-for="(spp, index) in spentPointsPool" :key="index">
          <span v-if="spp.rapid">Rapid {{spp.group}}: {{spp.value}}</span>
          <span v-else>{{spp.type}} {{spp.group}}: {{spp.value}}</span>
        </div>
      </div> 
    </div>

    <div class="skill-group-lists" v-if="skillSelectionMatrix">
      <div class="skill-group" v-for="sgk in Object.keys(skillSelectionMatrix)" :key="sgk">
        <div class="heading">
          <div class="skill-group-name">{{sgk}}</div>
          <div class="skill-group-legend">
            <div class="skill-ranks">ranks</div>
            <div class="skill-cost">cost</div>
          </div>
        </div>

        <div ref="skillList" class="skill-list">

          <!-- character Skills -->
          <div v-for="sk in Object.keys(skillSelectionMatrix[sgk].Skill)" :key="sk">

            <a class="character-skill" v-if="skillSelectionMatrix[sgk].Skill[sk].isTemplate===0" @click="newEditSkill(skillSelectionMatrix[sgk].Skill[sk], skillSelectionMatrix[sgk])">
              <div class="skill-name" :class="{'important': skillSelectionMatrix[sgk].Skill[sk].IsImportant===1}">
                {{skillSelectionMatrix[sgk].Skill[sk].SkillName}}
              </div>
              <div class="skill-stats">
                <div class="skill-ranks">
                  {{skillSelectionMatrix[sgk].Skill[sk].Ranks}}
                </div>
                <div class="skill-cost">
                  {{skillSelectionMatrix[sgk].Skill[sk].Cost}}
                </div>  
              </div>
            </a>
            
            <a class="skill" v-if="skillSelectionMatrix[sgk].Skill[sk].isTemplate===1 && skillFilter(skillSelectionMatrix[sgk].Skill[sk])" @click="newEditSkill(skillSelectionMatrix[sgk].Skill[sk], skillSelectionMatrix[sgk])">
              <div class="skill-name" :class="{'important': skillSelectionMatrix[sgk].Skill[sk].IsImportant===1}">
                {{skillSelectionMatrix[sgk].Skill[sk].SkillName}}
              </div>
              <div class="skill-stats">
                <div class="skill-ranks">
                  {{skillSelectionMatrix[sgk].Skill[sk].Ranks}}
                </div>
                <div class="skill-cost">
                  {{skillSelectionMatrix[sgk].Skill[sk].Cost}}
                </div>
              </div>
            </a>
          </div>

        </div>
 
      </div>
    </div>

  </div>

  <Modal :controller="skillModal" @close="skillModal.show=false">
    <SkillForm :payload="skillModal.payload" @close="skillModal.show=false" @save="saveSkill($event)" ></SkillForm>
  </Modal>

</MagicFrame>
</template>

<script>
//------------------------------------------------------
import { setPointPool, createSkillSelectionMatrix } from '../../../code/game/objectFactory'
import { blendSkillLedger, tallySpentPoints } from '../../../code/game/calculations'
//------------------------------------------------------
export default {
  name: "SkillsSelect",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; }
  },
  data() {
    return {
      character: {},
      filter: 'All',
      skillCosts: null,
      skillSelectionMatrix: null,
      skillModal: {
        show: false,
        payload: null
      },
      skillLedger: {},
      spellLedger: {},
      pointsPool: [],
      spentPointsPool: []
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {             
          let character = await this.$store.dispatch('Character/getCharacter', this.forgeState.characterRecordId); 
          this.character = character;

          let pointsPool = this.forgeState.ledger.pointsPool;
          if(!pointsPool) {
            pointsPool = setPointPool(character, this.forgeState.forgeMode);
            this.$store.dispatch('Forge/setLedgerItems', { pointsPool: pointsPool });
          }
          this.pointsPool = pointsPool;
          this.spentPointsPool = pointsPool;

          let skillGroupInfo = await this.$store.dispatch('Content/getSkillCosts', character.Class);
          this.skillCosts = skillGroupInfo.skillCosts;
          this.setState(skillGroupInfo.skillGroups);
        }
      },
      immediate: true
    }
  },
  updated() {
    if(this.$refs.skillList) {
      this.$refs.skillList.forEach(el => {
        let count = 0;
        el.children.forEach(c => {
          count += c.children.length;
        });
        if(count===0){
          el.parentNode.classList.add('hidden');
        } else {
          el.parentNode.classList.remove('hidden');
        }
      });
    }
  },
  methods: {
    
    setState(skillGroups) {

      let skillLedger = this.forgeState.ledger.skillLedger;
      this.spellLedger = this.forgeState.ledger.spellLedger;

      if(!skillLedger) {
        skillLedger = { 
          original: this.$deepClone(this.character.SkillGroups), 
          selected: this.$deepClone(skillGroups), 
          blended: {}
        }
      }
      if(!this.spellLedger) {
        this.spellLedger = { 
          original: this.$deepClone(this.character.SpellLists), 
          selected: [], 
          blended: []
        }
      }
      this.skillLedger = blendSkillLedger(skillLedger);
      this.skillSelectionMatrix = createSkillSelectionMatrix(this.skillLedger, this.skillCosts);
      this.setLedgerItems();
    },

    skillFilter(skill) { 
      switch(this.filter) {
        case 'All': return true;
        case 'My Skills': return false;
        case 'Important': return skill.IsImportant===1;
        case 'Cost: 1': return skill.Cost===1;
        case 'Cost: 2': return skill.Cost===2;
        case 'Cost: 3': return skill.Cost===3;
        case 'Cost: 4': return skill.Cost===4;
        case 'Cost: 5': return skill.Cost===5;
        default: return true;
      }
    },

    newEditSkill(skill, skillGroup) {
      console.log("newEditSkill", skill);
      this.skillModal = {
        show: true,
        payload: { character: this.character, skill, skillGroup }
      }
    },

    saveSkill(skill) {
      console.log("save skill", skill);
      this.skillModal = {
        show: false,
        payload: null
      }
      let skillLedger = this.$deepClone(this.skillLedger);
      if(skill.AddedRanks===0) {
        delete skillLedger.selected[skill.SkillGroupName].Skill[skill.SkillName];
      } else {
        skillLedger.selected[skill.SkillGroupName].Skill[skill.SkillName] = skill;
      }
      this.skillLedger = blendSkillLedger(skillLedger);
      this.skillSelectionMatrix = createSkillSelectionMatrix(this.skillLedger, this.skillCosts);
      this.setLedgerItems();
    },

    async setLedgerItems() {
      this.spentPointsPool = tallySpentPoints({pointsPool: this.pointsPool, skills: this.skillLedger, spells: this.spellLedger});
      await this.$store.dispatch('Forge/setLedgerItems', { 
        skillLedger: this.skillLedger, 
        spellLedger: this.spellLedger,
        spentPointsPool: this.spentPointsPool        
      });
    }
  }
}
</script>

<style lang="scss" scoped>

.skills-select {
  header {
    display: flex;
    justify-content: space-between;
    padding: 3px 0;
    margin-bottom: 3px;
  }
}

.skill-points {
  padding: 10px;
  margin-bottom: 5px;
  border: solid 1px rgba(0,0,0,0.5);
  background-color: rgba(0,0,0,0.25);

  .title {
    margin-bottom: 2px;
  }

  .points-pool {
    display: flex;
    flex-wrap: wrap;
    
    .points-pool-item {
      width: 50%;
      font-size: 12px;
    }
  }

}

.skill-group-lists {
  columns: unset;
}

.skill-group {
  padding: 10px 5px;
  margin-bottom: 5px;
  border: solid 1px rgba(0,0,0,0.5);
  background-color: rgba(0,0,0,0.25);
  break-inside: avoid;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 2px;

    .skill-group-legend {
      display: flex;
      font-size: 10px;
    }
  }

  .skill-list {
    border: solid 1px rgba(0,0,0,0.5);
    background-color: rgba(0,0,0,0.25);
    break-inside: avoid;

    .character-skill,
    .skill {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: rgb(179, 193, 255);
      padding: 0 2px;
      margin: 2px 0;
      cursor: pointer;

      .skill-name.important {
        font-style: italic;
        color: rgb(255, 165, 0);
      }

      .skill-stats {
        display: flex;
      }

    }
    
    .character-skill {
      background-color: rgba(255,255,255,0.1);
      color: rgb(208, 245, 207);
    }

  }

  .skill-ranks,
  .skill-cost {
    width: 35px;
    text-align: center;
  }

}


@media screen and (min-width: 700px) {
  .skill-group-lists {
    columns: 2;
  }
}

@media screen and (min-width: 1000px) {
  .skill-group-lists {
    columns: 3;
  }
}
@media screen and (min-width: 1400px) {
  .skill-group-lists {
    columns: 4;
  }
}

</style>