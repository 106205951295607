<template>
<BrassFrame>
  <div class="campaign-listing" v-if="campaign">
    <div class="image">
      <img :src="campaign.Icon">
    </div>
    <div class="content">
      <div class="title">{{campaign.Title}}</div>
      <div class="description">
        {{campaign.Description}}
      </div>
      <div class="buttons" v-if="select">
        <button v-if="isGm" class="btn-gem blue btn-sm" @click="$emit('select-campaign', campaign)">Edit</button>
        <button class="btn-gem green btn-sm" @click="$emit('select-campaign', campaign)">Select</button>
      </div>
    </div>
  </div>
</BrassFrame>
</template>

<script>
// -----------------------------------------------------
// -----------------------------------------------------
export default {
  name:'CampaignListing',
  computed: {    
    isGm() { return this.$store.getters['App/isGm'] },
  },
  props:{
    campaign: Object,
    select: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>

.campaign-listing {
  display: flex;
  width: 100%;

  .image {

    width: 100px;
    height: 100px;
    margin-right: 10px;
    border: solid 1px #222;
    
    img {
      width: 100%;
      height: 100%;
    }

  }

  .content {
    position: relative;
    flex:1;
    padding-bottom: 28px;

    .title {
      font-size: 18px;
      padding-bottom:10px;
    }
    .description {
      font-size: 14px;
    }
  }

  .buttons {
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: right;
  }
}
</style>