<template>
<div class="character-item">

    <div class="character-item__info">

      <div class="equipment__buttons" v-if="editable">
        <button class="btn-gem btn-sm red" title="remove item" @click="$emit('remove-item', item)">x</button>
        <button class="btn-gem btn-sm blue" title="edit item" @click="$emit('edit-item', item)">?</button>
      </div>

      <div>
        <span class="character-item__corruption" :class="{'active': item.IsEquipped, 'invisible':!isMagic}">{{item.Corruption}}</span>
        <span class="character-item__name">{{item.Name}}</span>
        <span class="character-item__bonus modifier" v-if="item.ItemBonus != 0">{{$formatBonus(item.ItemBonus)}}</span>
        <span v-if="item.Quantity > 0">x {{item.Quantity}}</span>
      </div>

      <div class="character-item__appraisal hidden">({{item.Value}} {{item.WealthUnit}})</div>
    </div>
    <div class="character-item__description">
      {{item.ItemDesc}}
    </div>

</div>
</template>

<script>
export default {
  name:"CharacterItem",
  props:{
    item: Object,
    editable: { type: Boolean, default: false }
  },
  computed: {
    isMagic() { return this.item.Corruption > 0 }
  }
}
</script>


<style lang="scss" scoped>

@import '../styles/base/colors';
@import '../styles/base/typography';

$equipped: #8B0000;
$equipped-border: #8B0000;
$equipped-background: #FFC0CB;
$unequipped: #666;
$unequipped-border: #999;
$unequipped-background: #fff;

.equipment__add-button {
  position: absolute;
  top: 1px;
  right:4px;
}
.character-item {
  position: relative;
  margin-bottom: 5px;
}
.character-item__info { 
  display: flex; 
  justify-content: space-between;
  font-size: 14px;
  font-family: $stat-font;
}

.character-item__corruption {
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  line-height: 20px;
  border: solid 1px $unequipped-border;
  color: $unequipped;
  background-color: $unequipped-background;
  font-weight: bold;
  font-family: $stat-font;
}
.character-item__corruption.active{
  border: solid 1px $equipped-border;
  color: $equipped;
  background-color: $equipped-background;
  font-weight: bold;
}

.character-item__name {
  padding: 0 3px 0 5px;
}

.character-item__bonus {
  font-size: 12px;
}

.character-item__description {
  padding: 0 0 2px 30px;
  font-size: 11px;
}

.equipment__buttons {
  position: absolute;
  top: 1px;
  right: 1px;
}
</style>