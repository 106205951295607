<template>
<div class="realm-summary">
  <span class="label">Realm:</span>
  <span v-if="characterSpellAbility.isHybrid"> Hybrid</span>
  <span> {{characterSpellAbility.PrimaryRealm}}</span>
  <span v-if="characterSpellAbility.isHybrid"> / {{characterSpellAbility.SecondaryRealm}}</span>
  <span> {{characterSpellAbility.SpellAbilityDesc}}</span>   
</div>
</template>

<script>
export default {
  name: "RealmSummary",
  props: {
    characterSpellAbility: Object,
  }
}
</script>

<style lang="scss" scoped>
.realm-summary {
  text-align: center;
  border: solid 1px rgba(0,0,0,0.5);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  margin: 5px;
  color: rgb(179, 193, 255);

  .label {    
    color: #fff;
  }
}
</style>