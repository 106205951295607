<template>
<div class="rain" :class="{'lightning': lightning}"></div>
</template>

<script>
//----------------------------------------
export default {
  name: "FxRainstorm",
  data() {
    return {
      timeout: null,
      lightning: false
    }
  },
  mounted() {
    //this.setupLightningStrike();
  },
  methods: {
    setupLightningStrike() {
      let trigger = this.$getRandomInclusive(5, 10) * 1000;
      //console.log('next flash in ' + trigger/1000 + ' seconds');
      this.timeout = setTimeout(()=> {
        this.lightning = true;

        clearTimeout(this.timeout);
        this.timeout = setTimeout(()=> {
          this.lightning = false;
          this.setupLightningStrike();
        }, 3000);

      }, trigger);
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
    this.timeout = null;
  }
}
</script>