<template>
<FateFrame class="avatar-form">

  <cropper
    class="cropper"
    :src="image"
    :stencil-props="{ aspectRatio: 1/1 }"
    image-restriction="fit-area"
    ref="cropper" 
    @change="cropping"
  ></cropper>

  <div class="load-image-button">
    <span class="btn-capped-red btn-sm" @click="$refs.file.click()">
      <input type="file" ref="file" class="hidden" @change="loadImage($event)" accept="image/*">
      Load image
    </span>
  </div>
  
  <div class="buttons">
    <button class="btn-gem red" @click="close()"> Cancel </button>
    <button class="btn-gem green" @click="completeCrop()">Crop </button>
  </div>
  
</FateFrame>
</template>

<script>
//--------------------------------------------------------------
import { Cropper } from 'vue-advanced-cropper'
import { imageToCanvas } from '../../../code/utils/canvasImaging'
//--------------------------------------------------------------
export default {
  name: "AvatarForm",
  components: { Cropper },
  data() {
    return {
      image: null,
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
    }
  },
  methods: {
    
    loadImage(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },

    cropping({ coordinates }) {
      this.coordinates = coordinates;
    },
    
    completeCrop() {

      if(!this.image) { return; }

      const { coordinates, canvas, } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      let croppedImage = canvas.toDataURL();      

      imageToCanvas(croppedImage, 256, 256).then(cvs => {
        let finalImage = cvs.toDataURL();
        this.$emit('image-data', finalImage);
        this.reset();
      });
    
    },

    close() {
      this.reset();
      this.$emit('close');
    },

    reset() {
      this.image = null;
      this.$refs.file.value = null;
    }

  }

}
</script>

<style lang="scss" scoped>
.avatar-form { 

  .cropper {
    margin: 0 auto;
    width: 320px;
    max-height: 400px;
  }

  .load-image-button {
    margin: 5px 0;
    text-align: center;
  }

  .buttons {
    text-align: right;
  }
}

@media screen and (min-width: 550px ) {  
  .avatar-form { 
    .cropper {
      width: 100%;
      max-height: 500px;
    }
  }
}
</style>