<template>
<MagicFrame v-if="ready"> 

  <div class="character-info">

    <header>
      <div class="title">
        Your Character Profile
        <G20Help book="GameCanon.pdf" bookmark="Your Character Profile"></G20Help>
      </div>
    </header>

    <div class="character-info-form" v-if="characterInfo">

      <div class="split-col-lg">
        <div class="form-group">
          <label for="character-name"><strong>Character Name</strong></label>
          <input id="character-name" type="text" class="input-sm full" autocomplete="off" v-model="characterInfo.Name" @input="saveInfo()">
        </div>
        <div class="form-group">
          <label for="character-title"><strong>Character's Title</strong></label>
          <input id="character-title" type="text" class="input-sm full" autocomplete="off" v-model="characterInfo.Title" @input="saveInfo()">
        </div>
      </div>

      <div class="split-col-lg">
        <div class="split-col">
          <div class="form-group">
            <label for="character-age"><strong>Age</strong></label>
            <input id="character-age" type="text" class="input-sm full" autocomplete="off" v-model="characterInfo.Age" @input="saveInfo()">
          </div>
          <div class="form-group">
            <label for="character-gender"><strong>Gender</strong></label>
            <input id="character-gender" type="text" class="input-sm full" autocomplete="off" v-model="characterInfo.Gender" @input="saveInfo()">
          </div>
        </div>
        <div class="split-col">
          <div class="form-group">
            <label for="character-hair"><strong>Hair</strong></label>
            <input id="character-hair" type="text" class="input-sm full" autocomplete="off" v-model="characterInfo.Hair" @input="saveInfo()">
          </div>
          <div class="form-group">
            <label for="character-eyes"><strong>Eyes</strong></label>
            <input id="character-eyes" type="text" class="input-sm full" autocomplete="off" v-model="characterInfo.Eyes" @input="saveInfo()">
          </div>
        </div>
        <div class="split-col">
          <div class="form-group">
            <label for="character-height"><strong>Height</strong></label>
            <input id="character-height" type="text" class="input-sm full" autocomplete="off" v-model="characterInfo.Height" @input="saveInfo()">
          </div>
          <div class="form-group">
            <label for="character-weight"><strong>Weight</strong></label>
            <input id="character-weight" type="text" class="input-sm full" autocomplete="off" v-model="characterInfo.Weight" @input="saveInfo()">
          </div>
        </div>
      </div>
      
      <div class="form-group">
        <label for="character-appearance"><strong>Outward Appearance</strong></label>
        <textarea id="character-appearance" rows="4" class="input-sm full" autocomplete="off" v-model="characterInfo.OutwardAppearance" @input="saveInfo()"></textarea>
      </div>

      <div class="form-group">
        <label for="character-backstory"><strong>Story</strong></label>
        <textarea id="character-backstory" rows="4" class="input-sm full" autocomplete="off" v-model="characterInfo.Backstory" @input="saveInfo()"></textarea>
      </div>

    </div>    

  </div>

</MagicFrame>
</template>

<script>
//------------------------------------------------------
import g20CharacterModels from '../../../code/models/g20CharacterModels';
const timeoutVal = 1000;
//------------------------------------------------------
export default {
  name: "CharacterInfo",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
  },
  data() {
    return {
      characterInfo: null,
      characterSkills: null,
      timeout: null
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {            
          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {
    
    async setState() {

      let characterClass = this.forgeState.ledger.characterClass;
      let characterWeaponGroups = this.forgeState.ledger.characterWeaponGroups;      
      this.characterInfo = this.forgeState.ledger.characterInfo;

      let updateLedger = false;
      if(!this.forgeState.ledger.characterSkills) {
        this.characterSkills = await this.$store.dispatch('Content/initCharacterSkillGroups', { characterClass, characterWeaponGroups });
        updateLedger = true;
      }

      if(!this.characterInfo) {
        this.characterInfo = new g20CharacterModels.Info();
        updateLedger = true;
      }

      if(updateLedger) {
        this.setLedgerItems();
      }

    },

    saveInfo() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.setLedgerItems();
        clearTimeout(this.timeout);
      }, timeoutVal);
    },
    
    setLedgerItems() {
      this.$store.dispatch('Forge/setLedgerItems', { 
        characterInfo: this.characterInfo,
        characterSkills: this.characterSkills
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.character-info-form {  
  border: solid 1px rgba(0,0,0,0.5);
  background-color: rgba(0,0,0,0.4);
  margin-top: 10px;
  padding: 5px 10px;

  .split-col {
    display: flex;
  }
  .split-col > div {
    flex: 1;
  }
  .split-col > div:first-child {
    padding-right: 5px;
  }
  .split-col > div:last-child {
    padding-left: 5px;
  }
}

@media screen and (min-width: 768px) {
  .character-info-form {    
    padding: 10px 20px;

    .split-col-lg {
      display: flex;
    }
    .split-col-lg > div {
      flex: 1;
      padding: 0 5px;
    }
    .split-col-lg > div:first-child {
      padding: 0;
      padding-right: 5px;
    }
    .split-col-lg > div:last-child {
      padding: 0;
      padding-left: 5px;
    }
  }
}
</style>