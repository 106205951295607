<template>
<SimplePanel class="backgrounds" v-if="character">
  
  <div slot="header"> Origin </div>

  <div slot="body">

    <div class="background-item" v-if="character.Backgrounds.Inheritance.Inheritance">
      <div class="title"> 
        <div>Inheritance:</div>
        <div>{{character.Backgrounds.Inheritance.Inheritance}}</div> 
      </div>
      <div class="notes">
        ({{character.Backgrounds.Inheritance.SkillPoints}} / {{character.Backgrounds.Inheritance.RapidPoints}} | {{character.Backgrounds.Inheritance.StartingPossessions}}
      </div>
    </div>


    <div class="background-item" v-if="character.Backgrounds.Family.LBBName">
      <div class="title"> 
        <div>Family:</div> 
        <div>{{character.Backgrounds.Family.LBBName}} </div>
      </div>
      <div class="notes"> ({{character.Backgrounds.Family.LBBDescription}}) </div>
    </div>

    <div class="background-item" v-if="character.Backgrounds.Adolescence.LBBName">
      <div class="title"> 
        <div>Adolescence:</div> 
        <div>{{character.Backgrounds.Adolescence.LBBName}}</div>
      </div>
      <div class="notes">
        ({{character.Backgrounds.Adolescence.LBBDescription}})
      </div>
    </div>

    <div class="background-item" v-if="character.Backgrounds.YoungAdult.LBBName">
      <div class="title">
        <div>Young Adult:</div>
        <div>{{character.Backgrounds.YoungAdult.LBBName}}</div>
      </div>
      <div class="notes">
        ({{character.Backgrounds.YoungAdult.LBBDescription}})
      </div>
    </div>

    <div class="background-item" v-if="character.Backgrounds.ChosenPath.LBBName">
      <div class="title"> 
        <div>Chosen Path:</div> 
        <div>{{character.Backgrounds.ChosenPath.LBBName}}</div>
      </div>
      <div class="notes">
        ({{character.Backgrounds.ChosenPath.LBBDescription}})
      </div>
    </div>

    <div class="background-item" v-for="s in character.Specials" :key="s.ID">
      <div class="title">
        <div>{{s.Name}}</div>
      </div>
      <div class="notes">
        {{s.Description}}
      </div>
    </div>

    
  </div>
  
</SimplePanel>
</template>

<script>
//----------------------------------------------------------------------------------
//----------------------------------------------------------------------------------
export default {
  name:"Backgrounds",
  props: {
    value: Object
  },
  watch: {    
    value: {
      handler() { this.character = this.$deepClone(this.value) },
      immediate: true
    },
  },
  data() {
    return {
      character: null
    }
  }
}
</script>

<style lang="scss" scoped>
.backgrounds {
  .background-item {
    .title {
      font-size: 14px;
      display: flex;
      &> div:first-child {
        font-weight: bold;
        width: 90px;
      }
    }
    .notes {
      font-size: 11px;
      padding: 3px;
      display: none;
    } 
  } 
}
@media screen and (min-width: 400px) {
  .backgrounds {
    .background-item {
      margin-bottom: 2px;
      .notes {
        display: block;
      }  
    }
  }
}
</style>