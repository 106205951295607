<template>
<SimplePanel v-if="character" class="wealth" :class="{'editable': editable}">

  <div slot="header" @click.stop="editable=true">Wealth</div>

  <div slot="body" @click.stop="editable=true">

    <div class="command-buttons" v-if="editable">
      <button class="btn-gem green btn-sm" title="Save Character" @click.stop="saveCharacter()">Save</button>
      <button class="btn-gem red btn-sm" title="Cancel" @click.stop="cancel">Cancel</button>
    </div>

    <div class="columns">    
      <div class="column" v-for="c in currencies" :key="c">
        <NumericStepper v-if="editable" class="stepper" :min="0" v-model="character.Wealth.Currency[c].Quantity" />
        <span v-else>{{character.Wealth.Currency[c].Quantity}}</span>
        <div class="label">{{character.Wealth.Currency[c].label}}</div>
      </div>
    </div>

    <div class="other-treasure">
      <div>Other Treasure: </div>
      <div class="other-treasure-listing">
        <EditableContent v-if="editable" v-model="character.Wealth.Other" :allow-enter="true" />
        <span v-else>{{character.Wealth.Other}}</span>      
      </div>
    </div>
  </div>

</SimplePanel>
</template>

<script>
//------------------------------------------------------
export default {
  name:"Wealth",
  props: {
    value: Object
  },
  watch: {    
    value: {
      handler() { this.character = this.$deepClone(this.value) },
      immediate: true
    },
  },
  data() {
    return {
      editable: false,
      character: null,
      currencies: ['bp','sp','gp','mp']
    }
  },
  methods: {
 
    cancel() {
      this.editable=false;
      this.$emit('cancel');
    },

    saveCharacter() {
      this.editable = false;
      this.$emit('save-character', this.character);
    }

  }
}
</script>


<style lang="scss">
.wealth {  

  .command-buttons {
    margin-bottom: 5px;
    text-align: right;
  }

  .columns {
    display: flex;
    justify-content: space-around;
    padding: 5px;
    width: 100%;

    .column {
      text-align: center;
      padding: 10px;
      font-size: 20px;

      .label {
        font-size: 12px;
      }
    }
  }

  .other-treasure {
    padding: 5px;

    .other-treasure-listing {
      padding: 5px;
      font-size: 12px;
    }
  }

}
</style>