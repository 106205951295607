export default class Attribute {
  constructor(options) {
    
    this.Name = null;
    this.Code = null;
    this.Description = null;
    this.SortOrder = 0;
    this.Current = 0;
    this.Potential = 0;
    this.RacialBonus = 0;
    this.Misc = 0;
    this.PrimaryBonus = 0;
    this.SecondaryBonus = 0;
    this.PrimaryTotal = 0;
    this.SecondaryTotal = 0;
    
    if(options) {
      this.init(options);
    }

  }

  init(options) {

    this.Name = options.Name || this.Name;
    this.Code = options.Code || this.Code;
    this.Description = options.Description || this.Description;
    this.SortOrder = parseInt(options.SortOrder) || this.SortOrder;
    this.Current = parseInt(options.Current) || this.Current;
    this.Potential = parseInt(options.Potential) || this.Potential;
    this.RacialBonus = parseInt(options.RacialBonus) || this.RacialBonus;
    this.Misc = parseInt(options.Misc) || this.Misc;
    this.PrimaryBonus = parseInt(options.PrimaryBonus) || this.PrimaryBonus;
    this.SecondaryBonus = parseInt(options.SecondaryBonus) || this.SecondaryBonus;
    this.PrimaryTotal = parseInt(options.PrimaryTotal) || this.PrimaryTotal;
    this.SecondaryTotal = parseInt(options.SecondaryTotal) || this.SecondaryTotal; 

  }

}