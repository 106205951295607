<template>
<a class="help-link" href="#" @click.prevent="goToBookmark()">
  <img src="/img/svg/question.svg" />
</a>  
</template>

<script>
export default {
  name: "g20Help",
  props: {
    bookmark: String,
    book: String
  },
  methods: {
    goToBookmark() {
      let url = `http://g20gaming.net/canon/${this.book}#${this.bookmark}`;
      window.open(url);
    }
  }
}
</script>

<style lang="scss" scoped>
.help-link {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  padding-bottom: 5px;

  img {
    width: 22px;
    height: auto;
  }
}
</style>