export default class LevelBonusBackground {
  constructor(options) {

    this.LBBDescription = null;
    this.LBBName = null;
    this.LBB_ID = 0;
    this.LevelBonus = null;
    this.SkillGroup_ID = 0;
    this.g20Roll = 0;
    this.pz = null;
    
    if(options) {
      this.init(options);
    }    

  }

  init(options) {

    this.LBBDescription = options.LBBDescription || this.LBBDescription;
    this.LBBName = options.LBBName || this.LBBName;
    this.LBB_ID = parseInt(options.LBB_ID) || this.LBB_ID;
    this.LevelBonus = options.LevelBonus || this.LevelBonus;
    this.SkillGroup_ID = parseInt(options.SkillGroup_ID) || this.SkillGroup_ID;
    this.g20Roll = parseInt(options.g20Roll) || this.g20Roll;
    this.pz = options.pz || this.pz;

  }
}