<template>
<div class="triad-roll">

  <div class="triad-row">

    <div class="triad-fate">
      <FateFrame class="fate" v-if="fate">
        <div class="total">{{fate - spentFate}}</div>
        <div class="reference">
          <div>{{fate}}</div>
          <div>{{spentFate}}</div>
        </div>
      </FateFrame>
    </div>


    <div class="die-rolls" v-if="triad">
      <div class="number-set">
        <div class="label"><label for="high-roll">High</label></div>
        <div> <input name="high-roll" class="input-sm" type="number" :min="min" :max="max" :value="triad.high" :disabled="!enabled" @input="setTriad('high', $event.target.value)" /> </div>
      </div>
      <div class="number-set">
        <div class="label"><label for="mid-roll">Chosen by Fate</label></div>
        <div> <input name="mid-roll" class="input-sm" type="number" :min="min" :max="max" :value="triad.mid" :disabled="!enabled" @input="setTriad('mid', $event.target.value)" /> </div>
      </div>
      <div class="number-set">
        <div class="label"><label for="low-roll">Low</label></div>
        <div> <input name="low-roll" class="input-sm" type="number" :min="min" :max="max" :value="triad.low" :disabled="!enabled" @input="setTriad('low', $event.target.value)" /> </div>
      </div>
    </div>

  </div>

  <div class="roll-dice-button">
    <button class="btn-capped-red btn-sm" :disabled="!enabled" @click="rollDice()" title="Let the Fates Decide">
      Let the Fates Decide
    </button>  
  </div>

</div>
</template>

<script>
//------------------------------------------------------
//------------------------------------------------------
export default {
  name: "TriadRoll",

  props: { 
    triad: { type: Object, required: true },
    enabled: { type: Boolean, default: true },
    fate: { type: Number, required: true },
    spentFate: { type: Number, required: true },
    min: { type: Number, default: 1 },
    max: { type: Number, default: 25 }
  },

  methods: {
    setTriad(prop, val) {
      let triad = {...this.triad};
      triad[prop] = parseInt(val);
      this.$emit('triad-changed', triad);
    },
    rollDice() {
      this.$emit('dice-rolled', this.$rollTriad());
    }
  }
}
</script>

<style lang="scss" scoped>
$artworkRoot: '/img/artwork/';
.triad-roll {
  width: 100%;

  .triad-row {    
    display: flex;
  }

  .triad-fate {
    font-size: 12px;
    text-align: center;
    margin-left: 5px;

    .fate {
      position: relative;
      width: 90px;
      height: 80px;
      font-size: 30px;

      .total {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 5px;
        left: 0;
        right: 0;
      }
      .reference {
        position: absolute;
        bottom: -4px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        font-size: 12px;      
        color: #ccc;
      }      
    }

  }

  .die-rolls {
    flex: 1;
    margin-left: 10px;
  }

  .number-set {
    display: flex;
    justify-content: flex-end;
    padding: 2px 0;

    .label {
      font-weight: normal;
      margin-right: 10px;
    }
  }
  .roll-dice-button {
    padding: 5px;
    text-align: center;
  }
}

@media screen and (min-width: 768px) {


}
</style>