
export default class SpecialAbility {
  constructor(options) {

    this.ID = 0;
    this.Name = null;
    this.Description = null;
    this.SpecialAbilityType = null;
    this.g20Roll = 0;

    if(options) {
      this.init(options);
    }

  }

  init(options) {

    this.ID = parseInt(options.ID) || this.ID;
    this.Name = options.Name || this.Name;
    this.Description = options.Description || this.Description;
    this.SpecialAbilityType = options.SpecialAbilityType || this.SpecialAbilityType;
    this.g20Roll = parseInt(options.g20Roll) || this.g20Roll;
    
  }

}