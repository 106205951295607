export default class Inheritance {
  constructor(options) {

    this.Inheritance = null;
    this.RapidPoints = null;
    this.SkillPoints = null;
    this.StartingPossessions = null;

    this.g20Roll =  null;
    this.high = 0;
    this.mid = 0;
    this.low =  0;
    
    this.cost = 0;

    if(options) {
      this.init(options);
    }
    
  }

  init(options) {
    
    this.Inheritance = options.Inheritance || this.Inheritance;
    this.RapidPoints = options.RapidPoints || this.RapidPoints;
    this.SkillPoints = options.SkillPoints || this.SkillPoints;
    this.StartingPossessions = options.StartingPossessions || this.StartingPossessions;

    this.g20Roll = options.g20Roll || this.g20Roll;
    this.high = options.high || this.high;
    this.mid = options.mid || this.mid;
    this.low = options.low || this.low;

    this.cost = options.cost || this.cost;

  }

}