import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'vue-advanced-cropper/dist/style.css';
import "./ui/styles/main.scss";

import './code/utils/g20UtilsLink';

Vue.config.productionTip = false


import forgeUi from './ui/forgeUi';
Vue.use(forgeUi);

import fonts from './code/utils/fonts';  // may remove this in favor of custom SVG icons
Vue.use(fonts);

import validations from "./code/utils/validations"
validations.ValidationSetup();


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
