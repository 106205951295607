<template>
<div style="padding: 20px 30px;">

  <div>
    <a href="#" @click.prevent="show='buttons'">Buttons</a> | 
    <a href="#" @click.prevent="show='forms'">Forms</a> | 
  </div>
  
  <div v-if="show==='forms'">
    <h3>Forms</h3>
    <section>
      <div class="title">Form, Default sizes (width:450px) : [click to focus]</div>

      <form style="width: 450px;">
        <fieldset>

          <legend>
            This is a legend.
          </legend>

          <div class="form-group">
            <label>Default Text Input</label>
            <input type="text"  value="filled with value" >
          </div>

          <div class="form-group">
            <label>Default Text Input</label>
            <input type="text"  placeholder="filled with placeholder" >
          </div>

          <div class="form-group">
            <label>Default Number Input</label>
            <input type="number" size='2' value="0">
          </div>

          <div class="form-group">
            <label class="required">Default Input: Invalid, required label</label>
            <input type="text" class="invalid" placeholder="filled with placeholder" >
          </div>

          <div class="form-group">
            <label>Disabled Text Input</label>
            <input type="text"  disabled="disabled" value="disabled" >
          </div>

          <div class="form-group">
            <label>Default Number Input</label>
            <input type="number" disabled="disabled" value="0" >
          </div>

          <div class="form-group">
            <label>Default Input .full</label>
            <input type="text" class="full" placeholder="full width" >
          </div>
          
          <div class="form-group">
            <label>Default Select</label>
            <select>
              <option>Option 1</option>
              <option>Option 2</option>
            </select>
          </div>

          <div class="form-group">
            <label>Default Select .full</label>
            <select class="full" disabled="disabled">
              <option>Option 1</option>
              <option>Option 2</option>
            </select>
          </div>

        </fieldset>
      </form>

    </section>


    <section>
      <div class="title">Form (width:450px) : [click to focus]</div>

      <form style="width: 450px;">
        <fieldset>

          <legend>
            Other Props and Sizes.
          </legend>

          <div class="form-group">
            <label>Small Input (input-lg)</label>
            <input class="input-lg" type="text" value="value" >
          </div>

          <div class="form-group">
            <label>Small Input (input-sm)</label>
            <input class="input-sm" type="text" value="value" >
          </div>

          <div class="form-group">
            <label>light Input (.light)</label>
            <input class="light" type="text" value="value" >
          </div>

          <div class="form-group">
            <label>Small Select</label>
            <select class="input-sm">
              <option>Option 1</option>
              <option>Option 2</option>
            </select>
          </div>

        </fieldset>
      </form>
    </section>


    <section>
      <div class="title">Form (width:450px) : [click to focus]</div>

      <form style="width: 550px;">
        <fieldset>

          <legend>
            Input Groups.
          </legend>

          <div class="input-group">
            <div class="addon">              
              <label class="label-sm">Input Group: </label>
            </div>
            <input type="text" value="value" >
            <select>
              <option>Option 1</option>
              <option>Option 2</option>
            </select>
            <button class="btn-gem">Button</button>
          </div>
        </fieldset>
      </form>
    </section>

  </div>

  <!-- BUTTONS -->
  <div v-if="show==='buttons'">
    <h3>Buttons</h3>
    <section>
      <div class="title">Gem Buttons</div>
      <button class="btn-gem btn-lg">Button</button>
      <button class="btn-gem">Button</button>
      <button class="btn-gem btn-sm">Button</button>
      <button class="btn-gem btn-xs">Button</button>
    </section>
    <section>
      <section>
        <div class="title">Style: block (container: 300px)</div>
        <div style="width: 300px">
          <button class="btn-gem btn-lg block">Button</button>
          <button class="btn-gem block">Button</button>
          <button class="btn-gem btn-sm block">Button</button>
          <button class="btn-gem btn-xs block">Button</button>
        </div> 
      </section>
      <section>
        <div class="title">Style: full (container: 300px)</div>
        <div style="width: 300px">
          <button class="btn-gem btn-lg full">Button</button>
          <button class="btn-gem full">Button</button>
          <button class="btn-gem btn-sm full">Button</button>
          <button class="btn-gem btn-xs full">Button</button>
        </div> 
      </section>
    </section>
    <section>
      <div class="title">Gem Buttons</div>
      <button class="btn-gem red">Button</button>
      <button class="btn-gem green">Button</button>
      <button class="btn-gem blue">Button</button>
      <button class="btn-gem yellow">Button</button>
    </section>
    <section>
      <div class="title">Gem Buttons: .active</div>
      <button class="btn-gem red active">Button</button>
      <button class="btn-gem green active">Button</button>
      <button class="btn-gem blue active">Button</button>
      <button class="btn-gem yellow active">Button</button>
    </section>
    <section>
      <div class="title">Gem Buttons: .highlight</div>
      <button class="btn-gem red highlight">Button</button>
      <button class="btn-gem green highlight">Button</button>
      <button class="btn-gem blue highlight">Button</button>
      <button class="btn-gem yellow highlight">Button</button>
    </section>
    <section>
      <div class="title">Gem Buttons: :disabled</div>
      <button class="btn-gem red" disabled="disabled">Button</button>
      <button class="btn-gem green" disabled="disabled">Button</button>
      <button class="btn-gem blue" disabled="disabled">Button</button>
      <button class="btn-gem yellow" disabled="disabled">Button</button>
    </section>
  </div>


</div>
</template>

<script>
export default {
  name: "_controls",
  data() {
    return {
      show: 'forms'
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  margin: 15px 0;
}
section > div.title {
  margin: 10px 0;
}
</style>