export default class Vice {
  constructor(options) {

    this.Vice_ID = 0;
    this.ViceName = null;
    this.ViceDescription = null;
    this.pz = null;

    if(options) {
      this.init(options)
    }

  }

  init(options) {

    this.Vice_ID = parseInt(options.Vice_ID) || this.Vice_ID;
    this.ViceName = options.ViceName || this.ViceName;
    this.ViceDescription = options.ViceDescription || this.ViceDescription;
    this.pz = options.pz || this.pz;

  }

}