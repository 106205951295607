//-----------------------------------------------
import Dexie from 'dexie'
import axios from 'axios'
import { contentSchema } from './Schemas'
import { sanitizeData } from './services/SanitizeContent'
import * as g20DbProfile from '../app/g20DbProfile'
const apiRoot = process.env.VUE_APP_API_SERVER;
const debug = false;
//-----------------------------------------------
export default class ContentDatabase {
  //----------------------------
  constructor() {
    this.db = null;
    this.init();
  }
  //----------------------------
  async init() {

    //----------------------------
    let serverVersionResponse = await axios.get(apiRoot + '/version.php');
    let serverVersion = parseInt(serverVersionResponse.data.Version);
    let profile = g20DbProfile.getProfile();
    //-------------
    if(!profile) {
      profile = { version: serverVersion, hasData: false }
    } else {
      if(profile.version === serverVersion) {
        console.log('db version is up to date');
      } else {
        await this.deleteDatabase();
        profile = { version: serverVersion, hasData: false }
      }
    }
    //----------------------------
    //console.log(profile);
    //----------------------------
    //-------------------  set schema
    this.db = new Dexie('g20Content');
    this.db.version(profile.version).stores(contentSchema);
    //-------------------  map to classes
    let tables = Object.keys(contentSchema);
    //-------------------  populate with data from DB
    this.db.on("ready", async () => {
      //-------------------
      if(profile.hasData) {
        console.log('tables exist in db - no need to create');
        return;
      }
      //-------------------
      return await axios.get(apiRoot + '/getdata.php').then(res => {
        let dbOps = [];
        let data = sanitizeData(res.data);
        //-------------------
        tables.forEach((table) => {
          dbOps.push(this.db[table].count(num =>{
            if(debug) { console.log('table records: ' + num); }
            if(num===0) {       
              console.log('creating table: ' + table);    
              return this.db[table].bulkAdd(data[table]);
            }
          }));
        });
        //-------------------
        profile.hasData = true;
        g20DbProfile.setProfile(profile);
        //-------------------
        return Promise.all(dbOps);
      });
      //-------------------
    });
    //-------------------
    this.db.open();
  }
  //-----------------------------------------------
  //  DB Operations
  //-----------------------------------------------
  //  lists
  //-----------------------------------------------
  async listAll(tableName, sortBy) {
    let list = [];
    if(sortBy) {      
     list = await this.db[tableName].orderBy(sortBy).toArray();
    } else {      
     list = await this.db[tableName].toArray();
    }
    return list; 
  }
  
  async listAllIn(tableName, query) {
    let key = Object.keys(query)[0];
    let value = Object.values(query)[0];  
    return await this.db[tableName].where(key).anyOf(value).toArray(); 
  }

  async listWhere(tableName, query) {
    let list = await this.db[tableName].where(query).toArray();
    return list; 
  }

  async listDistinct(tableName, property) {
    let list = await this.db[tableName].toArray(); 
    return this.getDistinct(list, property);
  }

  async listWhereDistinct(tableName, property, query) {
    let key = Object.keys(query)[0];
    let value = Object.values(query)[0];
    let list = await this.db[tableName].where(key).anyOf(value).toArray();
    return this.getDistinct(list, property);
  }

  async getById(tableName, id) {
    let item = await this.db[tableName].get(id);
    return item; 
  }

  async getByQuery(tableName, query) {
    let item = await this.db[tableName].get(query);
    return item; 
  }

  //------------------------------------------------  specific
  async getClasses() {
    return await this.db.Class.where({IsActive:1}).toArray();
  }

  async getSpellLists() {

    return this.db.transaction('r', this.db.Realm, this.db.SpellListType, this.db.SpellList, this.db.Class, async () => {

      let activeClasses = await this.db.Class.where({IsActive:1}).toArray();

      let objects = {
        realms: await this.db.Realm.where({IsRealm:1}).toArray(),
        spellListTypes: await this.db.SpellListType.where('TypeDesc').noneOf(['Class', 'Obsolete']).toArray(),
        spellLists: await this.db.SpellList.toArray(),
        classes: activeClasses.map(c => c.ClassName)
      }

      return objects;

    });

  }

  async getSpells(spellListId) {
    return await this.db.Spell.where('SpellListID').equals(spellListId).sortBy('Level');
  }  

  async getArmorDamage(code) {
    let obj = await this.db.ManeuverRoll.where('Difficulty').equals(code).toArray();
    let result = obj.find(o => o.Result==='S');
    return result.g20Roll;  
  }
 
  //------------------------------------------------  private

  getDistinct(list, property) {
    let array = [];
    list.forEach(item => {
      if(array.indexOf(item[property]) < 0) {
        array.push(item[property]);
      }
    });
    return array.sort();
  }

  deleteDatabase() {
    return new Promise((resolve, reject) => {
      let req = indexedDB.deleteDatabase('g20Content');
      req.onsuccess = () => { resolve() }
      req.onerror = () => { reject() }
    });
  }
}