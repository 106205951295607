<template>
<div class="spell-list-detail">
  <header>
    <div class="title">
      {{spellList.SpellListName}}
    </div>
    <div>
      <small v-if="tomeUnlocked(spellList)">~ Unlocked ~&nbsp;&nbsp;</small>
      <button v-else type="button" class="btn-gem red btn-sm" @click="$emit('unlock', spellList)">Cost to Unlock: {{spellListCost}}</button>
      <button type="button" class="btn-gem blue btn-sm" @click.prevent="$emit('back')">Back</button>
    </div>
  </header>

  <BrassFrame class="note" v-if="spellList.ListNote">
    {{spellList.ListNote}}
  </BrassFrame>

  <div class="list-details">
    <SpellDetail v-for="s in spellList.Spells" 
      :key="s.SpellID" :spell="s" :spell-ability="character.SpellAbility"
      :tome-unlocked="tomeUnlocked(spellList)"
      :spell-known="spellKnown(s)"
      @select="$emit('spell-select', $event)">
    </SpellDetail>
  </div>

  <div class="bottom-button">
    <button type="button" class="btn-gem blue btn-sm" @click.prevent="$emit('back')">Back</button>
  </div>
</div>
</template>

<script>
//--------------------------------------------------------------------
import { getSpell, tomeUnlocked } from '../../../code/game/calculations'
//--------------------------------------------------------------------
export default {
  name: "SpellListDetail",
  props: {
    spellList: Object,
    character: Object,
    spellAbility: Object
  },

  computed: {
    spellLedger() { return this.$store.state.Forge.forgeState.ledger.spellLedger; },
    spellListCost() {      
      let hybridCost = this.spellAbility.isHybrid ? this.spellAbility.ExtraHybridCost : 0;
      return this.spellList.ListCost + hybridCost;
    }
  },

  methods: {
    spellKnown(sp) {
      let known = false;
      if(sp.SpellName) {
        let spell = getSpell(this.spellLedger, sp);
        return spell ? true : false;        
      }
      return known;
    },
    tomeUnlocked() {
      return tomeUnlocked(this.spellLedger, this.spellList)
    }
  }
}
</script>


<style lang="scss" scoped>

.spell-list-detail {
  header {
    display: flex;
    justify-content: space-between;
    margin: 5px 0 10px 0;
  }

  .note {
    background-color: rgba(0,0,0,0.5);
    margin: 10px 5px;
    font-size: 12px;
    white-space: pre-wrap;
  }

  .bottom-button {
    text-align: right;
    margin: 10px 5px;
  }
}
</style>