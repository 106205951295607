<template>
<MagicFrame v-if="ready"> 

  <div class="race-selection">

    <header>
      <div class="title">
        Your Race
        <G20Help book="GameCanon.pdf" bookmark="Choose Your Race"></G20Help>
      </div>

      <div class="multi-race-selection">
        <label>
          <small class="checkbox-label">Multi Race</small> 
          <input type="checkbox" v-model="multiRace" @change="multiRaceChanged()" />
        </label>
      </div>
    </header>

    <div class="race-selections">

      <div>
        <label for="PrimaryRace"><strong>Primary Race</strong></label>
        <select class="full" name="PrimaryRace" v-model="primaryRaceName" @change="primaryRaceNameChanged()">
          <option :value="null">~ please select ~</option>
          <option v-for="r in primaryRaces" :key="r.RaceName" :value="r.RaceName">({{r.RaceGroup}}): {{r.RaceName}}</option>
        </select>
      </div>

      <div v-if="multiRace">
        <label for="SecondaryRace"><strong>Secondary Race</strong></label>
        <select class="full" name="SecondaryRace" v-model="secondaryRaceName" @change="secondaryRaceNameChanged()">
        <option :value="null">~ none selected ~</option>
          <option v-for="r in secondaryRaces" :key="r.RaceName" :value="r.RaceName">({{r.RaceGroup}}): {{r.RaceName}}</option>
        </select>
      </div>
    </div>

    <RaceSummary :character-race="characterRace"></RaceSummary>

  </div>

</MagicFrame>
</template>

<script>
//------------------------------------------------------
import { 
  createCharacterRace, 
  createCharacterAttributes, 
  createCharacterResists, 
  createCharacterStatPool 
} from '../../../code/game/objectFactory'
//------------------------------------------------------
export default {
  name: "RaceSelect",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
    primaryRaceDesc() { return this.primaryRace ? this.primaryRace.RaceDesc : null }
  },
  data() {
    return {
      multiRace: false,
      stats: [],
      primaryRaces: [],
      primaryRaceName: null,
      primaryRace: null,
      secondaryRaces: [],
      secondaryRaceName: null,
      secondaryRace: null,
      characterRace: null,
      characterAttributes: null,
      characterResists: null,
      characterStatPool: null
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {            
          this.primaryRaces = await this.$store.dispatch('Content/getRaces');
          this.stats = await this.$store.dispatch('Content/getAttributes');
          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {

    setState() {

      this.characterRace = this.forgeState.ledger.characterRace;
      this.characterAttributes = this.forgeState.ledger.characterAttributes;
      this.characterResists = this.forgeState.ledger.characterResists;

      // create once - no altering
      if(this.forgeState.ledger.characterStatPool) {
        this.characterStatPool = this.forgeState.ledger.characterStatPool;
      } else {
        this.characterStatPool = createCharacterStatPool();
      }

      if(this.forgeState.ledger.characterRace) {
        this.filterSecondaryRaces();
        this.multiRace = this.forgeState.ledger.characterRace.MultiRace;  
        this.primaryRaceName = this.forgeState.ledger.characterRace.PrimaryRaceName;
        this.secondaryRaceName = this.forgeState.ledger.characterRace.SecondaryRaceName;        
      }
    },

    multiRaceChanged() {
      if(!this.multiRace) { 
        this.resetSecondaryRace(); 
        this.setPrimaryRace();
      }
    },

    primaryRaceNameChanged() {
      this.setPrimaryRace();
      this.resetSecondaryRace();
      this.filterSecondaryRaces();
    },

    secondaryRaceNameChanged() {
      this.setSecondaryRace();
    },

    //--------------------------------

    resetSecondaryRace() {
      this.secondaryRaceName = null;
      this.secondaryRace = null;
    },
    filterSecondaryRaces() {
      let races = this.primaryRaces.filter(r => { 
        if(r.RaceName !== this.primaryRaceName) {
          return r;
        }
      });
      this.secondaryRaces = races;
    },
    setPrimaryRace() {
      if(this.primaryRaceName) {
        let race = this.primaryRaces.find(r => r.RaceName === this.primaryRaceName); 
        this.primaryRace = race;
        this.characterRace = createCharacterRace({ primaryRace: this.primaryRace });
        this.characterAttributes = createCharacterAttributes({ stats: this.stats, race: this.characterRace });
        this.characterResists = createCharacterResists({ race: this.characterRace });
        this.setLedgerItems();
      }
    },
    setSecondaryRace() {
      if(this.secondaryRaceName) {
        let race = this.primaryRaces.find(r => r.RaceName === this.secondaryRaceName);
        this.secondaryRace = race;
        this.characterRace = createCharacterRace({ primaryRace: this.primaryRace, secondaryRace: this.secondaryRace });
        this.characterAttributes = createCharacterAttributes({ stats: this.stats, race: this.characterRace });
        this.characterResists = createCharacterResists({ race: this.characterRace });
        this.setLedgerItems();
      }
    },
    setLedgerItems() {
      this.$store.dispatch('Forge/setLedgerItems', { 
        characterRace: this.characterRace,
        characterAttributes: this.characterAttributes,
        characterResists: this.characterResists,
        characterStatPool: this.characterStatPool 
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.race-selection { 
  header{
    display: flex;
    justify-content: space-between;
    width: 100%;

    .title {
      font-size: 20px;
    }
    .multi-race-selection {
      padding-top: 5px;
      padding-right: 5px;

      .checkbox-label {
        vertical-align: top; 
        line-height: 20px; 
        margin-right: 5px;
      }
    }
  }

  .race-selections {
    border: solid 1px rgba(0,0,0,0.5);
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px 10px 10px 10px;
    margin: 5px;
  }

}
</style>