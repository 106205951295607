<template>
<section class="magic-frame">
  <slot></slot>
</section>
</template>

<script>
// -----------------------------------------------------
export default {
  name: "MagicFrame"
};
</script>

<style lang="scss" scoped>

$artworkRoot: '/img/artwork/';

.magic-frame {
  
  position: relative;
  border: solid 6px rgb(51, 51, 51);
  border-image-source: url($artworkRoot + 'border-magic-frame.png');
  border-image-slice: 75;
  border-image-width: auto;
  border-image-repeat: repeat;
  background: url($artworkRoot + 'texture-purple-cloth.png');
  background-size: 100%;
  color: #fff;
  z-index: 10;
  margin-bottom: 10px;
  padding: 10px 15px;  
  min-height: 150px;
  
}
</style>