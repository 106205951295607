<template>
<div class="stat-gain-list" v-if="levelBonusSummary">
  <div class="title">Level Bonuses:</div>
  <div class="list-item" v-for="lbs in Object.keys(levelBonusSummary)" :key="lbs">
    <div v-if="levelBonusSummary[lbs].LevelBonus>0">{{lbs}}: {{$formatBonus(levelBonusSummary[lbs].LevelBonus)}}</div>
  </div>
</div>
</template>

<script>
export default {
  name: "LevelBonusSummary",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
  },
  data() {
    return {
      levelBonusSummary: []
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {
          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {
    setState() {
      if(this.forgeState.ledger.levelBonusLedger) {          
        this.levelBonusSummary = this.$deepClone(this.forgeState.ledger.levelBonusLedger?.items);
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.stat-gain-list {
  margin: 10px;
  padding: 10px;
  background-color: rgba(0,0,0,0.25);
  border: solid 1px rgba(0,0,0,0.25);

  .list-item {
    font-size: 12px;
    padding: 0 0 0 10px;
    color: #b3c1ff;
  }
}
</style>