//----------------------------------------------
import Resist from './Resist'
//---------------------------------------------------------  // deprecating
export default class Resists {  
  constructor(options) {

    this.Qi = new Resist();
    this.Elemental = new Resist();
    this.Divine = new Resist();
    this.Poison = new Resist();
    this.Disease = new Resist();

    if(options) {
      this.init(options);
    }

  }

  init(options) {

    this.Qi = new Resist(options.Qi) || this.Qi;
    this.Elemental = new Resist(options.Elemental) || this.Elemental;
    this.Divine = new Resist(options.Divine) || this.Divine;
    this.Poison = new Resist(options.Poison) || this.Poison;
    this.Disease = new Resist(options.Disease) || this.Disease;

  }
  
}