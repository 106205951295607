export default class WeaponGroup {
  constructor(options) {

    this.Skill_ID = 0;
    this.WeaponGroupCode = null;
    this.WeaponGroupDesc = null;
    this.SortOrder = 0;
    this.Stat1 = null;
    this.Stat2 = null;
    this.IsWeaponGroup = null;

    if(options) {
      this.init(options);
    }

  }


  init(options) {
    
    this.Skill_ID = parseInt(options.Skill_ID) || this.Skill_ID;
    this.WeaponGroupCode = options.WeaponGroupCode || this.WeaponGroupCode;
    this.WeaponGroupDesc = options.WeaponGroupDesc || this.WeaponGroupDesc;
    this.SortOrder = parseInt(options.SortOrder) || this.SortOrder;
    this.Stat1 = options.Stat1 || this.Stat1;
    this.Stat2 = options.Stat2 || this.Stat2;
    this.IsWeaponGroup = options.IsWeaponGroup || this.IsWeaponGroup;

  }
}