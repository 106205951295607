<template>
<MagicFrame v-if="ready"> 

  <div class="weapon-group-selection">

    <header>
      <div class="title">
        Your Weapon Skills
        <G20Help book="GameCanon.pdf" bookmark="Your Weapon Skills"></G20Help>
      </div>
    </header>

    <div class="weapon-groups">

      <div class="weapon-skill-costs">
        <div class="legend">Costs</div>
        <ul v-if="weaponSkillCosts">
          <li v-for="(n, index) in weaponSkillCosts" :key="index">
            <span>{{n.Cost}}</span>
          </li>
        </ul>
      </div>

      <div class="weapon-skills-list">
        <div class="legend">Weapon Skills</div>
        <ul>
          <li v-for="(w, index) in weaponGroups" :key="w.WeaponGroupCode" :class="{'selected': swapList.indexOf(index)>=0}" @click="setSwapList(index)">
            <img :src="`img/svg/weapongroups/${w.WeaponGroupCode}.svg`" />         
            <span class="weapon-skill-code">({{w.WeaponGroupCode}})</span>
            <span class="weapon-skill-name">{{w.WeaponGroupDesc}}</span>
          </li>
        </ul>

      </div>
    </div>

  </div>

</MagicFrame>
</template>

<script>
//------------------------------------------------------
import g20CharacterModels from '../../../code/models/g20CharacterModels'
import g20ContentModels from '../../../code/models/g20ContentModels'
//------------------------------------------------------
export default {
  name: "WeaponGroupSelect",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
  },
  data() {
    return {
      characterClass: {},
      weaponSkillCosts: [],
      weaponGroups: [],
      swapList: []
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {            
          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {
    
    async setState() {
      
      this.characterClass = this.forgeState.ledger.characterClass;
      this.characterWeaponGroups = this.forgeState.ledger.characterWeaponGroups;

      let weaponGroupNames = ["WG1", "WG2", "WG3", "WG4", "WG5", "WG6", "WG7", "WG8"];
      let className = this.characterClass.ClassName;
      this.weaponSkillCosts = await this.$store.dispatch('Content/getWeaponSkillCosts', { weaponGroupNames, className });

      if(!this.forgeState.ledger.characterWeaponGroups) {
        this.weaponGroups = await this.$store.dispatch('Content/getWeaponGroups');
        this.setLedgerItems();
      } else {
        this.weaponGroups = this.characterWeaponGroups.map(cwg => new g20ContentModels.WeaponGroup(cwg))
      }

    },

    setSwapList(index) {
      this.swapList.push(index);
      if(this.swapList.length===2) {
        setTimeout(() => {          
          this.executeSwap();
        }, 500);
      }
    },

    executeSwap() {
      let weaponGroups = [...this.weaponGroups];
      let item1 = weaponGroups[this.swapList[0]];
      let item2 = weaponGroups[this.swapList[1]];
      weaponGroups[this.swapList[0]] = item2;
      weaponGroups[this.swapList[1]] = item1;
      this.weaponGroups = weaponGroups;
      this.swapList = [];
      this.setLedgerItems(); 
    },

    setLedgerItems() {

      let array = [];
      this.weaponGroups.forEach((wg, index) => {
        let characterWeaponGroup = new g20CharacterModels.WeaponGroup(wg);
        characterWeaponGroup.Skill_ID = this.weaponSkillCosts[index].Skill_ID;
        characterWeaponGroup.Cost = this.weaponSkillCosts[index].Cost;
        array.push(characterWeaponGroup);
      });
      this.characterWeaponGroups = array;
      this.$store.dispatch('Forge/setLedgerItems', { characterWeaponGroups: this.characterWeaponGroups });
    }

  }
}
</script>

<style lang="scss" scoped>

.weapon-groups {
  display:flex;
  width: 100%;
  color:#fff;
  text-shadow: 1px 1px 6px #000;
  margin-top:10px;

  .legend {
    font-size: 11px;
  }

  .weapon-skill-costs {
    text-align: center;
    flex: 0 1 50px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        background-color: rgba(0,0,0,0.3);
        padding: 0 3px;
        margin: 2px;
      }

    }
  }

  .weapon-skills-list {
    flex: 1;
    
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        width: 100%;
        height: 36px;
        background-color: rgba(0,0,0,0.3);
        padding: 0 3px;
        margin: 2px 0;
        cursor: pointer;

        &.selected {          
          background-color: rgba(0, 255, 255, 0.4);
        }

        img {
          width: auto; 
          height: 30px;
          vertical-align: middle;
        }

        .weapon-skill-code {
          display: inline-block;
          margin: 0 6px;
          width: 50px;
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}


@media screen and (min-width: 768px) {

}

</style>