export default class CriticalType {
  constructor(options) {

    this.CritTypeID = null;
    this.CritTypeDesc = null;
    this.CritSizeID = null;
    this.Hits = 0;
    this.Mods = 0;
    this.Bleed = 0;
    this.Stun = 0;
    this.SortOrder = 0;
    
    if(options) {
      this.init(options);
    }

  }

  init(options) {
    
    this.CritTypeID = options.CritTypeID || this.CritTypeID;
    this.CritTypeDesc = options.CritTypeDesc || this.CritTypeDesc;
    this.CritSizeID = options.CritSizeID || this.CritSizeID;
    this.Hits = parseInt(options.Hits) || this.Hits;
    this.Mods = parseInt(options.Mods) || this.Mods;
    this.Bleed = parseInt(options.Bleed) || this.Bleed;
    this.Stun = parseInt(options.Stun) || this.Stun;
    this.SortOrder = parseInt(options.SortOrder) || this.SortOrder;

  }
}