
import { boolHasValue } from '../../utils/g20Utils'
import Material from '../content-models/Material'

export default class Item {
  constructor(options) {

    this.ItemKey = Math.random();

    this.Name = null;
    this.ItemDesc = null;

    this.ItemType = null;
    this.ArmorType = null;
    this.WearsAs = null;
    this.ArmorLocation = null;

    this.WeaponSkill = null;

    this.ItemBonus = 0;
    this.Corruption = 0;
    
    this.CritPrevention = 0;
    this.CastingPenalty = 0;
    this.InitiativePenalty = 0;
    this.Quality = 0;

    this.IsEquipped = true;
    this.ApplyCorruption = true;
    this.IsEditable = false;

    this.Material = null;
    
    this.PZ = null;  // [{ Skill: Foraging, Val: 6 }, { Weaponchart: 'two-hand-sword', weight: 3.1, length: null }, { Weaponchart: 'frostbolt' }]
        
    if(options) {
      this.init(options);
    }

  }

  init(options) {
    
    this.ItemKey = options.ItemKey || this.ItemKey;

    this.Name = options.Name || this.Name;
    this.ItemDesc = options.ItemDesc || this.ItemDesc;

    this.ItemType = options.ItemType || this.ItemType;
    this.ArmorType = options.ArmorType || this.ArmorType;
    this.WearsAs = options.WearsAs || this.WearsAs;
    this.ArmorLocation = options.ArmorLocation || this.ArmorLocation;
    this.WeaponSkill = options.WeaponSkill || this.WeaponSkill;

    this.ItemBonus = parseInt(options.ItemBonus) || this.ItemBonus;
    this.Corruption = parseInt(options.Corruption) || this.Corruption;
    
    this.CritPrevention = parseInt(options.CritPrevention) || this.CritPrevention;
    this.CastingPenalty = parseInt(options.CastingPenalty) || this.CastingPenalty;
    this.InitiativePenalty = parseInt(options.InitiativePenalty) || this.InitiativePenalty;
    this.Quality = parseInt(options.Quality) || this.Quality;
    
    this.IsEquipped = boolHasValue(options.IsEquipped) ? options.IsEquipped : this.IsEquipped;
    this.ApplyCorruption = boolHasValue(options.ApplyCorruption) ? options.ApplyCorruption : this.ApplyCorruption;    
    this.IsEditable = boolHasValue(options.IsEditable) ? options.IsEditable : this.IsEditable;

    this.Material = options.Material ? new Material(options.Material) : this.Material;
    this.PZ = options.PZ || this.PZ;

  }
}