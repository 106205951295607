<template>
<main>
  <FxSmoke></FxSmoke>
  <section>
    <G20Logo></G20Logo>
    <div v-if="showLoading">
      <G20Loading></G20Loading>
    </div>
    <div v-if="!showLoading">
      <G20LoginForm @login-successful="loginSuccessful()"></G20LoginForm>
    </div>
  </section>
</main>
</template>

<script>
//-------------------------------------------------------
//-------------------------------------------------------
export default {
  name: "Login",
  
  computed: {
    ready() { return this.$store.state.App.ready; }
  },

  watch: {
    ready: {
      handler(val) {
        if(val) {
          this.$router.push({ name: 'Concourse' });
        }
      }
    }
  },

  data() {
    return {
      loggedIn: false,
      showLoading: false
    }
  },

  methods: {
    async loginSuccessful() {
      this.loggedIn = true;
      await this.$store.dispatch('App/initApp');
      this.$router.push({ name: 'Concourse' });
    }
  }

}
</script>