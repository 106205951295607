<template>
<MagicFrame v-if="ready"> 

  <div class="possessions-select">

    <header>
      <div class="title">
        Possessions
        <G20Help book="GameCanon.pdf" bookmark="Choose Your Realm"></G20Help>
      </div>
      <div class="">
        <button class="btn-gem red" @click="newEditItem()">Add</button>
      </div>
    </header>

    <div class="possessions-list">
      <div class="possession-list-item" v-for="i in itemsLedger" :key="i.ItemKey">

        <div class="name">{{i.Name}} {{$formatBonus(i.ItemBonus)}}</div>
        <div class="description">{{i.ItemDesc}}</div>
        <div class="corruption">{{i.Corruption}} Corruption</div>

        <div class="list-item-buttons">
          <button class="btn-gem btn-sm blue" @click.prevent="newEditItem(i)">edit</button>
          <button class="btn-gem btn-sm red" @click.prevent="removeItem(i)">Remove</button>
        </div>

      </div>
    </div>

  </div>

  <Modal :controller="itemModal" @close="itemModal.show=false">
    <ItemForm :controller="itemModal" @close="itemModal.show=false" @save="saveItem($event)" ></ItemForm>
  </Modal>

</MagicFrame>
</template>

<script>
//------------------------------------------------------
import swal from 'sweetalert'
//------------------------------------------------------
export default {
  name: "PossessionsSelect",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
  },
  data() {
    return {
      itemsLedger: [],
      itemModal: {
        show: false,
        payload: null
      }
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {            
          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {
    
    setState() {
      this.itemsLedger = this.forgeState.ledger.itemsLedger ? this.forgeState.ledger.itemsLedger : [];
    },

    newEditItem(obj) {
      this.itemModal.payload = null;
      this.itemModal.show = true;
      this.itemModal.payload = obj;
    },

    saveItem(item) {
      let items = this.$deepClone(this.itemsLedger);
      let index = items.findIndex(x => x.ItemKey === item.ItemKey);
      if(index<0) {
        items.push(item);
      } else {
        items[index] = item;
      }
      this.itemsLedger = items;
      this.setLedgerItems();
      this.resetModal();
    },

    async removeItem(item) {
      let confirm = await swal({
        title: "Remove Item?", text: `You are about to sunder this item.  Are you sure?`, buttons: true, dangerMode: true,
      });
      if (confirm) {
        let items = this.itemsLedger.filter(x => { 
          if(x.ItemKey !== item.ItemKey) {
            return x;
          }        
        });
        this.itemsLedger = items;
        this.setLedgerItems();
      }
    },

    resetModal() {
      this.itemModal = {
        show: false,
        payload: null
      }
    },

    setLedgerItems() {
      this.$store.dispatch('Forge/setLedgerItems', { itemsLedger: this.itemsLedger });
    }
  }
}
</script>

<style lang="scss" scoped>
.possessions-select {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .possessions-list {
    margin-top: 5px;
    .possession-list-item {
      position: relative;
      background-color: rgba(0,0,0,0.25);
      border: solid 1px rgba(0,0,0,0.25);
      padding: 5px;
      margin-bottom: 5px;

      .name { font-size: 14px; }
      .description { 
        font-size: 12px;
        padding: 2px 5px;
      }
      .corruption { 
        font-size: 11px;
        font-style: italic;
        color: #bbb;
        
      }

      .list-item-buttons {
        margin-top: 5px;
        text-align: right;
      }
    }
  }

}

@media screen and (min-width: 768px) {
  .possessions-list {
    .possession-list-item {
      .list-item-buttons {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
      }
    }
  }
}

</style>