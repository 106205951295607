
export default class ForgeState {
  constructor(options) {

    this.forgeMode = null;
    this.forgeMenu = null;
    this.forgeMenuIndex = 0;
    this.forgeMenuDirection = 'forward';

    this.characterRecordId = 0;
    this.ledger = {};

    if(options) {
      this.init(options);
    }

  }

  init(options) {
    this.forgeMode = options.forgeMode || this.forgeMode;
    this.forgeMenu = options.forgeMenu || this.forgeMenu;
    this.forgeMenuIndex = options.forgeMenuIndex || this.forgeMenuIndex;
    this.forgeMenuDirection = options.forgeMenuDirection || this.forgeMenuDirection;

    this.characterRecordId = options.characterRecordId || this.characterRecordId;
    this.ledger = options.ledger || this.ledger;
    
  }
}