export default class Stat {
  constructor(options) {

    this.OldStatName = null;
    this.Stat = null;
    this.StatName = null;
    this.StatUse = null;
    this.SortOrder = null;
    
    if(options) {
      this.init(options);
    } 

  }

  init(options) {
    this.OldStatName = options.OldStatName || this.OldStatName;
    this.Stat = options.Stat || this.Stat;
    this.StatName = options.StatName || this.StatName;
    this.StatUse = options.StatUse || this.StatUse;
    this.SortOrder = parseInt(options.SortOrder) || this.SortOrder;
  }
}