//-------------------------------------------------------------
const storageKey = process.env.VUE_APP_FORGE_KEY;
import ForgeState from '../models/ui-models/ForgeState'
import { forgeMenus } from '../constants'
//-------------------------------------------------------------

export function forgeStateInit({mode, characterRecordId}) {
  clearState();
  let state = new ForgeState({
    forgeMode: mode,
    forgeMenu: forgeMenus[mode],
    characterRecordId: characterRecordId
  });
  setState(state);
  return state;
}

export function forgeStateClear() { 
  clearState();
  return null;
}

//---------------------------------------------

export function forgeStep(dir) {
  let state = new ForgeState(getState());
  state.forgeMenuIndex += parseInt(dir);

  // safeties
  if(state.forgeMenuIndex < 0) { state.forgeMenuIndex = 0 }
  if(state.forgeMenuIndex > state.forgeMenu.length -1) { state.forgeMenuIndex = state.forgeMenu.length -1 }

  state.forgeMenuDirection = dir > 0 ? 'forward' : 'back';
  setState(state);
  return state;
}

export function getForgeState() {
  let state = new ForgeState(getState());
  return state;
}

export function setLedger(ledger) {
  let state = new ForgeState(getState());
  let keys = Object.keys(ledger);
  keys.forEach(key => {
    state.ledger[key] = ledger[key];
  });
  //console.log("Ledger:", state.ledger);
  setState(state);
  return state.ledger;
}

export function getLedger() {  
  let state = new ForgeState(getState());
  return state.ledger;
}

export function getLedgerItem(key) {  
  let state = new ForgeState(getState());
  return state.ledger[key];
}

//-------------------------------------------------------------
//-------------------------------------------------------------
function setState(obj) {
  let json = JSON.stringify(obj);
  let cypherText = btoa(json);
	localStorage.setItem(storageKey, cypherText);
}
//-------------------------------
function getState() {
  let cypherText = localStorage.getItem(storageKey);
  if(!cypherText) { return null; }
  let json = atob(cypherText);
  return JSON.parse(json);  
}
//-------------------------------
function clearState() {
	localStorage.removeItem(storageKey);
}
//-------------------------------------------------------------
//-------------------------------------------------------------  validate forge state
export function validateForgeState(forgeState) {

  if(!forgeState || !forgeState.forgeMenu) { return }

  let menu = forgeState.forgeMenu[forgeState.forgeMenuIndex];
  switch(menu.key) {
    case "Race": return (forgeState.ledger.characterRace) ? true : false;
    case "Class": return (forgeState.ledger.characterClass) ? true : false;
    case "Realm": return (forgeState.ledger.characterSpellAbility.PrimaryRealm) ? true : false;
    case "Attributes": return validateAttributes(forgeState.ledger.characterAttributes);
    case "Inheritance": return validateBackgrounds(forgeState.ledger.characterBackgrounds, 'Inheritance');
    case "Family":  return validateBackgrounds(forgeState.ledger.characterBackgrounds, 'Family');
    case "Adolescence":  return validateBackgrounds(forgeState.ledger.characterBackgrounds, 'Adolescence');
    case "YoungAdult":  return validateBackgrounds(forgeState.ledger.characterBackgrounds, 'YoungAdult');
    case "ChosenPath":  return validateBackgrounds(forgeState.ledger.characterBackgrounds, 'ChosenPath');

    case "StatGains": return validateStatGains(forgeState.ledger.statGainsLedger);
    case "LevelBonuses": return validateLevelBonuses(forgeState.ledger.levelBonusLedger);

    default: return true;
  }  
}

//-------------------------------------------------------------
function validateAttributes(characterAttributes) {
  let valid = true;
  for(const key in characterAttributes) {
    if(characterAttributes[key].Current === 0 || characterAttributes[key].Potential === 0) {
      return false;
    }
  }
  return valid;  
}

//-------------------------------------------------------------
function validateBackgrounds(characterBackgrounds, key) {
  if(!characterBackgrounds) {
    return false;
  }
  return (characterBackgrounds[key]) ? true : false;
}

//-------------------------------------------------------------
function validateStatGains(statGainsLedger) {
  let valid = true;
  for(const key in statGainsLedger) {
    if(statGainsLedger[key].roll === 0) {
      return false;
    }
  }
  return valid;  
}

//-------------------------------------------------------------
function validateLevelBonuses(levelBonusLedger) {

  if(!levelBonusLedger) { return false }

  let sum = 0;
  for(const key in levelBonusLedger.items) {
    sum += levelBonusLedger.items[key].LevelBonus;
  }
  return (levelBonusLedger.max == sum) ? true : false; 
}


//=====================================================================================================
/*
NOTES:

  Ledger:

  The Ledger is designed to show you where in the process you are, in addition to holding values.
  In the Character Creation process, ledger items start with 'character'.
  In the Level Up process they end with the word 'Ledger'

*/
//=====================================================================================================