<template>
<div class="fate" v-if="character">
  <div class="fate-legend">
    <div>Corruption: {{corruption}}</div>
    <div>Current Fate: {{character.Fate - corruption}}</div>
    <div>Fate: {{character.Fate}}</div>
  </div>
  <div class="fate-scale">
    <div class="fate-point" :class="{'corruption': fp.value <= corruption}" v-for="(fp, index) in fatePoints" :key="index"></div>
  </div>
</div>

</template>

<script>
//---------------------------------------------------
import * as calc from '../../code/game/calculations'
//---------------------------------------------------
export default {
  name: "Fate",
  props: {
    value: Object
  },
  computed: {
    fatePoints() {
      let fate = [];
      for(let i=0; i< this.character.Fate; i++) {
        fate.push({ value: i+1, inPlay: true });
      }
      return fate;
    },
    corruption() {
      return calc.corruption(this.character);
    }
  },
  watch: {    
    value: {
      handler(val) {
        if(val) {
          this.character = this.$deepClone(this.value);
        }
      },
      immediate: true
    },
  },
  data() {
    return {
      character: null,
      editable: false
    }
  },
  methods: {

    cancel() {
      this.editable=false;
      this.$emit('cancel');
    },

    saveCharacter() {
      this.editable = false;
      this.$emit('save-character', this.character);
    }

  }
}
</script>

<style lang="scss" scoped>
.fate {
  border: solid 1px #ccc;
  padding: 5px;
  margin: 5px 0;

  .fate-legend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  }

  .fate-scale {
    display: flex;
    .fate-point {
      flex : 1;
      height: 10px;
      border-top: solid 1px #ccc;
      border-bottom: solid 1px #ccc;
      border-right: solid 1px #ccc;
      background-color: rgb(211, 229, 255);

      &:first-child {
        border-left: solid 1px #ccc;
      }
      &.corruption {
        background-color: rgb(100, 0, 0);
      }
    }
  }
}
</style>