//---------------------------------------------------------------------

import ContentDatabase from '../code/idb/ContentDatabase'
import g20ContentModels from '../code/models/g20ContentModels'
import { createCharacterSkillGroups, createSkillCosts, createSkillGroups, createSpellListGroups } from '../code/game/objectFactory'

//---------------------------------------------------------------------

// state
//=============================
const state = { 
  contentDb: null,
  ready: false  
}

// getters
//=============================
const getters = {  }

// actions
//====================================================================
const actions = {

  initDatabase({state, commit, dispatch}) {
    if(!state.contentDb) {        
      let contentDb = new ContentDatabase();
      commit("SET_CONTENT_DB", contentDb);
    }
    dispatch('checkReadyState');
  },

  checkReadyState({state, commit}) {
    if(state.contentDb) {
      commit("SET_READY_STATE", true);
    }
  },   

  // --------------------------------------------------------  character forge db calls

  async getArmorDamage({state}, code) {
    return await state.contentDb.getArmorDamage(code);
  },

  async getArmorTypes({state}) {
    return await state.contentDb.listAll('Armor', 'SortOrder');
  },

  async getAttributes({state}) {
    let attributes = await state.contentDb.listAll('Stat', 'SortOrder');  
    return await attributes;
  },

  async getAttributeBonuses({state}) {
    return await state.contentDb.listAll('Score'); 
  },

  async getBackgrounds({state}, skillGroup) {
    return await state.contentDb.listWhere('LevelBonusBackground', { LevelBonus: skillGroup }); 
  },
 
  async getChosenPaths({state}, ids) {
    return await state.contentDb.listWhereDistinct('LevelBonusBackground', 'LevelBonus', { SkillGroup_ID: ids }); 
  },

  async getClasses({state}) {
    return await state.contentDb.getClasses();
  },

  async getDifficulties({state}) {
    return await state.contentDb.listAll('Difficulty', 'SortOrder');
  },
  
  async getExperience({state}) {
    return await state.contentDb.listAll('Experience');
  },

  async getExperienceNeeded({state}, level) {
    return await state.contentDb.getByQuery('Experience', { Level:level });
  },

  async getInheritance({state}) {
    return await state.contentDb.listAll('Inheritance'); 
  },

  async getLevelBonusCategories({state}) {
    return await state.contentDb.listDistinct('LevelBonusBackground', 'LevelBonus'); 
  },
 
  async getMaterials({state}) {
    return await state.contentDb.listAll('Material');
  },

  async getRaces({state}) {
    return await state.contentDb.listAll('Race');
  },

  async getRealms({state}) {
    return await state.contentDb.listWhere('Realm', {IsRealm: 1});
  },
  
  async getSkillGroup({state}) {
    return await state.contentDb.listAll('SkillGroup', 'SortOrder');
  },

  async getSkillGroups({state}, ) {
    return await state.contentDb.listAll('SkillGroup', 'SortOrder');
  },

  async getSkillCosts({state}, characterClass) {
    let groups = await state.contentDb.listAll('SkillGroup', 'SortOrder');
    let skills = await state.contentDb.listAll('Skill', 'SkillName');
    let skillGroups = createSkillGroups({groups, characterClass});
    let skillCosts = createSkillCosts({groups, skills, characterClass}); 
    return { skillGroups, skillCosts }
  },
  
  async getSpecialAbilities({state}, specialAbilityType) {
    return await state.contentDb.listWhere('SpecialAbility',{ SpecialAbilityType: specialAbilityType });
  },

  async getSpecialAbilityTypes({state}) {
    return await state.contentDb.listAll('SpecialAbilityType');
  },

  async getSpellAbilities({state}) {
    return await state.contentDb.listAll('SpellAbility');
  },

  async getSpellAbilityById({state}, id) {
    return await state.contentDb.getById('SpellAbility', id);
  },

  async getSpellListGroups({state}, character) {
    let objects = await state.contentDb.getSpellLists();
    objects.character = character;
    return createSpellListGroups(objects);
  },

  async getSpellListTypes() {
    return await state.contentDb.listAll('SpellListType');
  },

  async getSpells(context, spellListId) {
    return await state.contentDb.getSpells(spellListId);
  },

  async getStatGains({state}) {
    return await state.contentDb.listAll('StatGain');
  },

  async getWeaponGroups({state}) {
    return await state.contentDb.listAll('WeaponGroup', 'SortOrder');
  },

  async getWeaponSkillCosts({state}, { weaponGroupNames, className }) {
    let query = { SkillName: weaponGroupNames };
    let results = await state.contentDb.listAllIn('Skill', query, 'SortOrder');    
    return results.map(sk => new g20ContentModels.SkillCost(sk, sk[className]));
  },
    
  async initCharacterSkillGroups({state}, { characterClass, characterWeaponGroups }) {  // happens at initial creation
    let groups = await state.contentDb.listAll('SkillGroup', 'SortOrder');
    return createCharacterSkillGroups({groups, characterClass, characterWeaponGroups});
  },

}

// mutations
//====================================================================
const mutations = {
  SET_CONTENT_DB(state, contentDb) {
    state.contentDb = contentDb;
  },  
  SET_READY_STATE(state, ready) {
    state.ready = ready;
  },
}

//====================================================================
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}