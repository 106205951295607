<template>
<main class="character-forge">

  <ForgeOverlay :forge-key="forgeKey"></ForgeOverlay>
  
  <header>
    <div class="title"> Character Forge  </div>
    <div v-if="forgeState">
      <button class="btn-capped-red btn-sm" v-if="forgeState.forgeMode!==forgeMode.EDIT_CHARACTER" @click="cancel()">Cancel</button>
      <button class="btn-capped-red btn-sm" v-else @click="$router.push({name: 'Concourse'})">Back</button>
    </div>
  </header>

  <div class="tools" v-if="ready"> 

    <ForgeNavigation></ForgeNavigation>

    <div class="transition">
      <transition :name="direction">
        <component :is="forgeComponent" :forge-key="forgeKey"  :forge-label="forgeLabel"></component>
      </transition>
    </div>

  </div>

</main>

</template>

<script>
//-------------------------------------------------------
import { forgeMode } from '../../code/constants'
//-------------------------------------------------------
export default {
  name: "CharacterForge",

  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeMode() { return forgeMode },
    forgeState() { return this.$store.state.Forge.forgeState; }
  },

  watch: {
    ready: {
      handler(val) {
        console.log('ready?', val);
        if(!val) { this.$store.dispatch('App/initApp'); }
      },
      immediate: true
    },
    forgeState: { 
      handler(val) {
        if(val) {
          this.forgeKey = this.forgeState?.forgeMenu[this.forgeState.forgeMenuIndex].key;
          this.forgeLabel = this.forgeState?.forgeMenu[this.forgeState.forgeMenuIndex].label;
          this.forgeComponent = this.forgeState?.forgeMenu[this.forgeState.forgeMenuIndex].component;
          this.direction = this.forgeState?.forgeMenuDirection;
        }
      },
      immediate: true
    },
  },

  data() {
    return {
      forgeKey: null,
      forgeComponent: null,
      direction: 'forward'
    }
  },

  async mounted() {  
    await this.$store.dispatch('Forge/initForge');
  },

  methods: {
    async cancel() { 
      this.$router.push({name: 'Concourse'});
      await this.$store.dispatch('Forge/clearForgeState');
    }
  }
}
</script>

<style lang="scss" scoped>
.character-forge {
  font-family: 'Fondamento', cursive;

  header {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 5px;
    width: 100%;
    z-index: 100;

    .title {
      font-size: 30px;
      line-height: 30px;
      color: #fff;
      text-shadow: 0px 0px 5px #000;
    }
  }

  .tools {
    width: 95%;
    margin: auto;
  } 

  
  //------------------------------------  control animations

  .transition {
    position: relative;
  }

  .forward-enter {  
    position: absolute;
    opacity: 0;
  }

  .forward-enter-active {
    position: absolute;
    transition: opacity .3s;
    z-index: 10px;
    width: 100%;
  }
  
  .forward-enter-to {
    position: absolute;
    opacity: 1;
    width: 100%;
  }

  
  .forward-leave-enter {
    position: absolute;
    transition: opacity .3s;
    width: 100%;
  }

  .forward-leave-active {
    position: absolute;
    transition: opacity .3s;
    width: 100%;
  }

  .forward-leave-to {
    position: absolute;
    opacity: 0;
    width: 100%;
  }
  

  
  .back-enter {  
    opacity: 0;
    width: 100%;
  }

  .back-enter-active {
    position: absolute;
    transition: opacity .3s;
    z-index: 10px;
    width: 100%;
  }
  
  .back-enter-to {
    position: absolute;
    opacity: 1;
    width: 100%;
  }

  
  .back-leave-enter {
    position: absolute;
    transition: opacity .3s;
    width: 100%;
  }

  .back-leave-active {
    position: absolute;
    transition: opacity .3s;
    width: 100%;
  }

  .back-leave-to {
    position: absolute;
    opacity: 0;
    width: 100%;
  }
  //------------------------------------
 
}


@media screen and (min-width: 640px) {
  .character-forge { 
    header {
      margin-bottom: 15px;
    }   
    .tools {
      width: 75%;
    }
  }
}

</style>