export default class SpellListType {
  constructor(options) {
    
    this.TypeID = 0;
    this.TypeOrder = 0;
    this.TypeDesc = null;

    if(options) {
      this.init(options);
    }

  }

  init(options) {
    
    this.TypeID = parseInt(options.TypeID) || this.TypeID;
    this.TypeOrder = parseInt(options.TypeOrder) || this.TypeOrder;
    this.TypeDesc = options.TypeDesc || this.TypeDesc;

  }
}