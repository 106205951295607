<template>
<transition name="fade">
  <div v-if="showOverlay" class="forge-overlay">
    <div v-if="activeView==='fate'" class="fate-stats">
      <div>Current Fate: {{characterFate.startingFate - characterFate.spentFate}}</div>
      <div>Spent Fate: {{characterFate.spentFate}}</div>
    </div>
    <div v-if="activeView==='skills'" class="skill-stats">
      <div class="points-pool-item" v-for="(spp, index) in spentPointsPool" :key="index">
        <span v-if="spp.rapid">Rapid {{spp.group}}: {{spp.value}}</span>
        <span v-else>{{spp.type}} {{spp.group}}: {{spp.value}}</span>
      </div>  
    </div>
    <div v-if="activeView==='spells'" class="spell-stats">
      <div class="points-pool-item" v-for="(spp, index) in spentPointsPool" :key="index">
        <span v-if="spp.type!='Skill'">{{spp.type}} {{spp.group}}: {{spp.value}}</span>
      </div>
    </div>
  </div>
</transition>
</template>

<script>
//-----------------------------------------------------
const DISTANCE_TO_SHOW = 320;
//-----------------------------------------------------
export default {
  name: "ForgeOverlay",

  props: { forgeKey: String },

  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
    activeView() {
      if(['Inheritance','Family','Adolescence','YoungAdult','ChosenPath','Features'].indexOf(this.forgeKey) >= 0) {
        return 'fate'
      } else if(this.forgeKey === 'SkillsSelect') {
        return 'skills'
      } else if(this.forgeKey === 'SpellsSelect') {
        return 'spells'
      } else {
        return null
      }
    }
  },

  watch: {
    forgeState: { 
      handler(val) {
        if(val) {          
          if(this.activeView === 'fate') { this.fateCalc(); }  
          if(this.activeView === 'skills') { this.setSpentPoints(); }
          if(this.activeView === 'spells') { this.setSpentPoints(); }
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      showOverlay: false,
      characterFate: 0,
      spentPointsPool: [],
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition > DISTANCE_TO_SHOW) {
        this.showOverlay = true;
      } else {
        this.showOverlay = false;
      }
    },
    fateCalc() {
      this.characterFate = this.forgeState.ledger.characterFate ? this.forgeState.ledger.characterFate : null;
    },
    async setSpentPoints() {
      this.spentPointsPool = this.forgeState.ledger.spentPointsPool ? this.forgeState.ledger.spentPointsPool : [];
    }
  }

}
</script>

<style lang="scss" scoped>
.forge-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  border-bottom: solid 2px rgba(0, 0, 0, 0.5);
  z-index: 50;
  color: #fff;
  padding: 10px;

  .fate-stats {
    display: flex;
    justify-content: space-between;
  }
  .fate-stats > div {
    flex: 1;
    text-align: center;
  }

  .skill-stats,
  .spell-stats {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .skill-stats > div,
  .spell-stats > div {
    text-align: center;
    width: 50%;
  }

}

//------------------------------------------  transitions

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>