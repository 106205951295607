<template>
<div class="tomes-available" v-if="character">

  <div class="tome-filter">
    <select class="input-sm" v-model="filter" @change="filterGroups()">
      <option value="MyTomes">My Tomes</option>
      <option value="AllClass">All Class Tomes</option>
      <option value="All">All Tomes</option>
    </select>
  </div>

  <div>Ancient Tomes</div>
  <div class="unclassed-lists" v-show="showGeneral">
    <div class="unclassed-list-group" v-for="ol in spellListGroups.unclassedLists" :key="ol.groupName">
      <div class="title">{{ol.groupName}}</div>
      <div class="spell-lists" :class="{'unlocked': tomeUnlocked(sl)}" v-for="sl in ol.spellLists" :key="sl.SpellListID" @click="$emit('select-list', sl)">
        <div>{{sl.SpellListName}}</div>
        <div>{{sl.ListCost}}</div>
      </div>
    </div>
  </div>

  <hr>

  <div>Class Tomes</div>
  <div class="classed-lists" v-show="showClassLists">
    <div class="classed-list-group" v-for="ol in spellListGroups.classedLists" :key="ol.groupName">
      <div class="title">{{ol.groupName}}</div>
      <div class="spell-lists" :class="{'unlocked': tomeUnlocked(sl)}" v-for="sl in ol.spellLists" :key="sl.SpellListID" @click="$emit('select-list', sl)">
        <div>{{sl.SpellListName}}</div>
        <div>{{sl.ListCost}}</div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
//--------------------------------------------------------------------
import { tomeUnlocked } from '../../../code/game/calculations'
//--------------------------------------------------------------------
export default {
  name: "TomesAvailable",
  props: {
    character: Object
  },  
  computed: {
    spellLedger() { return this.$store.state.Forge.forgeState.ledger.spellLedger; }
  },
  data() {
    return { 
      masterSpellListGroups: {},
      spellListGroups: {},
      filter: 'MyTomes',
      showGeneral: true,
      showClassLists: true,
    }
  },
  watch: {
    character: {
      handler(val) {
        if(val && val.ready) { this.init(); }
      },
      immediate:true
    }
  },
  methods: {
    async init() {
      let masterSpellListGroups = await this.$store.dispatch('Content/getSpellListGroups', this.character);
      this.masterSpellListGroups = masterSpellListGroups;
      this.spellListGroups = masterSpellListGroups;
      this.filterGroups();
    },

    tomeUnlocked(spellList) {
      return tomeUnlocked(this.spellLedger, spellList);
    },

    filterGroups() {
      
      let spellListGroups = { classedLists:[], unclassedLists:[] }; 

      if(this.filter==='All' || this.filter==='AllClass') {
        spellListGroups.classedLists = this.masterSpellListGroups.classedLists;
      } else {
        spellListGroups.classedLists = this.masterSpellListGroups.classedLists.filter(cl => {
          if(cl.className === this.character.Class.ClassName) {
            return cl;
          }
        })
      }

      if(this.filter==='All') {
        spellListGroups.unclassedLists = this.masterSpellListGroups.unclassedLists;
      } else {
        spellListGroups.unclassedLists = this.masterSpellListGroups.unclassedLists.filter(ul => {
          if(ul.realm.RealmDesc === this.character.SpellAbility.PrimaryRealm || ul.realm.RealmDesc === this.character.SpellAbility.SecondaryRealm) {
            return ul;
          }
        });
      }

      this.spellListGroups = spellListGroups;
    }
  }
}
</script>

<style lang="scss" scoped>

.tomes-available {
  position: relative;

  .tome-filter {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.unclassed-lists {
  padding: 10px;
  break-inside: avoid;

  .unclassed-list-group {
    break-inside: avoid;
    padding: 10px;
    margin-bottom: 5px;
    border: solid 1px rgba(0,0,0,0.5);
    background-color: rgba(0,0,0,0.25);

    .spell-lists {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: rgb(179, 193, 255);
            
      &.unlocked {
        background-color: rgba(255,255,255,0.1);
        color: rgb(208, 245, 207);
      }  
      
    }
    .spell-lists:hover {
      cursor: pointer;
    }
  }
}

.classed-lists {
  break-inside: avoid;
  padding: 10px;
  
  .classed-list-group {
    break-inside: avoid;
    padding: 10px;
    margin-bottom: 5px;
    border: solid 1px rgba(0,0,0,0.5);
    background-color: rgba(0,0,0,0.25);

    .spell-lists {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: rgb(179, 193, 255);

      &.unlocked {
        background-color: rgba(255,255,255,0.1);
        color: rgb(208, 245, 207);
      }      
    }

    .spell-lists:hover {
      cursor: pointer;
    }
    
  }
}


@media screen and (min-width: 700px) {
  .unclassed-lists,
  .classed-lists {
    columns: 2;
  }
}

@media screen and (min-width: 1000px) {
  .unclassed-lists,
  .classed-lists {
    columns: 3;
  }
}
@media screen and (min-width: 1400px) {
  .unclassed-lists,
  .classed-lists {
    columns: 4;
  }
}

</style>