<template>
<div>

</div>
</template>

<script>


import ContentDatabase from '../../code/idb/ContentDatabase';

//const severityCode = "B";
const severityType = "PIERCE";
//const severityScore = "6";


export default {
  name: "Dev",
  mounted() {

    setTimeout(() => {
      this.spellListGroups();
    }, 1500);
    
    //this.getCrit(severityCode, severityScore)//;
  },
  methods: {

    async getCrit(severityCode, severityScore) {
      let contentDb = new ContentDatabase();
      let Severity = await contentDb.getById('CriticalSeverity', severityCode);
      let query = { ScoreText: severityScore, CritSeverityID: Severity.CritSeverityID, CritTypeID: severityType }
      let result = await contentDb.getByQuery('CriticalScore', query);      
      console.log(result.CriticalText);
    },

    
    async spellListGroups() {
      let slg = await this.$store.dispatch('Content/getSpellListGroups_2');
      console.log('slg', slg);
    }
    
  }

}
</script>