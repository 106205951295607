<template>
<div class="combat-info" v-if="character">

  <div class="title">Combat</div>

  <!-- Combat Info Stats -->
  <div class="combat-info-stats">

    <div class="combat-info-stat">
      <div class="label">
        <div>Init Bonus</div>
        <div class="small">RE<sub>1</sub> / LO<sub>2</sub> / CH<sub>2</sub> - AT</div>
      </div>
      <div class="stat modifier">
        {{character.CombatInfo.InitBonus}}
      </div>
      <div class="note">
        <span v-if="!editable">{{character.CombatInfo.InitNote}}</span>
        <EditableContent empty-width="10px" v-model="character.CombatInfo.InitNote" v-else />
      </div>
    </div>

    <div class="combat-info-stat">
      <div class="label">
        <div>Defense Bonus</div>
      </div>
      <div class="stat modifier">
        <span v-if="!editable">{{character.CombatInfo.DefensiveBonus}}</span>
        <NumericStepper  v-model="character.CombatInfo.DefensiveBonus" v-else />
      </div>
      <div class="note">
        <span v-if="!editable">{{character.CombatInfo.DefensiveNote}}</span>
        <EditableContent empty-width="10px" v-model="character.CombatInfo.DefensiveNote" v-else />
      </div>
    </div>

    <div class="combat-info-stat">
      <div class="label">
        <div>Shield Bonus</div>
      </div>
      <div class="stat modifier">
        <span v-if="!editable">{{character.CombatInfo.ShieldBonus}}</span>
        <NumericStepper v-model="character.CombatInfo.ShieldBonus" v-else />
      </div>
      <div class="note">
        <span v-if="!editable">{{character.CombatInfo.ShieldNote}}</span>
        <EditableContent empty-width="10px" v-model="character.CombatInfo.ShieldNote" v-else />
      </div>
    </div>
    
  </div>
  
  <!-- Armor Info -->
  <div class="armor-info-stats">

    <div class="armor-info" v-if="character && character.CombatInfo.Armor">
      <div class="armor-item"> 
        <img src="img/svg/armor/armor.svg" />
      </div>
      <div class="armor-durability">
        <ArmorDurability :character="character" :item="character.CombatInfo.Armor" :wears-as="character.CombatInfo.WearsAs"></ArmorDurability>
        <small>Base Maneuver Chart: {{getDifficulty(character.CombatInfo.Armor.WearsAs)}}</small>
      </div>
    </div>

    <div class="armor-info" v-if="character && character.CombatInfo.Shield">
      <div class="armor-item"> 
        <img src="img/svg/armor/shield.svg" />
      </div>
      <div class="armor-durability">
        <ArmorDurability :character="character" :item="character.CombatInfo.Shield"></ArmorDurability>
      </div>
    </div>
    
    <div class="armor-info" v-if="character && character.CombatInfo.Helm">
      <div class="armor-item"> 
        <img src="img/svg/armor/helm.svg" />
      </div>
      <div class="armor-durability">
        <ArmorDurability :character="character" :item="character.CombatInfo.Helm"></ArmorDurability>
      </div>
    </div>

    <div class="armor-info" v-if="character && character.CombatInfo.Arms">
      <div class="armor-item"> 
        <img src="img/svg/armor/bracer.svg" />
      </div>
      <div class="armor-durability">
        <ArmorDurability :character="character" :item="character.CombatInfo.Arms"></ArmorDurability>
      </div>
    </div>

    <div class="armor-info" v-if="character && character.CombatInfo.Legs">
      <div class="armor-item"> 
        <img src="img/svg/armor/greaves.svg" />
      </div>
      <div class="armor-durability">
        <ArmorDurability :character="character" :item="character.CombatInfo.Legs"></ArmorDurability>
      </div>
    </div>

  </div>

  <!-- add weapons here -->
  <div class="weapon-skills">      
    <WeaponSkillGroup v-model="character" :weapons="weapons"></WeaponSkillGroup>
  </div>
  
  <Vitals :character="character"></Vitals>

</div>
</template>

<script>
//------------------------------------------------------
import { getWeaponSkill } from '../../code/game/character/CharacterManager'
//------------------------------------------------------
export default {
  name:"CombatInfo",
  props: {
    value: Object,
    editable: {
      type: Boolean,
      default: false
    }
  },
  watch: {    
    value: {
      handler(val) { 
        if(val) {            
          this.init() 
        }
      },
      immediate: true
    },
  },
  data() {
    return {
      character: null,
      weapons: null,
      armorTypes: [],
      difficulties: []
    }
  },
  methods: {

    async init() { 
      this.armorTypes = await this.$store.dispatch('Content/getArmorTypes');
      this.difficulties = await this.$store.dispatch('Content/getDifficulties');
      this.character = this.$deepClone(this.value);
      this.getWeaponsSkills();
    },

    getDifficulty(code) {
      let armorType = this.armorTypes.find(item => item.ArmorCode === code);
      let difficulty = this.difficulties.find(item => item.Difficulty === armorType.ManeuverDifficulty);
      return difficulty.DifficultyDescription;
    },

    getWeaponsSkills() {
      let weapons = [];
      this.character.Items.forEach(item => {
        if(item.IsEquipped) {
          let weapon = getWeaponSkill(this.character, item);
          if(weapon) {
            weapons.push(weapon);
          }
        }
      });
      this.weapons = weapons;
    }

  }
}
</script>

<style lang="scss" scoped>
.combat-info {

  //---------------------------
  .title {
    font-size: 16px;
    margin: 5px 0;
  }

  //---------------------------
  .weapon-skills {
    border: solid 1px #ccc;
    margin-bottom: 10px; 
  }

  //---------------------------
  .combat-info-stat {
    display: flex;
    align-items: center;
    width: 100%;

    .label {
      width: 140px;
      margin-right: 10px;
      text-align: right;
    }
    .stat {
      width: 50px;
      font-size: 20px;
      text-align: center;
    }
    .small {
      margin-top: -2px;
      font-size: 10px;
      font-style: italic;
    }

  }

  //---------------------------

  .armor-info {
    display: flex;
    margin: 5px;
    margin-bottom: 10px;

    .armor-item {
      position: relative;
      font-size: 14px;

      img {
        width: auto; 
        height: 30px;
      }

    }

    .armor-durability {
      font-size: 12px;
      margin-left: 10px;
      width: 100%;

      small {
        font-style: italic;
        color: #999;
      }
    }

    .item-stats {
      font-style: italic;
    }
    
  }

}
</style>