
import { stringToBoolean } from '../../utils/g20Utils'

export default class Class {
  constructor(options) {

    this.ClassName = null;
    this.ChosenPaths = null;
    this.Stat1 = "VI";
    this.Stat2 = "MI";
    this.SpellAbility_ID = 1;
    this.ClassDesc = "A person of no particular importance";
    this.ClassSkillGroup = null;
    this.QuoteName1 = null;
    this.QuoteText1 = null;
    this.QuoteName2 = null;
    this.QuoteText2 = null;
    this.IsActive = true;

    if(options) {
      this.init(options);
    }

  }

  init(options) {

    this.ClassName = options.ClassName || this.ClassName;
    this.ChosenPaths = options.ChosenPaths || this.ChosenPaths;
    this.Stat1 = options.Stat1 || this.Stat1;
    this.Stat2 = options.Stat2 || this.Stat2;
    this.SpellAbility_ID = parseInt(options.SpellAbility_ID) || this.SpellAbility_ID;
    this.ClassDesc = options.ClassDesc || this.ClassDesc;
    this.ClassSkillGroup = options.ClassSkillGroup || this.ClassSkillGroup;
    this.QuoteName1 = options.QuoteName1 || this.QuoteName1;
    this.QuoteText1 = options.QuoteText1 || this.QuoteText1;
    this.QuoteName2 = options.QuoteName2 || this.QuoteName2;
    this.QuoteText2 = options.QuoteText2 || this.QuoteText2;
    this.IsActive = stringToBoolean(options.IsActive) || this.IsActive;

  }

}