<template>
<div class="skills-summary">
  <div class="title">Added Skills:</div>
  <div v-for="s in skillSummary" :key="s.SkillGroupName">
    <div class="skill-group-info">
      {{s.SkillGroupName}}
      <div class="skill-info" v-for="sk in Object.keys(s.Skill)" :key='sk.SkillName'>        
        <div>{{s.Skill[sk].SkillName}}</div>
        <div><em>{{s.Skill[sk].Ranks}} Ranks</em></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "SkillSummary",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
  },
  data() {
    return {
      skillSummary: []
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {
          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {
    setState() {
      this.skillSummary = this.getSkillSummary()
    },
    getSkillSummary() {
      let skillLedger = this.$deepClone(this.forgeState.ledger.skillLedger);
      let keys = Object.keys(skillLedger.selected);
      let skillSummary = [];
      keys.forEach(key => {
        let skillGroup = this.$deepClone(skillLedger.selected[key]);
        let skillKeys = Object.keys(skillGroup.Skill);
        if(skillKeys.length > 0) {
          skillSummary.push(skillGroup);
        }
      });
      return skillSummary;
    },
  }
}
</script>

<style lang="scss" scoped>
.skills-summary {
  margin: 10px;
  padding: 10px;
  background-color: rgba(0,0,0,0.25);
  border: solid 1px rgba(0,0,0,0.25);
  .title {
    margin-bottom: 10px;
  }
  .skill-group-info {
    font-size: 14px;
    color: #fff;

    .skill-info {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      padding: 0 0 0 10px;
      color: #b3c1ff;
    }
  }
}
</style>