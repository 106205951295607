//=============================================================================
//  Takes JSON Data from the server and transforms the data types using classes
//=============================================================================
import content from '../../models/g20ContentModels'
//---------------------------------------------------------------  sanitizeData
export function sanitizeData(data) {

  let contentData = {};
  //-------------------------------------------------------------  class maps...
  contentData.Armor = data.Armor.map(item => new content.Armor(item));
  contentData.AttackType = data.AttackType.map(item => new content.AttackType(item));
  contentData.Class = data.Class.map(item => new content.Class(item));
  contentData.CriticalScore = data.CriticalScore.map(item => new content.CriticalScore(item));
  contentData.CriticalSeverity = data.CriticalSeverity.map(item => new content.CriticalSeverity(item));
  contentData.CriticalSize = data.CriticalSize.map(item => new content.CriticalSize(item));
  contentData.CriticalType = data.CriticalType.map(item => new content.CriticalType(item));  
  contentData.Difficulty = data.Difficulty.map(item => new content.Difficulty(item));
  contentData.Experience = data.Experience.map(item => new content.Experience(item));
  contentData.Inheritance = data.Inheritance.map(item => new content.Inheritance(item));
  contentData.Language = data.Language.map(item => new content.Language(item));
  contentData.LevelBonusBackground = data.LevelBonusBackground.map(item => new content.LevelBonusBackground(item));
  contentData.ManeuverResult = data.ManeuverResult.map(item => new content.ManeuverResult(item));  
  contentData.ManeuverRoll = data.ManeuverRoll.map(item => new content.ManeuverRoll(item));
  contentData.Material = data.Material.map(item => new content.Material(item));
  contentData.Race = data.Race.map(item => new content.Race(item));
  contentData.Realm = data.Realm.map(item => new content.Realm(item));
  contentData.Score = data.Score.map(item => new content.Score(item));
  contentData.Setting = data.Setting.map(item => new content.Setting(item));
  contentData.Skill = data.Skill.map(item => new content.Skill(item));
  contentData.SkillGroup = data.SkillGroup.map(item => new content.SkillGroup(item));
  contentData.SpecialAbility = data.SpecialAbility.map(item => new content.SpecialAbility(item));
  contentData.SpecialAbilityType = data.SpecialAbilityType.map(item => new content.SpecialAbilityType(item));
  contentData.Spell = data.Spell.map(item => new content.Spell(item));
  contentData.SpellAbility = data.SpellAbility.map(item => new content.SpellAbility(item));
  contentData.SpellList = data.SpellList.map(item => new content.SpellList(item));
  contentData.SpellListType = data.SpellListType.map(item => new content.SpellListType(item));
  contentData.SpellPrep = data.SpellPrep.map(item => new content.SpellPrep(item));
  contentData.SpellType = data.SpellType.map(item => new content.SpellType(item));
  contentData.Stat = data.Stat.map(item => new content.Stat(item));
  contentData.StatGain = data.StatGain.map(item => new content.StatGain(item));
  contentData.Vice = data.Vice.map(item => new content.Vice(item));
  contentData.WeaponGroup = data.WeaponGroup.map(item => new content.WeaponGroup(item));
  contentData.Weapons = data.Weapons.map(item => new content.Weapons(item));
  //-------------------------------------------------------------  

  //console.log("contentData?", contentData);

  //------------------------------  return transformed data model
  return contentData;
  //------------------------------
}
//---------------------------------------------------------------  