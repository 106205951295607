<template>
<div class="kazdul-frame">
<slot></slot>
</div>
</template>

<script>
export default {
  name: "KazdulFrame"
}
</script>

<style lang="scss" scoped>
$artworkRoot: '/img/artwork/';
.kazdul-frame {
  
  background: url($artworkRoot + 'texture-hammered-steel.jpg');
  background-size: 100%;
  color:#000;

  border: solid 6px #ccc;
  border-image-source: url($artworkRoot + 'border-dwarven-engraved.png');
  border-image-slice: 220;
  border-image-width: auto;
  padding: 10px;

}

@media screen and (min-width: 768px) {
  .kazdul-frame {
    border-image-slice: 200;
    padding: 25px;
  }
}
</style>