<template>
<MagicFrame v-if="ready"> 

  <div class="inheritance-selection">

    <header>
      <div class="title">
        Your Inheritance
        <G20Help book="GameCanon.pdf" bookmark="Your Inheritance"></G20Help>
      </div>   
    </header>

    <div class="die-rolls" v-if="characterFate">
      <TriadRoll :fate="characterFate.startingFate" :spent-fate="characterFate.spentFate" :triad="triad" :min="-10"
                 @dice-rolled="diceRolled($event)" @triad-changed="triad = $event"></TriadRoll>
    </div>

    <div v-show="!isPristine">
      <RangeSelect :range-items="inheritance" :g20-roll="g20Roll" :triad="triad" @item-selected="selectInheritance($event)">
        <template v-slot="{ item }">   
          <div class="inheritance-items">        
            <div class="inheritance-left">
              <div class="g20-roll">{{item.g20Roll}}</div>
            </div>
            <div class="inheritance-right">
              <div class="title">{{item.Inheritance}}</div>
              <div class="content">
                <div>{{item.SkillPoints}} | {{item.RapidPoints}}</div>
                <div>{{item.StartingPossessions}}</div>
              </div>
            </div>
          </div>
        </template>
      </RangeSelect>
    </div>

  </div>

</MagicFrame>
</template>

<script>
//------------------------------------------------------
import g20CharacterModels from '../../../code/models/g20CharacterModels';
import * as calc from '../../../code/game/calculations'
//------------------------------------------------------
export default {
  name: "InheritanceSelect",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
  },
  data() {
    return {
      isPristine: true,
      characterFate: null,
      triad: { low: 0, mid: 0, high: 0 },
      g20Roll: 0,
      inheritance: [],
      characterBackgrounds: {}
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {
          this.inheritance = await this.$store.dispatch('Content/getInheritance');
          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {

    setState() {      
      this.characterFate = this.forgeState.ledger.characterFate;
      if(this.forgeState.ledger.characterBackgrounds) {
        this.characterBackgrounds = this.forgeState.ledger.characterBackgrounds;
        this.setRollData(this.characterBackgrounds.Inheritance);
        this.setInheritance(this.characterBackgrounds.Inheritance, this.characterBackgrounds.Inheritance.cost);
        this.setSpentFate();

      }
      this.checkPristine();
    },

    checkPristine() {
      this.isPristine = (this.triad.low === 0 && this.triad.mid === 0 && this.triad.high === 0)
    },

    diceRolled(triad) {
      this.g20Roll = triad.mid;
      this.triad = triad;
      let item = this.inheritance.find(i => i.g20Roll === this.g20Roll);
      this.selectInheritance(item);
      this.checkPristine();
    },
    
    selectInheritance(item) {

      this.g20Roll=item.g20Roll;
      let distance = Math.abs(this.triad.mid - item.g20Roll);
      let direction = (this.triad.mid - item.g20Roll) >= 0 ? -1 : 1;

      let fate = 0;
      for(let i=1; i<=distance; i++) {
        let cursor = this.triad.mid + (i * direction);
        if(this.triad.low <= cursor && cursor <= this.triad.high) {
          fate = fate + 1;
        } else {
          fate = fate + 2;
        }
      }

      this.setInheritance(item, fate);
      this.setSpentFate();
      this.setLedgerItems();
    },

    setRollData(inheritance) {
      this.triad = { low: inheritance.low, mid: inheritance.mid, high: inheritance.high },
      this.g20Roll = inheritance.g20Roll;
    },

    setInheritance(item, fate) {
      this.characterBackgrounds.Inheritance = new g20CharacterModels.Inheritance({
        Inheritance: item.Inheritance,
        RapidPoints: item.RapidPoints,
        SkillPoints: item.SkillPoints,
        StartingPossessions: item.StartingPossessions,
        cost: fate,
        g20Roll: this.g20Roll,
        high: this.triad.high,
        mid: this.triad.mid,
        low: this.triad.low
      });
    },

    setSpentFate() {
      this.characterFate.spentFate = calc.spentFate(this.characterBackgrounds);
    },

    setLedgerItems() {
      this.$store.dispatch('Forge/setLedgerItems', { 
        characterFate: this.characterFate,
        characterBackgrounds: this.characterBackgrounds
      });
    }

  }
}
</script>

<style lang="scss" scoped>
$artworkRoot: '/img/artwork/';  
.inheritance-selection { 

  header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .title {
      font-size: 20px;
    }    
  }

  .die-rolls {
    margin: 10px 0;
  }

  .range-link {
    color: #ccc;
  }

  .range-link:hover,
  .range-link:active {
    color: #fff;
  }

  .inheritance-items {
    display: flex;
    .inheritance-left {
      width: 50px;
      align-self: center;
      text-align: center;

      .g20-roll {
        font-size: 20px;
      }
    }
    .inheritance-right {
      flex: 1;
      .title {
        font-size: 16px;
      }
      .content {
        padding: 2px;
        font-size: 12px;
      }
    }
  }
}

@media screen and (min-width: 768px) {


}

</style>