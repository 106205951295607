
export default class CriticalScore {
  constructor(options) {

    this.Score_ID = 0;
    this.CritTypeID = null;
    this.CritSeverityID = null;
    this.SortOrder = 0;
    this.ScoreText = null;
    this.CriticalText = null;
    this.critSizes = null;
    this.Location = null;
    this.BeginText = null;
    this.OtherwiseText = null;
    this.Hits = 0;
    this.Bleed = 0;
    this.Mods = 0;
    this.Stun = 0;
    this.AM = 0;
    this.MP = 0;
    this.ActionMod = 0;
    this.MPMod = 0;
    this.NP = 0;
    this.Initiative = 0;
    this.Bonus = 0;
    this.Dur = 0;
    this.Armor = 0;
    this.AHits = 0;
    this.Ableed = 0;
    this.Amods = 0;
    this.AStun = 0;
    this.EndText = null;
    this.Val = 0;
    this.SeverityCompare = 0;
    this.Vs = 0;

    if(options) {
      this.init(options);
    }

  }

  init(options) {

    this.Score_ID = parseInt(options.Score_ID) || this.Score_ID;
    this.CritTypeID = options.CritTypeID || this.CritTypeID;
    this.CritSeverityID = options.CritSeverityID || this.CritSeverityID;
    this.SortOrder = parseInt(options.SortOrder) || this.SortOrder;
    this.ScoreText = options.ScoreText || this.ScoreText;
    this.CriticalText = options.CriticalText || this.CriticalText;
    this.critSizes = options.critSizes || this.critSizes;
    this.Location = options.Location || this.Location;
    this.BeginText = options.BeginText || this.BeginText;
    this.OtherwiseText = options.OtherwiseText || this.OtherwiseText;
    this.Hits = parseInt(options.Hits) || this.Hits;
    this.Bleed = parseInt(options.Bleed) || this.Bleed;
    this.Mods = parseInt(options.Mods) || this.Mods;
    this.Stun = parseInt(options.Stun) || this.Stun;
    this.AM = parseInt(options.AM) || this.AM;
    this.MP = parseInt(options.MP) || this.MP;
    this.ActionMod = parseInt(options.ActionMod) || this.ActionMod;
    this.MPMod = parseInt(options.MPMod) || this.MPMod;
    this.NP = parseInt(options.NP) || this.NP;
    this.Initiative = parseInt(options.Initiative) || this.Initiative;
    this.Bonus = parseInt(options.Bonus) || this.Bonus;
    this.Dur = parseInt(options.Dur) || this.Dur;
    this.Armor = parseInt(options.Armor) || this.Armor;
    this.AHits = parseInt(options.AHits) || this.AHits;
    this.Ableed = parseInt(options.Ableed) || this.Ableed;
    this.Amods = parseInt(options.Amods) || this.Amods;
    this.AStun = parseInt(options.AStun) || this.AStun;
    this.EndText = options.EndText || this.EndText;
    this.Val = parseInt(options.Val) || this.Val;
    this.SeverityCompare = parseInt(options.SeverityCompare) || this.SeverityCompare;
    this.Vs = parseInt(options.Vs) || this.Vs;
    
  }
}






