
import { stringToInt, boolHasValue } from '../../utils/g20Utils'

export default class Race {
  constructor(options) {

    this.PrimaryRaceName = null;
    this.SecondaryRaceName = null;
    this.MultiRace = false,
    
    this.RaceGroup = null;
    this.RaceDesc = null;

    this.StartingFate = 0;
    this.HitsPerRank = 0;

    this.VI = 0;
    this.AG = 0;
    this.WI = 0;
    this.ME = 0;
    this.LO = 0;
    this.MI = 0;
    this.RE = 0;
    this.CH = 0;
    this.EM = 0;
    this.IN = 0;

    this.DivineResist = 0;
    this.ElementalResist = 0;
    this.QiResist = 0;
    this.PoisonResist = 0;
    this.DiseaseResist = 0;

    if(options){
      this.init(options);
    }
  }

  init(options) {
    
    this.PrimaryRaceName = options.PrimaryRaceName || this.PrimaryRaceName;
    this.SecondaryRaceName = options.SecondaryRaceName || this.SecondaryRaceName;

    this.RaceGroup = options.RaceGroup || this.RaceGroup;
    this.RaceDesc = options.RaceDesc || this.RaceDesc;

    this.StartingFate = stringToInt(options.StartingFate) || this.StartingFate;
    this.HitsPerRank = stringToInt(options.HitsPerRank) || this.HitsPerRank;

    this.VI = stringToInt(options.VI) || this.VI;
    this.AG = stringToInt(options.AG) || this.AG;
    this.WI = stringToInt(options.WI) || this.WI;
    this.ME = stringToInt(options.ME) || this.ME;
    this.LO = stringToInt(options.LO) || this.LO;
    this.MI = stringToInt(options.MI) || this.MI;
    this.RE = stringToInt(options.RE) || this.RE;
    this.CH = stringToInt(options.CH) || this.CH;
    this.EM = stringToInt(options.EM) || this.EM;
    this.IN = stringToInt(options.IN) || this.IN;

    this.DivineResist = stringToInt(options.DivineResist) || this.DivineResist;
    this.ElementalResist = stringToInt(options.ElementalResist) || this.ElementalResist;
    this.QiResist = stringToInt(options.QiResist) || this.QiResist;
    this.PoisonResist = stringToInt(options.PoisonResist) || this.PoisonResist;
    this.DiseaseResist = stringToInt(options.DiseaseResist) || this.DiseaseResist;
    this.MultiRace = boolHasValue(options.MultiRace) ? options.MultiRace : this.MultiRace;

  }

}