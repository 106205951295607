export default class Language {
  constructor(options) {

    this.LanguageID = 0;
    this.LanguageDesc = null;
    
    if(options) {
      this.init(options);
    }
    
  }

  init(options) {
    
    this.LanguageID = parseInt(options.LanguageID) || this.LanguageID;
    this.LanguageDesc = options.LanguageDesc || this.LanguageDesc;
    
  }
}