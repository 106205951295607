//-------------------------------
import axios from 'axios';
//-------------------------------
const API_ROOT = process.env.VUE_APP_API_SERVER;
//-------------------------------------------------------------
export default {

  app: {
    version: () => { return axios.get(`${API_ROOT}/version.php`) }
  },

  character: {
    save: (req) => { return axios.post(`${API_ROOT}/character-C.php`, req) },   
    update: (req) => { return axios.put(`${API_ROOT}/character-U.php`, req) },  
  },

  database: { 
    getdata: () => { return axios.get(`${API_ROOT}/getdata.php`) },
    schema: () => { return axios.get(`${API_ROOT}/schema.php`) },
  },

  player: { 
    login: (req) => { return axios.post(`${API_ROOT}/login.php`, req) },
    register: (req) => { return axios.post(`${API_ROOT}/register.php`, req) },
  }

}