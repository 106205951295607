//---------------------------------------------------------------
export default class SkillGroup {
  constructor(options) {
    //------------------------------------------------
    this.SkillGroupName =  null;
    this.SkillGroup_ID = null;
    this.SortOrder = 0;
    this.Stat1 = null;
    this.Stat2 = null;
    this.UseSkillStats = true;
    this.LevelBonus = 0;
    this.ItemBonus = 0;
    this.MiscBonus = 0;
    this.Skill = {};
    //------------------------------------------------
    if(options) {
      this.init(options);
    }
    //------------------------------------------------
  }

  //---------------------------------------------------------------
  init(options) {

    this.SkillGroupName = options.SkillGroupName || this.SkillGroupName;
    this.SkillGroup_ID = parseInt(options.SkillGroup_ID) || this.SkillGroup_ID;
    this.SortOrder = parseInt(options.SortOrder) || this.SortOrder;
    this.Stat1 = options.Stat1 || this.Stat1;
    this.Stat2 = options.Stat2 || this.Stat2;
    this.UseSkillStats =  parseInt(options.UseSkillStats) || this.UseSkillStats;
    this.LevelBonus = options.LevelBonus || this.LevelBonus;
    this.ItemBonus = options.ItemBonus || this.ItemBonus;
    this.MiscBonus = options.MiscBonus || this.MiscBonus;
    this.Skill = options.Skill || this.Skill;

  }

}