import { render, staticRenderFns } from "./g20Info.vue?vue&type=template&id=3988d5fe&scoped=true&"
import script from "./g20Info.vue?vue&type=script&lang=js&"
export * from "./g20Info.vue?vue&type=script&lang=js&"
import style0 from "./g20Info.vue?vue&type=style&index=0&id=3988d5fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3988d5fe",
  null
  
)

export default component.exports