//---------------------------------------------------------
export default class CombatInfo {
  constructor(options) {
    
    this.InitBonus = 0;
    this.InitNote = null;
    this.DefensiveBonus = 0;
    this.DefensiveNote = null;
    this.ShieldBonus = 0;
    this.ShieldNote = null;
    this.Armor = null;
    this.Helm = null;
    this.Shield = null;
    this.Arms = null;
    this.Legs = null;
        
    if(options) {
      this.init(options);
    }

  }

  init(options) {

    this.InitBonus = options.InitBonus || this.InitBonus;
    this.InitNote = options.InitNote || this.InitNote;
    this.DefensiveBonus = options.DefensiveBonus || this.DefensiveBonus;
    this.DefensiveNote = options.DefensiveNote || this.DefensiveNote;
    this.ShieldBonus = options.ShieldBonus || this.ShieldBonus;
    this.ShieldNote = options.ShieldNote || this.ShieldNote;
    this.Armor = options.Armor || this.Armor;
    this.Helm = options.Helm || this.Helm;
    this.Shield = options.Shield || this.Shield;
    this.Arms = options.Arms || this.Arms;
    this.Legs = options.Legs || this.Legs;

  }
}