
// state
//=============================
const state = { 
}

// getters
//=============================
const getters = {  }

// actions
//====================================================================
const actions = {

  // --------------------------------------------------------  GM db calls

  async getCriticalStrike({rootState}) {    
    return await rootState.Content.contentDb.listWhere('Skill', 'SkillName');
  }

}

// mutations
//====================================================================
const mutations = {

}

//====================================================================
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}