<template>
<MagicFrame v-if="ready"> 

  <div class="features-selection">

    <header>
      <div class="title">
        Your Special Features
        <G20Help book="GameCanon.pdf" bookmark="Special Abilities"></G20Help>
      </div>
    </header>

    <div class="feature-slots">
      <div class="feature-buttons">
        <button class="btn-capped-red btn-sm" @click="addCharacterFeature()">Add New</button>
      </div> 
      <div>
        <div class="feature-slot" :class="{'selected': activeSlot && cfs.SlotID === activeSlot.SlotID}" v-for="cfs in characterFeatures" :key="cfs.SlotID" @click.stop="selectSlot(cfs)">

          <div class="feature-slot-controls">
            <select class="input-sm" v-model="cfs.SpecialAbilityType" @change.stop="selectSlot(cfs)" @click.stop="noop()">
              <option v-for="cfc in characterFeatureCategories" :key="cfc.SpecialAbilityType" :value="cfc.SpecialAbilityType">{{cfc.SpecialAbilityType}}</option>
            </select>
            <div class="feature-name">{{cfs.Name}}</div>
          </div>
          <div>            
            <button class="btn-gem red btn-sm" @click="confirmRemoveSlot(cfs)">X</button>
          </div>
          
        </div>
      </div>
    </div>
    
    <div class="die-rolls" v-if="characterFate">
      <TriadRoll :fate="characterFate.startingFate" :spent-fate="characterFate.spentFate" :triad="triad" :min="-10" :enabled="activeSlot!=null"
                 @dice-rolled="diceRolled($event)" @triad-changed="triad = $event"></TriadRoll>
    </div>

    
    <div v-show="!isPristine">
      <RangeSelect :range-items="featuresList" :g20-roll="g20Roll" :triad="triad" @item-selected="setFeature($event)">
      <template v-slot="{ item }">


        <div class="feature-items">        
          <div class="feature-left">
            <div class="g20-roll">{{item.g20Roll}}</div>
          </div>
          <div class="feature-right">
            <div class="title">
              <span v-if="item.Name">{{item.Name}}</span>
            </div>
            <div class="content">
              <div>{{item.Description}}</div>
            </div>
          </div>
        </div>

      </template>
      </RangeSelect>
    </div>
 
  </div>

</MagicFrame>
</template>

<script>
//------------------------------------------------------  deprecating
import swal from 'sweetalert'
import g20CharacterModels from '../../../code/models/g20CharacterModels';
import * as calc from '../../../code/game/calculations'
//------------------------------------------------------
export default {
  name: "FeaturesSelect",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
  },
  data() {
    return {

      isPristine: true,
      triad: { low: 0, mid: 0, high: 0 },
      g20Roll: 0,

      characterBackgrounds: {},
      characterFate: null,

      characterFeatureCategories: [],
      characterFeatures: [],
      activeSlot: null,
      
      featuresList: []
      
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {          
          this.characterFeatureCategories = await this.$store.dispatch('Content/getSpecialAbilityTypes');  
          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {
    
    setState() {
      this.characterFate = this.forgeState.ledger.characterFate;
      this.characterBackgrounds = this.forgeState.ledger.characterBackgrounds;
      this.characterFeatures = this.forgeState.ledger.characterFeatures ? this.forgeState.ledger.characterFeatures : [];
      this.setSpentFate();  
    },

    addCharacterFeature() {
      let characterFeature = new g20CharacterModels.SpecialAbility();
      characterFeature.SpecialAbilityType = this.characterFeatureCategories[0].SpecialAbilityType;
      this.characterFeatures.push(characterFeature);
      this.activeSlot = characterFeature;
      this.setDieRolls();
      this.setLedgerItems();
    },

    selectSlot(slot) {
      this.activeSlot = slot;
      this.setDieRolls();
      if(!this.isPristine) {   
        this.getFeaturesList();  // awaitable...
      }
    },

    confirmRemoveSlot(slot) {
      swal({
        title: "Remove?", text: `Remove this Special Ability Slot?`, buttons: true, dangerMode: true,
      })
      .then((confirm) => {
        if (confirm) {
          this.removeCharacterFeatureSlot(slot);
        }
      });
    },

    removeCharacterFeatureSlot(slot) {
      let characterFeatures = this.characterFeatures.filter(s => {
        if(s.SlotID !== slot.SlotID) {
          return s;
        }
      });
      this.characterFeatures = characterFeatures;
      this.setLedgerItems();
      if(slot.SlotID === this.activeSlot.SlotID) { 
        this.activeSlot=null; 
        this.setDieRolls();
      }
    },

    async getFeaturesList() {      
      this.featuresList = await this.$store.dispatch('Content/getSpecialAbilities', this.activeSlot.SpecialAbilityType);
      this.setActiveSlot();
      this.setActiveSlotRolls();
      this.checkPristine();
    },

    setDieRolls() { 
      if(this.activeSlot) {
        this.triad.low = this.activeSlot.low;
        this.triad.mid = this.activeSlot.mid;
        this.triad.high = this.activeSlot.high;
        this.g20Roll = this.activeSlot.g20Roll;        
      } else {
        this.triad.low = 0;
        this.triad.mid = 0;
        this.triad.high = 0;
        this.g20Roll = 0; 
      }
      this.checkPristine();
    },

    setActiveSlot() {
      let feature = this.featuresList.find(item => item.g20Roll === this.g20Roll);
      this.setFeature(feature);
    },

    setActiveSlotRolls() {      
      this.activeSlot.low = this.triad.low;
      this.activeSlot.mid = this.triad.mid;
      this.activeSlot.high = this.triad.high;
      this.activeSlot.g20Roll = this.g20Roll;
    },

    setFeature(feature) {
      if(feature) {
        this.activeSlot.ID = feature.ID;
        this.activeSlot.Name = feature.Name;
        this.activeSlot.Description = feature.Description;      
        this.activeSlot.g20Roll = feature.g20Roll;
        this.g20Roll = feature.g20Roll;
        this.activeSlot.cost = this.setFeatureCost(feature);
        this.setLedgerItems();
      }
    },

    setFeatureCost(feature) {

      let distance = Math.abs(this.triad.mid - feature.g20Roll);
      let direction = (this.triad.mid - feature.g20Roll) >= 0 ? -1 : 1;

      let cost = 10;
      for(let i=1; i<=distance; i++) {
        let cursor = this.triad.mid + (i * direction);
        if(this.triad.low <= cursor && cursor <= this.triad.high) {
          cost = cost + 1;
        } else {
          cost = cost + 2;
        }
      }
      return cost;
    },

    //--------------------------------------------------------------

    checkPristine() {
      this.isPristine = (this.triad.low === 0 && this.triad.mid === 0 && this.triad.high === 0)
    },

    diceRolled(triad) {
      this.g20Roll = triad.mid;
      this.triad = triad;
      this.getFeaturesList();
    },

    setSpentFate() {
      this.characterFate.spentFate = calc.spentFate(this.characterBackgrounds, this.characterFeatures);
    },

    async setLedgerItems() {
      console.log('features ledger set');
      this.setSpentFate();
      await this.$store.dispatch('Forge/setLedgerItems', { 
        characterFate: this.characterFate,
        characterFeatures: this.characterFeatures 
      });
    },

    noop() {}
  }
}
</script>

<style lang="scss" scoped>

.feature-buttons {
  text-align: right;
  margin-bottom: 10px;
}

.feature-slot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px rgba(0,0,0,0.5);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  margin: 5px;
  font-size: 14px;

  &.selected {      
    background-color: rgba(255, 255, 180, 0.4);
  }

  .feature-slot-controls {
    display: flex;

    .feature-name {
      margin-left: 10px;
    }
  }
}


.die-rolls {
  margin: 10px 0;
}

  .feature-items {
  display: flex;
  .feature-left {
    width: 50px;
    padding-top: 1px;
    text-align: center;

    .g20-roll {
      font-size: 24px;
    }
  }
  .feature-right {
    flex: 1;
    .title {
      font-size: 16px;
    }
    .content {
      padding: 2px;
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 768px) {

}

</style>