<template>
<span ref="field" class="editable-content" :class="{ 'empty': !value, 'block': allowEnter }"
      :style="style"
      v-text="value"
      @blur="sendVal($event)"
      @keydown.enter="endEdit($event)"
      contenteditable></span>
</template>

<script>
export default {
  name: "EditableContent",
  props: {
    value: [String, Number],
    allowEnter: { type: Boolean, default: false }
  },
  data() {
    return { style: {} }
  },
  methods: {
    sendVal($event) {
      let val = $event.target.innerText.trim();
      this.$emit('input', val);
    },
    endEdit(evt) {
      if(!this.allowEnter) {
        this.$el.blur();
        evt.preventDefault();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.editable-content {
  display: inline-block;
  min-width: 15px;
  border: dotted 1px #ccc;

  &.block {
    display: block;
    width: 100%;
  }
  &:hover {
    cursor: pointer;
  }
}
</style>