
export default class Race {
  constructor(options) {

    this.RaceName = null;
    this.RaceGroup = null;
    this.RaceDesc = null;

    this.StartingFate = 0;
    this.HitsPerRank = 0;

    this.AG = null;
    this.CH = null;
    this.EM = null;
    this.IN = null;
    this.LO = null;
    this.ME = null;
    this.MI = null;
    this.RE = null;
    this.VI = null;
    this.WI = null;

    this.DiseaseResist = 0;
    this.DivineResist = 0;
    this.ElementalResist = 0;
    this.PoisonResist = 0;
    this.QiResist = 0;

    this.Inheritance = 0;
    this.SortOrder = 0;

    if(options) {
      this.init(options);
    }

  }

  init(options) {
    
    this.RaceName = options.RaceName || this.RaceName;
    this.RaceGroup = options.RaceGroup || this.RaceGroup;
    this.RaceDesc = options.RaceDesc || this.RaceDesc;

    this.StartingFate = parseInt(options.StartingFate) || this.StartingFate;
    this.HitsPerRank = parseInt(options.HitsPerRank) || this.HitsPerRank;

    this.AG = parseInt(options.AG) || this.AG;
    this.CH = parseInt(options.CH) || this.CH;
    this.EM = parseInt(options.EM) || this.EM;
    this.IN = parseInt(options.IN) || this.IN;
    this.LO = parseInt(options.LO) || this.LO;
    this.ME = parseInt(options.ME) || this.ME;
    this.MI = parseInt(options.MI) || this.MI;
    this.RE = parseInt(options.RE) || this.RE;
    this.VI = parseInt(options.VI) || this.VI;
    this.WI = parseInt(options.WI) || this.WI;

    this.DiseaseResist = parseInt(options.DiseaseResist) || this.DiseaseResist;
    this.DivineResist = parseInt(options.DivineResist) || this.DivineResist;
    this.ElementalResist = parseInt(options.ElementalResist) || this.ElementalResist;
    this.PoisonResist = parseInt(options.PoisonResist) || this.PoisonResist;
    this.QiResist = parseInt(options.QiResist) || this.QiResist;

    this.Inheritance = parseInt(options.Inheritance) || this.Inheritance;
    this.SortOrder = parseInt(options.SortOrder) || this.SortOrder;

  }
}