<template>
<div class="loader">Signing In...</div>
</template>

<script>
export default {
  name: "LoginLoader"
}
</script>

<style lang="scss" scoped>
.loader {
  color: black;
  animation: load7 1.8s infinite ease-in-out;
}
@keyframes load7 {
  0% {
    text-shadow: 0 0 0 rgb(255, 0, 0);
  }
  50% {
    text-shadow: 0 0 5px rgb(255, 119, 119);
  }
  100% {
    text-shadow: 0 0 0 rgb(255, 0, 0);
  }
}
</style>