export default class SkillCost {
  constructor(options, cost) {

    this.Skill_ID = 0;
    this.SkillGroup_ID = 0;
    this.SkillName = null;
    this.Description = null;
    this.Stat1 = null;
    this.Stat2 = null;
    this.IsActive = false;
    this.Cost = 0;

    if(options) {
      this.init(options, cost);
    }    

  }

  init(options, cost) {

    this.Skill_ID = parseInt(options.Skill_ID) || this.Skill_ID;
    this.SkillGroup_ID = parseInt(options.SkillGroup_ID) || this.SkillGroup_ID;
    this.SkillName = options.SkillName || this.SkillName;
    this.Description = options.Description || this.Description;
    this.Stat1 = options.Stat1 || this.Stat1;
    this.Stat2 = options.Stat2 || this.Stat2;
    this.IsActive = options.IsActive || this.IsActive;
    this.Cost = parseInt(cost) || this.Cost;

  }

}