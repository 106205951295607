export default class ManeuverRoll {
  constructor(options) {

    this.ManeuverRoll_ID = 0;
    this.Difficulty = null;
    this.Result = null;
    this.g20Roll = null;
    this.SortOrder = 0;

    if(options) {
      this.init(options);
    }

  }

  init(options) {
        
    this.ManeuverRoll_ID = parseInt(options.ManeuverRoll_ID) || this.ManeuverRoll_ID;
    this.Difficulty = options.Difficulty || this.Difficulty;
    this.Result = options.Result || this.Result;
    this.g20Roll = options.g20Roll || this.g20Roll;
    this.SortOrder = parseInt(options.SortOrder) || this.SortOrder;
    
  }
}