<template>  
<transition name="modal">
  <div class="modal-mask" @click="closeOnMaskClick ? close() : null" v-show="controller.show">
    <div class="modal-wrapper">
      <div class="modal-container" :class="containerClass" @click.stop>  
        <slot></slot>      
      </div>
    </div>
  </div>
</transition>
</template>

<script>
//--------------------------------------------
//  Base Modal - defines styles and behaviors
//--------------------------------------------
export default {
  name: "Modal",
  props: {
    controller: {
      type: Object,
      required: true
    },
    containerClass: {
      type: String,
      default: "default"
    },
    closeOnMaskClick:{
      type: Boolean,
      default:false
    },
    closeOnEscapeKey:{
      type: Boolean,
      default:true
    }
  },
  methods: {
    close: function() {
      this.$emit('close');
    }
  },
  mounted: function() {
    if (this.closeOnEscapeKey == true) {
      document.addEventListener("keydown", e => {
        if (this.controller.show && e.key == 'Escape') {
          this.close();
        }
      });
    }
  }
};
//--------------------------------------------
</script>

<style lang="scss" scoped>

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  text-align: left;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container {
      margin: 0px auto;
      background-color: #333;
      border: #000 solid 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;

      &.default {
        width: 96%;
      }
    }
    
  }

}

@media screen and (min-width: 768px) {
  .modal-mask {
    .modal-wrapper {
      .modal-container {
        &.default {
          width: 500px;
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .modal-mask {
    .modal-wrapper {
      .modal-container {
        &.default {
          width: 800px;
        }
      }
    }
  }
}

//-----------------------------------------  TRANSITIONS
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>