<template>
<div class="logo">
  <img class="logo__image" src="/img/logo.png" />
  <div class="logo__version">
    <strong>Version: {{version}}</strong>
  </div>
</div>
</template>

<script>
//-------------------------------------------------------
const versionKey = process.env.VUE_APP_VERSION_KEY;
//-------------------------------------------------------
export default {
  name: "g20Logo",
  computed: {
    version() {
      let version = localStorage.getItem(versionKey);
      return version || '0.0.0.0';
    }
  },
}
</script>

<style lang="scss" scoped>

$progress-bar-width-desktop: 350px;
$progress-bar-width-mobile: 225px;
$logo-image-height-desktop: 225px;
$logo-image-height-mobile: 180px;

.logo {
  display: block;
  text-align: center;
  color: #fff;
  padding: 30px 0 40px 0;

  .logo__image {
    height: $logo-image-height-mobile;
    width: auto;
  }

  .logo__version {
    margin-top: -55px;
    padding-left: 70px;
  }

  .logo__progress-bar {
    margin: 0 auto;
    color: #fff;
    width: $progress-bar-width-mobile;
  }

}
@media screen and (min-width: 768px) {

  .logo {
    padding: 60px 0 100px 0;

    .logo__image {
      height: $logo-image-height-desktop;
      width: auto;
    }
    .logo__version {
      margin-top: -65px;
      padding-left: 70px;
    }
    .logo__progress-bar {
      margin: 0 auto;
      color: #fff;
      width: $progress-bar-width-desktop;
    }
  }

}


</style>