<template>
<div class="skill-group" v-if="skillGroup">

  <div class="title">
    <div class="title-skill-name">
      <a title="Untrained='(Lvl. Bonus + Stat 1 + Stat 2) / 2'">
        {{skillGroup.SkillGroupName}}
      </a>
    </div>
    <div class="legend">
      <div>Cost</div>
      <div>Ranks</div>
      <div>g20</div>
    </div>
  </div>

  <div class="untrained">
    <div>Untrained {{skillGroup.SkillGroupName}} Skill</div>
    <div class="untrained-bonus modifier">{{untrained}}</div>
  </div>

  <div class="list">
    <Skill 
      v-for="[sKey, skill] in Object.entries(skillGroup.Skill)" 
      :key="sKey" 
      :value="skill"
      :editable="editable"
      @update-skill="$emit('update-skill', $event)">
    </Skill>
  </div>
  
</div>
</template>

<script>
//--------------------------------------------
//--------------------------------------------
export default {
  name: "SkillGroup",
  props: {
    attributes: Object,
    skillGroup: Object,
    editable: {
      type: Boolean,
      default: false
    }
  },
  computed:{
    skillGroupBonus() {

      let primaryBonus = 0;
      if(this.skillGroup.Stat1)
        primaryBonus = this.skillGroup.Stat1 != "" ? this.attributes[this.skillGroup.Stat1].PrimaryTotal : 0;

      let secondaryBonus = 0;
      if(this.skillGroup.Stat2)
        secondaryBonus = this.skillGroup.Stat2 != "" ? this.attributes[this.skillGroup.Stat2].SecondaryTotal : 0;

      let finalValue = parseInt(primaryBonus) + parseInt(secondaryBonus);
      return finalValue;
    },
    untrained() {
      return Math.round((this.skillGroupBonus + parseInt(this.skillGroup.LevelBonus)) * 0.5);
    }
  }
};
</script>

<style lang="scss" scoped>

@import '../styles/base/colors';
@import '../styles/base/typography';

$skill-stat-width: 30px;

.skill-group {
  min-width: 200px;
  font-size: 11px;

  .title {
    display: flex;
    justify-content: space-between;
    background:#ccc;
    
    .title-skill-name {
      font-size: 13px;
      padding: 2px;
      font-weight: bold;
    }
    
    .legend {
      display: flex;
      justify-content: flex-end;
      padding: 3px 2px 0 0;

      &> div {
        text-align: center;
        font-size: 10px;
        width: $skill-stat-width;
      }

    }

  }

  .untrained {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #efefef;
    background: #efefef;
    font-style: italic;
    
    .untrained-bonus {
      width: $skill-stat-width;
      font-family: $stat-font;
      text-align: center;
      padding-top: 2px;
      font-style: normal;
    }
  }
}
</style>