import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import App from './store/App'
import Character from './store/Character'
import Content from './store/Content'
import Forge from './store/Forge'
import GameMaster from './store/GameMaster'

// ---------------------------------------------------------
const store = new Vuex.Store({
  modules: {
    App,
    Character,
    Content,
    Forge,
    GameMaster
  }
});

// ---------------------------------------------------------
export default store