<template>
  <div class="basic-info" v-if="character" :class="{'editable': editable}" @click.stop="editable=true">

    <div class="command-buttons" v-if="editable">
      <button class="btn-gem green btn-sm" title="Save Character" @click.stop="saveCharacter()">Save</button>
      <button class="btn-gem red btn-sm" title="Cancel" @click.stop="cancel()">Cancel</button>
    </div>
  
    <div>
      <div class="name">
        <EditableContent v-if="editable" v-model="character.Info.Name" />
        <span v-else>{{character.Info.Name}}</span>
      </div>
      <div class="title">
        <EditableContent v-if="editable" v-model="character.Info.Title" />
        <span v-else>{{character.Info.Title}}</span>
      </div>
    </div>

    <div class="level-and-race">
      <div class="level">
        <span>{{ numericWord(character.Level) }} </span>
        <EditableContent v-if="editable" v-model="character.LevelText" />
        <span v-else>{{character.LevelText}}</span>
      </div>
      <div class="race">
        <span>{{character.Race.PrimaryRaceName}} </span>
        <span>{{character.Class.ClassName}} </span>
      </div>
    </div>

    <div class="demographic">
      <div>
        <span>Age:</span>
        <EditableContent v-if="editable" v-model="character.Info.Age" />
        <span v-else>{{character.Info.Age}}</span>
        <span> | </span>
        <EditableContent v-if="editable" v-model="character.Info.Gender" />
        <span v-else>{{character.Info.Gender}}</span>
      </div>
      <div>
        <span>Height:</span>
        <EditableContent v-if="editable" v-model="character.Info.Height" />
        <span v-else>{{character.Info.Height}}</span>
        <span> | </span>
        <span>Weight:</span>
        <EditableContent v-if="editable" v-model="character.Info.Weight" />
        <span v-else>{{character.Info.Weight}}</span>
        <span> | </span>
        <span> Hair:</span>
        <EditableContent v-if="editable" v-model="character.Info.Hair" />
        <span v-else>{{character.Info.Hair}}</span>
        <span> | </span>
        <span> Eyes:</span>
        <EditableContent v-if="editable" v-model="character.Info.Eyes" />
        <span v-else>{{character.Info.Eyes}}</span>
      </div>
    </div>

    <div class="experience">
      <span>Experience: </span>
      <EditableContent v-if="editable" v-model="character.Xp" :numeric="true" />
      <span v-else>{{character.Xp}}</span>
      <span> / {{ nextLevelXp }} </span>
    </div>

  </div>
</template>

<script>
//------------------------------------------------------
import { numericWord } from '../../code/utils/g20Utils'
//------------------------------------------------------
export default {
  name: "BasicInfo",
  props: {
    value: Object
  },
  watch: {    
    value: {
      handler(val) {
        if(val) {
          this.character = this.$deepClone(this.value);
          this.getExperienceNeeded(this.character.Level + 1);
        }
      },
      immediate: true
    },
  },
  data() {
    return {
      character: null,
      editable: false,
      nextLevelXp: -1
    }
  },
  methods: {

    numericWord(val) {
      return numericWord(val);
    },

    async getExperienceNeeded(level) {      
      let experience = await this.$store.dispatch('Content/getExperienceNeeded', level);
      this.nextLevelXp = experience.ExperienceNeeded;
    },

    cancel() {
      this.editable=false;
      this.$emit('cancel');
    },

    saveCharacter() {
      this.editable = false;
      this.$emit('save-character', this.character);
    }

  }
};
</script>

<style lang="scss" scoped>

.basic-info {
  position: relative;
  display: block;
  text-align: center;
  border: dotted 1px transparent;
  padding: 5px 0;

  &.editable {
    border: dotted 1px blue;
    padding-top: 20px;
  }

  .command-buttons{
    position: absolute;
    top: 0;
    right: 0;
  }
}

.name {
  width: 100%;
  font-size: 35px;
  text-align: center;
}

.title {
  margin: 0 auto;
  width: 80%;
  color: #666;
  font-style: italic;
  font-size: 16px;
  text-align: center;
}

.level-and-race { 
  font-size:18px;
}

.demographic { 
  display: block;
  font-size: 14px;
}
.demographic > div { 
  padding: 2px 0;
}

.experience {
  padding: 2px 0;
}



.character-sheet.condensed .demographic {
  display: none;
}

</style>