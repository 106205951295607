//--------------------------------------------------------------- import models
import Armor from './content-models/Armor'
import AttackType from './content-models/AttackType'
import Class from './content-models/Class'
import CriticalScore from './content-models/CriticalScore'
import CriticalSeverity from './content-models/CriticalSeverity'
import CriticalSize from './content-models/CriticalSize'
import CriticalType from './content-models/CriticalType'
import Difficulty from './content-models/Difficulty'
import Experience from './content-models/Experience'
import Inheritance from './content-models/Inheritance'
import Language from './content-models/Language'
import LevelBonusBackground from './content-models/LevelBonusBackground'
import ManeuverResult from './content-models/ManeuverResult'
import ManeuverRoll from './content-models/ManeuverRoll'
import Material from './content-models/Material'
import Race from './content-models/Race'
import Realm from './content-models/Realm'
import Score from './content-models/Score'
import Setting from './content-models/Setting'
import Skill from './content-models/Skill'
import SkillCost from './content-models/SkillCost'
import SkillGroup from './content-models/SkillGroup'
import SpecialAbility from './content-models/SpecialAbility'
import SpecialAbilityType from './content-models/SpecialAbilityType'
import Spell from './content-models/Spell'
import SpellAbility from './content-models/SpellAbility'
import SpellList from './content-models/SpellList'
import SpellListType from './content-models/SpellListType'
import SpellPrep from './content-models/SpellPrep'
import SpellType from './content-models/SpellType'
import Stat from './content-models/Stat'
import StatGain from './content-models/StatGain'
import Vice from './content-models/Vice'
import WeaponGroup from './content-models/WeaponGroup'
import Weapons from './content-models/Weapons'
//---------------------------------------------------------------  exports
export default {
  Armor,
  AttackType,
  Class,
  CriticalScore,
  CriticalSeverity,
  CriticalSize,
  CriticalType,
  Difficulty,
  Experience,
  Inheritance,
  Language,
  LevelBonusBackground,
  ManeuverResult,
  ManeuverRoll,
  Material,
  Race,
  Realm,
  Score,
  Setting,
  Skill,
  SkillCost, // * used to transform 'skill'
  SkillGroup,
  SpecialAbility,
  SpecialAbilityType,
  Spell,
  SpellAbility,
  SpellList,
  SpellListType,
  SpellPrep,
  SpellType,
  Stat,
  StatGain,
  Vice,
  WeaponGroup,
  Weapons
}