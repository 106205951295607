export default class Score {
  constructor(options) {

    this.Score = 0;
    this.PrimaryBonus = 0;
    this.SecondaryBonus = 0;
    this.Fate = 0;
    this.PrimaryMana = 0;
    this.SecondaryMana = 0;

    if(options) {
      this.init(options);
    }

  }

  init(options) {
        
    this.Score = parseInt(options.Score) || this.Score;
    this.PrimaryBonus = parseInt(options.PrimaryBonus) || this.PrimaryBonus;
    this.SecondaryBonus = parseInt(options.SecondaryBonus) || this.SecondaryBonus;
    this.Fate = parseInt(options.Fate) || this.Fate;
    this.PrimaryMana = parseInt(options.PrimaryMana) || this.PrimaryMana;
    this.SecondaryMana = parseInt(options.SecondaryMana) || this.SecondaryMana;
    
  }
}