export default class SpellAbility {
  constructor(options) {

    this.SpellAbility_ID = 0;
    this.SpellAbilityDesc = null;

    this.BaseTomeCost = 0;
    this.TomesBeforeCostIncrease = 0;
    this.AdditionalTomeCost = 0;
    this.ExtraSpellCost = 0;
    this.ExtraMundaneCost = 0;
    this.ExtraUrbaneCost = 0;
    this.ExtraArcaneCost = 0;
    this.ExtraClassCost = 0;
    this.ExtraHybridCost = 0;
    
    if(options) {
      this.init(options);
    } 

  }

  init(options) {
    
    this.SpellAbility_ID = parseInt(options.SpellAbility_ID) || this.SpellAbility_ID;
    this.SpellAbilityDesc = options.SpellAbilityDesc || this.SpellAbilityDesc;

    this.BaseTomeCost = parseInt(options.BaseTomeCost) || this.BaseTomeCost;
    this.TomesBeforeCostIncrease = parseInt(options.TomesBeforeCostIncrease) || this.TomesBeforeCostIncrease;
    this.AdditionalTomeCost = parseInt(options.AdditionalTomeCost) || this.AdditionalTomeCost;
    this.ExtraSpellCost = parseInt(options.ExtraSpellCost) || this.ExtraSpellCost;
    this.ExtraMundaneCost = parseInt(options.ExtraMundaneCost) || this.ExtraMundaneCost;
    this.ExtraUrbaneCost = parseInt(options.ExtraUrbaneCost) || this.ExtraUrbaneCost;
    this.ExtraArcaneCost = parseInt(options.ExtraArcaneCost) || this.ExtraArcaneCost;
    this.ExtraClassCost = parseInt(options.ExtraClassCost) || this.ExtraClassCost;
    this.ExtraHybridCost = parseInt(options.ExtraHybridCost) || this.ExtraHybridCost;
    
  }
}