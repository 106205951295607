<template>
<div class="brass-frame">
<slot></slot>
</div>
</template>

<script>
export default {
  name: "BrassFrame"
}
</script>

<style lang="scss" scoped>

$artworkRoot: '/img/artwork/';

.brass-frame {
  border: solid 6px rgb(170, 152, 93);
  border-image-source: url($artworkRoot + 'border-brass-frame.png');
  border-image-slice: 10;
  border-image-width: auto;
  border-image-repeat: repeat;
  background: url($artworkRoot + 'bkg-slate.png');
  background-size: 100%;
  color: #fff;
}

</style>