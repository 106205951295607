export default class AttackType {
  constructor(options) {

    this.AttackType = null;
    this.AttackCode = null;
    this.SortOrder = -1;

    if(options){
      this.init(options);
    }
  }

  init(options) {    
    this.AttackType = options.AttackType || this.AttackType;
    this.AttackCode = options.AttackCode || this.AttackCode;
    this.SortOrder = parseInt(options.SortOrder) || this.SortOrder;
  }
}