<template>
<div class="forge-nav-frame">
<slot></slot>
</div>
</template>

<script>
export default {
  name: "ForgeNavFrame"
}
</script>

<style lang="scss" scoped>

$artworkRoot: '/img/artwork/';

.forge-nav-frame {
  border: solid 6px rgb(170, 152, 93);
  border-image-source: url($artworkRoot + 'border-forge-nav.png');
  border-image-slice: 33;
  border-image-width: auto;
  border-image-repeat: repeat;
  background: url($artworkRoot + 'texture-dark-blue-cloth.png');
  background-size: 100%;
  color: #fff;
  padding: 5px 3px;
  min-height: 50px;
}

</style>