<template>
<KazdulFrame class="login">

  <h2 class="login__header"> {{greeting}} </h2>

  <validation-observer ref="form">

    <div class="login__form">
      <validation-provider name="Username" rules="required|max:20" v-slot="{ errors }">
        <div class="form-group" :class="{'has-error': errors.length > 0}">
          <label for="Username">Username</label>
          <input name="Username" class="full" type="text" v-model="loginForm.Handle" >
          <small class="text-danger">{{ errors[0] }}</small>
        </div>
      </validation-provider>

      <validation-provider name="Password" rules="required|max:20" v-slot="{ errors }">
        <div class="form-group" :class="{'has-error': errors.length > 0}">
          <label for="Password">Password</label>
          <input name="Password" class="full" type="password" v-model="loginForm.pw" @keyup.enter="login()" >
          <small class="text-danger">{{ errors[0] }}</small>
        </div>
      </validation-provider>
    </div>
    
  </validation-observer>

  <AnnotationContainer category="error" v-if="message">
    {{message}}
  </AnnotationContainer>

  <div class="login__buttons">
    <div>
      <div v-show="loggingIn">
        <LoginLoader></LoginLoader>
      </div>
    </div>
    <div>
      <button class="btn-sm btn-capped-red" @click="login()">Sign In</button>
    </div>
  </div>

</KazdulFrame>
</template>


<script>
//-------------------------------------------------------
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { setProfile, getProfile } from '../../../code/app/playerProfile'
import repo from '../../../code/app/repo'
//-------------------------------------------------------
const greetings = [
  "Through shadow, and flame",
  "Speak Friend, and Enter",
  "Prove thyself worthy",
  "All heroes welcome",
  "It all starts here",
  "Glory awaits"
]
//-------------------------------------------------------
export default {
  name: "g20LoginForm",

  components: { ValidationObserver, ValidationProvider },

  data() {
    return {
      greeting: null,
      loggingIn: false,
      message: null,
      loginForm: {
        Handle: null,
        pw: null
      }
    }
  },

  mounted() {
    this.greeting = greetings[this.$getRandomInclusive(0, greetings.length-1)];

    let profile = getProfile();
    //console.log("profile", profile);

    if(profile) {
      // handle login expiration?
      this.$router.push({ name: "Concourse"});
    }

  },

  methods: {

    login() {

      this.$refs.form.validate().then(isValid => {
        if(isValid) {

          this.message = null;
          this.loggingIn = true; 
          repo.player.login(this.loginForm).then(res => {
            this.loggingIn = false;

            if(res.data.success) {
              let playerData = {
                Email: res.data.data.Email,
                Handle: res.data.data.Handle,
                IsValidated: res.data.data.IsValidated,
                NameFirst: res.data.data.NameFirst,
                NameLast: res.data.data.NameLast,
                Phone: res.data.data.Phone,
                Player_ID: parseInt(res.data.data.Player_ID),
                VerifyGUID: res.data.data.VerifyGUID
              }
              setProfile(playerData); 
              this.$emit('login-successful');
            } else {
              this.message = res.data.message;
            }
        
          });

        }
      });

    },

  }
}
</script>

<style scoped lang="scss">

$login-control-width-mobile: 300px;
$login-control-width-desktop: 420px;

.login {

  font-family: 'Fondamento', cursive;
  position: relative;
  display: block;
  margin: 0 auto;
  text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.75);
  width: $login-control-width-mobile;
  z-index: 10;

  .login__header {
    margin: 0;
    text-align: center;
    margin-bottom: 15px;
    font-size: 25px;
  }

  .login__form {
    margin: 10px;
  }

  .login__message {
    margin: 10px;
    padding: 5px 10px;
    color: #fff;
    text-align: center;
    background-color: rgba(150,0,0,0.5);
    text-shadow: none;
  }

  .login__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

}

@media screen and (min-width: 768px) {
  .login {
    width: $login-control-width-desktop;    

    .login__header {
      margin-bottom: 25px;
      font-size: 30px;
    }   

  }
}

</style>