
export default class Wealth {
  constructor(options) {

    this.Currency = {
      bp: { label:'Bronze', Code:'Bp', Value: .01, Color: "#EEA35F", Quantity: 0 },
      sp: { label:'Silver', Code:'Sp', Value: 1, Color: "#B2A690", Quantity: 0 },
      gp: { label:'Gold', Code:'Gp', Value: 100, Color: "#E0B027", Quantity: 0 },
      mp: { label:'Mithril', Code:'Mp', Value: 10000, Color: "#F4EDCC", Quantity: 0 }
    };
    this.Other = null;

    if(options){
      this.init(options);
    }

  }

  init(options) {    
    this.Currency = options.Currency ? options.Currency : this.Currency;
    this.Other = options.Other ? options.Other : null;
  }

}