<template>
<div class="spell-ability" v-if="character">

  <div class="spell-ability__column">
    <div class="spell-ability__label">Spell&nbsp;Ability</div>
    <div class="spell-ability__info">
      <span v-if="!isEditMode"> {{character.SpellAbility.SpellAbilityDesc}} </span>
      <editable-dropdown v-else 
        :text="character.SpellAbility.SpellAbilityDesc" 
        :items="spellAbilities" 
        empty-width="80px" 
        name-prop="SpellAbilityDesc" 
        @input="updateSpellAbility($event)"></editable-dropdown>
    </div>
  </div>

  <div class="spell-ability__column">
    <div class="spell-ability__label">Realm</div>
    <div class="spell-ability__info">
      <editable-dropdown  v-if="isEditMode"
        :text="character.SpellAbility.PrimaryRealm"
        :items="realms" 
        empty-width="80px" 
        name-prop="RealmDesc" 
        @input="updateCharacterRealm('PrimaryRealm', $event)"></editable-dropdown>
      <span v-else>
        <span v-if="character.SpellAbility.PrimaryRealm">{{character.SpellAbility.PrimaryRealm}}</span>
        <span v-else>None</span>
      </span>

      <span v-if="character.SpellAbility.SecondaryRealm">
        <span> / </span>
        <span>
          <editable-dropdown  v-if="isEditMode" 
            :text="character.SpellAbility.SecondaryRealm"
            :items="realms"
            empty-width="80px"  
            name-prop="RealmDesc" 
            @input="updateCharacterRealm('SecondaryRealm', $event)"></editable-dropdown>
          <span v-else>{{character.SpellAbility.SecondaryRealm}}</span>

        </span>
      </span>

    </div>
  </div>

  <div class="spell-ability__column">
    <div class="spell-ability__label">Manapool</div>
    <div class="spell-ability__info">
      <span class="modifier mana-points">{{mana}}</span>
    </div>
  </div>

</div>
</template>

<script>
//------------------------------------------------------
//------------------------------------------------------
export default {
  name: "SpellAbility",

  props: {
    value: Object,
      realms: Array,
      spellAbilities: Array
  },

  watch: {    
    value: {
      handler() { this.init() },
      immediate: true
    },
  },

  data() {
    return {
      character: null
    }
  },
  
  computed:{
    isEditMode() { return false; },
    mana() { 
      let skill = this.character.SkillGroups.Spellcasting ? this.character.SkillGroups.Spellcasting.Skill.Manapool : null;
      return skill ? skill.SkillBonus : 0;
    }
  },

  methods: {
    async init() { 
      this.character = this.$deepClone(this.value);
    },
  }
};
</script>

<style lang="scss" scoped>

.spell-ability {
  display: flex;
  border: dotted 1px silver;
  background-color: #fff;
  width: 100%;
  padding: 5px;
  margin-bottom:5px;

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  
}

.spell-ability__column {
  flex: 1;
  text-align: center;

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.spell-ability__label {
  font-weight: bold;
  font-size: 14px;
}

.spell-ability__info {
  font-size: 12px;
}

</style>