<template>
  <div class="character-skill" v-if="skill && skill.Ranks>0">
    <div class="skill-name">{{skill.SkillName}}</div>
    <div class="stats">
      <div class="stat">
        <span>{{skill.Cost}}</span>
      </div>
      <div class="stat">
        <transition name="fade" mode="out-in">
          <span v-if="!editable" :key="skill.Ranks">{{skill.Ranks}}</span>
          <NumericStepper v-else :min="0" v-model="skill.Ranks" :horizontal="true" @input="updateSkill()" />
        </transition>
      </div>
      <div class="stat modifier">
        <transition name="fade" mode="out-in">
          <span :key="skill.SkillBonus">{{skill.SkillBonus}}</span>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
//--------------------------------------------
//--------------------------------------------
export default {
  name: "Skill",
  props: {
    value: Object,
    showZeroRanks: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  watch: { 
    value: {
      handler() { this.skill = this.$deepClone(this.value) },
      immediate: true
    },
  },
  data() {
    return {
      character: null,  //?
      skill: null
    }
  },
  methods: {
    updateSkill() {
      this.$emit('update-skill', this.skill);
    }
  }
};
</script>

<style lang="scss" scoped>

@import '../styles/base/colors';
@import '../styles/base/typography';

$skill-stat-width: 30px;

.character-skill {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: solid 1px #efefef;
  font-size: 11px;
  font-family: $stat-font;

  .stats {
    display: flex;
    .stat {
      padding: 0;
      margin: 0;
      width: $skill-stat-width;
      text-align: center;
    }
  }  
}
</style>