<template>
<MagicFrame v-if="ready"> 

  <div class="spells-select">

    <header>
      <div class="title">
        Spells
        <G20Help book="GameCanon.pdf" bookmark="Magic"></G20Help>
      </div>
    </header>

    <div class="spell-points">
      <div class="points-pool">
        <div class="points-pool-item" v-for="(spp, index) in spentPointsPool" :key="index">
          <span v-if="spp.type!='Skill'">{{spp.type}} {{spp.group}}: {{spp.value}}</span>
        </div>
      </div> 
    </div>

    <!-- TOMES -->
    <div v-if="mode === 'tomes'">
      <TomesAvailable
        :character="character" 
        @select-list="selectSpellList"
      ></TomesAvailable>
    </div>

    <!-- DETAIL -->
    <div v-if="spellList && mode === 'detail'">
      <SpellListDetail 
        :spell-list="spellList" 
        :spellAbility="character.SpellAbility"
        :character="character" 
        @unlock="unlockTome" 
        @spell-select="selectSpell"
        @back="mode='tomes'">
      </SpellListDetail>
    </div>

  </div>

</MagicFrame>
</template>

<script>
//------------------------------------------------------
import swal from 'sweetalert'
import { setPointPool } from '../../../code/game/objectFactory'
import { blendSpellLedger,tallySpentPoints } from '../../../code/game/calculations'
import { getSpellList } from '../../../code/game/calculations'
import g20CharacterModels from "../../../code/models/g20CharacterModels";
//------------------------------------------------------
export default {
  name: "SpellsSelect",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
  },
  data() {
    return {
      mode: 'tomes',
      character: {},
      pointsPool: [],
      spentPointsPool: [],
      spellList: null,
      skillLedger: {},
      spellLedger: {}
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {

          let characterData = await this.$store.dispatch('Character/getCharacter', this.forgeState.characterRecordId); 
          let character = new g20CharacterModels.Character(characterData);

          let pointsPool = this.forgeState.ledger.pointsPool;
          if(!pointsPool) {
            pointsPool = setPointPool(character, this.forgeState.forgeMode);
            this.$store.dispatch('Forge/setLedgerItems', { pointsPool: pointsPool });
          }
          this.pointsPool = pointsPool;
          this.spentPointsPool = pointsPool;
          this.character = character;

          this.setState();
        }
      },
      immediate: true
    },
    mode() {
      window.scrollTo(0, 0);
    }
  },
  methods: {
    
    setState() {
      this.skillLedger = this.forgeState.ledger.skillLedger;
      let spellLedger = this.forgeState.ledger.spellLedger;
      this.spellLedger = blendSpellLedger(spellLedger);
      this.setLedgerItems();
    },

    async selectSpellList(sl) {
      let spells = await this.$store.dispatch('Content/getSpells', sl.SpellListID);
      sl.Spells = spells;
      this.spellList = sl;
      this.mode = 'detail';
    },

    //-------------------------------------------

    unlockTome(sl) {
      swal({
        title: "Unlock Tome?", text: `Unlock Tome for ${sl.ListCost}?`, buttons: true, dangerMode: true,
      })
      .then((confirm) => {
        if (confirm) {
          let spellList = new g20CharacterModels.SpellList(sl);
          spellList.Spells = [];
          this.spellLedger.selected.push(spellList);
          this.setLedgerItems();
          console.log(this.spellLedger);
        }
      });
    },

    selectSpell(sp) {

      let spellLedger = this.$deepClone(this.spellLedger);
      let spellListObj = getSpellList(spellLedger, sp.SpellListID);
      let spellList = null;

      if(spellListObj.selected) {
        spellList = spellLedger.selected.find(sl => sl.SpellListID === sp.SpellListID);
      } else  {
        spellList = spellLedger.original.find(sl => sl.SpellListID === sp.SpellListID);
      }

      let spellIndex = spellList.Spells.findIndex(s => s.Spell_ID === sp.Spell_ID);
      if(spellIndex<0) {
        spellList.Spells.push(sp);
      } else if(spellIndex >= 0 && spellListObj.selected) {
        spellList.Spells.splice(spellIndex, 1);  // limit removal to 'selected' list only!!
      }
      spellList.Spells.sort((a, b) => (a.Level > b.Level) ? 1 : -1);

      this.spellLedger = blendSpellLedger(spellLedger);
      this.setLedgerItems();

    },

    async setLedgerItems() {
      this.spentPointsPool = tallySpentPoints({pointsPool: this.pointsPool, skills: this.skillLedger, spells: this.spellLedger});
      await this.$store.dispatch('Forge/setLedgerItems', { 
        skillLedger: this.skillLedger, 
        spellLedger: this.spellLedger,
        spentPointsPool: this.spentPointsPool        
      });
    }
  }
}
</script>

<style lang="scss" scoped>

.spells-select {
  header {
    display: flex;
    justify-content: space-between;
    padding: 3px 0;
    margin-bottom: 3px;
  }
}

.spell-points {
  padding: 10px;
  margin-bottom: 5px;
  border: solid 1px rgba(0,0,0,0.5);
  background-color: rgba(0,0,0,0.25);

  .title {
    margin-bottom: 2px;
  }

  .points-pool {
    display: flex;
    flex-wrap: wrap;

    .points-pool-item {
      width: 50%;
      font-size: 12px;
    }
  }

}
</style>