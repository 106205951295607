<template>
<div class="possessions-list" v-if="possessionSummary">
  <div class="title">Added Gear:</div>
  <div class="possession-list-item" v-for="i in possessionSummary" :key="i.ItemKey">
    <div class="name">{{i.Name}} {{$formatBonus(i.ItemBonus)}}</div>
  </div>
</div>
</template>

<script>
export default {
  name: "PossessionSummary",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
  },
  data() {
    return {
      possessionSummary: []
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {
          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {
    setState() {
      this.possessionSummary = this.$deepClone(this.forgeState.ledger.itemsLedger);
    }
  }
}
</script>


<style lang="scss" scoped>
.possessions-list {
  margin: 10px;
  padding: 10px;
  background-color: rgba(0,0,0,0.25);
  border: solid 1px rgba(0,0,0,0.25);
  .title {
    margin-bottom: 10px;
  }
  .possession-list-item {
    font-size: 14px;
    padding: 2px 10px;
    color: #b3c1ff;
  }
}
</style>