<template>
<MagicFrame v-if="ready"> 

  <div class="creation-summary">

    <header>
      <div class="title">
        Character Creation Summary
        <G20Help book="GameCanon.pdf" bookmark="Character Creation Summary"></G20Help>
      </div>
    </header>

    <InfoSummary :character-info="characterInfo"></InfoSummary>

    <FateSummary :character-fate="characterFate"></FateSummary>

    <RaceSummary 
      :summary="true" 
      :character-race="characterRace">
    </RaceSummary>

    <ClassSummary 
      :summary="true"
      :character-class="characterClass" 
      :character-spell-ability="characterSpellAbility">
    </ClassSummary>

    <RealmSummary :character-spell-ability="characterSpellAbility"></RealmSummary>
    
    <AttributesSummary 
      :character-attributes="characterAttributes"
      :character-class="characterClass">
    </AttributesSummary>

    <BackgroundsSummary 
      :character-backgrounds="characterBackgrounds"
      :character-features="characterFeatures">
    </BackgroundsSummary>

    <div class="save-button">
      <button class="btn-capped-red btn-sm" @click="saveCharacter()">Finish and Save</button>
    </div>

  </div>

</MagicFrame>
</template>

<script>
//------------------------------------------------------
import swal from 'sweetalert'
//------------------------------------------------------
export default {
  name: "CreationSummary",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; }
  },
  data() {
    return {
      characterAttributes: null,
      characterClass: null,
      characterFate: null,
      characterFeatures: null,
      characterInfo: null,
      characterRace: null,
      characterResists: null,
      characterSpellAbility: null
    }
  },
  watch: {
    ready: {
      handler(val) {
        if(val) {
          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {

    setState() {      

      this.characterAttributes = this.forgeState.ledger.characterAttributes;
      this.characterBackgrounds = this.forgeState.ledger.characterBackgrounds;
      this.characterClass = this.forgeState.ledger.characterClass;
      this.characterFate = this.forgeState.ledger.characterFate;
      this.characterFeatures = this.forgeState.ledger.characterFeatures;
      this.characterInfo = this.forgeState.ledger.characterInfo;
      this.characterRace = this.forgeState.ledger.characterRace;
      this.characterResists = this.forgeState.ledger.characterResists;
      this.characterSpellAbility = this.forgeState.ledger.characterSpellAbility;
    },

    async saveCharacter() {
 
      let confirm = await swal({
        title: "Save Character?", text: `Have you made all of the selections you want for this Characrer?`, buttons: true, dangerMode: true,
      })

      if (confirm) {
        let res = await this.$store.dispatch('Character/createCharacter');        
        console.log(res);
        if(res) {              
          this.$router.push({name: 'Concourse'});
        }        
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
.save-button {
  width: 100%;
  border: solid 1px rgba(0,0,0,0.5);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  margin: 5px;
  font-size: 14px;
  text-align: center;
}
</style>