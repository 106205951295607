export default class StatGain {
  constructor(options) {

    this.StatGain_ID = 0;
    this.highroll = 0;
    this.lowroll = 0;
    this.adj = 0;
    this.diff = 0;

    if(options) {
      this.init(options)
    }

  }

  init(options) {

    this.StatGain_ID = parseInt(options.StatGain_ID) || this.StatGain_ID;
    this.highroll = parseInt(options.highroll) || this.highroll;
    this.lowroll = parseInt(options.lowroll) || this.lowroll;
    this.adj = parseInt(options.adj) || this.adj;
    this.diff = parseInt(options.diff) || this.diff;    

  }

}