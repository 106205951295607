<template>
<MagicFrame v-if="ready"> 

  <div class="fate-display">

    <div class="fate-info-cols">
      <div class="stat-block">
        <h1>
          Your Fate          
          <G20Help book="GameCanon.pdf" bookmark="Your Fate"></G20Help>
        </h1>

        <FateFrame class="fate">
          {{characterFate.startingFate - characterFate.spentFate}}
        </FateFrame>

        <div class="fate-data">
          <div>Starting Fate: {{characterFate.startingFate}}</div>
          <div>Spent Fate: {{characterFate.spentFate}}</div>          
        </div>

      </div>
      <div class="stat-block fate-description">
        Use your Starting Fate to modify your Background and Abilities.
      </div>
    </div>

  </div>

</MagicFrame>
</template>

<script>
//------------------------------------------------------
import * as calc from '../../../code/game/calculations';
//------------------------------------------------------
export default {
  name: "StartingFate",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
  },
  data() {
    return {
      characterFate: { startingFate: 0, spentFate: 0, maxFate: 100 },
      characterBackgrounds: {}
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {            
          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {

    setState() {
      let characterRace = this.forgeState.ledger.characterRace;
      let characterClass = this.forgeState.ledger.characterClass;
      let characterAttributes = this.forgeState.ledger.characterAttributes;

      this.characterFate.startingFate = calc.startingFate(characterRace, characterClass, characterAttributes);
      if(this.forgeState.ledger.characterBackgrounds) {
        this.characterBackgrounds = this.forgeState.ledger.characterBackgrounds;
        this.characterFate.spentFate = calc.spentFate(this.characterBackgrounds);
      }
      this.setLedgerItems();
    },

    setLedgerItems() {
      this.$store.dispatch('Forge/setLedgerItems', { 
        characterFate: this.characterFate,
      });
    }
  }
}
</script>

<style lang="scss" scoped>
$artworkRoot: '/img/artwork/';
.fate-display { 

  .fate {
    display: flex;
    justify-content: center;
    font-size: 55px;
    width: 150px;
    height: 125px;
    margin: auto;
    padding-top: 50px;
    line-height: 0;

  }

  .fate-data {
    display: flex;
  }

  .fate-data > div {
    margin-top:20px;
    text-align: center;
    flex: 1;
  }

  .stat-block {
    padding: 5px;
    background-color: rgba(0,0,0,0.4);
    margin-top:5px;

    h1 {
      margin: 10px 0 0 0;
      padding: 0;
      font-size: 24px;
      text-align: center;
    }
  }

  .fate-description {
    padding: 15px;
    font-size: 14px;
    text-align: center;
  }

}

@media screen and (min-width: 768px) {
  
  .fate-display { 
    .fate-info-cols {
      display: flex;    
    }
    .fate-info-cols > div:first-child {
      margin: 0 3px 0 0;
    }
    .fate-info-cols > div:last-child {
      margin: 0 0 0 3px;
      flex: 1;
    }  

    .fate-description {
      text-align: left;
    }
  }
}

</style>