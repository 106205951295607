import Vue from 'vue';

import * as g20 from './g20Utils'

Vue.mixin({
  methods: {
    //----------------------------------------------  general
    $stringToInt(val) {
      return g20.stringToInt(val);
    },

    $stringToBoolean(val) {
      return g20.stringToBoolean(val);
    },

    $getRandomInclusive(min, max) {
      return g20.getRandomInclusive(min, max);
    },

    $getDistinct(array) {
      return g20.getDistinct(array);
    },
    
    $deepClone(obj) {
      return g20.deepClone(obj);
    },

    //----------------------------------------------  dice
    $rollDice(dieRollString) {
      return g20.rollDice(dieRollString);
    },

    $rollTriad() {
      return g20.rollTriad();
    },
    //----------------------------------------------  screen format
    
    $numericWord(obj) {
      return g20.numericWord(obj);
    },

    $formatBonus(val, includeZero) {
      return g20.formatBonus(val, includeZero);
    },

    $scrollToElement(name) {
      return g20.scrollToElement(name);
    },
    //----------------------------------------------  game logic
    $getDifficultyByCode(difficulties, code) {
      return g20.getDifficultyByCode(difficulties, code);
    },

    $determineCost(triad, list, baseCost) {
      return g20.determineCost(triad, list, baseCost);
    },
    //----------------------------------------------
 
  }
})