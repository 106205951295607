
import g20CharacterModels from '../models/g20CharacterModels'
import QueryRoll from '../models/ui-models/QueryRoll'
import * as calc from '../game/calculations'
import { deepClone } from '../utils/g20Utils'
import { forgeMode } from '../constants'

//====================================================
// Game Object Factory
//   (object creation and conversion)
//====================================================

//===================================================================  Character Race
// creates 'CharacterRace' from two 'ContentRace' inputs
//------------------------------------------------------------
export function createCharacterRace({primaryRace, secondaryRace}) {

  if(!primaryRace) {
    console.error('characterRace requires a primaryRace');
    return;
  }

  let race = new g20CharacterModels.Race();

  race.PrimaryRaceName = primaryRace.RaceName;
  race.SecondaryRaceName = secondaryRace ? secondaryRace.RaceName : null;

  race.RaceGroup = primaryRace.RaceGroup;
  race.MultiRace = secondaryRace ? true : false;

  if(secondaryRace) {
    setMultiRaceStats({race, primaryRace, secondaryRace});
  } else {
    setSingularRaceStats({race, primaryRace});
  }

  return race;
}

function setSingularRaceStats({race, primaryRace}) {
  race.RaceDesc = primaryRace.RaceDesc;
  race.StartingFate = primaryRace.StartingFate;
  race.HitsPerRank = primaryRace.HitsPerRank;
  race.VI = primaryRace.VI;
  race.AG = primaryRace.AG;
  race.WI = primaryRace.WI;
  race.ME = primaryRace.ME;
  race.LO = primaryRace.LO;
  race.MI = primaryRace.MI;
  race.RE = primaryRace.RE;
  race.CH = primaryRace.CH;
  race.EM = primaryRace.EM;
  race.IN = primaryRace.IN;
  race.DivineResist = primaryRace.DivineResist;
  race.ElementalResist = primaryRace.ElementalResist;
  race.QiResist = primaryRace.QiResist;
  race.PoisonResist = primaryRace.PoisonResist;
  race.DiseaseResist = primaryRace.DiseaseResist;
}

function setMultiRaceStats({race, primaryRace, secondaryRace}) {  
  race.RaceDesc = primaryRace.RaceDesc + "<br><br>" + secondaryRace.RaceDesc;
  race.StartingFate = blendStats(primaryRace.StartingFate, secondaryRace.StartingFate);
  race.HitsPerRank = blendStats(primaryRace.HitsPerRank, secondaryRace.HitsPerRank);
  race.VI = blendStats(primaryRace.VI, secondaryRace.VI);
  race.AG = blendStats(primaryRace.AG, secondaryRace.AG);
  race.WI = blendStats(primaryRace.WI, secondaryRace.WI);
  race.ME = blendStats(primaryRace.ME, secondaryRace.ME);
  race.LO = blendStats(primaryRace.LO, secondaryRace.LO);
  race.MI = blendStats(primaryRace.MI, secondaryRace.MI);
  race.RE = blendStats(primaryRace.RE, secondaryRace.RE);
  race.CH = blendStats(primaryRace.CH, secondaryRace.CH);
  race.EM = blendStats(primaryRace.EM, secondaryRace.EM);
  race.IN = blendStats(primaryRace.IN, secondaryRace.IN);
  race.DivineResist = blendStats(primaryRace.DivineResist, secondaryRace.DivineResist);
  race.ElementalResist = blendStats(primaryRace.ElementalResist, secondaryRace.ElementalResist);
  race.QiResist = blendStats(primaryRace.QiResist, secondaryRace.QiResist);
  race.PoisonResist = blendStats(primaryRace.PoisonResist, secondaryRace.PoisonResist);
  race.DiseaseResist = blendStats(primaryRace.DiseaseResist, secondaryRace.DiseaseResist);
}

function blendStats(primaryRaceStat, secondaryRaceStat) {
  let avg = (primaryRaceStat + secondaryRaceStat) * .5;
  return Math.round(avg);
}

//===================================================================  Character Attributes
// creates 'CharacterAttributes' from 'ContentStats' input
//------------------------------------------------------------
export function createCharacterAttributes({stats, race}) {

  if(!stats) {
    console.error('characterAttributes requires a stats array');
    return;
  }

  let attributes = {};

  stats.forEach(stat => {

    let attribute = new g20CharacterModels.Attribute({ 
      Name: stat.StatName,
      Code: stat.Stat,
      Description: stat.StatUse,
      RacialBonus: race[stat.Stat] ? race[stat.Stat] : 0
    });

    calc.setAttributeTotals(attribute);

    attributes[stat.Stat] = attribute;

  });

  return attributes;
}

//===================================================================  Character Stat Pool
// creates List of 'QueryRoll'
//------------------------------------------------------------

export function createCharacterStatPool() {
  let statPool = [];
  for(let i=0; i < 10; i++) {
    statPool.push(new QueryRoll());
  }
  return statPool;
}

//===================================================================  Character Resists
// creates 'CharacterResists' from 'ContentRace' input
//------------------------------------------------------------
export function createCharacterResists({race}) {

  if(!race) {
    console.error('createCharacterResists requires a race');
    return;
  }

  let resists = {
    Disease: new g20CharacterModels.Resist({Value: race.DiseaseResist}),
    Divine: new g20CharacterModels.Resist({Value: race.DivineResist}),
    Elemental: new g20CharacterModels.Resist({Value: race.ElementalResist}),
    Poison: new g20CharacterModels.Resist({Value: race.PoisonResist}),
    Qi: new g20CharacterModels.Resist({Value: race.QiResist})
  };  

  return resists;
}

//===================================================================  Character SpellAbility
// creates 'characterSpellAbility' from 'ContentSpellAbility' input
//------------------------------------------------------------
export function createCharacterSpellAbility(spellAbility) {

  if(!spellAbility) {
    console.error('characterSpellAbility requires a spellAbility');
    return;
  }

  let characterSpellAbility = new g20CharacterModels.SpellAbility(spellAbility);
  return characterSpellAbility;
}

//===================================================================  Point Pools
//  Set Point Pool: Calculate level-up points
//--------------------------------------------------------------- 
export function setPointPool(character, mode) {

  let pointPool = [        
    { type: 'Leveling', rapid: false, group: null, item: null, value: 40 },
    { type: 'Reserve', rapid: false, group: null, item: null, value: calc.getReservePoints(character) }
  ];

  let inheritancePoints = [];
  if(mode === forgeMode.LEVEL_UP_FIRST) {
    inheritancePoints = calc.getInheritancePoints(character);
  }

  return [...pointPool, ...inheritancePoints ];

}

//===================================================================  createSkillGroups
// creates 'skillGroups' from 'db tables' input
//------------------------------------------------------  base function for skill groups

export function createSkillGroups({ groups, characterClass }) {
  
  if(!groups) {
    console.error('createSkillGroups requires groups');
    return;
  }
  let characterSkillGroups = {};
  groups.forEach(group => {
    let characterSkillGroup = new g20CharacterModels.SkillGroup(group);

    if(group.SkillGroupName === 'Spellcasting') {
      characterSkillGroup.Stat1 = characterClass.Stat1;
      characterSkillGroup.Stat2 = characterClass.Stat2;
    }

    characterSkillGroups[group.SkillGroupName] = characterSkillGroup;
  });

  return characterSkillGroups;

}

//===================================================================  skillCosts
// creates 'skillCosts' from 'db table' input
//------------------------------------------------------  used in skill selection
export function createSkillCosts({groups, skills, characterClass}) {  

  if(!groups || !skills || !characterClass) {
    console.error('createSkillCosts requires groups, skills and character class');
    return;
  }

  //-----------------------------------------------
  let characterSkillGroups = createSkillGroups({groups, characterClass});  
  let skillGroupArray = Object.values(characterSkillGroups);
  let characterSkillCosts = {};
  //-----------------------------------------------

  skillGroupArray.forEach(characterSkillGroup => {

    skills.forEach(skill => {
      if(skill.SkillGroup_ID === characterSkillGroup.SkillGroup_ID && skill.SkillTypeCode !== "WEAPON") {
        let characterSkill = new g20CharacterModels.Skill(skill);

        if(characterSkillGroup.SkillGroupName==='Spellcasting') {
          characterSkill.Stat1 = characterClass.Stat1;
          characterSkill.Stat2 = characterClass.Stat2;
        } else {
          characterSkill.Stat1 = characterSkillGroup.Stat1;
          characterSkill.Stat2 = characterSkillGroup.Stat2;
        }

        characterSkill.SkillGroupName = characterSkillGroup.SkillGroupName;
        characterSkill.SkillName = skill.SkillName;
        characterSkill.OrigSkillName = skill.SkillName;
        characterSkill.Cost = skill[characterClass.ClassName];
        characterSkill.OrigSkillCost = skill[characterClass.ClassName];
        characterSkillGroup.Skill[characterSkill.SkillName] = characterSkill;
      }
    });
    characterSkillCosts[characterSkillGroup.SkillGroupName] = characterSkillGroup;
  });
  //-----------------------------------------------

  return characterSkillCosts;

}

//===================================================================  createCharacterSkillGroups
// creates 'characterSkillGroups' from 'db table' input
//-----------------------------------------------------------------------  used in character init
export function createCharacterSkillGroups({groups, characterClass, characterWeaponGroups}) {

  if(!groups || !characterClass || !characterWeaponGroups) {
    console.error('createCharacterSkillGroups requires groups, characterClass and characterWeaponGroups');
    return;
  }

  let characterSkillGroups = createSkillGroups({groups, characterClass});
  
  // weapon skills
  characterWeaponGroups.forEach((cwg, index) => {
    let characterSkill = new g20CharacterModels.Skill({
      Skill_ID: cwg.Skill_ID,
      SkillGroup_ID: characterSkillGroups.Weapons.SkillGroup_ID,
      SkillGroupName: characterSkillGroups.Weapons.SkillGroupName,
      SkillName: `WG${index+1}: ${cwg.WeaponGroupDesc}`,
      OrigSkillName: `WG${index+1}`,
      Description: `Weapon Skill: ${cwg.WeaponGroupDesc}`,
      Stat1: cwg.Stat1,
      Stat2: cwg.Stat2,
      Cost: cwg.Cost,
      OrigSkillCost: cwg.Cost,
      isTemplate: 0,
      isLinked: 1
    });
    characterSkillGroups.Weapons.Skill[characterSkill.SkillName] = characterSkill;
  });

  return characterSkillGroups;

}

//===================================================================  createCharacterSkillGroups
// creates 'characterSkillGroups' from 'db table' input
//-----------------------------------------------------------------------  used in character init
export function createSkillSelectionMatrix(skillLedger, skillCosts) {

  console.log(skillLedger, skillCosts);

  let skillSelectionMatrix = deepClone(skillCosts);
  let skillCostGroupNames = Object.keys(skillSelectionMatrix);

  skillCostGroupNames.forEach(skillCostGroupName => {

    let skillCostGroup = skillSelectionMatrix[skillCostGroupName];
    let skills = Object.values(skillCostGroup.Skill);  // values
    let bSkills = Object.values(skillLedger.blended[skillCostGroupName].Skill);  // overlay from blended          

    skills.forEach(skill => {

      let skillSet = [];          
      bSkills.forEach(s => {
        if(s.Skill_ID === skill.Skill_ID){
          skillSet.push(s);
        }
      });  // blended skill(s)

      if(skillSet.length>0) {
        skillSet.forEach(bSkill => {
          bSkill.isTemplate = 0;

          if(bSkill.SkillName.startsWith("WG")) { 
            delete skillCostGroup.Skill[skill.SkillName];
          } 
            
          skillCostGroup.Skill[bSkill.SkillName] = bSkill;
          
        });
      }

    });

  });

  return skillSelectionMatrix;
}

//===================================================================  spellListGroups
// creates 'spellListGroups' from 'db tables' input
//------------------------------------------------------------
export function createSpellListGroups({character, realms, classes, spellListTypes, spellLists}) {

  if(!realms || !classes || !spellListTypes || !spellLists) {
    console.error('createSkillCosts requires realms, classes, spellListTypes and spellLists');
    return;
  }

  let spellListGroups = [];

  spellLists.forEach(spellList => {

    let realm = realms.find(item => item.Realm_ID === spellList.RealmID);
    let spellListType = spellListTypes.find(item => item.TypeID === spellList.TypeID);

    let spellListGroup = null;
    if(spellList.ClassName.toLowerCase() === 'none') {
      spellList.SpellListType = spellListType;
      spellListGroup = spellListGroups.find(item => item.realm?.Realm_ID === spellList.RealmID && item.spellListType?.TypeID === spellList.TypeID);
    } else {
      spellListGroup = spellListGroups.find(item => item.className === spellList.ClassName);
    }

    if(!spellListGroup) {
      spellListGroup = createSpellListGroup(spellListType, realm, spellList.ClassName);
      spellListGroups.push(spellListGroup);
    }

    calc.getTomeCost(character, spellList);    

    spellListGroup.spellLists.push(spellList);
  });

  // filter classes
  let classedLists = spellListGroups.filter(item => { 
    if(item.realm === null &&  classes.indexOf(item.className)>=0) { return item }
  });
  classedLists.sort((a, b) => (a.className > b.className) ? 1 : -1);

  // unclasses lists
  let unclassedLists = spellListGroups.filter(item => { if(item.realm!==null) { return item }});
  unclassedLists.sort((a, b) => (a.realm.RealmDesc > b.realm.RealmDesc) ? -1 : 1);
  unclassedLists.sort((a, b) => (a.spellListType.TypeID > b.spellListType.TypeID) ? 1 : -1);

  return { unclassedLists, classedLists};

}

function createSpellListGroup(spellListType, realm, className) {

  let groupName = className;
  if(className.toLowerCase() === 'none') {
    groupName = spellListType.TypeDesc + ' ' + realm.RealmDesc;
  }

  return {
    groupName: groupName,
    spellListType: spellListType || null,
    realm: realm || null,
    className: className,
    spellLists: []
  }
}
