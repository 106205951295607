<template>
<div v-if="character" class="attributes" :class="{'editable': editable}" @click.stop="editable=true">

  <div class="command-buttons" v-if="editable">
    <button class="btn-gem green btn-sm" title="Save Character" @click.stop="saveCharacter()">Save</button>
    <button class="btn-gem red btn-sm" title="Cancel" @click.stop="cancel()">Cancel</button>
  </div>

  <div class="legend">
    <div>
      <div class="attribute-legend">Attributes</div>
    </div>
    <div class="stat-labels">
      <div>Curr.</div>
      <div>Pot.</div>
      <div>
        <div>Bonus</div><div class="pri-sec-label">Pri./Sec.</div>
      </div>
    </div>
  </div>

  <div class="body" v-for="k in Object.keys(character.Attributes)" :key="k">
    <div>
      <div class="attribute" :title="character.Attributes[k].Description">        
        <span>{{character.Attributes[k].Name}}</span>
        <sub v-if="character.Class.Stat1 == k">1</sub>
        <sub v-if="character.Class.Stat2 == k">2</sub>
      </div>
      <div class="code">({{character.Attributes[k].Code }})</div>
    </div>
    <div>
      <div class="body-col">
        <NumericStepper 
          v-if="editable" 
          :max="maxStat" 
          v-model="character.Attributes[k].Current" 
          :horizontal="true"
          @input="checkAttribute(k)">
        </NumericStepper>
        <span v-else>{{character.Attributes[k].Current}}</span>
      </div>
      <div class="body-col">
        <NumericStepper v-if="editable" :max="maxStat" v-model="character.Attributes[k].Potential" :horizontal="true" />
        <span v-else>{{character.Attributes[k].Potential}}</span>
      </div>
      <div class="body-col">
        <div>{{character.Attributes[k].PrimaryTotal}} / {{character.Attributes[k].SecondaryTotal}}</div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
//------------------------------------------------------
import { setAttributeValues } from '../../code/game/calculations'
//------------------------------------------------------
export default {
  name: "Attributes",
  props: {
    value: Object
  },
  computed:{
    primary() { return this.character.Class.Stat1 },
    secondary() { return this.character.Class.Stat2 }
  },
  watch: {    
    value: {
      handler() { this.init() },
      immediate: true
    },
  },
  data() {
    return {
      editable: false,
      character: null,
      maxStat: 25,
      attributeBonuses: []
    }
  },
  methods: {

    async init() {
      this.character = this.$deepClone(this.value);
      this.attributeBonuses = await this.$store.dispatch('Content/getAttributeBonuses');
    },

    checkAttribute(attributeKey) {
      let attributes = this.$deepClone(this.character.Attributes); 
      attributes[attributeKey] = setAttributeValues(attributes[attributeKey], this.attributeBonuses);
      this.character.Attributes = attributes;
    },

    cancel() {
      this.editable=false;
      this.$emit('cancel');
    },

    saveCharacter() {
      this.editable = false;
      this.$emit('save-character', this.character);
    }

  }
};
</script>

<style lang="scss" scoped>


.attributes {
  display: block;
  text-align: center;

  &.editable {
    border: dotted 1px blue;
  }

  .command-buttons {
    text-align: right;
  }



  .legend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
    background:#eee;
    font-size: 11px;
    font-weight: bold;

    .stat-labels {
      display: flex;
      align-items: center;
    }

    .stat-labels > div {
      text-align: center;
      width: 60px;
        
      .pri-sec-label {
        margin-top: -2px;
        font-size: 9px;
      }

    }

  }

  .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0;
    font-size: 13px;
    border-bottom: solid 1px #ccc;
  }
    
  .legend, 
  .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
  }

  .legend > div, 
  .body > div {
    display: flex;
    align-items: center;
  }

  .legend-col, 
  .body-col {
    text-align: center;
    width: 60px;
  }

  .legend {
    background:#eee;
    font-size: 11px;
    font-weight: bold;
  }
    .legend .pri-sec-label {
      margin-top: -3px;
      font-size: 9px;
    }

  .body {
    padding: 3px 0;
    font-size: 13px;
    border-bottom: solid 1px #ccc;
  }

  .attribute {
    width: 70px; 
    text-align: left;
  }
  .attribute:hover {
    cursor: help;
  }

  .attribute-legend {
    width: 70px; 
    text-align: right;
  }

  .code {
    width: 50px; 
    text-align: center;
  }

}
</style>