<template>
<div class="backgrounds-summary">

  <div class="heading">Backgrounds:</div>

  <div class="background-item" v-if="characterBackgrounds.Inheritance.Inheritance">
    <div class="title"> 
      <div>Inheritance:</div>
      <div>{{characterBackgrounds.Inheritance.Inheritance}}</div> 
    </div>
    <div class="notes mobile-hide">
      ({{characterBackgrounds.Inheritance.SkillPoints}} / {{characterBackgrounds.Inheritance.RapidPoints}} | {{characterBackgrounds.Inheritance.StartingPossessions}}
    </div>
  </div>

  <div class="background-item" v-if="characterBackgrounds.Family.LBBName">
    <div class="title"> 
      <div>Family:</div> 
      <div>{{characterBackgrounds.Family.LBBName}} </div>
    </div>
    <div class="notes mobile-hide"> ({{characterBackgrounds.Family.LBBDescription}}) </div>
  </div>

  <div class="background-item" v-if="characterBackgrounds.Adolescence.LBBName">
    <div class="title"> 
      <div>Adolescence:</div> 
      <div>{{characterBackgrounds.Adolescence.LBBName}}</div>
    </div>
    <div class="notes mobile-hide">
      ({{characterBackgrounds.Adolescence.LBBDescription}})
    </div>
  </div>

  <div class="background-item" v-if="characterBackgrounds.YoungAdult.LBBName">
    <div class="title">
      <div>Young Adult:</div>
      <div>{{characterBackgrounds.YoungAdult.LBBName}}</div>
    </div>
    <div class="notes mobile-hide">
      ({{characterBackgrounds.YoungAdult.LBBDescription}})
    </div>
  </div>

  <div class="background-item" v-if="characterBackgrounds.ChosenPath.LBBName">
    <div class="title"> 
      <div>Chosen Path:</div> 
      <div>{{characterBackgrounds.ChosenPath.LBBName}}</div>
    </div>
    <div class="notes mobile-hide">
      ({{characterBackgrounds.ChosenPath.LBBDescription}})
    </div>
  </div>

  <div class="background-item" v-for="f in characterFeatures" :key="f.ID">
    <div class="title">
      <div>{{f.Name}}</div>
    </div>
    <div class="notes">
      {{f.Description}}
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: "BackgroundsSummary",
  props: {
    characterBackgrounds: Object,
    characterFeatures: Array
  }
}
</script>

<style lang="scss" scoped>
.backgrounds-summary {
  border: solid 1px rgba(0,0,0,0.5);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px 10px 10px 10px;
  margin: 5px;

  .heading {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .background-item {
    .title {
      font-size: 14px;
      display: flex;
      &> div:first-child {
        font-weight: bold;
        width: 90px;
      }
    }
    .notes {
      font-size: 11px;
      padding: 3px;
      color: #ccc;
      &.mobile-hide {
        display: none;
      }
    } 
  } 
}
@media screen and (min-width: 400px) {
  .backgrounds-summary {
    .background-item {
      margin-bottom: 2px;
      .notes {
        &.mobile-hide {
          display: block;
        }
      }  
    }
  }
}
</style>