export default class SpellList {
  constructor(options) {

    this.ClassName = null;
    this.ListNote = null;
    this.Location = null;
    this.SpellListName = null;
    this.RealmID = 0;
    this.Realm = null;
    this.SpellListID = 0;
    this.TypeID = 0;
    this.Type = null;
    this.ListCost = 0;

    if(options) {
      this.init(options);
    } 

  }
  init(options) {

    this.ClassName = options.ClassName || this.ClassName;
    this.ListNote = options.ListNote || this.ListNote;
    this.Location = options.Location || this.Location;
    this.SpellListName = options.SpellListName || this.SpellListName;
    this.RealmID = parseInt(options.RealmID) || this.RealmID;
    this.Realm = options.Realm || this.Realm;
    this.SpellListID = parseInt(options.SpellListID) || this.SpellListID;
    this.TypeID = parseInt(options.TypeID) || this.TypeID;
    this.Type = options.Type || this.Type;
    this.ListCost = parseInt(options.ListCost) || this.ListCost;

  }
}