<template>
<MagicFrame v-if="ready"> 

  <div class="class-selection">

    <header>
      <div class="title">
        Your Class
        <G20Help book="GameCanon.pdf" bookmark="Choose Your Class"></G20Help>
      </div>   
    </header>

    <div class="class-selections">
      <div>
      <label for="CharacterClass"><strong>Character Class</strong></label>
        <select class="full" name="CharacterClass" v-model="className">
          <option :value="null">~ please select ~</option>
          <option v-for="c in classes" :key="c.ClassName" :value="c.ClassName">{{c.ClassName}}</option>
        </select>
      </div>
    </div>

    <ClassSummary 
      :character-class="characterClass" 
      :character-spell-ability="characterSpellAbility">
    </ClassSummary>

  </div>

</MagicFrame>
</template>

<script>
//------------------------------------------------------
import { createCharacterSpellAbility } from '../../../code/game/objectFactory'
//------------------------------------------------------
export default {
  name: "ClassSelect",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
  },
  data() {
    return {
      classes: [],
      className: null,
      characterClass: null,
      characterSpellAbility: null
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {            
          this.classes = await this.$store.dispatch('Content/getClasses');
          this.setState();
        }
      },
      immediate: true
    },
    className() {
      this.setCharacterClass();
    }
  },
  methods: {
    
    setState() {
      this.characterClass = this.forgeState.ledger.characterClass;
      this.characterSpellAbility = this.forgeState.ledger.characterSpellAbility;
      if(this.forgeState.ledger.characterClass) {
        this.className = this.forgeState.ledger.characterClass.ClassName;         
      }
    },

    async setCharacterClass() {
      this.characterClass = this.classes.find(c => c.ClassName === this.className);  
      let spellAbility = await this.$store.dispatch('Content/getSpellAbilityById', this.characterClass.SpellAbility_ID);
      this.characterSpellAbility = createCharacterSpellAbility(spellAbility);    
      this.setLedgerItems();
    },

    setLedgerItems() {
      this.$store.dispatch('Forge/setLedgerItems', { characterClass: this.characterClass, characterSpellAbility: this.characterSpellAbility });
    }
  }
}
</script>

<style lang="scss" scoped>
.class-selection { 
  header{
    display: flex;
    justify-content: space-between;
    width: 100%;

    .title {
      font-size: 20px;
    }
  }

  .class-selections {
    border: solid 1px rgba(0,0,0,0.5);
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px 10px 10px 10px;
    margin: 5px;
  }

}
</style>