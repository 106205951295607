//--------------------------------------------------------------- import models

import Character from './character-models/Character'
import CharacterRecord from './character-models/CharacterRecord'

import Attribute from './character-models/Attribute'
import Attributes from './character-models/Attributes' //?
import Background from './character-models/Background'
import Backgrounds from './character-models/Backgrounds' //?
import Class from './character-models/Class'
import CombatInfo from './character-models/CombatInfo'
import Info from './character-models/Info'
import Inheritance from './character-models/Inheritance'
import Item from './character-models/Item'
import Race from './character-models/Race'
import Resist from './character-models/Resist'
import Resists from './character-models/Resists' //?
import Skill from './character-models/Skill'
import SkillGroup from './character-models/SkillGroup'
import SpecialAbility from './character-models/SpecialAbility'
import Spell from './character-models/Spell'
import SpellAbility from './character-models/SpellAbility'
import SpellList from './character-models/SpellList'
import Vice from './character-models/Vice'
import Wealth from './character-models/Wealth'
import WeaponGroup from './character-models/WeaponGroup'
//---------------------------------------------------------------  exports
export default {

  Character,
  CharacterRecord,

  Attribute,
  Attributes,   //?
  Background,
  Backgrounds,  //?
  Class,
  CombatInfo,
  Info,
  Inheritance,
  Item,
  Race,
  Resist,
  Resists,      //?
  Skill,
  SkillGroup,
  SpecialAbility,
  Spell,
  SpellAbility,
  SpellList,
  Vice,
  Wealth,
  WeaponGroup
}