<template>
<div class="range-select">
  <div class="range-item" v-for="r in rangeItems" :key="r.g20Roll">
    <div class="range-link" :class="setItemClass(r)" @click="$emit('item-selected', r)">
      <slot :item="r"></slot>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "RangeSelect",
  props: { 
    rangeItems: { type: Array, required: true },
    triad: { type: Object, required: true },
    g20Roll: Number
  },
  methods: {
    setItemClass(r) {
      let cssClass = [];
      if(r.g20Roll === this.triad.mid) {
        cssClass.push('initial')
      } else if(r.g20Roll >= this.triad.low && r.g20Roll <= this.triad.high) {
        cssClass.push('inside');
      } else {
        cssClass.push('outside');
      }
      if(r.g20Roll === this.g20Roll) {
        cssClass.push('selected')
      }
      return cssClass.join(' ');
    }
  }
}
</script>


<style lang="scss" scoped>
$artworkRoot: '/img/artwork/';

.range-select {

  border: solid 2px rgba(0,0,0,0.5);
  background-color: rgba(0, 0, 0, 0.4);

  .range-item {
    display: block;
    margin: 2px 5px;

    .range-link {
      display: block;
      border: solid 2px rgba(0,0,0,0);
      &.outside {
        background-color: rgba(255, 0, 0, 0.125);
      }   
      &.inside {
        background-color: rgba(255, 255, 145, 0.5);
      }   
      &.initial {
        background-color: rgba(255, 255, 145, 0.4);
      }
      &.selected {
        background-color: rgba(70, 247, 16, 0.75)
      }
    }
    .range-link:hover { 
      cursor: pointer;
      border: solid 2px rgba(255, 255, 255, 0.25)
    }

  }

}

</style>