
export default class Skill {
  constructor(obj) {
    
    this.Skill_ID = obj ? parseInt(obj.Skill_ID) : null;
    this.SkillGroup_ID = obj ? parseInt(obj.SkillGroup_ID) : null;
    this.SkillName = obj ? obj.SkillName : null;
    this.SkillNameContested = obj ? obj.SkillNameContested : null;
    this.SelectionCode = obj ? obj.SelectionCode : null;
    this.SkillTypeCode = obj ? obj.SkillTypeCode : null;
    this.IsImportant = obj ? obj.IsImportant : null;
    this.Description = obj ? obj.Description : null;
    this.Stat1 = obj ? obj.Stat1 : null;
    this.Stat2 = obj ? obj.Stat2 : null;
    this.IsActive = obj ? parseInt(obj.IsActive) : 0;

    //class costs

    this.Administrator = obj ? parseInt(obj.Administrator) : 0;
    this.Alchemist = obj ? parseInt(obj.Alchemist) : 0;
    this.Anarchist = obj ? parseInt(obj.Anarchist) : 0;
    this.Animist = obj ? parseInt(obj.Animist) : 0;
    this.Archmage = obj ? parseInt(obj.Archmage) : 0;
    this['Arms Tech'] = obj ? parseInt(obj['Arms Tech']) : 0;
    this.Armsman = obj ? parseInt(obj.Armsman) : 0;
    this.Artist = obj ? parseInt(obj.Artist) : 0;
    this.Assassin = obj ? parseInt(obj.Assassin) : 0;
    this["Astral Traveller"] = obj ? parseInt(obj["Astral Traveller"]) : 0;
    this.Astrologer = obj ? parseInt(obj.Astrologer) : 0;
    this.Barbarian = obj ? parseInt(obj.Barbarian) : 0;
    this.Bard = obj ? parseInt(obj.Bard) : 0;
    this.Bashkar = obj ? parseInt(obj.Bashkar) : 0;
    this.Beastmaster = obj ? parseInt(obj.Beastmaster) : 0;
    this.Burglar = obj ? parseInt(obj.Burglar) : 0;
    this.Cavalier = obj ? parseInt(obj.Cavalier) : 0;
    this['Chaotic Lord'] = obj ? parseInt(obj['Chaotic Lord']) : 0;
    this.Cleric = obj ? parseInt(obj.Cleric) : 0;
    this['Con Artist'] = obj ? parseInt(obj['Con Artist']) : 0;
    this.Conjuror = obj ? parseInt(obj.Conjuror) : 0;
    this.Craftwright = obj ? parseInt(obj.Craftwright) : 0;
    this.Craftsman = obj ? parseInt(obj.Craftsman) : 0;
    this.Criminologist = obj ? parseInt(obj.Criminologist) : 0;
    this['Crystal Mage'] = obj ? parseInt(obj['Crystal Mage']) : 0;
    this.Dancer = obj ? parseInt(obj.Dancer) : 0;
    this.Delver = obj ? parseInt(obj.Delver) : 0;
    this.Dervish = obj ? parseInt(obj.Dervish) : 0;
    this['DL Illus'] = obj ? parseInt(obj['DL Illus']) : 0;
    this['DL Shaman'] = obj ? parseInt(obj['DL Shaman']) : 0;
    this.Doomspeaker = obj ? parseInt(obj.Doomspeaker) : 0;
    this.Druid = obj ? parseInt(obj.Druid) : 0;
    this.Duellist = obj ? parseInt(obj.Duellist) : 0;
    this.Electrician = obj ? parseInt(obj.Electrician) : 0;
    this['Elemental Channeller'] = obj ? parseInt(obj['Elemental Channeller']) : 0;
    this['Elemental Chanter'] = obj ? parseInt(obj['Elemental Chanter']) : 0;
    this['Elemental Enchanter'] = obj ? parseInt(obj['Elemental Enchanter']) : 0;
    this['Elemental Healer'] = obj ? parseInt(obj['Elemental Healer']) : 0;
    this['Elemental Hunter'] = obj ? parseInt(obj['Elemental Hunter']) : 0;
    this['Elemental Physician'] = obj ? parseInt(obj['Elemental Physician']) : 0;
    this['Elemental Summoner'] = obj ? parseInt(obj['Elemental Summoner']) : 0;
    this['Elemental Warrior'] = obj ? parseInt(obj['Elemental Warrior']) : 0;
    this.Enchanter = obj ? parseInt(obj.Enchanter) : 0;
    this.Engineer = obj ? parseInt(obj.Engineer) : 0;
    this.Entertainer = obj ? parseInt(obj.Entertainer) : 0;
    this['Evil Elementalist'] = obj ? parseInt(obj['Evil Elementalist']) : 0;
    this.Evolutionist = obj ? parseInt(obj.Evolutionist) : 0;
    this.Explorer = obj ? parseInt(obj.Explorer) : 0;
    this.Farmer = obj ? parseInt(obj.Farmer) : 0;
    this.Farocoth = obj ? parseInt(obj.Farocoth) : 0;
    this.Fighter = obj ? parseInt(obj.Fighter) : 0;
    this.Forcemage = obj ? parseInt(obj.Forcemage) : 0;
    this.Gypsy = obj ? parseInt(obj.Gypsy) : 0;
    this.Healer = obj ? parseInt(obj.Healer) : 0;
    this['High Elementalist'] = obj ? parseInt(obj['High Elementalist']) : 0;
    this['High Warrior Monk'] = obj ? parseInt(obj['High Warrior Monk']) : 0;
    this.Houri = obj ? parseInt(obj.Houri) : 0;
    this.Hunter = obj ? parseInt(obj.Hunter) : 0;
    this.Illusionist = obj ? parseInt(obj.Illusionist) : 0;
    this.Investigator = obj ? parseInt(obj.Investigator) : 0;
    this['Law Enforcer'] = obj ? parseInt(obj['Law Enforcer']) : 0;
    this.Lawyer = obj ? parseInt(obj.Lawyer) : 0;
    this['Lay Healer'] = obj ? parseInt(obj['Lay Healer']) : 0;
    this.Leader = obj ? parseInt(obj.Leader) : 0;
    this.Macabre = obj ? parseInt(obj.Macabre) : 0;
    this.Maleficant = obj ? parseInt(obj.Maleficant) : 0;
    this['Med Tech'] = obj ? parseInt(obj['Med Tech']) : 0;
    this.Mentalist = obj ? parseInt(obj.Mentalist) : 0;
    this.Merchant = obj ? parseInt(obj.Merchant) : 0;
    this.Monk = obj ? parseInt(obj.Monk) : 0;
    this.Montebanc = obj ? parseInt(obj.Montebanc) : 0;
    this['Moon Mage'] = obj ? parseInt(obj['Moon Mage']) : 0;
    this.Mystic = obj ? parseInt(obj.Mystic) : 0;
    this['Mystical Elementalist'] = obj ? parseInt(obj['Mystical Elementalist']) : 0;
    this.Necromancer = obj ? parseInt(obj.Necromancer) : 0;
    this.Netrunner = obj ? parseInt(obj.Netrunner) : 0;
    this.Nightblade = obj ? parseInt(obj.Nightblade) : 0;
    this['No Profession'] = obj ? parseInt(obj['No Profession']) : 0;
    this['Noble Warrior'] = obj ? parseInt(obj['Noble Warrior']) : 0;
    this.Paladin = obj ? parseInt(obj.Paladin) : 0;
    this.Physician = obj ? parseInt(obj.Physician) : 0;
    this.Pilot = obj ? parseInt(obj.Pilot) : 0;
    this.Planetologist = obj ? parseInt(obj.Planetologist) : 0;
    this.Professional = obj ? parseInt(obj.Professional) : 0;
    this.Ranger = obj ? parseInt(obj.Ranger) : 0;
    this['Rescue Worker'] = obj ? parseInt(obj['Rescue Worker']) : 0;
    this.Researcher = obj ? parseInt(obj.Researcher) : 0;
    this.Rogue = obj ? parseInt(obj.Rogue) : 0;
    this['Rogue Elementalist'] = obj ? parseInt(obj['Rogue Elementalist']) : 0;
    this.Runemaster = obj ? parseInt(obj.Runemaster) : 0;
    this.Sage = obj ? parseInt(obj.Sage) : 0;
    this.Sailor = obj ? parseInt(obj.Sailor) : 0;
    this.Scholar = obj ? parseInt(obj.Scholar) : 0;
    this.Seer = obj ? parseInt(obj.Seer) : 0;
    this['Semi Tel'] = obj ? parseInt(obj['Semi Tel']) : 0;
    this.Shaman = obj ? parseInt(obj.Shaman) : 0;
    this.Sleuth = obj ? parseInt(obj.Sleuth) : 0;
    this.Softechnician = obj ? parseInt(obj.Softechnician) : 0;
    this.Sorcerer = obj ? parseInt(obj.Sorcerer) : 0;
    this['Sys Tech'] = obj ? parseInt(obj['Sys Tech']) : 0;
    this.Teacher = obj ? parseInt(obj.Teacher) : 0;
    this.Theologist = obj ? parseInt(obj.Theologist) : 0;
    this.Thief = obj ? parseInt(obj.Thief) : 0;
    this.Trader = obj ? parseInt(obj.Trader) : 0;
    this['True Telepath'] = obj ? parseInt(obj['True Telepath']) : 0;
    this.Warlock = obj ? parseInt(obj.Warlock) : 0;
    this.Warrior = obj ? parseInt(obj.Warrior) : 0;
    this['Warrior Mage'] = obj ? parseInt(obj['Warrior Mage']) : 0;
    this['Warrior Monk'] = obj ? parseInt(obj['Warrior Monk']) : 0;
    this.Witch = obj ? parseInt(obj.Witch) : 0;
    this.Wizard = obj ? parseInt(obj.Wizard) : 0;
  }
}