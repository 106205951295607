export default class SpellPrep {
  constructor(options) {

    this.SpellPrepCode = null;
    this.SpellPrepDesc = null;

    if(options) {
      this.init(options);
    } 

  }

  init(options) {

    this.SpellPrepCode = options.SpellPrepCode || this.SpellPrepCode;
    this.SpellPrepDesc = options.SpellPrepDesc || this.SpellPrepDesc;

  }
}