export default class CriticalSeverity {
  constructor(options) {

    this.CritSizeID = null;
    this.CritSizeDesc = null;

    if(options) {
      this.init(options);
    }

  }

  init(options) {
        
    this.CritSizeID = options.CritSizeID || this.CritSizeID;
    this.CritSizeDesc = options.CritSizeDesc || this.CritSizeDesc;
    
  }
}