<template>
<div v-if="forgeState">
  <ForgeNavFrame class="forge-nav-frame">
    <header class="forge-nav-controls">
      <a href="#" @click.prevent="changeForgeStep(-1)" title="Previous Step" :class="{ 'hidden': forgeState.forgeMenuIndex === 0 }">
        <img src="/img/artwork/arrow_L.png" />
      </a>
      <div class="step-indicator">
        <ForgeProgress :step="forgeState.forgeMenuIndex+1" :steps="forgeState.forgeMenu.length"></ForgeProgress>
      </div>
      <a href="#" @click.prevent="changeForgeStep(1)" title="Next Step" :class="{'hidden': forgeState.forgeMenuIndex === forgeState.forgeMenu.length-1 }">
        <img src="/img/artwork/arrow_R.png" v-show="valid" />
      </a>
    </header>
  </ForgeNavFrame>
</div>

</template>

<script>
export default {
  name: "ForgeNavigation",
  computed: {
    valid() { return this.$store.state.Forge.valid; },
    forgeState() { return this.$store.state.Forge.forgeState; }
  },
  methods: {
    changeForgeStep(dir) {
      this.$store.dispatch('Forge/changeForgeStep', dir);
    }
  }
}
</script>

<style lang="scss" scoped>

.forge-nav-frame {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.forge-nav-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  a {
    display: block;
    line-height: 0;
    margin: 0;
    width: 45px;
    height: 30px;

    img {
      width: 45px;
      height: 30px;
    }
  }

  a.hidden img {
    display: none;
  }

  .step-indicator {
    flex: 1;
  }

}

</style>