<template>
<MagicFrame v-if="ready"> 
  <div class="stat-gains">

    <header>
      <div class="title">
        Stat Gains
        <G20Help book="GameCanon.pdf" bookmark="Choose Your Realm"></G20Help>
      </div>
    </header>

    <div class="fates-button">
      <button class="btn-capped-red btn-sm" @click="rollDice()">Let The Fates Decide</button>  
    </div>

    <div class="stat-gains-table">

      <table class="table" v-if="attributes">
        <thead class="table-legend">
          <tr>
            <th class="attribute-header">Attributes</th>
            <th class="stat-code">&nbsp;</th>
            <th class="stat-col">Cur.</th>
            <th class="stat-col">Pot.</th>
            <th class="stat-col">Diff.</th>
            <th class="choice-col"></th>
            <th class="text-center">roll</th>
          </tr>
        </thead>
        <tbody class="tbody-data">
          <tr v-for="statKey in Object.keys(attributes)" :key="statKey">
            <td class="attribute-col">
              <span>{{attributes[statKey].Name}}</span>
              <sub v-if="character.Class.Stat1 === statKey">1</sub>
              <sub v-if="character.Class.Stat2 === statKey">2</sub>
            </td>
            <td class="stat-code">({{attributes[statKey].Code }})</td>
            <td class="stat-col">
              <span>{{attributes[statKey].Current}}</span>
              <span :class="{'text-success-pale': statGainsLedger[statKey].cur > 0, 'text-danger': statGainsLedger[statKey].cur < 0 }" v-if="statGainsLedger[statKey].cur !== 0">
                (<span v-if="statGainsLedger[statKey].cur>0">+</span>{{statGainsLedger[statKey].cur}})
              </span>
            </td>
            <td class="stat-col">
              <span>{{attributes[statKey].Potential}}</span>
              <span class="text-info-pale" v-if="statGainsLedger[statKey].pot!==0"> (+{{statGainsLedger[statKey].pot}})</span>
            </td>
            <td class="stat-col">
              {{fourPlus(attributes[statKey].Potential - attributes[statKey].Current)}}
            </td>
            <td class="choice-col"> 
              <div v-if="statGainsLedger[statKey].cur > 0 && statGainsLedger[statKey].pot > 0">
                <button type="button" class="inline-block btn-gem btn-xs green" title="Add to Current" @click="selectBonus(statKey, 'current')"> Cur.</button>
                <button type="button" class="inline-block btn-gem btn-xs blue" title="Add to Potential" @click="selectBonus(statKey, 'potential')"> Pot.</button>
              </div>
            </td>
            <td class="input-col">
              <input type="number" class="input-trans-sm" min="1" max="20" :value="statGainsLedger[statKey].roll" @input="numberEntered(statKey, parseInt($event.target.value))">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
  </div>
</MagicFrame>
</template>

<script>
//------------------------------------------------------
//------------------------------------------------------
export default {
  name: "StatGains",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },    
    attributes() { return this.character.Attributes },
    statKeys() { return Object.keys(this.attributes); },
  },
  data() {
    return {
      character: {},
      statGains: [],
      statGainsLedger: { 
        VI: { roll:0, cur: 0, pot: 0 }, 
        AG: { roll:0, cur: 0, pot: 0 }, 
        WI: { roll:0, cur: 0, pot: 0 }, 
        ME: { roll:0, cur: 0, pot: 0 }, 
        LO: { roll:0, cur: 0, pot: 0 }, 
        MI: { roll:0, cur: 0, pot: 0 }, 
        RE: { roll:0, cur: 0, pot: 0 }, 
        CH: { roll:0, cur: 0, pot: 0 }, 
        EM: { roll:0, cur: 0, pot: 0 }, 
        IN: { roll:0, cur: 0, pot: 0 } 
      }
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {         
            
          let character = await this.$store.dispatch('Character/getCharacter', this.forgeState.characterRecordId); 
          this.character = character;
          let statGains = await this.$store.dispatch('Content/getStatGains'); 
          this.statGains = statGains;

          if(!this.forgeState.ledger.statGainsLedger) {
            this.setLedgerItems();
          }          

          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {
    
    setState() {
      this.statGainsLedger = this.forgeState.ledger.statGainsLedger;
    },

    fourPlus(num) {
      return num >= 4 ? '4+' : num;
    },

    getDiff(statKey) {
      let diff = this.attributes[statKey].Potential - this.attributes[statKey].Current;
      return diff < 4 ? diff : 4;
    },

    rollDice() {
      this.statKeys.forEach(key => {
        this.rollDie(key);
      });
      this.setLedgerItems(); 
    },

    rollDie(statKey) {
      let diff = this.getDiff(statKey);
      let roll = this.$rollDice('1d20');
      this.setStatGain(statKey, diff, roll);
    },

    numberEntered(statKey, roll) {
      let diff = this.getDiff(statKey);
      this.setStatGain(statKey, diff, roll);
      this.setLedgerItems();   
    },
              
    setStatGain(statKey, diff, roll) {
      if(roll>0) {
        let statGainsLedger = {...this.statGainsLedger};        
        let statGainObj = this.getStatGain(diff, roll);
        statGainsLedger[statKey] = {
          roll: roll, 
          cur: statGainObj.adj, 
          pot: roll === 20 ? 1 : 0
        }
        this.statGainsLedger = statGainsLedger;
      }
    },

    getStatGain(diff, roll) {
      for(let i=0; i<this.statGains.length; i++) {
        if(diff === this.statGains[i].diff && roll >= this.statGains[i].lowroll && roll <= this.statGains[i].highroll) {
          return this.statGains[i];
        }
      }
    },
    
    selectBonus(statKey, type) {
      if(type==='current') {
        this.statGainsLedger[statKey].pot=0;
      } else {
        this.statGainsLedger[statKey].cur=0;
      }
      this.setLedgerItems();
    },

    setLedgerItems() {
      this.$store.dispatch('Forge/setLedgerItems', { statGainsLedger: this.statGainsLedger });
    }
  }
}
</script>

<style lang="scss" scoped>
.stat-gains {

  .fates-button {
    border: solid 1px rgba(0,0,0,0.5);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    margin: 5px;
    font-size: 14px;
    text-align: center;
  }

  .stat-gains-table {
    padding: 5px;

    .table {
      width: 100%;
      border-collapse: collapse;
      background-color: transparent;

      .table-legend {
        background:#222;
        th {
          padding: 3px;
          font-size: 11px;
          font-weight: bold;
          height: 32px;
        }
        th.attribute-header {
          text-align: center;
        }
        th.stat-code {
          display: none;
        }
        th.stat-col {
          text-align: center;
        }
        th.choice-col {
          min-width: 60px;
        }
      }
      //------------------------------
      .tbody-data {
        padding: 3px;
        padding: 3px 0;
        font-size: 12px;

        tr {
          border-top: solid 1px #ccc;
        }
        td {
          height: 28px;
        }

        td.attribute-col {
          width: 70px; 
        }
        th.stat-code,
        td.stat-code {
          display: none;
        }
        td.stat-col {
          text-align: center;
        }
        td.input-col {
          width: 50px;       
          input {
            width: 50px; 
          }
        }
        td.roll-col {
          text-align: center;
          width: 35px; 
        }
        td.choice-col {
          text-align: center;
        }  
      }
    }
  }
}

@media screen and (min-width:768px) {
  
.stat-gains {
  .stat-gains-table {
    .table {
      .table-legend {
        th.stat-code {
          display: table-cell;
        }
      }
      //------------------------------
      .tbody-data {
        td.stat-code {
          display: table-cell;
          width: auto;   
        }
        td.stat-col {
          text-align: center;
          width: 70px;   
        }
        td.input-col {
          width: 60px;       
          input {
            width: 60px; 
          }
        }
        td.roll-col {
          text-align: center;
          width: 35px; 
        }
        td.choice-col {
          text-align: center;
          width: 80px;  
        }  
      }
    }
  }
}
}

//---------------------------------------  table


</style>