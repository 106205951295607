<template>
<div class="loading">
  <img src="/img/fire-orb.gif" />
  <h1>Loading...</h1>
</div>
</template>

<script>
export default {
  name: "g20Loading"
}
</script>

<style lang="scss" scoped>
.loading {
  display: block;
  margin: 0 auto;
  width: 200px;
  color: #fff;
  text-align: center;
  margin-top: -80px;

  h1 {
    margin: 0;
    margin-top: -20px;
    padding: 0;
  }

  img {
    height: 130px;
    width: auto;
  }
}
</style>