
// import dChar from '../_dev/luthriel.json'
// import dCam from '../_dev/campaign1.json'

//mport Campaign from '../code/models/gm-models/Campaign'
//import Character from '../code/models/character-models/Character'

// state
//=============================
const state = { 
  playerRole: 'gm',         // todo: set during login
  playerId: null,           // todo: set during login
  invites: [],
  notifications: [],
  characters: [],
  campaigns: [],
  ready: false,
}

// getters
//=============================
const getters = { 

  isGm: (state) => {
    return (state.playerRole.toLowerCase() === 'gm')
  },

}

// actions
//====================================================================
const actions = {

  async initApp({rootState, dispatch}) { 
    if(!rootState.Content.contentDb) {        
      await dispatch('Content/initDatabase', null, {root:true});
    }
    if(!rootState.Character.characterDb) {  
      await dispatch('Character/initDatabase', null, {root:true});    
    }    
    if(!rootState.Forge.foregeState) {
      await dispatch('Forge/initForge', null, {root:true});      
    }
    dispatch('checkReadyState');
  },

  checkReadyState({rootState, commit}) {
    if(rootState.Forge.forgeState && rootState.Character.characterDb && rootState.Content.contentDb) {
      commit("SET_READY_STATE", true);
    }
  },

  //-------------------------------------------------------

  async listCharacters({commit, rootState}) {
    let characters = await rootState.Character.characterDb.listCharacters();
    commit('SET_CHARACTERS', characters);
  },

  async listCampaigns({commit}) {
    let campaigns = [];  // todo: g20 DB hit
    commit('SET_CAMPAIGNS', campaigns);
  },
}

// mutations
//====================================================================
const mutations = {

  SET_READY_STATE(state, ready) {
    console.log('...app initialized');
    state.ready = ready;
  },

  SET_CHARACTERS(state, characters) {
    state.characters = characters;
  },

  SET_CAMPAIGNS(state, campaigns) {
    state.campaigns = campaigns;
  }
}

//====================================================================
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}