<template>
<div class="info-summary">
  <div>
    <div class="character-name">{{characterInfo.Name}}</div>
    <div class="character-title" v-if="characterInfo.Title">{{characterInfo.Title}}</div>
  </div>
  <div class="character-demographics">
    <div v-if="characterInfo.Age"><span>Age:</span> {{characterInfo.Age}}</div>
    <div v-if="characterInfo.Gender"><span>Gender:</span> {{characterInfo.Gender}}</div>
    <div v-if="characterInfo.Hair"><span>Hair:</span> {{characterInfo.Hair}}</div>  
    <div v-if="characterInfo.Eyes"><span>Eyes:</span> {{characterInfo.Eyes}}</div>
    <div v-if="characterInfo.Height"><span>Height:</span> {{characterInfo.Height}}</div>
    <div v-if="characterInfo.Weight"><span>Weight:</span> {{characterInfo.Weight}}</div>
  </div>
  <div class="character-stories">    
    <div v-if="characterInfo.OutwardAppearance"><span>Appearance:</span>{{characterInfo.OutwardAppearance}}</div>
    <div v-if="characterInfo.Backstory"><span>My Story:</span>{{characterInfo.Backstory}}</div>
  </div>
</div>
</template>

<script>
export default {
  name: "InfoSummary",
  props: {
    characterInfo: Object
  }
}
</script>

<style lang="scss" scoped>
.info-summary {
  border: solid 1px rgba(0,0,0,0.5);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px 10px 10px 10px;
  margin: 5px;

  .character-name {
    font-size: 20px;
    text-align: center;
  }
  .character-title {
    font-size: 14px;
    text-align: center;
    font-style:italic;
    color:#aaa;
  }
  .character-demographics {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 5px;
    &> div {
      width: 33%;
      font-size: 12px;
        color:#ccc;

      &> span {
        color:#fff;
        padding-right: 5px;
      }
    }
  }
  .character-stories {
    font-size: 14px;
    color:#ccc;

    &> div {
      margin: 5px 0;
      padding: 5px;
      &> span {
        color:#fff;
        padding-right: 5px;
      }
    }
  }
}
</style>