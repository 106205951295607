
export const characterSchema = {  
  characters: '++CharacterRecordId, Player_ID, Name, Level, Created, Updated',
  snapshots: '++CharacterRecordId, Player_ID, Name, Level, Created'  
}

export const contentSchema = {
  Armor: 'ArmorCode, Description, SortOrder',
  AttackType: 'AttackCode, AttackType, SortOrder',
  Class: 'ClassName, Stat1, Stat2, SpellAbility_ID, ClassSkillGroup, IsActive',
  CriticalScore: 'Score_ID, CritTypeID, CritSeverityID, ScoreText, critSizes, Location, SortOrder',
  CriticalSeverity: 'CritSeverityID, CritSeverityDesc, ArmorDamageDie',
  CriticalSize: 'CritSizeID, CritSizeDesc',
  CriticalType: 'CritTypeID, CritSizeID, SortOrder',
  Difficulty: 'Difficulty, DifficultyDescription, SortOrder',
  Experience: 'Level, ExperienceNeeded',
  Inheritance: 'g20Roll, Inheritance',
  Language: 'LanguageID, LanguageDesc',
  LevelBonusBackground: 'LBB_ID, SkillGroup_ID, LevelBonus, g20Roll',
  ManeuverResult: 'Result, ResultDescription, SortOrder',
  ManeuverRoll: 'ManeuverRoll_ID, Difficulty, Result, g20Roll, SortOrder',
  Material: 'Material_ID, MaterialName, Durability, EnchantingRRMod, DifficultyMin, BonusMin, QualityRange',
  Race: 'RaceName, RaceGroup, HitsPerRank, SortOrder',
  Realm: 'Realm_ID, RealmDesc, IsRealm',
  Score: 'Score',
  Setting: 'SettingCode',
  Skill: 'Skill_ID, SkillName, SkillNameContested, SkillGroup_ID, SkillTypeCode, Stat1, Stat2, IsActive, IsImportant',
  SkillGroup: 'SkillGroup_ID, SkillGroupName, Stat1, Stat2, UseSkillStats, SortOrder',
  SpecialAbility: 'ID, SpecialAbilityType, g20Roll, Name',
  SpecialAbilityType: 'SpecialAbilityType',
  Spell: 'Spell_ID, SpellListID, Level, SpellName, SpellPrepCode, AreaEffect, Duration, Range, SpellTypeCode, reference_ID, IsActive',
  SpellAbility: 'SpellAbility_ID, SpellAbilityDesc',
  SpellList: 'SpellListID, SpellListName, TypeID, RealmID, ClassName',
  SpellListType: 'TypeID, TypeDesc, TypeOrder',
  SpellPrep: 'SpellPrepCode, SpellPrepDesc',
  SpellType: 'SpellTypeCode, SpellTypeDesc',
  Stat: 'Stat, StatName, SortOrder',
  StatGain: 'StatGain_ID, lowroll, highroll, diff',
  Vice: 'Vice_ID, ViceName',
  WeaponGroup: 'WeaponGroupCode, WeaponGroupDesc, Stat1, Stat2, SortOrder',
  Weapons: 'Weapon_ID, Name, WeaponGroupCode'
}