<template>
<div class="stat-gain-list" v-if="statGainSummary">
  <div class="title">Stat Gains:</div>
  <div class="list-item" v-for="sgk in Object.keys(statGainSummary)" :key="sgk">
    <div v-if="statGainSummary[sgk].cur>0">
      {{sgk}}: {{$formatBonus(statGainSummary[sgk].cur)}} to Current
    </div>
    <div v-if="statGainSummary[sgk].pot>0">
      {{sgk}}: {{$formatBonus(statGainSummary[sgk].pot)}} to Potential
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "StatGainSummary",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
  },
  data() {
    return {
      statGainSummary: []
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {
          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {
    setState() {
      this.statGainSummary = this.$deepClone(this.forgeState.ledger.statGainsLedger);
    }
  }
}
</script>

<style lang="scss" scoped>
.stat-gain-list {
  margin: 10px;
  padding: 10px;
  background-color: rgba(0,0,0,0.25);
  border: solid 1px rgba(0,0,0,0.25);

  .list-item {
    font-size: 12px;
    padding: 0 0 0 10px;
    color: #b3c1ff;
  }
}
</style>