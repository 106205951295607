<template>
<div>

  <div class="character-sheet-navs">
    <button class="btn-capped-red btn-sm" @click="$router.push({name: 'Concourse'})">Back</button>
    <button class="btn-capped-red btn-sm" v-if="isLevelUp" @click="levelCharacter()">Level Up!</button>
  </div>

  <div class="character-sheet">

    <div class="character-sheet-pages">

      <div class="page front">
        <div class="left">
          <BasicInfo :value="character" @save-character="saveCharacter" @cancel="cancel"></BasicInfo>
          <Attributes :value="character" @save-character="saveCharacter" @cancel="cancel"></Attributes>
          <CombatInfo :value="character" @save-character="saveCharacter" @cancel="cancel"></CombatInfo>
          <Resists :value="character" @save-character="saveCharacter" @cancel="cancel"></Resists>
        </div>
        <div class="right">
          <Skills :value="character" @save-character="saveCharacter" @cancel="cancel"></Skills>
          <Fate :value="character" @save-character="saveCharacter" @cancel="cancel"></Fate>
        </div>
      </div>

      <div class="page back">
        <div class="left">
          <Equipment :value="character" @save-character="saveCharacter" @cancel="cancel"></Equipment>
          <CharacterStory :value="character" @save-character="saveCharacter" @cancel="cancel"></CharacterStory>
        </div>
        <div class="right">
          <Wealth :value="character" @save-character="saveCharacter" @cancel="cancel"></Wealth>
          <Backgrounds :value="character" @save-character="saveCharacter" @cancel="cancel"></Backgrounds>
        </div>
      </div>

      <div class="page spellbook">
        <Spellbook :value="character" @save-character="saveCharacter" @cancel="cancel"></Spellbook>  
      </div>

    </div>

  </div>

</div>
</template>


<script>
export default {
  name: "CharacterSheet",

  computed: {
    ready() { return this.$store.state.Forge.ready; },
    isLevelUp() { return this.character?.Xp >= this.nextLevelXp }
  },

  watch: {
    ready: {
      handler(val) {
        if(!val) { 
          this.init();
        } else {
          this.setState();
        }
      },
      immediate: true
    },
  },

  data() {
    return {
      characterRecordId: parseInt(this.$route.params.characterRecordId),
      character: null,
      nextLevelXp: -1,
      editable: false
    }
  },
  
  methods: {

    async init() {
      await this.$store.dispatch('App/initApp'); 
    },

    async setState() {
      await this.getCharacter();
      await this.getExperienceNeeded(this.character.Level +1);
    },

    async getCharacter() {
      let character = await this.$store.dispatch('Character/getCharacter', this.characterRecordId); 
      this.character = character;
    },

    async getExperienceNeeded(level) {
      let experience = await this.$store.dispatch('Content/getExperienceNeeded', level);
      this.nextLevelXp = experience.ExperienceNeeded;
    },

    async levelCharacter() {
      await this.$store.dispatch('Character/levelCharacter', this.characterRecordId);
      this.$router.push({name: "CharacterForge"})
    },

    async cancel() {
      await this.setState();
    },

    async saveCharacter(character) {
      console.log('save char?', character);
      await this.$store.dispatch('Character/saveCharacter', {
        characterRecordId: this.characterRecordId,
        character: character 
      }); 
      await this.setState();
    }
  }
}
</script>

<style lang="scss" scoped>

.character-sheet-navs {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2px 4px;
  z-index: 100;
}

.character-sheet {
  position: relative;
  color: #333;
  width: 99%;
  margin: 5px auto; 
  padding: 5px;


  .character-sheet-pages {
    background-color: #fff;
    padding: 30px 10px 10px 10px; 

  }

}

@media screen and ( min-width: 850px) {

  .character-sheet {
    background-color: transparent;
    max-width: 8.5in;

    .character-sheet-pages {

      .page {
        height: 11in;
        margin-bottom:20px;
        background-color: #fff;
      }

      .front {
        padding: .25in;
        display: flex;
        height: 11in;
        background-color: #fff;
        margin-bottom: 10px;

        .left {
          flex: 1;
          padding-right: 5px;
        }
        .right {
          flex: 1;
          padding-left: 5px;
        }

      }

      .back {
        padding: .25in;
        display: flex;
        height: 11in;
        background-color: #fff;
        margin-bottom: 10px;

        .left {
          flex: 2;
          padding-right: 3px;
        }
        .right {
          flex: 1;
          padding-left: 3px;
        }

      }
      
      .col {
        flex: 1;
        padding: 0 5px;
      }

    }
  
  }
}

</style>