<template>
<div class="smoke"></div>
</template>

<script>
//----------------------------------------
export default {
  name: "FxSmoke",
  data() {
    return {
      interval: null,
      whispLimit: 25,
      whisps: []
    }
  },
  mounted() {
    this.setupWhisps();
  },
  methods: {
    setupWhisps() {

      this.interval = setInterval(() => {
        if(this.$el.children.length < this.whispLimit) {              
         this.addWhisp();
        }
      }, 500);

      this.addWhisp();
    },

    addWhisp() {
      let startX = parseInt((window.innerWidth/2) - 100);
      startX = startX + this.$getRandomInclusive(0, 200);

      let element = document.createElement('div');
      element.classList.add('whisp');
      element.style.left = startX + 'px';

      element.addEventListener('animationend', (evt) => {
        this.$el.removeChild(evt.target);
      })

      this.$el.appendChild(element);
    }


  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval = null;      
  }
}
</script>