export default class CharacterSpell {
  constructor(options) {

    this.AreaEffect = null;
    this.Description = null;
    this.Duration = null;
    this.IsActive = null;
    this.Level = 0;
    this.Range = null;
    this.SpellListID = 0;
    this.SpellName = null;
    this.SpellPrepCode = null;
    this.SpellTypeCode = null;
    this.SpellType = null;
    this.Spell_ID = 0;
    this.SpellCost = 0;
    
    this.reference = null;
    this.reference_ID = 0;
    
    if(options) {
      this.init(options);
    } 

  }

  init(options) {
    
    this.AreaEffect = options.AreaEffect || this.AreaEffect;
    this.Description = options.Description || this.Description;
    this.Duration = options.Duration || this.Duration;
    this.IsActive = options.IsActive || this.IsActive;
    this.Level = parseInt(options.Level) || this.Level;
    this.Range = options.Range || this.Range;
    this.SpellListID = parseInt(options.SpellListID) || this.SpellListID;
    this.SpellName = options.SpellName || this.SpellName;
    this.SpellPrepCode = options.SpellPrepCode || this.SpellPrepCode;
    this.SpellTypeCode = options.SpellTypeCode || this.SpellTypeCode;
    this.SpellType = options.SpellType || this.SpellType;
    this.Spell_ID = parseInt(options.Spell_ID) || this.Spell_ID;
    this.SpellCost = parseInt(options.SpellCost) || this.SpellCost;

    this.reference = options.reference || this.reference;
    this.reference_ID = parseInt(options.reference_ID) || this.reference_ID;

  }
}