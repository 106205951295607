<template>
<a class="info-link" href="#" @click.prevent="showInfo()" :title="info">
  <img src="/img/svg/info.svg" />
</a>  
</template>

<script>
export default {
  name: "g20Info",
  props: {
    info: String
  },
  methods: {
    showInfo() {
      console.log('not impemented')
    }
  }
}
</script>

<style lang="scss" scoped>
.info-link {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;

  img {
    width: 24px;
    height: auto;
  }
}
</style>