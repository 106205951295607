<template>
<div v-if="item">

  <div class="item-stats">
    <span>{{$formatBonus(item.ItemBonus)}} {{item.Name}},</span>
    <span>{{item.CritPrevention}} CP</span>
    <span> ({{armorDamage}} to damage)</span>
  </div>

  <div v-if="item.ArmorLocation==='Armor'">    
    <span>{{getArmorTypeDesc(item.ArmorType)}} ({{item.ArmorType}})</span>
    <em v-if="wearsAs">, wears as {{wearsAs.Description}}</em>
  </div>

  <div class="durability" >
    <div class="durability__items">
      <div class="durability__item" :class="{'undamaged': d.undamaged}" v-for="(d, index) in durability" :key="index">{{d}}</div>
      <div class="durability__tail-item damaged">dmg.</div> 
    </div>
  </div>  

</div> 
</template>

<script>

import { equipmentTypes } from '../../code/constants'

export default {
  name: "ArmorDurability",

  props: {
    character: Object,
    item: Object,
    wearsAs: Object
  },

  computed: {
    durability() {
      return this.getDurability();
    },
    showDefenseBonus() { return this.item.ArmorLocation==='Armor' || this.item.ArmorLocation==='Shield' }
  },

  watch: {
    item: {
      handler(val) {
        if(val) {            
          this.init();
        }
      },
      immediate: true
    }
  },

  data() {
    return {
      armorDamage: 0,
      armorTypes: [],
      difficulties: []
    }
  },

  methods: {

    async init() {
      this.armorTypes = await this.$store.dispatch('Content/getArmorTypes');
      this.difficulties = await this.$store.dispatch('Content/getDifficulties');
      this.setArmorDamage()
    },

    getArmorTypeDesc(code) {
      if(this.armorTypes.length===0) { return }
      let armorType = this.armorTypes.find(item => item.ArmorCode === code);
      return armorType.Description;
    },

    getDurability() {
      let armorLocation = this.item.ArmorLocation.toLowerCase();
      let critPenalty = Math.floor((this.item.CritPrevention + 21)/2) + " CP"
      if(armorLocation == equipmentTypes.ARMOR){
        return [critPenalty, 'NO CP', '1/2 DB', '+1 Diff.', '0 DB', '+2 Diff.'];
      } else if(armorLocation == equipmentTypes.SHIELD) {
        return [critPenalty, 'NO CP', '1/2 DB', '0 DB'];
      } else {
        return [critPenalty, 'NO CP' ];
      }
    },

    getDifficulty(code) {
      let armorType = this.armorTypes.find(item => item.ArmorCode === code);
      let difficulty = this.difficulties.find(item => item.Difficulty === armorType.ManeuverDifficulty);
      return difficulty.Difficulty;
    },

    async setArmorDamage() {
      let diff = this.getDifficulty(this.character.CombatInfo.Armor.WearsAs);
      let dmg = await this.$store.dispatch('Content/getArmorDamage', diff);
      this.armorDamage = dmg; 
    }

  }
}
</script>

<style lang="scss" scoped>

$durability-box-height: 18px;
$durability-box-width: 36px;
$durability-border-color: #999;

.durability {
  display: flex;
  font-size: 9px;
  width: 90%;

  .durability__items {
    display: flex;
    flex-wrap: wrap;
  }

  .durability__item {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px $durability-border-color;
    margin-left: -1px;
    margin-bottom: -1px;
    width: $durability-box-width;
    height: $durability-box-height;

    &.undamaged {
      background-color: #E2EFDA;
    }

  }

  .durability__tail {    
    display: flex;
    padding-left:3px;
  }

  .durability__tail-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px $durability-border-color;
    height: $durability-box-height;
    margin-left: -1px;
    padding: 0 2px;

    &.damaged {
      background-color: #FFE699;
    }
    &.destroyed {
      background-color: #F4B084;
    }
    
  }

}
</style>