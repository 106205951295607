export default class SpecialAbility {
  constructor(options) {

    this.ID = 0;
    this.Name = null;
    this.Description = null;
    this.SpecialAbilityType = null;

    this.SlotID = Math.random();
    this.pz = null;

    this.low =  0;
    this.mid = 0;
    this.high = 0;
    this.g20Roll = 0;
    
    this.cost = 10;

    if(options) {
      this.init(options);
    }

  }

  init(options) {
    this.ID = options.ID || this.ID;
    this.Name = options.Name || this.Name;
    this.Description = options.Description || this.Description;
    this.SpecialAbilityType = options.SpecialAbilityType || this.SpecialAbilityType;
    this.SlotID = options.SlotID || this.SlotID;
    this.pz = options.pz || this.pz;
    this.g20Roll = parseInt(options.g20Roll) || this.g20Roll;
    this.high = parseInt(options.high) || this.high;
    this.mid = parseInt(options.mid) || this.mid;
    this.low = parseInt(options.low) || this.low;
    this.cost = parseInt(options.cost) || this.cost;
  }

}