<template>
<MagicFrame v-if="ready"> 
  <div class="welcome-display">
    <h1>
      Welcome to the Character Forge
    </h1>
    <div class="welcome-text">
      <p>          
        The Character Forge is a guided, step-by-step tool to help you quickly create a g20 Character.
      </p>
      <p>
        At any point in the process, if you need help, click on the: 

        <G20Help book="GameCanon.pdf" bookmark="Your First Character"></G20Help>
  
        These help icons will take you directly to relevant page in Game Canon.
      </p>
      <p>
        Use the "Previous" and "Next" Arrows (<img src="/img/artwork/arrow_L.png" /> <img src="/img/artwork/arrow_R.png" />) 
        above to switch between steps. If you do not see the "Next" Arrow 
        (<img src="/img/artwork/arrow_R.png" />) then the 
        current step requires a selection to proceed.     
      </p>

    </div>

  </div>
</MagicFrame>
</template>

<script>
export default {
  name: "Welcome",
  computed: {
    ready() { return this.$store.state.Forge.ready; }
  }
}
</script>

<style lang="scss" scoped>
.welcome-display {
  h1 {
    margin: 0;
  }
  .welcome-text {      
    border: solid 1px rgba(0,0,0,0.5);
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px 10px 10px 10px;
    margin: 5px;

    img {
      width: 30px;
      height: auto;
      vertical-align: middle;
    }
  }
}
</style>