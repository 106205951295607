
export default class Skill {
  constructor(options) {
    //------------------------------------------------
    this.Skill_ID = 0;
    this.SkillGroup_ID = 0;
    this.SkillGroupName = null;
    this.SkillName = null;
    this.OrigSkillName = null;
    this.SkillNameContested = null;
    this.SkillTypeCode = null;
    this.Description = null;
    this.Stat1 = null;
    this.Stat2 = null;
    this.IsActive = 0;
    this.IsImportant = 0;
    this.Ranks = 0;
    this.ItemBonus = 0;
    this.MiscBonus = 0;
    this.Cost = 0;
    this.OrigSkillCost = 0;
    this.SkillBonus = 0;

    this.AddedRanks = 0;
    this.isTemplate = 1;        // used in skill selection
    this.isLinked = 0;          // used to include/isolate ranks by Skill_ID
    //----------------------
    if(options) {
      this.init(options);
    }
    //------------------------------------------------
  }

  init(options) {
    this.Skill_ID = parseInt(options.Skill_ID) || this.Skill_ID;
    this.SkillGroup_ID = parseInt(options.SkillGroup_ID) || this.SkillGroup_ID;
    this.SkillGroupName = options.SkillGroupName || this.SkillGroupName;
    this.SkillName = options.SkillName || this.SkillName;
    this.OrigSkillName = options.OrigSkillName || this.OrigSkillName;
    this.SkillNameContested = options.SkillNameContested || this.SkillNameContested;
    this.SkillTypeCode = options.SkillTypeCode || this.SkillTypeCode;
    this.Description = options.Description || this.Description;
    this.Stat1 = options.Stat1 || this.Stat1;
    this.Stat2 = options.Stat2 || this.Stat2;
    this.IsActive = parseInt(options.IsActive) || this.IsActive;
    this.IsImportant = parseInt(options.IsImportant) || this.IsImportant;
    this.Ranks = options.Ranks || this.Ranks;
    this.ItemBonus = options.ItemBonus || this.ItemBonus;
    this.MiscBonus = options.MiscBonus || this.MiscBonus;
    this.Cost = options.Cost || this.Cost;
    this.OrigSkillCost = options.OrigSkillCost || this.OrigSkillCost;
    this.SkillBonus = options.SkillBonus || this.SkillBonus;
    
    this.AddedRanks = options.AddedRanks || this.AddedRanks;
    this.isTemplate = options.isTemplate || this.isTemplate;
    this.isLinked = options.isLinked || this.isLinked;
    
  }

}