
//---------------------------------------------------------  
import Attribute from './Attribute'
//---------------------------------------------------------  // deprecating
export default class Attributes {
  constructor(options) {

    this.VI = new Attribute();
    this.AG = new Attribute();
    this.WI = new Attribute();
    this.ME = new Attribute();
    this.LO = new Attribute();
    this.MI = new Attribute();
    this.RE = new Attribute();
    this.CH = new Attribute();
    this.EM = new Attribute();
    this.IN = new Attribute();

    if(options) {
      this.init(options)
    }

  }

  init(options) {

    this.VI = new Attribute(options.VI) || this.VI;
    this.AG = new Attribute(options.AG) || this.AG;
    this.WI = new Attribute(options.WI) || this.WI;
    this.ME = new Attribute(options.ME) || this.ME;
    this.LO = new Attribute(options.LO) || this.LO;
    this.MI = new Attribute(options.MI) || this.MI;
    this.RE = new Attribute(options.RE) || this.RE;
    this.CH = new Attribute(options.CH) || this.CH;
    this.EM = new Attribute(options.EM) || this.EM;
    this.IN = new Attribute(options.IN) || this.IN;

  }

}