<template>
<StoneFrame class="characters">

  <div class="header" slot="header">
    <div class="title">My Characters</div>
    <div>        
      <button class="btn-gem green btn-sm" title="New Character" @click="newCharacter()">New</button>
      <button class="btn-gem blue btn-sm" v-if="forgeState.forgeMode" title="Resume Last Character" @click="resumeCharacterCreation()">Resume</button>
    </div>
  </div>

  <div slot="body">
    
    <AnnotationContainer category="warning" v-if="characters.length===0">
      You do not have a character yet.
    </AnnotationContainer>
    <div class="list-item" v-for="c in characters" :key="c.CharacterRecordId">
      <CharacterListing :character-record="c" :select="true"
                        @sync-character="syncCharacter" 
                        @edit-character="editCharacter" 
                        @level-character="levelCharacter">
      </CharacterListing>
    </div>

  </div>

</StoneFrame>
</template>

<script>
// -----------------------------------------------------
import CharacterListing from './CharacterListing'
import { getForgeState } from '../../../code/app/forgeManager'
// -----------------------------------------------------
export default {
  name:'Characters',
  components: { CharacterListing },
  computed: {
    ready() { return this.$store.state.App.ready; },
    characters() { return this.$store.state.App.characters },
    forgeState() { return getForgeState() }
  },
  watch: {
    ready: {
      handler(val) {
        if(val) {            
          this.$store.dispatch('App/listCharacters');
        }
      },
      immediate: true
    }
  },
  methods: {
    async newCharacter() {
      await this.$store.dispatch('Character/newCharacter');
      this.$router.push({name: "CharacterForge"})
    },
    resumeCharacterCreation() {
      this.$router.push({name: "CharacterForge"})
    },
    async syncCharacter() {
      console.error('syncCharacter not implemented yet');
    },
    async levelCharacter(characterRecord) {
      await this.$store.dispatch('Character/levelCharacter', characterRecord.CharacterRecordId);
      this.$router.push({name: "CharacterForge"})
    },
    async editCharacter(characterRecordId) {
      this.$router.push({name: "CharacterSheet", params: { characterRecordId: characterRecordId }});
    },
  }
}
</script>


<style lang="scss" scoped>
.characters {
  width: 100%;
    
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .title {
      padding: 1px 2px;
    }
  }

  .list-item {
    margin-bottom: 5px;
  }

  .hilight {
    background-color: #eee;
  }

}

@media screen and (min-width: 768px) {
  .characters {
    min-width: 350px;
  }
}
</style>