export default class Weapons {
  constructor(options) {

    this.Name = null;
    this.Weapon_ID = 0;
    this.WeaponGroupCode = null;
    this.Crush = 0;
    this.Inertia = 0;
    this.InertialRaw = 0;
    
    this.defaultLength = 0;
    this.Length = 0;
    this.LengthMax = 0;
    this.LengthMin = 0;

    this.MaximumDamage = 0;
    this.Pierce = 0;
    this.Skill = 0;
    this.Slash = 0;
    this.Speed = 0;

    this.defaultWeight = 0;
    this.Weight = 0;
    this.WeightMin = 0;
    this.WeightMax = 0;

    if(options) {
      this.init(options);
    }

  }

  init(options) {
    
    this.Name = options.Name || this.Name;
    this.Weapon_ID = parseInt(options.Weapon_ID) || this.Weapon_ID;
    this.WeaponGroupCode = options.WeaponGroupCode || this.WeaponGroupCode;
    this.Crush = parseFloat(options.Crush) || this.Crush;
    this.Inertia = parseFloat(options.Inertia) || this.Inertia;
    this.InertialRaw = parseFloat(options.InertialRaw) || this.InertialRaw;  

    this.Length = parseFloat(options.Length) || this.Length;
    this.LengthMin = parseFloat(options.LengthMin) || this.LengthMin;
    this.LengthMax = parseFloat(options.LengthMax) || this.LengthMax;

    this.MaximumDamage = parseFloat(options.MaximumDamage) || this.MaximumDamage;
    this.Pierce = parseFloat(options.Pierce) || this.Pierce;
    this.Skill = parseFloat(options.Skill) || this.Skill;
    this.Slash = parseFloat(options.Slash) || this.Slash;
    this.Speed = parseFloat(options.Speed) || this.Speed;
    
    this.Weight = parseFloat(options.Weight) || this.Weight;
    this.WeightMin = parseFloat(options.WeightMin) || this.WeightMin;
    this.WeightMax = parseFloat(options.WeightMax) || this.WeightMax;

    this.defaultLength =  parseFloat(options.Length) || this.Length;
    this.defaultWeight = parseFloat(options.Weight) || this.Weight;
    
  }
}