export default class Resist {
  constructor(options) {

    //------------------------------------------------
    this.Value = null;
    this.ItemMod = 0;
    this.MiscMod = 0;
    this.ResistNote = null;
    //------------------------------------------------
    this.Total = 0
    //--------------------------------
    if(options) {
      this.init(options);
    }
    //--------------------------------
  }
  
  //--------------------------------------------------------------------
  init(options) {
    this.Value = parseInt(options.Value) || this.Value;
    this.ItemMod = parseInt(options.ItemMod) || this.ItemMod;
    this.MiscMod = parseInt(options.MiscMod) || this.MiscMod;
    this.ResistNote = options.ResistNote || this.ResistNote;
    this.Total = this.Value + this.ItemMod + this.MiscMod;
  }

}