<template>
<div class="race-info" v-if="characterRace">

  <div class="race-name" v-if="summary">
    <span>Race: {{characterRace.PrimaryRaceName}}</span>
    <span v-if="characterRace.MultiRace"> / {{characterRace.SecondaryRaceName}}</span>
  </div>

  <div class="race-info-cols">
    <div class="stat-block stat-item">
      <div>Endurance Hits:</div> <div>{{characterRace.HitsPerRank}} / rank </div>            
    </div>
    <div class="stat-block stat-item">
      <div>Starting Fate:</div> <div>{{$formatBonus(characterRace.StartingFate)}}   </div>       
    </div>
  </div>

  <div class="race-info-cols">
    <div class="stat-block">
      <div>Primary Bonuses</div>
      <div class="stat-table">
        <div class="stat-item">
          <div>Vitality:</div>
          <div>{{$formatBonus(characterRace.VI)}}</div>
        </div>
        <div class="stat-item">
          <div>Agility:</div>
          <div>{{$formatBonus(characterRace.AG)}}</div>
        </div>
        <div class="stat-item">
          <div>Will:</div>
          <div>{{$formatBonus(characterRace.WI)}}</div>
        </div>
        <div class="stat-item">
          <div>Memory:</div>
          <div>{{$formatBonus(characterRace.ME)}}</div>
        </div>
        <div class="stat-item">
          <div>Logic:</div>
          <div>{{$formatBonus(characterRace.LO)}}</div>
        </div> 
        <div class="stat-item">
          <div>Might:</div>
          <div>{{$formatBonus(characterRace.MI)}}</div>
        </div>
        <div class="stat-item">
          <div>Reflexes:</div>
          <div>{{$formatBonus(characterRace.RE)}}</div>
        </div>
        <div class="stat-item">
          <div>Charisma:</div>
          <div>{{$formatBonus(characterRace.CH)}}</div>
        </div>
        <div class="stat-item">
          <div>Empathy:</div>
          <div>{{$formatBonus(characterRace.EM)}}</div>
        </div>
        <div class="stat-item">
          <div>Insight:</div>
          <div>{{$formatBonus(characterRace.IN)}}</div>
        </div>
      </div>
    </div>

    <div class="stat-block">
      <div>Resist Bonuses</div>
      <div class="stat-table">
        <div class="stat-item">
          <div>Divine:</div>
          <div>{{$formatBonus(characterRace.DivineResist)}}</div>
        </div>
        <div class="stat-item">
          <div>Elemental:</div>
          <div>{{$formatBonus(characterRace.ElementalResist)}}</div>
        </div>
        <div class="stat-item">
          <div>Qi:</div>
          <div>{{$formatBonus(characterRace.QiResist)}}</div>
        </div>
        <div class="stat-item">
          <div>Poison:</div>
          <div>{{$formatBonus(characterRace.PoisonResist)}}</div>
        </div>
        <div class="stat-item">
          <div>Disease:</div>
          <div>{{$formatBonus(characterRace.DiseaseResist)}}</div>
        </div>
      </div>
    </div>
  
  </div>

</div>
</template>

<script>
export default {
  name: "RaceSummary",
  props: {
    characterRace: Object,
    summary: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.race-info {  
  border: solid 1px rgba(0,0,0,0.5);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px 10px 10px 10px;
  margin: 5px;

  .stat-block {
    padding: 5px;
    background-color: rgba(0,0,0,0.4);
    margin-top:5px;
  }
  .stat-item {
    display: flex;
    justify-content: space-between;     
  }
  .stat-table {
    display: flex; 
    flex-wrap: wrap;
      
    .stat-item {
      font-size: 14px;
      color: rgb(179, 193, 255);
    }
  }
  .stat-table > div{
    width: 50%; 
    padding: 0 5px;
  }

}

@media screen and (min-width: 768px) {
  .race-info-cols {
    display: flex;    
  }
  .race-info-cols > div{
    flex: 1;
  }
  .race-info-cols > div:first-child {
    margin: 0 3px 0 0;
  }
  .race-info-cols > div:last-child {
    margin: 0 0 0 3px;
  }
}
</style>