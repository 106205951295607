export default class Armor {
  constructor(options) {

    this.ArmorCode = null;
    this.CastingPenalty = 0;
    this.CritPrevention = 0;
    this.CrushDef = 0;
    this.Description = null;
    this.Evasion = 0;
    this.FrostDef = 0;
    this.HeatDef = 0;
    this.InitiativePenalty = 0;
    this.ManeuverDifficulty = null;
    this.Mitigation = 0;
    this.PierceDef = 0;
    this.ShockDef = 0;
    this.SlashDef = 0;
    this.SortOrder = -1;
    this.VoidDef = 0;

    if(options) {
      this.init(options);
    }

  }

  init(options) {

    this.ArmorCode = options.ArmorCode || this.ArmorCode;
    this.CastingPenalty = parseFloat(options.CastingPenalty) || this.CastingPenalty;
    this.CritPrevention = parseFloat(options.CritPrevention) || this.CritPrevention;
    this.CrushDef = parseFloat(options.CrushDef) || this.CrushDef;
    this.Description = options.Description || this.Description;
    this.Evasion = parseFloat(options.Evasion) || this.Evasion;
    this.FrostDef = parseFloat(options.FrostDef) || this.FrostDef;
    this.HeatDef = parseFloat(options.HeatDef) || this.HeatDef;
    this.InitiativePenalty = parseFloat(options.InitiativePenalty) || this.InitiativePenalty;
    this.ManeuverDifficulty = options.ManeuverDifficulty || this.ManeuverDifficulty;
    this.Mitigation = parseFloat(options.Mitigation) || this.Mitigation;
    this.PierceDef = parseFloat(options.PierceDef) || this.PierceDef;
    this.ShockDef = parseFloat(options.ShockDef) || this.ShockDef;
    this.SlashDef = parseFloat(options.SlashDef) || this.SlashDef;
    this.SortOrder = parseInt(options.SortOrder) || this.SortOrder;
    this.VoidDef = parseFloat(options.VoidDef) || this.VoidDef;
    
  }
}