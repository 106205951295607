export default class ManeuverResult {
  constructor(options) {

    this.Result = null;
    this.ResultDescription = null;
    this.SortOrder = null;

    if(options) {
      this.init(options);
    }

  }

  init(options) {
        
    this.Result = options.Result || this.Result;
    this.ResultDescription = options.ResultDescription || this.ResultDescription;
    this.SortOrder = parseInt(options.SortOrder) || this.SortOrder;
    
  }
}