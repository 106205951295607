<template>
<div class="character-spellbook" v-if="character">
  <div>
    <SpellAbility 
      :value="character" 
      :realms="realms" 
      :spellAbilities="spellAbilities">
    </SpellAbility>
    <SpellList v-for="sl in spellLists" :key="sl.SpellListID" 
      :value="character" 
      :spell-list="sl"
      :realms="realms"
      :spellListTypes="spellListTypes">
    </SpellList>
  </div>
</div>
</template>

<script>
//---------------------------------------------
import characterModels from '../../code/models/g20CharacterModels'
//---------------------------------------------
export default {
  name: "Spellbook",
  props: {
    value: Object
  },
  watch: {    
    value: {
      handler() { this.init() },
      immediate: true
    },
  },
  data() {
    return {
      character: null,
      realms: [],
      spellAbilities: [],
      spellListTypes: []
    }
  },
  computed: {    
    spellLists() {   
      return this.character.SpellLists.map(list => new characterModels.SpellList(list));     
    }
  },
  methods: {
    async init() { 
      this.realms = await this.$store.dispatch('Content/getRealms');
      this.spellAbilities = await this.$store.dispatch('Content/getSpellAbilities');
      this.spellListTypes = await this.$store.dispatch('Content/getSpellListTypes');
      this.character = this.$deepClone(this.value);
    },
  }
}
</script>