
export default class Setting {
  constructor(options) {

    this.SettingCode = null;
    this.Val = null;

    if(options) {
      this.init(options);
    } 

  }

  init(options) {
    this.SettingCode = options.SettingCode || this.SettingCode;
    this.Val = options.Val || this.Val;
  }
}