<template>
<SimplePanel v-if="character" class="character-story" :class="{'editable': editable}">
  
  <div slot="header" @click.stop="editable=true"> My Story </div>

  <div slot="body" @click.stop="editable=true">

    <div class="command-buttons" v-if="editable">
      <button class="btn-gem green btn-sm" title="Save Character" @click.stop="saveCharacter()">Save</button>
      <button class="btn-gem red btn-sm" title="Cancel" @click.stop="cancel()">Cancel</button>
    </div>

    <div class="story-items">

      <div>
        <div class="story-image" @click="openModal()">
          <img :src="character.Picture" />
        </div>
      </div>

      <div class="story-content">
        <div>
          <div class="story-label">First Impression: </div>
          <EditableContent class="story-text" v-if="editable" v-model="character.Info.OutwardAppearance" :allow-enter="true" />
          <span class="story-text" v-else>{{character.Info.OutwardAppearance}}</span>
        </div>
        <div>
          <div class="story-label">Backstory: </div>
          <EditableContent class="story-text" v-if="editable" v-model="character.Info.Backstory" :allow-enter="true" />
          <span class="story-text" v-else>{{character.Info.Backstory}}</span>
        </div>
      </div>

    </div>

    <Modal :controller="modal" @close="modal.show=false">
      <AvatarForm  @close="modal.show=false" @image-data="setPicture"></AvatarForm>
    </Modal>

  </div>


</SimplePanel>
</template>

<script>
//------------------------------------------------------
//------------------------------------------------------
export default {

  name:"CharacterStory",

  props: {
    value: Object
  }, 

  watch: {    
    value: {
      handler() { this.character = this.$deepClone(this.value) },
      immediate: true
    },
  },

  data() {
    return {
      editable: false,
      character: null,
      modal: {
        show: false
      }
    }
  },

  methods: {

    openModal() {
      if(this.editable) {
        this.modal.show=true;
      }
    },

    setPicture(imageData) {
      let character = this.$deepClone(this.character);
      character.Picture = imageData;
      this.character = character;
      this.modal.show = false;
    },

    cancel() {
      this.editable=false;
      this.$emit('cancel');
    },

    saveCharacter() {
      this.editable = false;
      this.$emit('save-character', this.character);
    }

  }
}
</script>


<style lang="scss" scoped>
.character-story {
  border: solid 1px #ccc;

  .command-buttons {
    margin-bottom: 5px;
    text-align: right;
  }

  .story-items {
    display: flex;

    .story-image {
      border: solid 1px #ccc;
      border-radius: 3px;
      margin-right: 5px;
      padding: 5px;
      width: 120px;
      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }
    .story-content{
      flex-grow: 1;
      padding-right: 20px;

      .story-label {
        font-size: 14px;
        font-weight: bold;
      }

      .story-text {
        font-size: 12px;
        white-space: pre-wrap;
      }

    }

  }

}
</style>