<template>
<div class="numeric-stepper" :class="{'horizontal': horizontal}">

  <div>
    <button type="button" class="button" :class="{'visible': handles }" @click="step($event, 1)" title="increase">
      <font-awesome-icon class="text-light" icon="chevron-up" />
    </button>
  </div>

  <span ref="field" class="field"
    v-text="value"
    @blur="sendVal($event)"
    @keydown.enter="endEdit()"
    contenteditable>
  </span>

  <div>
    <button type="button" class="button" :class="{'visible': handles }" @click="step($event, -1)" title="decrease">
      <font-awesome-icon class="text-light" icon="chevron-down" />
    </button>
  </div>

</div>
</template>

<script>
export default {
  name: "StepperSpan",
  props: {
    value: { type: Number, default: 0 },
    min: { type: Number, default: 0 },
    max: { type: Number, default: Infinity },
    horizontal: { type: Boolean, default: false }
  },
  data() {
    return {
      handles: false
    }
  },
  methods: {
    step($event, dir) {
      let val = this.stringToInt(this.$refs.field.innerText);

      val += dir;
      if(this.min != null && val < this.min){
        val = this.min;
      }
      if(this.max != null && val > this.max){
        val = this.max;
      }

      if(val>= this.min && val <= this.max) {
        this.$refs.field.innerText = val;
        this.$emit('input', val);
      }

    },
    sendVal($event) {
      let val = this.stringToInt($event.target.innerText);
      $event.target.innerText = val;
      this.$emit('input', val);
    },
    endEdit() {
      this.$refs.field.blur();
    },
    stringToInt(str) {
      let num = parseInt(str);
      if(Number.isNaN(num)) {
        return 0;
      }else{
        return num;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.numeric-stepper {
  &.horizontal {
    display: flex;

    .field {
      width: 20px;
    }

    .button {
      margin: 0;
      padding: 0;
      font-size: 10px;
    }
  }
}
</style>