<template>
<MagicFrame v-if="ready"> 

  <div class="level-bonuses">

    <header>
      <div class="title">
        Level Bonuses
        <G20Help book="GameCanon.pdf" bookmark="Choose Your Realm"></G20Help>
      </div>
    </header>

    <div class="score-keeper" v-if="character">
      <div>
        Skill Group Max Bonus: +{{maxPointsPerGroup}}
      </div>
      <div :class="{'text-danger':!isValid}">
        Available: {{maxPoints - spentPoints}}
      </div>
    </div>

    <div class="level-bonus-legend">
      <div class="prev-lbl">prev.</div>
      <div class="tot-lbl">tot.</div>
    </div>

    <div class="level-bonus-items" v-if="snapshot && levelBonusLedger">

      <div class="level-bonus-item" v-for="sg in Object.keys(levelBonusLedger.items)" :key="sg">

          <div class="label" :class="{'text-success-pale': isSelected(sg)}">
            {{levelBonusLedger.items[sg].SkillGroupName}} 
          </div>
              
          <div class="calculation">

            <div class="calculation-item">{{snapshot[sg].LevelBonus}}</div>
            <div class="calculation-item">+</div>  

            <input class="form-control input-trans-sm" type="number" min="0" style="width:45px;"
                  :value="levelBonusLedger.items[sg].LevelBonus"
                  :max="maxPointsPerGroup"
                  :disabled="disableInput(sg)"
                  @input="updateLedger(sg, $event.target.value)" /> 

            <div class="calculation-item">=</div>
            <div class="calculation-item">{{snapshot[sg].LevelBonus + levelBonusLedger.items[sg].LevelBonus}}</div>    

          </div>
 
      </div>
    </div>

  </div>

</MagicFrame>
</template>

<script>
//------------------------------------------------------
import { levelBonusPointsPerLevel } from '../../../code/game/calculations'
//------------------------------------------------------
export default {
  name: "LevelBonuses",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
    maxPoints() { return levelBonusPointsPerLevel(this.character.Level); },
    maxPointsPerGroup() { return Math.ceil(this.character.Level/2); }
  },
  data() {
    return {
      character: {},
      levelBonusLedger: null,
      snapshot: null,
      spentPoints: 0,
      isComplete: false,
      isValid: true
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {
          
          let character = await this.$store.dispatch('Character/getCharacter', this.forgeState.characterRecordId); 
          this.character = character;

          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {
    
    setState() {
      
      if(this.forgeState.ledger.levelBonusLedger) {
        this.levelBonusLedger = this.forgeState.ledger.levelBonusLedger;
      } else {
        let levelBonusLedger = { max: this.maxPoints, items: this.$deepClone(this.character.SkillGroups)} ;
        let keys = Object.keys(levelBonusLedger.items);
        keys.forEach(key => { levelBonusLedger.items[key].LevelBonus = 0});  // zero out LB
        this.levelBonusLedger = levelBonusLedger;
        this.setLedgerItems();
      }

      this.snapshot = this.$deepClone(this.character.SkillGroups);
      this.spentPoints = this.getSpentPoints();
      this.$emit("validate");

    },

    updateLedger(sg, value) {
      this.levelBonusLedger.items[sg].LevelBonus = parseInt(value);
      this.spentPoints = this.getSpentPoints();
      this.isComplete = (this.spentPoints === this.maxPoints);
      this.isValid = this.spentPoints <= this.maxPoints;
      this.setLedgerItems();
      this.$emit("validate");
    },

    getSpentPoints() {
      let points = 0;
      let sg = Object.keys(this.levelBonusLedger.items);
      sg.forEach(key => {
        points += this.levelBonusLedger.items[key].LevelBonus;
      });
      return points;
    },

    isSelected(sg) {
      return (this.levelBonusLedger.items[sg].LevelBonus > 0);
    },

    disableInput(sg) {
      if(this.snapshot[sg].LevelBonus === this.maxPointsPerGroup) {
        return true;
      } else if (this.isComplete && this.levelBonusLedger.items[sg].LevelBonus === 0) {
        return true;
      } else {
        return false;
      }
    },

    setLedgerItems() {
      this.$store.dispatch('Forge/setLedgerItems', { levelBonusLedger: this.levelBonusLedger  });
    }
  }
}
</script>

<style lang="scss" scoped>

.level-bonuses {

  .score-keeper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    border-bottom: solid 1px #eee;
    padding: 10px;
    margin-bottom: 10px;
  }

  .level-bonus-legend {
    display: flex;
    justify-content: flex-end;
    padding: 0 5px;
    font-size: 12px;
    .prev-lbl {
      width: 120px;   
    }
    .tot-lbl {
      width: 30px;
      text-align:center;
    }
  }

  .level-bonus-items {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;

    .level-bonus-item {
      display: flex;
      justify-content: space-between;
      background-color: rgba(255,255,255,0.1);
      margin: 2px 0;
      padding: 3px 5px;
      width: 100%;      

      .label {
        width: 85px; 
        padding: 2px;
        font-size: 14px;
        margin:0;      
      }
      .calculation {
        display: flex;
        .calculation-item {
          width: 25px;
          text-align: center;
        }        
      }



    }

  }



}

@media screen and (min-width: 768px) {

}

</style>