<template>
<div class="fate-summary">
  <div>
    <div>Starting Fate:</div>
    <div>{{characterFate.startingFate}}</div>
  </div>
  <div>
    <div>Spent Fate:</div>
    <div>{{characterFate.spentFate}}</div>
  </div>
  <div>
    <div>Remaining Fate:</div>
    <div>{{characterFate.startingFate - characterFate.spentFate}}</div>
  </div>
</div>
</template>

<script>
export default {
  name: "FateSummary",
  props: {
    characterFate: Object
  }
}
</script>

<style lang="scss" scoped>
.fate-summary {
  display: flex;
  justify-content: space-around;
  border: solid 1px rgba(0,0,0,0.5);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px 10px 10px 10px;
  margin: 5px;

  &> div {
    text-align: center;
  }
}
</style>