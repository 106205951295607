export default class Material {
  constructor(options) {

    this.Material_ID = 0;
    this.MaterialName = null;
    
    this.BonusMin = 0;
    this.DifficultyMin = null;
    this.Durability = 0;
    this.EnchantingRRMod = 0;
    this.QualityRange = 0;

    if(options){
      this.init(options);
    }
  }

  init(options) {

    this.Material_ID = parseInt(options.Material_ID) || this.Material_ID;
    this.MaterialName = options.MaterialName || this.MaterialName;
    
    this.BonusMin = parseInt(options.BonusMin) || this.BonusMin;
    this.DifficultyMin = options.DifficultyMin || this.DifficultyMin;
    this.Durability = parseInt(options.Durability) || this.Durability;
    this.EnchantingRRMod = parseInt(options.EnchantingRRMod) || this.EnchantingRRMod;
    this.QualityRange = parseInt(options.QualityRange) || this.QualityRange;

  }
}