<template>
<MagicFrame v-if="ready"> 

  <div class="realm-selection">

    <header>
      <div class="title">
        Your Realm
        <G20Help book="GameCanon.pdf" bookmark="Choose Your Realm"></G20Help>
      </div>
      <div class="multi-realm-selection">
        <label>
          <small class="checkbox-label">Hybrid</small> 
          <input type="checkbox" v-model="multiRealm" @change="multiRealmChanged()" />
        </label>
      </div>
    </header>

    <div class="realm-selections">
      <label><strong>Realm<span v-if="multiRealm">s</span></strong></label>

      <div class="realm-selectors">

        <select class="full" name="PrimaryRealm" v-model="primaryRealmName" @change="primaryRealmNameChanged()">
          <option :value="null">~ please select ~</option>
          <option v-for="r in primaryRealms" :key="r.RealmDesc" :value="r.RealmDesc">{{r.RealmDesc}}</option>
        </select>

        <select v-if="multiRealm" class="full" name="SecondaryRealm" v-model="secondaryRealmName" @change="secondaryRealmNameChanged()">
          <option :value="null">~ please select ~</option>
          <option v-for="r in secondaryRealms" :key="r.RealmDesc" :value="r.RealmDesc">{{r.RealmDesc}}</option>
        </select>

      </div>

    </div>

    <div class="realm-info" v-if="primaryRealm">
      {{primaryRealm.RealmLongDesc}}
      <div v-if="secondaryRealm">
        <br/>     
        {{secondaryRealm.RealmLongDesc}}
      </div>
    </div>

  </div>

</MagicFrame>
</template>

<script>
//------------------------------------------------------
//------------------------------------------------------
export default {
  name: "RealmSelect",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
  },
  data() {
    return {
      multiRealm: false,
      primaryRealms: [],
      primaryRealmName: null,
      primaryRealm: null,
      secondaryRealms: [],
      secondaryRealmName: null,
      secondaryRealm: null,
      characterSpellAbility: null
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {            
          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {
    
    async setState() {
  
      this.primaryRealms = await this.$store.dispatch('Content/getRealms');
      this.characterSpellAbility = this.forgeState.ledger.characterSpellAbility;

      if(this.characterSpellAbility) {
        this.filterSecondaryRealms();
        this.primaryRealmName = this.characterSpellAbility.PrimaryRealm;
        if(this.primaryRealmName) { this.setPrimaryRealm() }
        this.secondaryRealmName = this.characterSpellAbility.SecondaryRealm; 
        if(this.secondaryRealmName) { this.setSecondaryRealm() }
        this.multiRealm = (this.primaryRealmName && this.secondaryRealmName);        
      }
    },

    multiRealmChanged() {
      if(!this.multiRealm) { 
        this.resetSecondaryRealm(); 
        this.setPrimaryRealm();
      }
    },    

    primaryRealmNameChanged() {
      this.setPrimaryRealm();
      this.resetSecondaryRealm();
      this.filterSecondaryRealms();
    },

    secondaryRealmNameChanged() {
      this.setSecondaryRealm();
    },

    //-------------------------------


    resetSecondaryRealm() {
      this.secondaryRealmName = null;
      this.secondaryRealm = null;
      this.characterSpellAbility.SecondaryRealm = null;
      this.characterSpellAbility.isHybrid = false;
    },

    filterSecondaryRealms() {
      let realms = this.primaryRealms.filter(r => { 
        if(r.RealmDesc !== this.primaryRealmName) {
          return r;
        }
      });
      this.secondaryRealms = realms;
    },

    setPrimaryRealm() {
      if(this.primaryRealmName) {
        let realm = this.primaryRealms.find(r => r.RealmDesc === this.primaryRealmName);
        console.log('pri', realm);
        this.primaryRealm = realm;
        this.characterSpellAbility.PrimaryRealm = this.primaryRealmName;
        this.setLedgerItems();
      }
    },

    setSecondaryRealm() {
      if(this.secondaryRealmName) {
        let realm = this.secondaryRealms.find(r => r.RealmDesc === this.secondaryRealmName);
        console.log('sec', realm);
        this.secondaryRealm = realm;
        this.characterSpellAbility.SecondaryRealm = this.secondaryRealmName;
        this.characterSpellAbility.isHybrid = true;
        this.setLedgerItems();
      }
    },

    setLedgerItems() {
      this.$store.dispatch('Forge/setLedgerItems', { characterSpellAbility: this.characterSpellAbility });
    }
  }
}
</script>

<style lang="scss" scoped>

.realm-selection { 
  header{
    display: flex;
    justify-content: space-between;
    width: 100%;

    .title {
      font-size: 20px;
    }
    
    .multi-realm-selection {
      padding-top: 5px;
      padding-right: 5px;

      .checkbox-label {
        vertical-align: top; 
        line-height: 20px; 
        margin-right: 5px;
      }
    }
  }

  .realm-selections {
    border: solid 1px rgba(0,0,0,0.5);
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px 10px 10px 10px;
    margin: 5px;

    .realm-selectors {
      display: block;
              
      select:last-child {
        margin-top:5px;
      }
    }
  }

  .realm-info {    
    border: solid 1px rgba(0,0,0,0.5);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 10px 10px 10px;
    margin: 5px;
    font-size: 12px;
    color: #b3c1ff;
  }

}

@media screen and (min-width: 768px) {
  .realm-selection { 
    .realm-selections {
      .realm-selectors {
        display: flex;
        select:last-child {
          margin: 0;
        }
      }   
    }
  }
}
</style>