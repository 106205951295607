<template>
<div class="character-spell" v-if="spell">
  <div class="character-spell-info">
    <div class="character-spell-stats">
      <span><strong>Rng:</strong> {{range}}</span>
      <span><strong>Dur:</strong> {{duration}}</span>
      <span><strong>Area:</strong> {{areaEffect}}</span>
    </div>
    <div class="character-spell-name">
      <div>{{spell.Level}}&mdash;{{spell.SpellName}}</div>
    </div>
    <div class="character-spell-casting">
      {{$formatBonus(castingMod, true)}}
      <span class="text-italic">  
        <span>{{spellType}} </span>
        <span>{{spellPrepType}}.</span>
        <span>{{spellPrep}}</span>
      </span>
    </div>
    <div class="character-spell-description"> 
      <span class="pl-2">{{spell.Description}}</span>      
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Spell",

  props: {
    value: Object,
    spell: Object
  },

  computed: {
    casterLevel() { return this.character.Level },
    range() { return this.spell.Range ? this.spell.Range : "n/a" },
    duration() { return this.spell.Duration ? this.spell.Duration : "n/a" },
    areaEffect() { return this.spell.AreaEffect ? this.spell.AreaEffect : "n/a" },
    spellPrep() {
      if(!this.character) { return }
      let prep = "";
      if(this.spellPrepType == 'Ritual') {
        let lvlDif = this.character.Level - this.spell.Level;
        if(lvlDif > 0) {
          prep = "No prep.";
        } else if (lvlDif == 0) {
          prep = "1 round of prep.";
        }else {
          prep = "2 rounds of prep.";
        }        
      }
      return prep;
    },
    spellPrepType() { 
      switch(this.spell.SpellPrepCode) {
        case "C": return "Cantrip";
        case "R": return "Ritual";
        default: return null;
      }
    },
    spellType() { 
      switch(this.spell.SpellTypeCode) {
        case "C": return "Conjure";
        case "E": return "Ensorcel";
        case "V": return "Evoke";
        default: return null;
      }
    }
  },
  
  watch: {    
    value: {
      handler() { this.init() },
      immediate: true
    },
  },

  data() {
    return {
      character: null,
      castingMod: 0
    }
  },

  methods: {

    async init() { 
      this.character = this.$deepClone(this.value);
    },

    setCastingMod() {
      
        // ----------------------
        let diff = this.casterLevel - this.spell.Level;
        let penalty = 0;
        if(diff < 0) {
          penalty = diff * 2;
        }
        // ----------------------
        let spellType = this.spellType.toLowerCase();        
        let skillBonus = this.context[spellType] ? this.context[spellType].SkillBonus : 0;  //?
        this.castingMod = skillBonus + penalty;
      
    }
  }
};
</script>


<style lang="scss" scoped>

$list-header-bg-color: #dac4bd;
$spell-header-bg-color: #e0decb;

$list-note-bg-color: #fff7f5;
$list-note-brdr-color: #ffbcac;

$ref-bg-color: #FFFFDD;
$ref-brdr-color: #D2CB97;

$spell-prep-bg-color: #f8f7f4;

.character-spell {
  padding: 0;
  padding-bottom: 5px;

  .character-spell-select {
    margin: 0;
    padding: 0 10px 5px 10px;
    text-align: center;
    vertical-align: middle;
  }
  .character-spell-select input {
    margin: 0;
  }
  .character-spell-name {
    padding: 1px 2px;
    font-size: 12px;
    background: $spell-header-bg-color;
    font-weight: bold;
  }
  .character-spell-stats {
    float: right;
    font-size: 9px;
    padding: 3px 3px;
  }
  
  .character-spell-stats > span {
    padding: 0 3px;
  }

  .character-spell-casting{
    padding: 0;
    font-size: 11px;
    background: $spell-prep-bg-color;
  }
  
  .character-spell-description {
    padding: 0;
    font-size: 11px;
  }
}
</style>