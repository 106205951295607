<template>
<div class="resists" v-if="character">

  <div>Resist vs...</div>

  <div v-for="cr in Object.keys(character.Resists)" :key="cr">
    <div class="label">{{cr}}</div>
    <div class="modifier">{{character.Resists[cr].Total}}</div>
  </div>

</div>
</template>

<script>
//------------------------------------------------------
export default {
  name:"Resists",
  props: { 
    value: Object,
    editable: {
      type: Boolean,
      default: false
    }
  },
  watch: {    
    value: {
      handler() { this.character = this.$deepClone(this.value) },
      immediate: true
    },
  },
  data() {
    return {
      character: null
    }
  },
}
</script>

<style lang="scss" scoped>

.resists {
  display: flex;
  justify-content: space-between;
  padding: 0;
  border: dotted 1px #ccc;
  font-size: 12px;
  width:100%; 
  padding: 5px;
}

.resists > div {
  text-align: center;
  min-width: 25px;

  .label {
    font-weight: bold;
  }
  .modifier {
    color: #f00;
  }
}
</style>
