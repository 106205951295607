
export const forgeMode = {
  NONE: null,
  NEW_CHARACTER: 'new-character',
  LEVEL_UP_FIRST: 'level-up-first',
  LEVEL_UP: 'level-up',
  EDIT_CHARACTER: 'edit-character'
}

export const forgeMenus = {  
  'new-character': [
    { key: "Welcome", label: "Welcome", component: "Welcome" },
    { key: "Race", label: "Your Race", component: "RaceSelect" },
    { key: "Class", label: "Your Class", component: "ClassSelect" },
    { key: "Realm", label: "Your Realm", component: "RealmSelect" },
    { key: "Attributes", label: "Your Attributes", component: "AttributesSelect" },
    { key: "StartingFate", label: "Starting Fate", component: "StartingFate" },
    { key: "Inheritance", label: "Inheritance", component: "InheritanceSelect" },
    { key: "Family", label: "Family Background", component: "BackgroundSelect" },
    { key: "Adolescence", label: "Adolescent Background", component: "BackgroundSelect" },
    { key: "YoungAdult", label: "Young Adult Background", component: "BackgroundSelect" },
    { key: "ChosenPath", label: "Chosen Path", component: "BackgroundSelect" },
    { key: "WeaponGroups", label: "Weapon Skills", component: "WeaponGroupsSelect" },
    { key: "Features", label: "Personal Features", component: "FeaturesSelect" },
    { key: "CharacterInfo", label: "Character Info", component: "CharacterInfo" },
    { key: "CreationSummary", label: "Creation Summary", component: "CreationSummary" }
  ],
  'level-up-first': [
    { key: "SkillsSelect", label: "Skills", component: "SkillsSelect" },
    { key: "SpellsSelect", label: "Spells", component: "SpellsSelect" },
    { key: "PossessionsSelect", label: "Possessions", component: "PossessionsSelect" },
    { key: "LevelUpSummary", label: "Level Up Summary", component: "LevelUpSummary" }
  ],
  'level-up': [
    { key: "StatGains", label: "Stat Gains", component: "StatGains" },
    { key: "LevelBonuses", label: "Level Bonuses", component: "LevelBonuses" },
    { key: "SkillsSelect", label: "Skills", component: "SkillsSelect" },
    { key: "SpellsSelect", label: "Spells", component: "SpellsSelect" },
    { key: "Features", label: "Personal Features", component: "FeaturesSelect" },       // 10 lvl + 10 fate
    { key: "LevelUpSummary", label: "Level Up Summary", component: "LevelUpSummary" }
  ],
  'level-up-fifth': [
    { key: "StatGains", label: "Stat Gains", component: "StatGains" },
    { key: "LevelBonuses", label: "Level Bonuses", component: "LevelBonuses" },
    { key: "SkillsSelect", label: "Skills", component: "SkillsSelect" },
    { key: "SpellsSelect", label: "Spells", component: "SpellsSelect" },
    { key: "Features", label: "Personal Features", component: "FeaturesSelect" },       // Free Special Feature
    { key: "LevelUpSummary", label: "Level Up Summary", component: "LevelUpSummary" }
  ]
}

export const spellType = {
  C: 'Conjure',
  E: 'Ensorcel',
  V: 'Evoke'
}

export const spellPrep = {
  C: 'Cantrip',
  R: 'Ritual'
}

export const armorLocations = [
  { name: "Body", code: "BDY", prop: 'Armor' },
  { name: "Helm", code: "HLM", prop: 'Helm' },
  { name: "Shield", code: "SHL", prop: 'Shield' },
  { name: "Arms", code: "BRC", prop: 'Arms' },
  { name: "Legs", code: "GRV", prop: 'Legs' }
];

export const weaponSkills = [
  { name: "1-Hand Slash", code: "1HS" },
  { name: "1-Hand Pierce", code: "1HP" },
  { name: "1-Hand Crush", code: "1HC" },
  { name: "2-Hand", code: "2H" },
  { name: "Chain", code: "CH" },
  { name: "Bow", code: "BO" },
  { name: "Crossbow", code: "XB" },
  { name: "Thrown", code: "TH" },
  { name: "Evoke", code: "EV" }
];






























export const equipmentTypes = {
  ARMOR: 'armor',
  SHIELD: 'shield',
  HELM: 'helm',
  BRACERS: 'bracers',
  GREAVES: 'greaves',
  ALCHEMICAL_AGENT: 'alchemical agent',
  MAGICAL_JEWELRY: 'magical jewelry',
  MAGICAL_WEAPON: 'magical weapon',
  MASTERWORK_WEAPON: 'masterwork weapon',
}




export const armorTypes = [
  { name: "None", code: "NO" },
  { name: "Full Plate", code: "FP" },
  { name: "Half Plate", code: "HP" },
  { name: "Full Chain", code: "FC" },
  { name: "Half Chain", code: "HC" },
  { name: "Scaled Hide", code: "SH" },
  { name: "Thick Hide", code: "TH" },
  { name: "Light Hide", code: "LH" },
  { name: "Hard Leather", code: "HL" },
  { name: "Soft Leather", code: "SL" }
];



export const wealth = {
  bronze: { label:'Bronze', code:'Bp', value: .01, color: "#222" },
  silver: { label:'Silver', code:'Sp', value: 1, color: "#222"  },
  gold: { label:'Gold', code:'Gp', value: 100, color: "#222"  },
  mithril: { label:'Mithril', code:'Mp', value: 10000, color: "#222" }
}


export const difficulties = [
  { name: "Absurd", code: "A" },
  { name: "Augmenting Action", code: "AA" },
  { name: "Easy", code: "E" },
  { name: "Folly", code: "F" },
  { name: "Hard", code: "H" },
  { name: "Impossible", code: "I" },
  { name: "Light", code: "L" },
  { name: "Medium", code: "M" },
  { name: "Very Hard", code: "VH" },
  { name: "Extremely Hard", code: "XH" }
];