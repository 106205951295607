export default class SpellType {
  constructor(options) {

    this.SpellTypeCode = null;
    this.SpellTypeDesc = null;

    if(options) {
      this.init(options);
    } 

  }

  init(options) {

    this.SpellTypeCode = options.SpellTypeCode || this.SpellTypeCode;
    this.SpellTypeDesc = options.SpellTypeDesc || this.SpellTypeDesc;

  }
}