
import QueryRoll from '../models/ui-models/QueryRoll'

//------------------------------------------------------------------
//  JS Tools
//------------------------------------------------------------------
export function stringToInt(str) {
  let num = parseInt(str);
  if(Number.isNaN(num)) {
    return 0;
  }else{
    return num;
  }
}
//------------------------------------------------------------------
export function stringToBoolean(str) {
  let num = parseInt(str);
  if(Number.isNaN(num)) {
    return false;
  }else if(num <= 0) {
    return false;
  }else {
    return true;
  }
}
//------------------------------------------------------------------
export function getRandomInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
//------------------------------------------------------------------
export function getDistinct(array) {
  return array.filter((value, index, self) => { return self.indexOf(value) === index; });
}
//------------------------------------------------------------------
export function boolHasValue(value) {
  return (value === undefined || value === null) ? false : true;
}
//------------------------------------------------------------------
export function parseBoolean(str) {
  let num = parseInt(str);
  if(Number.isNaN(num)) {
    return false;
  }else if(num <= 0) {
    return false;
  }else {
    return true;
  }
}
//------------------------------------------------------------------
export function deepClone(obj) {
  if(!obj) { return null; }  // null  escape valve
  return JSON.parse(JSON.stringify(obj));
}

//------------------------------------------------------------------
//  DICE ROLLS
//------------------------------------------------------------------
export function rollDice(rollString, allowNeg=true) {

  let nums = rollString.split(/[d,+,\-,*,x]/).map(item => parseInt(item));

  let val = 0;
  for(let i=0; i<nums[0]; i++) {
    let roll = getRandomInclusive(1, nums[1]); 
    val += roll;
  }

  // append
  if(rollString.match(/[+]/)) { val += nums[2] }
  if(rollString.match(/[-]/)) { val -= nums[2] }
  if(rollString.match(/[*,x]/)) { val *= nums[2] }
  
  if(!allowNeg && val < 0) { val = 0;}

  return val;
}
//----------------------------------------------
export function rollTriad() {
  let stats = [
    getRandomInclusive(1, 20),
    getRandomInclusive(1, 20),
    getRandomInclusive(1, 20)
  ]
  stats.sort((a,b) => { return a-b; }).reverse();
  let triad = new QueryRoll({
    high:stats[0],
    mid:stats[1],
    low:stats[2]
  });
  return triad;
}

//------------------------------------------------------------------
//  SCREEN FORMAT
//------------------------------------------------------------------
export function formatBonus(val, includeZero) {
  includeZero = includeZero || false;
  let value = val ? val : 0;
  if(value == 0 && includeZero == true){
    return "+" + value.toString();
  }else if(value > 0) {
    return "+" + value.toString();
  } else {
    return value.toString();
  }
}

export function numericWord(value) {
  let numVal = parseInt(value);
  let vals = [
    "Zeroth", "First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh", "Eighth", "Ninth", "Tenth",
    "Eleventh", "Twelvth", "Thirteenth", "Fourteenth", "Fifteenth", "Sisteenth", "Seventeenth", "Eighteenth", "Nineteenth",
    "Twentieth", "Twenty-first", "Twenty-second", "Twenty-third", "Twenty-fourth", "Twenty-fifth", "Twenty-sixth", "Twenty-seventh", "Twenty-eighth", "Twenty-ninth",
    "Thirtieth", "Thirty-first", "Thirty-second", "Thirty-third", "Thirty-fourth", "Thirty-fifth", "Thirty-sixth", "Thirty-seventh", "Thirty-eighth", "Thirty-ninth",
    "Fourtieth", "Fourty-first", "Fourty-second", "Fourty-third", "Fourty-fourth", "Fourty-fifth", "Fourty-sixth", "Fourty-seventh", "Fourty-eighth", "Fourty-ninth",
    "Fiftieth", "Fifty-first", "Fifty-second", "Fifty-third", "Fifty-fourth", "Fifty-fifth", "Fifty-sixth", "Fifty-seventh", "Fifty-eighth", "Fifty-ninth",
    "Sixtieth", "Sixty-first", "Sixty-second", "Sixty-third", "Sixty-fourth", "Sixty-fifth", "Sixty-sixth", "Sixty-seventh", "Sixty-eighth", "Sixty-ninth",
    "Seventieth", "Seventy-first", "Seventy-second", "Seventy-third", "Seventy-fourth", "Seventy-fifth", "Seventy-sixth", "Seventy-seventh", "Seventy-eighth", "Seventy-ninth",
    "Eightieth", "Eighty-first", "Eighty-second", "Eighty-third", "Eighty-fourth", "Eighty-fifth", "Eighty-sixth", "Eighty-seventh", "Eighty-eighth", "Eighty-ninth",
    "Ninetieth", "Ninety-first", "Ninety-second", "Ninety-third", "Ninety-fourth", "Ninety-fifth", "Ninety-sixth", "Ninety-seventh", "Ninety-eighth", "Ninety-ninth",
    "One Hundreth"
  ];
  return vals[numVal];
}

export function autocap(value) {
  return value[0].toUpperCase() + value.substring(1,value.length);
}
//----------------------------------------------

export function scrollToElement(name) {  
  let el = document.getElementById(name);
  if(el) {      
    let pos = el.offsetTop - 70;
    window.scrollTo(0, pos);
  }
}


//------------------------------------------------------------------
//  GAME LOGIC
//------------------------------------------------------------------
export function getDifficultyByCode(difficulties, code) {
  return difficulties.find(item => {
    return item.Difficulty==code;
  })
}

//----------------------------------------------
export function determineCost(triad, list, baseCost) {
  //-----------------------
  let cost = baseCost || 0;
  let initialIndex = list.findIndex(item => { return item.g20Roll == triad.mid });
  let selectedIndex = list.findIndex(item => { return item.g20Roll == triad.g20Roll });
  let offset = selectedIndex - initialIndex;
  //-----------------------
  if(offset > 0) {
    for(let i = 0; i < offset; i++ ) {
      cost += list[initialIndex + i].g20Roll < triad.high ? 1 : 2;
    }
  }else {
    for(let i = 0; i > offset; i-- ) {
      cost += list[initialIndex + i].g20Roll > triad.low ? 1 : 2;
    }
  }
  //-----------------------
  return cost;
  //-----------------------
}

//------------------------------------------------------------------
//  STAND-ALONE FUNCTIONS
//------------------------------------------------------------------
export function getChosenPaths(character, backgroundGroups) { 
  //-----------------------
  let chosenPathIds = character.Class.ChosenPaths.split('|').map(id => { return parseInt(id)});
  //-----------------------
  let chosenPaths = backgroundGroups.filter(item => {
    if(chosenPathIds.indexOf(item.SkillGroup_ID) >=0) {
      return item;
    }
  }).map(item => { return item.LevelBonus });
  //-----------------------
  return chosenPaths;
}

//----------------------------------------------
export function getSelectedBackgroundKeys(character, key) {
  let selected = [];
  let backgroundKeys = ["Family", "Adolescence", "YoungAdult"].filter(item => { if(item!=key) { return item}}); 
  backgroundKeys.forEach(itemKey => {
    if(character.Backgrounds[itemKey].LevelBonus) { 
      selected.push(character.Backgrounds[itemKey].LevelBonus) 
    }
  });
  return selected;
}
