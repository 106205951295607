<template>
<div class="character-spell-list">
  <div class="character-spell-list-details">
    <span>{{listTypeDescription}}</span>
  </div>
  <div class="character-spell-list-heading">
    {{spellList.SpellListName}}
  </div>
  <div class="list-note" v-if="spellList.ListNote">
    {{spellList.ListNote}}
  </div>
  <div class="spell-references" v-if="spellList.SpellReferences.length>0">
    <div class="text-center"><strong>Spell References</strong></div>
    <div class="spell-reference" v-for="sr in spellList.SpellReferences" :key="sr.Spell_ID">
      <strong><em>{{sr.SpellName}}</em></strong> - 
      <span>{{sr.Description}}</span>
    </div>
  </div>
  <div class="character-spell-list-spells" v-for="s in spellList.Spells" :key="s.Id">
    <spell :value="character" :spell="s"></spell>
  </div>
</div>
</template>

<script>
//------------------------------------------------------
//------------------------------------------------------
export default {

  name:"SpellList",

  props:{
    value: Object,
    spellList: Object,
    realms: Array,
    spellListTypes: Array
  },

  watch: {    
    value: {
      handler() { this.init() },
      immediate: true
    },
  },

  data() {
    return {
      character: null
    }
  },

  computed:{
    listTypeDescription() {
      if(this.spellList.ClassName!="None") {
        return this.spellList.ClassName + ' Tome';
      } else {
        return this.listType(this.spellList.TypeID) + ' ' + this.listRealm(this.spellList.RealmID) + ' Tome';
      }
    }
  },

  methods: {

    async init() { 
      this.character = this.$deepClone(this.value);
    },

    listRealm(id) {
      if(!this.realms || this.realms.length===0) { return }
      let realm = this.realms.find(item => {
        return item.Realm_ID == id;
      });
      return realm.RealmDesc;
    },

    listType(id) {
      if(!this.spellListTypes || this.spellListTypes.length===0) { return }
      let spellListType = this.spellListTypes.find(item => {
        return item.TypeID === id;
      });
      return spellListType.TypeDesc;
    }

  }
}
</script>
<style lang="scss" scoped>

$list-header-bg-color: #dac4bd;
$spell-header-bg-color: #e0decb;

$list-note-bg-color: #fff7f5;
$list-note-brdr-color: #ffbcac;

$ref-bg-color: #FFFFDD;
$ref-brdr-color: #D2CB97;

$spell-prep-bg-color: #f8f7f4;


.character-spell-list {
  margin-bottom: 10px;
  
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  
  .character-spell-list-heading {
    padding: 3px 5px;
    font-weight: bold;
    background: $list-header-bg-color;
  }

  .character-spell-list-details {
    float: right;
    padding: 7px 10px;
    font-size: 10px;
  }

  .character-spell-list-spells {
    padding: 2px;
  }

  .list-note {
    font-size: 11px;
    border: dotted 1px $list-note-brdr-color;
    background-color: $list-note-bg-color;
    padding: 2px 4px;
    font-style: italic;
  }

  .spell-references {
    border: dotted 1px $ref-brdr-color;
    border-top: none;
    background: $ref-bg-color;
    padding: 2px 4px;
    font-size: 11px;
  }

  .spell-reference {
    margin: 1px 0;
  }
}
</style>