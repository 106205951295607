<template>
<MagicFrame v-if="ready"> 

  <div class="attribute-selection">

    <header>
      <div class="title">
        Your Attributes
        <G20Help book="GameCanon.pdf" bookmark="Roll Your Attributes"></G20Help>
      </div> 
    </header>

    <div class="fates-button">
      <button class="btn-capped-red btn-sm" :disabled="!canAutoassign" @click="autoAssignAttributes()">Auto Assign</button>
      <button class="btn-capped-red btn-sm" :disabled="!isPristine" @click="rollCharacterStatPool()">Let The Fates Decide</button>
    </div>

    <div class="attributes-row">

      <div class="attributes-col">

        <div class="attributes-table" v-if="characterAttributes">
          <table>
            <thead>
              <tr>
                <th>Attributes</th>
                <th>Curr.</th>
                <th>Pot.</th>                
                <th>Race</th>
                <th class="large-screen">Bonus <br/> Pri./Sec.</th>
                <th>Totals <br/> Pri./Sec.</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="a in attributeDefinitions" :key="a.key"
                :class="{ 'assigned' :isAssigned(a.key) }" @click="selectAttribute(a)">
                <td>
                  <span class="attr-name" :class="{'primary': isPrimary(a.key), 'secondary': isSecondary(a.key)}">
                    <span>{{characterAttributes[a.key].Name}}</span>
                    <sub v-if="isPrimary(a.key)"> 1</sub>
                    <sub v-if=" isSecondary(a.key)"> 2</sub>
                  </span>
                  <span class="attr-code">{{characterAttributes[a.key].Code}}</span>                  
                </td>
                <td>{{characterAttributes[a.key].Current}}</td>
                <td>{{characterAttributes[a.key].Potential}}</td>
                <td>{{characterAttributes[a.key].RacialBonus}}</td>
                <td class="large-screen">{{characterAttributes[a.key].PrimaryBonus}}/{{characterAttributes[a.key].SecondaryBonus}}</td>
                <td>{{characterAttributes[a.key].PrimaryTotal}}/{{characterAttributes[a.key].SecondaryTotal}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="attributes-col">

        <div class="attribute-pool">
          <div class="title">Attribute Value Pool:</div>

          <AnnotationContainer category="warning" v-if="isPristine && characterStatPool.length===0">
            Attribute Value Pool is empty.
          </AnnotationContainer>

          <div class="fates-button" v-if="!isPristine && characterStatPool.length===0">
            <button class="btn-capped-red btn-sm" @click="confirmReset()">
              Unassign ALL Attributes
            </button>
          </div>

          <div class="attribute-pool-items">
            <div v-for="(p, index) in characterStatPool" :key="index" class="attribute-pool-item"
                :class="{'selected': index === poolIndex}"
                @click="selectPoolItem(index)">
                <span>
                  <input type="number" class="pool-stat input-sm" min="1" max="30" v-model.number="p.mid" @input="checkAutoassign()" />
                </span> / 
                <span>
                  <input type="number" class="pool-stat input-sm" min="1" max="30" v-model.number="p.high" @input="checkAutoassign()" />
                </span>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>

</MagicFrame>
</template>

<script>
//------------------------------------------------------
import swal from 'sweetalert'
import QueryRoll from '../../../code/models/ui-models/QueryRoll'
import { setAttributeValues } from '../../../code/game/calculations'
//------------------------------------------------------
export default {
  name: "AttributeSelect",
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; }
  },
  data() {
    return {
      characterClass: null,
      characterAttributes: null,
      characterStatPool: null,
      attributeBonuses: [],
      attributeDefinitions: [],
      isPristine: true,
      canAutoassign: false,
      poolIndex: -1 
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {
          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {
    //------------------------------------------------  setup
    async setState() {
      this.characterClass = this.forgeState.ledger.characterClass;
      this.characterAttributes = this.forgeState.ledger.characterAttributes;
      this.characterStatPool = this.forgeState.ledger.characterStatPool;

      this.attributeBonuses = await this.$store.dispatch('Content/getAttributeBonuses');
      let stats = await this.$store.dispatch('Content/getAttributes');
      
      this.attributeDefinitions = stats.map(s => {
        return {
          key: s.Stat,
          name: s.StatName
        }
      });
      this.checkPristine();
      this.checkAutoassign();
    },

    rollCharacterStatPool() {
      let characterStatPool = [];
      for(let i=0; i<10; i++) {
        characterStatPool.push(this.$rollTriad());
      }
      this.characterStatPool = this.sortCharacterStatPool(characterStatPool);
      this.$store.dispatch('Forge/setLedgerItems', { 
        characterStatPool: this.characterStatPool 
      });
      this.checkAutoassign();
    },

    sortCharacterStatPool(characterStatPool) {
      characterStatPool.sort((a, b) => (a.mid > b.mid) ? 1 : -1).reverse();
      characterStatPool.sort((a, b) => (a.high > b.high) ? 1 : -1).reverse();
      return characterStatPool;
    },

    //--------------------
    
    checkPristine() {
      this.isPristine = this.characterStatPool.length === 10;
    },

    checkAutoassign() {
      let autoassign = this.characterStatPool.length === 10;
      if(autoassign) {
        for(let i=0; i<this.characterStatPool.length;i++) {
          autoassign = (this.characterStatPool[i].mid > 0 && this.characterStatPool[i].high > 0);
          if(!autoassign) { break; }
        }
      }
      this.canAutoassign = autoassign;
    },

    isAssigned(key) {
      return (this.characterAttributes[key].Current > 0 && this.characterAttributes[key].Potential > 0);
    },

    isPrimary(key) {
      return this.characterAttributes[key].Code === this.characterClass.Stat1;
    },

    isSecondary(key) {
      return this.characterAttributes[key].Code === this.characterClass.Stat2;
    },

    selectPoolItem(index) {    
      this.poolIndex = index;
    },

    selectAttribute(attributeDefinition) {

      if(this.isAssigned(attributeDefinition.key)) {
        //this.confirmUnassign(attributeDefinition);
        this.unassignFromAttribute(attributeDefinition.key);
      } else {
        if(this.poolIndex < 0) { return }
        //this.confirmAssign(attributeDefinition);
        this.assignToAttribute(attributeDefinition.key);
      }
    },

    confirmUnassign(attributeDefinition) {
      // DEPRECATED
      swal({
        title: "Unassign?", text: `Unassign from ${attributeDefinition.name}`, buttons: true, dangerMode: true,
      })
      .then((confirm) => {
        if (confirm) {
          this.unassignFromAttribute(attributeDefinition.key);
        }
      });
    },

    confirmAssign(attributeDefinition) {
      // DEPRECATED
      let scores = `Current: ${this.characterStatPool[this.poolIndex].mid} / Potential: ${this.characterStatPool[this.poolIndex].high}`; 
      swal({
        title: "Assign?", text: `${scores}\n Assign to ${attributeDefinition.name}?`, buttons: true, dangerMode: true,
      })
      .then((confirm) => {
        if (confirm) {
          this.assignToAttribute(attributeDefinition.key);
        }
      });
    },

    assignToAttribute(key) {
      this.setAttribute(key, this.poolIndex);
      this.poolIndex = -1;
      this.setLedgerItems();
      this.checkPristine();
      this.checkAutoassign();
    },

    setAttribute(key, poolIndex) {

      let characterStatPool = [...this.characterStatPool];
      let characterAttribute = {...this.characterAttributes[key]};
      let poolItem = characterStatPool[poolIndex];     
            
      characterAttribute.Current = poolItem.mid;
      characterAttribute.Potential = poolItem.high;
      characterAttribute = setAttributeValues(characterAttribute, this.attributeBonuses);

      characterStatPool.splice(poolIndex, 1);

      this.characterAttributes[key] = characterAttribute;
      this.characterStatPool = characterStatPool;
    },

    autoAssignAttributes() {
      let characterStatPool = [...this.characterStatPool];
      let characterAttributes = {...this.characterAttributes};
      let keys = this.randomizeKeys();
      keys.forEach(key => {
        let poolItem = characterStatPool[0];
        characterAttributes[key].Current = poolItem.mid;
        characterAttributes[key].Potential = poolItem.high;        
        characterAttributes[key] = setAttributeValues(characterAttributes[key], this.attributeBonuses);
        characterStatPool.splice(0, 1);        
      });
      this.characterAttributes = characterAttributes;
      this.characterStatPool = characterStatPool;
      this.setLedgerItems();
      this.checkPristine();
      this.checkAutoassign();
    },

    randomizeKeys() {
      let array = [];
      let keys = Object.keys(this.characterAttributes);

      let s1Index = keys.indexOf(this.characterClass.Stat1);
      array.push(keys[s1Index]);
      keys.splice(s1Index, 1);

      let s2Index = keys.indexOf(this.characterClass.Stat2);
      array.push(keys[s2Index]);
      keys.splice(s2Index, 1);

      while(keys.length > 0) {
        let ind = this.$getRandomInclusive(0, keys.length-1);      
        array.push(keys[ind]);
        keys.splice(ind, 1);
      }

      return array;
    },

    //-----------------------------


    unassignFromAttribute(key) {  
      this.resetAttribute(key);
      this.poolIndex = -1;
      this.setLedgerItems();
      this.checkPristine();
      this.checkAutoassign();
    },

    confirmReset() {
      swal({
        title: "Unassign All?", text: `This will return all rolls to the pool.`, buttons: true, dangerMode: true,
      })
      .then((confirm) => {
        if (confirm) {
          this.resetAttributes();
        }
      });
    },

    resetAttributes() {
      let keys = Object.keys(this.characterAttributes);
      keys.forEach(key => {        
        this.resetAttribute(key);
      });
      this.checkPristine();
      this.checkAutoassign();
      this.setLedgerItems();
    },

    resetAttribute(key) {

      let characterStatPool = [...this.characterStatPool];
      let characterAttribute = {...this.characterAttributes[key]};

      let poolItem = new QueryRoll();
      poolItem.mid = characterAttribute.Current;
      poolItem.high = characterAttribute.Potential;

      characterAttribute.Current = 0;
      characterAttribute.Potential = 0;
      characterAttribute = setAttributeValues(characterAttribute, this.attributeBonuses);
      this.characterAttributes[key] = characterAttribute;

      characterStatPool.push(poolItem);
      this.characterStatPool = this.sortCharacterStatPool(characterStatPool);

    },

    //--------------------

    setLedgerItems() {
      this.$store.dispatch('Forge/setLedgerItems', { 
        characterAttributes: this.characterAttributes,
        characterStatPool: this.characterStatPool 
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.attribute-selection { 
  header{
    display: flex;
    justify-content: space-between;
    width: 100%;

    .title {
      font-size: 20px;
    }
    
  }

  .fates-button {
    border: solid 1px rgba(0,0,0,0.5);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    margin: 5px;
    font-size: 14px;
    text-align: center;
  }

  .attributes-table {
    margin: 5px;
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        background-color: rgba(0, 0, 0, 0.5);
        tr {
          th {
            text-align: center;
            padding: 2px 4px;
            font-size: 10px;

            &.large-screen { 
              display: none;
            }

          }
          th:first-child {
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          color: #777;
          td {
            text-align: center;
            padding: 2px 4px;
            font-size: 12px;
            border-bottom: solid 1px rgba(255,255,255,0.25);

            &.large-screen { 
              display: none;
            }

            .attr-name {
              display: inline-block;
              width: 80px;
            }
            .attr-code {
              text-align: center;
              display: inline-block;
              width: 40px;
            }
          }
          td:first-child {
            text-align: left;
          }
        }
        tr:hover {
          cursor: pointer;
          background-color: rgba(255, 255, 0, 0.15);
        }
        tr.assigned {
          color: #fff;
          .primary {
            text-shadow: 0 0 3px #29B8DB, 0 0 2px #29B8DB, 0 0 1px #29B8DB;
          }
          .secondary {
            text-shadow: 0 0 3px #b700ff, 0 0 2px #b700ff, 0 0 1px #b700ff;
          }
        }
      }
    }


  }

  .attribute-pool {
    border: solid 1px rgba(0,0,0,0.5);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    margin: 5px;
    font-size: 14px;

    .title {
      margin-bottom: 10px;
    }

    .attribute-pool-items {      
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .attribute-pool-item {
        width: 45%;
        padding: 5px;
        text-align: center;
        border: solid 1px rgba(0,0,0,0.5);
        background-color: rgba(255, 255, 255, 0.25);
        border-radius: 5px;
        .pool-stat {
          display: inline-block;
          width: 40px;
        }
      }
      
      .attribute-pool-item:hover {        
        cursor: pointer;
        background-color: rgba(255, 255, 0, 0.15);
      }
      .attribute-pool-item.selected {
        background-color: rgba(0, 255, 255, 0.5);
      }
    } 
  }
}
@media screen and (min-width: 450px) and (max-width: 880px) {
    .attribute-selection {
    .attributes-table {
      table {
        thead {
          tr {
            th {
              &.large-screen { 
                display: table-cell;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              &.large-screen { 
                display: table-cell;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 880px) {
  .attribute-selection { 
    .attributes-row {
      display: flex;
      .attributes-col {
        width: 50%;
      }
    } 
    .attribute-pool {
      .attribute-pool-items { 
        .attribute-pool-item {
          .pool-stat {
            width: 47px;
          }
        }
      }
    }
  }
  
}

@media (min-width: 1000px) {
  .attribute-selection {
    .attributes-table {
      table {
        thead {
          tr {
            th {
              &.large-screen { 
                display: table-cell;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              &.large-screen { 
                display: table-cell;
              }
            }
          }
        }
      }
    }
  }
  
}
</style>