<template>
<div class="spell-detail" :class="{'closed': !tomeUnlocked, 'known': spellKnown }" v-if="spell.Description" @click="selectSpell(spell)">

  <div class="spell-header">
    <div class="title"> {{spell.Level}} - {{spell.SpellName}} </div>
    <div class="cost">Cost: {{cost}}</div>
  </div>

  <div class="spell-footer">
    <div class="type"> 
      {{ type }} {{ prep }}
    </div>
    <div class="details">
      <span>Rng: {{range}} </span> <span v-if="range && duration || areaOfEffect">, </span>
      <span>Dur: {{duration}} </span> <span v-if="range || duration && areaOfEffect">, </span>
      <span>Area: {{areaEffect}} </span>  
    </div>
  </div>

  <div class="spell-description">{{spell.Description}}</div>  

</div>
</template>

<script>
//---------------------------------------------------------
import { spellPrep, spellType } from '../../../code/constants'
//---------------------------------------------------------
export default {
  name: "SpellDetail",
  props: {
    spellAbility: Object,
    spell: Object,
    tomeUnlocked: {
      type: Boolean,
      default: false
    },
    spellKnown: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ledger() { return this.$store.state.Forge.forgeState.ledger; },
    range() { return this.spell.Range ? this.spell.Range : "NA" },
    duration() { return this.spell.Duration ? this.spell.Duration : "NA" },
    areaEffect() { return this.spell.AreaEffect ? this.spell.AreaEffect : "NA" },
    type() { return spellType[this.spell.SpellTypeCode] },
    prep() { return spellPrep[this.spell.SpellPrepCode] },
    cost() { 
      let hybridCost = this.spellAbility.isHybrid ? this.spellAbility.ExtraHybridCost : 0;
      return this.spellAbility.ExtraSpellCost + hybridCost + Math.ceil(this.spell.Level/2);
    },
  },
  methods: {
    spellUnlocked(spell) {
      console.log("spellUnlocked", spell);
      return false;
    },
    selectSpell(sp) {
      if(this.tomeUnlocked) {
        let spell = this.$deepClone(sp);
        spell.SpellCost = this.cost;
        this.$emit('select', spell);
      } 
    }
  }
};
</script>

<style lang="scss" scoped>
.spell-detail {
  padding: 5px 5px 10px 5px;
  background-color: rgba(0, 0, 0, .33);
  margin: 0 5px 5px 5px;

  .spell-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .title {
      font-size: 16px;
    }
    .cost {
      font-size: 12px;
    }
  }

  .spell-description {
    font-size: 12px;
    padding: 10px;
  }
  
  .spell-footer {
    color: #aaa;
    margin-left: 20px;
    
    .type {
      font-size: 12px;
    }
    .details {
      font-size: 12px;
    }
  }


  &:hover {
    cursor: pointer;
    background-color: rgba(0, 255, 255, .15);
  }

  &.closed {
    color: #777;

    .spell-footer {
      color: #777;
    }

    &:hover {
      cursor: not-allowed;
    }
  }

  &.known {
    background-color: rgba(255,255,255,0.1);
    color: rgb(208, 245, 207);

    &:hover {
      background-color: rgba(255,255,255,0.1);
      color: rgb(208, 245, 207);
    }
  }

}

</style>