
//------------------------------------------------------------------  preload into cache

const preloadList = [
  
  '/img/logo.png',
  '/img/smoke.png',
  '/img/rain.png',

  '/img/backgrounds/character-builder.jpg',
  '/img/backgrounds/character-lists.jpg',
  '/img/backgrounds/error-page.jpg',
  '/img/backgrounds/GMTools.jpg',
  '/img/backgrounds/title-screen.jpg',

  '/img/artwork/RedJeweledButton.png',
  '/img/artwork/arrow_L.png',
  '/img/artwork/arrow_R.png',
  '/img/artwork/border-brass-frame-shadow.png',
  '/img/artwork/border-brass-frame.png',
  '/img/artwork/border-celtic.png',
  '/img/artwork/border-dwarven-engraved.png',
  '/img/artwork/border-dwarven.png',
  '/img/artwork/border-elvish.png',
  '/img/artwork/border-fae.png',
  '/img/artwork/border-fate.png',
  '/img/artwork/border-forge-nav.png',
  '/img/artwork/border-magic-frame.png',
  '/img/artwork/border-stone-frame.png',
  '/img/artwork/facia-stone-border.png',
  '/img/artwork/jewelled-divider.png',
  '/img/artwork/texture-hammered-steel.jpg',
  '/img/artwork/texture-blue-leather.jpg',
  '/img/artwork/texture-brown-cloth.png',
  '/img/artwork/texture-olive-cloth.png',
  '/img/artwork/texture-purple-cloth.png',
  '/img/artwork/texture-brown-sandstone.jpg',
  '/img/artwork/texture-dark-blue-cloth.png',
  '/img/artwork/texture-slate.jpg',
  '/img/artwork/texture-tan-leather.jpg',
  '/img/artwork/texture-pillow-leather.jpg',
  '/img/artwork/texture-parchment-dark.jpg',
  '/img/artwork/texture-parchment.jpg',
  '/img/artwork/texture-marble.jpg'
]

export function preloadAppImages() {
  preloadList.forEach(img => {
    let image = new Image();
    image.src = img;
  });
}


//------------------------------------------------------------------  set background image

const backgrounds = [
  { name: 'Home', background: 'wallpaper__main' },
  { name: 'Login', background: 'wallpaper__main' },
  { name: 'Concourse', background: 'wallpaper__dashboard' },
  { name: 'CharacterForge', background: 'wallpaper__character' },
  { name: 'GameMasterTools', background: 'wallpaper__gmtools' },
  { name: 'ErrorView', background: 'wallpaper__error' },

  { name: '_dev', background: 'wallpaper__whiteout' },
  { name: '_controls', background: 'wallpaper__dark' }
]

export function setPageBackground(routeName) {

  let backgroundItem = backgrounds.find(item => {
    return item.name == routeName;
  });

  if(!backgroundItem) {
    backgroundItem = { name: 'Unknown', background: 'wallpaper__character' }
  }

  document.body.classList = "wallpaper " + backgroundItem.background;
}
