<template>
<div class="class-info" v-if="characterClass">

  <div class="class-name" v-if="summary">
    <span>Class: {{characterClass.ClassName}}</span>
  </div>

  <div class="class-info-cols">
    <div class="stat-block stat-item">
      <div>Primary Attribute:</div> <div>{{characterClass.Stat1}}</div>            
    </div>
    <div class="stat-block stat-item">
      <div>Secondary Attribute:</div> <div>{{characterClass.Stat2}}</div>   
    </div>
  </div>

  <div class="class-info-cols" v-if="characterSpellAbility">
    <div class="stat-block">
      <div>Magical Aptitude</div>
      <div class="stat-table">
        <div class="stat-item">
          <div>Spell Ability:</div>
          <div>{{$formatBonus(characterSpellAbility.SpellAbilityDesc)}}</div>
        </div>        
        <div class="stat-item">
          <div>Extra Spell Cost:</div>
          <div>{{$formatBonus(characterSpellAbility.ExtraSpellCost)}}</div>
        </div>
        <div class="stat-item" v-if="characterSpellAbility.isHybrid">
          <div>Additional Hybrid Cost:</div>
          <div>{{$formatBonus(characterSpellAbility.ExtraHybridCost)}}</div>
        </div>
        <div class="stat-item">
          <div>Class Tome Cost:</div>
          <div>{{characterSpellAbility.BaseTomeCost}} / Tome</div>
        </div>
        <div class="stat-item">
          <div>Cost Increase After:</div>
          <div>{{characterSpellAbility.TomesBeforeCostIncrease}} Tomes</div>
        </div>
        <div class="stat-item">
          <div>Additional Tome Cost:</div>
          <div>{{$formatBonus(characterSpellAbility.AdditionalTomeCost)}}</div>
        </div>
      </div>
    </div>

    <div class="stat-block">
      <div>Chosen Paths</div>
      <div class="stat-table">
        <div class="stat-item" v-for="(c, index) in chosenPaths" :key="index">
          <div>{{c}}</div>
        </div>
      </div>
    </div>
  </div>   

</div>

</template>

<script>
export default {
  name: "ClassSummary",
  props: {
    characterClass: Object,
    characterSpellAbility: Object,
    summary: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    characterClass: { 
      handler() { 
        this.getChosenPaths() 
      },
      immediate: true
    }
  },
  data() {
    return {
      chosenPaths: []
    }
  },
  methods: {
    async getChosenPaths() {
      if(this.characterClass) {
        let ids = this.characterClass.ChosenPaths.split('|').map(cp => parseInt(cp));
        this.chosenPaths = await this.$store.dispatch('Content/getChosenPaths', ids); 
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.class-info {    
  border: solid 1px rgba(0,0,0,0.5);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px 10px 10px 10px;
  margin: 5px;
  
  .stat-block {
    padding: 5px;
    background-color: rgba(0,0,0,0.4);
    margin-top:5px;
  }

  .stat-item {
    display: flex;
    justify-content: space-between;     
  }

  .stat-table {
    padding: 4px;    
    .stat-item {
      font-size: 14px;
      color: rgb(179, 193, 255);
    }
  }

}

@media screen and (min-width: 768px) {
  .class-info-cols {
    display: flex;    
  }
  .class-info-cols > div{
    flex: 1;
  }
  .class-info-cols > div:first-child {
    margin: 0 3px 0 0;
  }
  .class-info-cols > div:last-child {
    margin: 0 0 0 3px;
  }
}
</style>