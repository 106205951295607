
export default class Realm {
  constructor(options) {

    this.Realm_ID = null;
    this.RealmDesc = null;
    this.RealmLongDesc = null;
    this.IsRealm = 0;

    if(options) {
      this.init(options);
    } 

  }

  init(options) {

    this.Realm_ID = parseInt(options.Realm_ID) || this.Realm_ID;
    this.RealmDesc = options.RealmDesc || this.RealmDesc;
    this.RealmLongDesc = options.RealmLongDesc || this.RealmLongDesc;
    this.IsRealm = parseInt(options.IsRealm) || this.IsRealm;

  }
}