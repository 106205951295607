export default class CharacterRecord {
  constructor(options) {

    this.Player_ID = 0;
    this.CharacterRecordId = 0;
    this.ServerId = 0;
    this.Name = null;
    this.Level = 0;
    this.Created = new Date().toISOString();
    this.Updated = null;

    this.CharacterData = null;

    if(options) {
      this.init(options)
    }

  }

  init(options) {

    this.Player_ID = options.Player_ID || this.Player_ID;
    this.CharacterRecordId = options.CharacterRecordId || this.CharacterRecordId;
    this.ServerId = options.ServerId || this.ServerId;
    this.Name = options.Name || this.Name;
    this.Level = options.Level || this.Level;
    this.Created = options.Created || this.Created;
    this.Updated = options.Updated || this.Updated;
    this.CharacterData = options.CharacterData || this.CharacterData;

  }

}