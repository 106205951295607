export default class Inheritance {
  constructor(options) {

    this.g20Roll = 0;
    this.Inheritance = null;
    this.StartingPossessions = null;
    this.SkillPoints = null;
    this.RapidPoints = null;
    
    if(options) {
      this.init(options);
    }
    
  }

  init(options) {

    this.g20Roll = parseInt(options.g20Roll) || this.g20Roll;
    this.Inheritance = options.Inheritance || this.Inheritance;
    this.StartingPossessions = options.StartingPossessions || this.StartingPossessions;
    this.SkillPoints = options.SkillPoints || this.SkillPoints;
    this.RapidPoints = options.RapidPoints || this.RapidPoints;

  }
}