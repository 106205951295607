export default class CriticalSeverity {
  constructor(options) {

    this.CritSeverityID = null;
    this.CritSeverityDesc = null;
    this.ArmorDamageDie = 0;

    if(options) {
      this.init(options);
    }

  }

  init(options) {
        
    this.CritSeverityID = options.CritSeverityID || this.CritSeverityID;
    this.CritSeverityDesc = options.CritSeverityDesc || this.CritSeverityDesc;
    this.ArmorDamageDie = parseInt(options.ArmorDamageDie) || this.ArmorDamageDie;
    
  }
}