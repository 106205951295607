<template>
<div class="skills" :class="{'editable': editable}" v-if="character">

  <div class="command-buttons" v-if="editable">
    <button class="btn-gem green btn-sm" title="Save Character" @click.stop="saveCharacter()">Save</button>
    <button class="btn-gem red btn-sm" title="Cancel" @click.stop="cancel()">Cancel</button>
  </div>

  <header @click="editable=true">    
    <div class="title"> Skills </div>
  </header>

  <div class="skills-list">
    <div class="skills-groups" v-for="[sgKey, group] in Object.entries(character.SkillGroups)" :key="sgKey" ref="skillGroup">
      <SkillGroup 
        :attributes="character.Attributes"
        :skill-group="group" 
        :editable="editable"        
        @update-skill="skillUpdated">
      </SkillGroup>
    </div>
  </div>
  
</div>
</template>

<script>
//--------------------------------------------------------
import * as cm from '../../code/game/character/CharacterManager'
//--------------------------------------------------------
export default {
  name: "Skills",
  props: {
    value: Object,
  },
  watch: {    
    value: {
      handler() { this.character = this.$deepClone(this.value) },
      immediate: true
    },
  },
  data() {
    return {
      character: null,
      editable: false
    }
  },
  methods: {

    skillUpdated(skill) {
      console.log('not implemented:', skill);

      let character = this.$deepClone(this.character);

      character.SkillGroups[skill.SkillGroupName].Skill[skill.SkillName] = skill;
      character = cm.evalCharacter(character);

      this.character = character;

    },

    cancel() {
      this.editable=false;
      this.$emit('cancel');
    },

    saveCharacter() {
      this.editable = false;
      this.$emit('save-character', this.character);
    }
  }
};
</script>

<style lang="scss" scoped>

.skills {
  position: relative;
  border: solid 1px #ddd; 
  
  &.editable {
    border: dotted 1px blue;
  }

  .command-buttons {
    position: absolute;
    top: 0;
    right: 0;
  }

  header {
    .title {
      font-size: 20px;
      padding: 0 5px;
    }
  }

  .skills-list {
    width: 100%;

  }
}

.character-sheet.mobile .skills .skills-list {
  display: block;
  width: 100%;
}

@media screen and (min-width: 500px) {
  .skills {
    .skills-list {
      display: inline-grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
      width: 100%;
    }
  }
}
</style>