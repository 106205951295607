<template>
<StoneFrame class="notifications">

  <div class="header" slot="header">
    <div class="title">My Messages</div>
    <G20Help book="GameCanon.pdf" bookmark="Weapon Groups"></G20Help>
  </div>

  <div slot="body">
    <AnnotationContainer category="warning" v-if="notifications.length===0">
      No Messages.
    </AnnotationContainer>
  </div>

</StoneFrame>
</template>

<script>
// -----------------------------------------------------
export default {
  name: "Notifications",
  computed: {
    notifications() { return this.$store.state.App.notifications }
  },
}
</script>

<style lang="scss" scoped>
.notifications {
  width: 100%;
  
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .title {
    padding: 1px 2px;
  }
}
@media screen and (min-width: 768px) {
  .notifications {
    min-width: 350px;
  }  
}
</style>