<template>
<div id="CharacterVitals" class="vitals" v-if="character">
  <table class="vitals__table">
    <tr>
      <td><strong>VI</strong></td>
      <td>&nbsp;</td>
      <td><strong>WI</strong></td>
      <td>&nbsp;</td>
      <td><strong>Hits/<br/>Rank</strong></td>
      <td>&nbsp;</td>
      <td><strong>Endurance<br/>Ranks</strong></td>
      <td>&nbsp;</td>
      <td><strong>Misc</strong></td>
      <td>&nbsp;</td>
      <td><strong>LB</strong></td>
      <td>&nbsp;</td>


      <td class="max-hits-container">
        <span class="max-hits-label">Max Hits</span>
      </td>


    </tr>
    <tr style="font-size:15px;">
      <td>{{vitals.VI}}</td>
      <td>+</td>
      <td>{{vitals.WI}}</td>
      <td>+</td>
      <td>({{vitals.HitLvl}}</td>
      <td class="text-center">x</td>
      <td>{{vitals.Endurance}})</td>
      <td>+</td>
      <td>{{vitals.Misc}}</td>
      <td>+</td>
      <td>{{vitals.LB}}</td>
      <td>=</td>
      <td>
        <span class="max-hits modifier">{{vitals.Hits}}</span>
      </td>
    </tr>
  </table>

  <table class="vitals__table vitals__table-top-border">
    <tr>
      <td>Current</td>
      <td class="dead">-{{vitals.tmD}}</td>
      <td class="unconcious">-{{vitals.tmD-1}} to 0</td>
      <td class="hurt-25">1 to {{vitals.tm6}}</td>
      <td class="hurt-50">{{vitals.tm6+1}} to {{vitals.tm4}}</td>
      <td class="hurt-75">{{vitals.tm4+1}} to {{vitals.tm2}}</td>
      <td class="unhurt">{{vitals.tm2+1}} to {{vitals.tm0}}</td>
    </tr>
    <tr>
      <td>Hits</td>
      <td class="dead">DEAD</td>
      <td class="unconcious">KO</td>
      <td class="hurt-25">-6</td>
      <td class="hurt-50">-4</td>
      <td class="hurt-75">-2</td>
      <td class="unhurt">No Mod</td>
    </tr>
  </table>

</div>
</template>


<script>
//------------------------------------------------------
export default {
  name: "Vitals",
  props: {
    character: Object
  },
  computed: {
    vitals() {

      let Survival = this.character.SkillGroups.Survival; 
      let Endurance = Survival.Skill.Endurance;       
      let vitals = {
        VI: this.character.Attributes.VI.Current,        
        WI: this.character.Attributes.WI.Current,        
        HitLvl: this.character.Race ? this.character.Race.HitsPerRank : 0,
        Endurance: Endurance ? Endurance.Ranks : 0,
        Misc: Endurance ? Endurance.MiscBonus : 0, 
        LB: Survival ? Survival.LevelBonus : 0
      }

      vitals.Hits = vitals.VI + vitals.WI + (vitals.HitLvl * vitals.Endurance) + vitals.Misc + vitals.LB;
      vitals.Hits = (vitals.Hits==0) ? 10 : vitals.Hits;  // min-hits

      vitals.tm0 = Math.floor(vitals.Hits * 1);
      vitals.tm2 = Math.floor(vitals.Hits * .75);
      vitals.tm4 = Math.floor(vitals.Hits * .5);
      vitals.tm6 = Math.floor(vitals.Hits * .25);
      vitals.tmKO = Math.floor(0 - (vitals.WI + vitals.VI));
      vitals.tmD = Math.floor(vitals.Hits * .5)-1;      

      return vitals;

    }
  }
};
</script>


<style lang="scss" scoped>

//-----------------------------------------
$dead: #000;
$unconcious: #B000E0;
$hurt-25: #FF979E;
$hurt-50: #FBC100;
$hurt-75: #DDFB00;
$unhurt: #fff;
//-----------------------------------------

.vitals {
  margin-top: 20px;
  display: block;
}

.vitals__table{
  border-collapse: collapse;
  width: 100%;
  font-size: 11px;
  background:#efefef;
  margin-bottom: 0;
}

  .vitals__table tr td{
    border: none;
    text-align: center;
    vertical-align: middle;
    padding: 3px;
    line-height: 1;
  }
  
  .vitals__table-top-border{
    border-top: solid 1px  #333;
  }

  .max-hits-container{
    padding-top: 4px;
    line-height: 16px;
  }

  .max-hits-label{
    display:inline-block;
    font-size:14px;
    font-weight:bold;
    margin-bottom:3px;
  }
  
  .max-hits{
    font-size:18px;
    font-weight:bold;
  }

//-----------------------------------------  character-vital-states
.dead{
  background: $dead;
  color:#fff;
}
.unconcious{
  background: $unconcious;
  color:#fff;
}
.hurt-25{
  background: $hurt-25;
}
.hurt-50{
  background: $hurt-50;
}
.hurt-75{
  background: $hurt-75;
}
.unhurt{
  background: $unhurt;
}
</style>