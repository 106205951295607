//-------------------------------------------------------------------  import
// app/containers
import AnnotationContainer from './app/containers/AnnotationContainer'
import BrassFrame from './app/containers/BrassFrame'
import FateFrame from './app/containers/FateFrame'
import ForgeNavFrame from './app/containers/ForgeNavFrame'
import KazdulFrame from './app/containers/KazdulFrame'
import MagicFrame from './app/containers/MagicFrame'
import SimplePanel from './app/containers/SimplePanel'
import StoneFrame from './app/containers/StoneFrame'

// app/controls
import EditableContent from './app/controls/EditableContent'
import EditableDropdown from './app/controls/EditableDropdown'
import g20Help from './app/controls/g20Help'
import g20Info from './app/controls/g20Info'
import g20Loading from './app/controls/g20Loading'
import g20Logo from './app/controls/g20Logo'
import LoginLoader from './app/controls/LoginLoader'
import Modal from './app/controls/Modal'
import NumericStepper from './app/controls/NumericStepper'

// app/effects
import FxRainstorm from './app/effects/FxRainstorm'
import FxSmoke from './app/effects/FxSmoke'

// app/forms
import AvatarForm from './app/forms/AvatarForm'
import g20LoginForm from './app/forms/g20LoginForm'
import ItemForm from './app/forms/ItemForm'
import SkillForm from './app/forms/SkillForm'

// app/player-tools
import CampaignListing from './app/player-tools/CampaignListing'
import Campaigns from './app/player-tools/Campaigns'
import CharacterListing from './app/player-tools/CharacterListing'
import Characters from './app/player-tools/Characters'
import Notifications from './app/player-tools/Notifications'

// character-sheet
import ArmorDurability from './character-sheet/ArmorDurability'
import Attributes from './character-sheet/Attributes'
import Backgrounds from './character-sheet/Backgrounds'
import BasicInfo from './character-sheet/BasicInfo'
import CharacterStory from './character-sheet/CharacterStory'
import CombatInfo from './character-sheet/CombatInfo'
import Equipment from './character-sheet/Equipment'
import Fate from './character-sheet/Fate'
import Item from './character-sheet/Item'
import Resists from './character-sheet/Resists'
import Skill from './character-sheet/Skill'
import SkillGroup from './character-sheet/SkillGroup'
import Skills from './character-sheet/Skills'
import Spell from './character-sheet/Spell'
import SpellAbility from './character-sheet/SpellAbility'
import Spellbook from './character-sheet/Spellbook'
import SpellList from './character-sheet/SpellList'
import Vitals from './character-sheet/Vitals'
import Wealth from './character-sheet/Wealth'
import WeaponSkillGroup from './character-sheet/WeaponSkillGroup'

// character-forge
import ForgeNavigation from './forge/ForgeNavigation'

// character-forge-tools
import AttributesSelect from './forge/tools/AttributesSelect'
import BackgroundSelect from './forge/tools/BackgroundSelect'
import CharacterInfo from './forge/tools/CharacterInfo'
import ClassSelect from './forge/tools/ClassSelect'
import FeaturesSelect from './forge/tools/FeaturesSelect'
import InheritanceSelect from './forge/tools/InheritanceSelect'
import LevelBonuses from './forge/tools/LevelBonuses'
import PossessionsSelect from './forge/tools/PossessionsSelect'
import RaceSelect from './forge/tools/RaceSelect'
import RealmSelect from './forge/tools/RealmSelect'
import StartingFate from './forge/tools/StartingFate'
import SkillsSelect from './forge/tools/SkillsSelect'
import SpellsSelect from './forge/tools/SpellsSelect'
import StatGains from './forge/tools/StatGains'
import WeaponGroupsSelect from './forge/tools/WeaponGroupsSelect'
import Welcome from './forge/tools/Welcome'

// character-forge-controls
import ForgeOverlay from './forge/controls/ForgeOverlay'
import ForgeProgress from './forge/controls/ForgeProgress'
import RangeSelect from './forge/controls/RangeSelect'
import SpellDetail from './forge/controls/SpellDetail'
import SpellListDetail from './forge/controls/SpellListDetail'
import TomesAvailable from './forge/controls/TomesAvailable'
import TriadRoll from './forge/controls/TriadRoll'

// character-forge-summaries
import AttributesSummary from './forge/summaries/AttributesSummary'
import BackgroundsSummary from './forge/summaries/BackgroundsSummary'
import ClassSummary from './forge/summaries/ClassSummary'
import CreationSummary from './forge/summaries/CreationSummary'
import FateSummary from './forge/summaries/FateSummary'
import InfoSummary from './forge/summaries/InfoSummary'
import LevelBonusSummary from './forge/summaries/LevelBonusSummary'
import LevelUpSummary from './forge/summaries/LevelUpSummary'
import PossessionSummary from './forge/summaries/PossessionSummary'
import RaceSummary from './forge/summaries/RaceSummary'
import RealmSummary from './forge/summaries/RealmSummary'
import SkillSummary from './forge/summaries/SkillSummary'
import SpellSummary from './forge/summaries/SpellSummary'
import StatGainSummary from './forge/summaries/StatGainSummary'


//-------------------------------------------------------------------  register
const forgeUi = {
  install(Vue) {  

    // app/containers
    Vue.component('AnnotationContainer', AnnotationContainer);
    Vue.component('BrassFrame', BrassFrame);
    Vue.component('FateFrame', FateFrame);
    Vue.component('ForgeNavFrame', ForgeNavFrame);
    Vue.component('KazdulFrame', KazdulFrame);
    Vue.component('MagicFrame', MagicFrame);
    Vue.component('SimplePanel', SimplePanel);
    Vue.component('StoneFrame', StoneFrame);

    // app/controls
    Vue.component('EditableContent', EditableContent);
    Vue.component('EditableDropdown', EditableDropdown);
    Vue.component('G20Help', g20Help);
    Vue.component('g20Info', g20Info);
    Vue.component('G20Loading', g20Loading);
    Vue.component('G20Logo', g20Logo);
    Vue.component('LoginLoader', LoginLoader);
    Vue.component('Modal', Modal);
    Vue.component('NumericStepper', NumericStepper);

    // app/effects
    Vue.component('FxRainstorm', FxRainstorm);
    Vue.component('FxSmoke', FxSmoke);
    
    // app/forms
    Vue.component('AvatarForm', AvatarForm);
    Vue.component('G20LoginForm', g20LoginForm);
    Vue.component('ItemForm', ItemForm);
    Vue.component('SkillForm', SkillForm);
    
    // app/player-tools
    Vue.component('CampaignListing', CampaignListing);
    Vue.component('Campaigns', Campaigns);
    Vue.component('CharacterListing', CharacterListing);
    Vue.component('Characters', Characters);
    Vue.component('Notifications', Notifications);

    // character-sheet
    Vue.component('ArmorDurability', ArmorDurability);
    Vue.component('Attributes', Attributes);
    Vue.component('Backgrounds', Backgrounds);
    Vue.component('BasicInfo', BasicInfo);
    Vue.component('CharacterStory', CharacterStory);
    Vue.component('CombatInfo', CombatInfo);
    Vue.component('Equipment', Equipment);
    Vue.component('Fate', Fate);
    Vue.component('Item', Item);
    Vue.component('Resists', Resists);
    Vue.component('Skill', Skill);
    Vue.component('SkillGroup', SkillGroup);
    Vue.component('Skills', Skills);
    Vue.component('Spell', Spell);
    Vue.component('SpellAbility', SpellAbility);
    Vue.component('Spellbook', Spellbook);
    Vue.component('SpellList', SpellList);
    Vue.component('Vitals', Vitals);
    Vue.component('Wealth', Wealth);
    Vue.component('WeaponSkillGroup', WeaponSkillGroup);

    // character-forge
    Vue.component('ForgeNavigation', ForgeNavigation);

    // character-forge-tools
    Vue.component('AttributesSelect', AttributesSelect);
    Vue.component('BackgroundSelect', BackgroundSelect);
    Vue.component('CharacterInfo', CharacterInfo);
    Vue.component('ClassSelect', ClassSelect);
    Vue.component('FeaturesSelect', FeaturesSelect); 
    Vue.component('InheritanceSelect', InheritanceSelect);
    Vue.component('LevelBonuses', LevelBonuses);
    Vue.component('PossessionsSelect', PossessionsSelect);  
    Vue.component('RaceSelect', RaceSelect);
    Vue.component('RealmSelect', RealmSelect);  
    Vue.component('SkillsSelect', SkillsSelect);
    Vue.component('SpellsSelect', SpellsSelect);  
    Vue.component('StartingFate', StartingFate); 
    Vue.component('StatGains', StatGains);
    Vue.component('WeaponGroupsSelect', WeaponGroupsSelect);
    Vue.component('Welcome', Welcome);

    // character-forge-controls
    Vue.component('ForgeOverlay', ForgeOverlay);
    Vue.component('ForgeProgress', ForgeProgress);
    Vue.component('RangeSelect', RangeSelect);
    Vue.component('SpellDetail', SpellDetail);
    Vue.component('SpellListDetail', SpellListDetail);
    Vue.component('TomesAvailable', TomesAvailable);
    Vue.component('TriadRoll', TriadRoll);
    
    // character-forge-summaries    
    Vue.component('AttributesSummary', AttributesSummary);
    Vue.component('BackgroundsSummary', BackgroundsSummary);
    Vue.component('ClassSummary', ClassSummary);
    Vue.component('CreationSummary', CreationSummary);
    Vue.component('FateSummary', FateSummary);
    Vue.component('InfoSummary', InfoSummary);
    Vue.component('LevelBonusSummary', LevelBonusSummary);
    Vue.component('LevelUpSummary', LevelUpSummary);
    Vue.component('RaceSummary', RaceSummary);
    Vue.component('RealmSummary', RealmSummary);
    Vue.component('SkillSummary', SkillSummary);
    Vue.component('SpellSummary', SpellSummary);
    Vue.component('PossessionSummary', PossessionSummary);
    Vue.component('StatGainSummary', StatGainSummary);

  }
}

export default forgeUi;