
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faDiceD20,
  faGripVertical,
  faQuestionCircle,
  faSearch
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const Fonts = {
  install(Vue) {
    library.add(
      faChevronDown,
      faChevronLeft,
      faChevronRight,
      faChevronUp,
      faDiceD20,
      faGripVertical,
      faQuestionCircle,
      faSearch
    );
    Vue.component('font-awesome-icon', FontAwesomeIcon);
  }
}

export default Fonts;