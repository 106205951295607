<template>
<div class="box" :class="cssClass">
  <slot></slot>
</div>
</template>

<script>
export default {
  name: "AnnotationContainer",
  computed: {
    cssClass() {
      let css = this.thin ? 'thin ':'';
      return css + this.category
    }
  },
  props: {
    category: { type: String, default: 'message'  },
    thin: { type: Boolean, default: false  }
  }
}
</script>

<style lang="scss" scoped>

.box {
  margin: 10px;
  padding: 10px;
  font-size: 12px;
  color: #fff;

  &.thin {
    margin: 10px 0;
    padding: 5px;
  }
}

.message {
  border: solid 2px rgba(0,0,0,0.25);
  background-color: rgba(0,0,0,0.25);
}

.info {
  border: solid 2px rgba(0,0,0,0.25);
  background-color: rgba(0, 0, 255, 0.3);
}

.warning {
  border: solid 2px rgba(0,0,0,0.25);
  background-color: rgba(100, 100, 0, 0.3);
}

.error {
  border: solid 2px rgba(0,0,0,0.25);
  background-color: rgba(150,0,0,0.5);
}

</style>