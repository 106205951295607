<template>
<div class="attributes-summary">
  <table v-if="characterAttributes">
    <thead>
      <tr>
        <th>Attributes</th>
        <th>Curr.</th>
        <th>Pot.</th>                
        <th>Race</th>
        <th class="large-screen">Bonus <br/> Pri./Sec.</th>
        <th>Totals <br/> Pri./Sec.</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="a in attributeDefinitions" :key="a.key">
        <td>
          <span class="attr-name" :class="{'primary': isPrimary(a.key), 'secondary': isSecondary(a.key)}">
            <span>{{characterAttributes[a.key].Name}}</span>
            <sub v-if="isPrimary(a.key)"> 1</sub>
            <sub v-if=" isSecondary(a.key)"> 2</sub>
          </span>
          <span class="attr-code">{{characterAttributes[a.key].Code}}</span>                  
        </td>
        <td>{{characterAttributes[a.key].Current}}</td>
        <td>{{characterAttributes[a.key].Potential}}</td>
        <td>{{characterAttributes[a.key].RacialBonus}}</td>
        <td class="large-screen">{{characterAttributes[a.key].PrimaryBonus}}/{{characterAttributes[a.key].SecondaryBonus}}</td>
        <td>{{characterAttributes[a.key].PrimaryTotal}}/{{characterAttributes[a.key].SecondaryTotal}}</td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
export default {
  name: "AttributesSummary",
  props: {
    characterAttributes: Object,
    characterClass: Object
  },
  data() {
    return {
      attributeDefinitions: null
    }
  },
  mounted() {
    let entries = Object.entries(this.characterAttributes);
    this.attributeDefinitions = entries.map(s => {
      return { key: s[0], name: s[1].Name }
    });
  },
  methods: {    
    isPrimary(key) {
      return this.characterAttributes[key].Code === this.characterClass.Stat1;
    },
    isSecondary(key) {
      return this.characterAttributes[key].Code === this.characterClass.Stat2;
    }
  }
}
</script>

<style lang="scss" scoped>
.attributes-summary {
  border: solid 1px rgba(0,0,0,0.5);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px 10px 10px 10px;
  margin: 5px;

  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      background-color: rgba(0, 0, 0, 0.5);
      tr {
        th {
          text-align: center;
          padding: 2px 4px;
          font-size: 10px;

          &.large-screen { 
            display: none;
          }

        }
        th:first-child {
          text-align: left;
        }
      }
    }
    tbody {
      tr {
        color: #fff;
        td {
          text-align: center;
          padding: 2px 4px;
          font-size: 12px;
          border-bottom: solid 1px rgba(255,255,255,0.25);

          &.large-screen { 
            display: none;
          }

          .attr-name {
            display: inline-block;
            width: 80px;
          }
          .attr-code {
            text-align: center;
            display: inline-block;
            width: 40px;
          }
        }
        td:first-child {
          text-align: left;
        }
      }
      .primary {
        text-shadow: 0 0 3px #da0000, 0 0 2px #da0000, 0 0 1px #da0000;
      }
      .secondary {
        text-shadow: 0 0 3px #29B8DB, 0 0 2px #29B8DB, 0 0 1px #29B8DB;
      }
    }
  }
}
</style>