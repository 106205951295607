<template>
<MagicFrame v-if="ready"> 

  <div class="background-selection">

    <header>
      <div class="title">
        Your {{forgeLabel}}
        <G20Help book="GameCanon.pdf" bookmark="Your Background"></G20Help>
      </div>   
    </header>

    <div class="level-bonus-category">
      <label>Select Category:</label>
      <select class="input-sm" v-model="selectedCategory" @change="getBackgrounds()">
        <option v-for="lbs in levelBonusSelections" :key="lbs" :value="lbs">{{lbs}}</option>
      </select>
    </div>

    <div class="die-rolls" v-if="characterFate">
      <TriadRoll :fate="characterFate.startingFate" :spent-fate="characterFate.spentFate" :triad="triad" :min="-10"
                 @dice-rolled="diceRolled($event)" @triad-changed="triad = $event"></TriadRoll>
    </div>

    <div v-show="!isPristine">
      <RangeSelect :range-items="backgrounds" :g20-roll="g20Roll" :triad="triad" @item-selected="selectBackground($event)">
      <template v-slot="{ item }">

          <div class="background-items">        
            <div class="background-left">
              <div class="g20-roll">{{item.g20Roll}}</div>
            </div>
            <div class="background-right">
              <div class="title">
                <span v-if="item.LBBName">{{item.LBBName}}</span>
                <span v-else>{{item.LevelBonus}}</span>
              </div>
              <div class="content">
                <div>{{item.LBBDescription}}</div>
              </div>
            </div>
          </div>

      </template>
      </RangeSelect>
    </div>

  </div>

</MagicFrame>
</template>

<script>
//------------------------------------------------------
import g20CharacterModels from '../../../code/models/g20CharacterModels';
import * as calc from '../../../code/game/calculations'
//------------------------------------------------------
export default {
  name: "BackgroundSelect",
  props: {
    forgeKey: String,
    forgeLabel: String
  },
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
  },
  data() {
    return {
      isPristine: true,
      triad: { low: 0, mid: 0, high: 0 },
      g20Roll: 0,

      characterClass: null,
      characterFate: null,

      levelBonusCategories: [],
      levelBonusSelections: [],
      selectedCategory: null,

      backgrounds: [],
      characterBackgrounds: {}
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {
          this.levelBonusCategories = await this.$store.dispatch('Content/getLevelBonusCategories');
          this.setState();
        }
      },
      immediate: true
    },
    forgeKey(newVal, oldVal) {
      if(newVal !== oldVal ) {
        this.triad = { low: 0, mid: 0, high: 0 };
        this.g20Roll = 0;
        this.setState();
      }
    }
  },
  methods: {

    async setState() {      
      this.characterFate = this.forgeState.ledger.characterFate;
      this.characterClass = this.forgeState.ledger.characterClass;
      if(this.forgeState.ledger.characterBackgrounds) {
        this.characterBackgrounds = this.forgeState.ledger.characterBackgrounds;
        if(this.characterBackgrounds[this.forgeKey]) {
          this.setRollData(this.characterBackgrounds[this.forgeKey]);
          this.setBackground(this.characterBackgrounds[this.forgeKey], this.characterBackgrounds[this.forgeKey].cost);      
        }
      }
      await this.filterDropdown();
      this.getBackgrounds();
      this.setSpentFate();
      this.checkPristine();
    },

    async filterDropdown() {
      // filter only if not 'chosen path...
      if(this.forgeKey === 'ChosenPath') {
        await this.getChosenPaths();
        this.getSelectedCategory();
      } else {
        let previousCategories = [];      
        Object.values(this.characterBackgrounds).forEach(item => {
          if(item.LevelBonus) {
            previousCategories.push(item.LevelBonus);
          }
        });
        let array = this.levelBonusCategories.filter(c => {
          if(previousCategories.indexOf(c) < 0) {
            return c;
          }
        });
        this.levelBonusSelections = array.sort();
        this.getSelectedCategory();
        if(this.characterBackgrounds[this.forgeKey]) {
          array.push(this.selectedCategory);
        }
      }
    },

    async getChosenPaths() {
      let skillGroupIDs = this.characterClass.ChosenPaths.split('|').map(i => parseInt(i));
      this.levelBonusSelections = await this.$store.dispatch('Content/getChosenPaths', skillGroupIDs);
    },

    getSelectedCategory() {
      if(this.characterBackgrounds[this.forgeKey]) {
        this.selectedCategory = this.characterBackgrounds[this.forgeKey].LevelBonus;
      } else {
        this.selectedCategory = this.levelBonusSelections[0];
      }
    },

    checkPristine() {
      this.isPristine = (this.triad.low === 0 && this.triad.mid === 0 && this.triad.high === 0)
    },

    diceRolled(triad) {
      this.g20Roll = triad.mid;
      this.triad = triad;
      let item = this.backgrounds.find(i => i.g20Roll === this.g20Roll);
      this.selectBackground(item);
      this.checkPristine();
    },

    async getBackgrounds() {      
      if(this.selectedCategory) {
        this.backgrounds = await this.$store.dispatch('Content/getBackgrounds', this.selectedCategory);
      }
    },
    
    selectBackground(item) {

      this.g20Roll=item.g20Roll;
      let distance = Math.abs(this.triad.mid - item.g20Roll);
      let direction = (this.triad.mid - item.g20Roll) >= 0 ? -1 : 1;

      let fate = 0;
      for(let i=1; i<=distance; i++) {
        let cursor = this.triad.mid + (i * direction);
        if(this.triad.low <= cursor && cursor <= this.triad.high) {
          fate = fate + 1;
        } else {
          fate = fate + 2;
        }
      }

      this.setBackground(item, fate);
      this.setSpentFate();
      this.setLedgerItems();
    },

    setRollData(background) {
      this.triad = { low: background.low, mid: background.mid, high: background.high },
      this.g20Roll = background.g20Roll;
    },

    setBackground(item, fate) {
      this.characterBackgrounds[this.forgeKey] = new g20CharacterModels.Background({
        LBB_ID: item.LBB_ID,
        LBBName: item.LBBName,
        LBBDescription: item.LBBDescription,
        SkillGroup_ID: item.SkillGroup_ID,
        LevelBonus: item.LevelBonus,   
        cost: fate,
        g20Roll: this.g20Roll,
        high: this.triad.high,
        mid: this.triad.mid,
        low: this.triad.low
      });
    },

    setSpentFate() {
      this.characterFate.spentFate = calc.spentFate(this.characterBackgrounds);
    },

    setLedgerItems() {
      this.$store.dispatch('Forge/setLedgerItems', { 
        characterFate: this.characterFate,
        characterBackgrounds: this.characterBackgrounds
      });
    }

  }
}
</script>

<style lang="scss" scoped>
$artworkRoot: '/img/artwork/';
.background-selection { 
  header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .title {
      font-size: 20px;
    }    
  }

  .level-bonus-category {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0 15px 0;

    label {
      margin-right: 10px;
    }
  }

  .die-rolls {
    margin: 10px 0;
  }

  .range-link {
    color: #ccc;
  }

  .range-link:hover,
  .range-link:active {
    color: #fff;
  }

  .background-items {
    display: flex;
    .background-left {
      width: 50px;
      padding-top: 1px;
      text-align: center;

      .g20-roll {
        font-size: 24px;
      }
    }
    .background-right {
      flex: 1;
      .title {
        font-size: 16px;
      }
      .content {
        padding: 2px;
        font-size: 12px;
      }
    }
  }
}

@media screen and (min-width: 768px) {


}

</style>