export default class Info {
  constructor(options) {

    this.Name = "No name yet",
    this.Title = null;
    this.Gender = 'Male';
    this.Hair = null;
    this.Eyes = null;
    this.Age = null;
    this.Height = null;
    this.Weight = null;
    this.Demeanor = null;
    this.OutwardAppearance = null;
    this.Backstory = null;
            
    if(options) {
      this.init(options);
    }

  }

  init(options) {

    this.Name = options.Name || this.Name;
    this.Title = options.Title || this.Title;
    this.Gender = options.Gender || this.Gender;
    this.Hair = options.Hair || this.Hair;
    this.Eyes = options.Eyes || this.Eyes;
    this.Age = options.Age || this.Age;
    this.Height = options.Height || this.Height;
    this.Weight = options.Weight || this.Weight;
    this.Demeanor = options.Demeanor || this.Demeanor;
    this.OutwardAppearance = options.OutwardAppearance || this.OutwardAppearance;
    this.Backstory = options.Backstory || this.Backstory;

  }
}