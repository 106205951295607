<template>
<div class="spells-summary" v-if="spellSummary.length>0">
  <div class="title">Added Spells:</div>
  <div v-for="s in spellSummary" :key="s.SpellListName">
    <div class="spell-list-info">
      {{s.SpellListName}}
      <div class="spell-info" v-for="sp in s.Spells" :key='sp.SpellName'>
        {{sp.SpellName}}
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "SpellSummary",  
  computed: {
    ready() { return this.$store.state.Forge.ready; },
    forgeState() { return this.$store.state.Forge.forgeState; },
  },
  data() {
    return {
      spellSummary: []
    }
  },
  watch: {
    ready: {
      async handler(val) {
        if(val) {
          this.setState();
        }
      },
      immediate: true
    }
  },
  methods: {
    setState() {
      let spellLedger = this.$deepClone(this.forgeState.ledger.spellLedger); 
      this.spellSummary = spellLedger.selected;
    }
  }
}
</script>

<style lang="scss" scoped>
.spells-summary {
  margin: 10px;
  padding: 10px;
  background-color: rgba(0,0,0,0.25);
  border: solid 1px rgba(0,0,0,0.25);
  .title {
    margin-bottom: 10px;
  }
  .spell-list-info {
    font-size: 14px;
    color: #fff;
    .spell-info {
      font-size: 12px;
      padding: 0 0 0 10px;
      color: #b3c1ff;
    }
  }
}
</style>