//---------------------------------------------------------------------

import * as ForgeManager from '../code/app/forgeManager'
import { deepClone } from '../code/utils/g20Utils'

//---------------------------------------------------------------------

// state
//=============================
const state = {
  forgeState: null,
  ready: false,
  valid: false
}

// getters
//=============================
const getters = { 
  ledger(state) { return state.forgeState.ledger }
}

// actions
//====================================================================
const actions = {

  initForge({state, commit, dispatch}) { 
     if(!state.forgeState) {
      let forgeState = ForgeManager.getForgeState();
      commit("SET_FORGE_STATE", forgeState);
    }
    dispatch('checkReadyState');
  },

  checkReadyState({state, rootState, commit}) {
    if(state.forgeState && rootState.Character.characterDb && rootState.Content.contentDb) {
      commit("SET_READY_STATE", true);
    }
  },

  // --------------------------------------------------------  nav / forgeState

  initForgeState({commit}, payload) {
    let forgeState = ForgeManager.forgeStateInit(payload);  
    commit('SET_FORGE_STATE', forgeState);
  },

  clearForgeState({commit}) {
    let forgeState = ForgeManager.forgeStateClear();  
    commit('SET_FORGE_STATE', forgeState);
  },

  changeForgeStep({commit}, dir) {
    let forgeState = ForgeManager.forgeStep(dir);   
    commit('SET_FORGE_STATE', forgeState); 
  },

  setLedgerItems({commit}, ledger) {
    let forgeStateLedger = ForgeManager.setLedger(ledger);   
    commit('SET_LEDGER', forgeStateLedger); 
  },

  getLedgerItem(context, key) {
    return ForgeManager.getLedgerItem(key);
  },

  // --------------------------------------------------------
  
}

// mutations
//====================================================================
const mutations = {

  SET_FORGE_STATE(state, forgeState) {
    state.forgeState = forgeState;
    state.valid = ForgeManager.validateForgeState(forgeState);
  },

  SET_LEDGER(state, ledger) {
    let forgeState = deepClone(state.forgeState);
    forgeState.ledger = ledger;
    state.forgeState = forgeState;
    state.valid = ForgeManager.validateForgeState(forgeState);
  },

  SET_READY_STATE(state, ready) {
    state.ready = ready;
  }

}

//====================================================================
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}