//---------------------------------------------------------
import models from '../g20CharacterModels'
//---------------------------------------------------------  // deprecating
export default class Backgrounds {
  constructor(options) {

    this.Inheritance = new models.Inheritance();
    this.Family = new models.Background();
    this.Adolescence = new models.Background();
    this.YoungAdult = new models.Background();
    this.ChosenPath = new models.Background();

    if(options) {
      this.init(options);
    }
  }

  init(options) {

    this.Inheritance = new models.Inheritance(options.Inheritance) || this.Inheritance;
    this.Family = new models.Background(options.Family) || this.Family;
    this.Adolescence = new models.Background(options.Adolescence) || this.Adolescence;
    this.YoungAdult = new models.Background(options.YoungAdult) || this.YoungAdult;
    this.ChosenPath = new models.Background(options.ChosenPath) || this.ChosenPath;

  }

}