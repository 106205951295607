<template>
<div class="panel" :class="colorClass">
  <header :class="colorClass">      
    <slot name="header"></slot>
  </header>
  <div class="body">
    <slot name="body"></slot>
  </div>
</div>
</template>

<script>
export default {
  name: "SimplePanel",
  props: {
    colorClass: {
      type: String,
      default: 'gray'
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  position: relative;
  min-height: 100px;
  border: solid 1px transparent;
  margin-bottom: 5px;

  &.gray {
    border-color: #ccc;
  }
  &.editable {
    border-color: blue;
  }

  header {
    padding: 3px 0;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    &.gray {
      background: #ccc;
    }
  }

  .body {
    padding: 5px;
  }
}
</style>