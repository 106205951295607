<template>
<SimplePanel v-if="character" :class="{'editable': editable}">

  <div slot="header" @click.stop="editable=true">
    <div>Equipment</div>
  </div>

  <div slot="body" @click.stop="editable=true">

    <div class="command-buttons" v-if="editable">
      <button class="btn-gem blue btn-sm" @click="addEditItem()">Add</button>
      <button class="btn-gem green btn-sm" title="Save Character" @click.stop="saveCharacter()">Save</button>
      <button class="btn-gem red btn-sm" title="Cancel" @click.stop="cancel()">Cancel</button>
    </div>

    <div class="item-list">
      <Item v-for="item in character.Items" :key="item.ItemKey"
        :editable="editable" 
        :item="item" 
        @remove-item="confirmRemove($event)" 
        @edit-item="addEditItem($event)">
      </Item>
    </div>  

    <Modal :controller="itemModal" @close="itemModal.show=false">
      <ItemForm :controller="itemModal" @close="itemModal.show=false" @save="saveItem($event)" ></ItemForm>
    </Modal>
  
  </div>


</SimplePanel>
</template>

<script>
//----------------------------------------------------
import swal from 'sweetalert'
//----------------------------------------------------
export default {
  name: "Equipment",
  props: {
    value: Object
  },
  watch: {    
    value: {
      handler() { this.character = this.$deepClone(this.value) },
      immediate: true
    },
  },
  data() {
    return {
      character: null,
      controls: false,
      editable: false,
      itemModal: {
        show: false,
        payload: null
      }
    }
  },
  methods: {
    
    addEditItem(obj) {
      this.itemModal.show = true;
      this.itemModal.payload = obj ? obj : null;
    },

    async confirmRemove(item) {
      let confirm = await swal({
        title: "Remove?", text: `Removing this Item is permanent?`, buttons: true, dangerMode: true,
      })
      if (confirm) {
        this.removeItem(item);
      }
    },

    async removeItem(item) {
      let characterItems = this.$deepClone(this.character.Items);
      let ind = this.character.Items.findIndex(i => i.ItemKey===item.ItemKey);
      characterItems.splice(ind, 1);
      this.character.Items = characterItems;
    },

    saveItem(item) {

      let characterItems = this.$deepClone(this.character.Items);
      let ind = this.character.Items.findIndex(i => i.ItemKey===item.ItemKey);

      if(ind<0) {
        characterItems.push(item);
        characterItems.sort((a,b) => (a.Name < b.Name) ? -1 : 1);
      } else {
        characterItems[ind]=item;
      }

      this.character.Items = characterItems;

      this.itemModal.show = false;
      this.itemModal.payload = null;
    },
    
    cancel() {
      this.editable=false;
      this.$emit('cancel');
    },

    saveCharacter() {
      this.editable = false;
      this.$emit('save-character', this.character);
    }
  }
};
</script>

<style lang="scss" scoped>

.command-buttons {
  margin-bottom: 5px;
  text-align: right;
}

.item-list {
  display: block;
}
@media screen and (min-width: 500px) {
  .item-list {
    display: flex;
    flex-wrap: wrap;

    &> div {
      width: 50%;
    }
  }
}
</style>