
// may add this in a future code reorg

import CharacterDatabase from '../code/idb/CharacterDatabase'
//import g20CharacterModels from '../code/models/g20CharacterModels'

import { deepClone } from '../code/utils/g20Utils'
import { forgeMode } from '../code/constants'
import * as cm from '../code/game/character/CharacterManager'

// state
//=============================
const state = { 
  characterDb: null,
  ready: false
}

// getters
//=============================
const getters = {  }

// actions
//====================================================================
const actions = {

  initDatabase({state, commit, dispatch}) {
    if(!state.characterDb) {  
      let characterDb = new CharacterDatabase();
      commit("SET_CHARACTER_DB", characterDb);
    }
    dispatch('checkReadyState');
  },
  checkReadyState({state, commit}) {
    if(state.characterDb) {
      commit("SET_READY_STATE", true);
    }
  },
  // --------------------------------------------------------  character

  newCharacter({dispatch}) {
    let payload = { mode:forgeMode.NEW_CHARACTER }
    dispatch('Forge/initForgeState', payload, { root:true });
  },
  
  async levelCharacter({dispatch}, characterRecordId) {
    
    let characterRecord = await state.characterDb.getCharacter({id: characterRecordId});
    let payload = { characterRecordId: characterRecordId };

    if(characterRecord.Level===0) {
      payload.mode = forgeMode.LEVEL_UP_FIRST;
    } else {
      payload.mode = forgeMode.LEVEL_UP;
    }
    dispatch('Forge/initForgeState', payload, { root:true });
  },

  async createCharacter({ state, rootState, dispatch }) {
    let character = cm.createNewCharacter(rootState.Forge.forgeState.ledger);
    let id = await state.characterDb.createCharacter(character);
    await dispatch('Forge/clearForgeState', null, { root:true });
    return await state.characterDb.getCharacter({id:id});
  },

  async applyLedgerAndSave({state, rootState}) {
    try {
      let characterRecordId = rootState.Forge.forgeState.characterRecordId;
      let characterRecord = await state.characterDb.getCharacter({id: characterRecordId});
      let ledger = deepClone(rootState.Forge.forgeState.ledger);
      let character = cm.applyLedger(characterRecord.CharacterData, ledger);

      characterRecord.Level = character.Level;
      characterRecord.Name = character.Info.Name;
      characterRecord.CharacterData = character;
      await state.characterDb.saveCharacter(characterRecord);

      return { success: true, message: 'character created successfully' }
    } catch(err) {
      return { success: false, message: err }
    }
  },

  async getCharacter({state}, characterRecordId) {
    let characterRecord = await state.characterDb.getCharacter({id: characterRecordId});
    return characterRecord.CharacterData;
  },

  async saveCharacter({state}, {characterRecordId, character}) {
    
    // eval character: 
    character = cm.evalCharacter(character);

    let characterRecord = await state.characterDb.getCharacter({id: characterRecordId});
    characterRecord.CharacterData = character;
    await state.characterDb.saveCharacter(characterRecord);
    return character;
  },

  //--------------------------------------------------------

}

// mutations
//====================================================================
const mutations = {
  SET_CHARACTER_DB(state, characterDb) {
    state.characterDb = characterDb;
  },
  SET_READY_STATE(state, ready) {
    state.ready = ready;
  }
}

//====================================================================
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}