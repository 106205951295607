
export default class QueryRoll {
  constructor(options) {

    this.low = 0;
    this.mid = 0;
    this.high = 0;

    if(options){
      this.init(options);
    }

  }

  init(options) {
    this.low = options.low || this.low;
    this.mid = options.mid || this.mid;
    this.high = options.high || this.high;
  }

  isValid() {
    return this.low > 0 && this.mid > 0 && this.high > 0;
  }
}