<template>
<FateFrame class="skill-form">

  <header v-if="payload">
    <div class="skill-name-cost">
      <div>Skill: 
        <EditableContent v-model="skill.SkillName"></EditableContent>
      </div>
      <div>Skill Cost:
        <EditableContent v-model.number="skill.Cost"></EditableContent>
      </div>
    </div>
    <div class="original-skill" v-if="showOrigial">
      <div><span v-if="showOriginalSkill">Skill: {{skill.OrigSkillName}}</span></div>
      <div><span v-if="showOriginalCost">(Original Cost: {{skill.OrigSkillCost}})</span></div>
    </div>
  </header>

  <div class="form-body">  

    <div class="skill-bonus-defs" v-if="payload">
      <div class="form-group">
        <label>Group Bonus:</label>
        <input class="input-sm full" type="number" disabled="disabled" v-model.number="payload.skillGroup.LevelBonus" >
      </div>
      <div class="form-group">
        <label>Item Bonus:</label>
        <input class="input-sm full" type="number" v-model.number="skill.ItemBonus" @change="skillBonusCheck()" >
      </div>
      <div class="form-group">
        <label>Misc. Bonus:</label>
        <input class="input-sm full" type="number" v-model.number="skill.MiscBonus" @change="skillBonusCheck()" >
      </div>
    </div>


    <div class="skill-cost-ledger">
      <table style="width: 100%;">
        <tr>
          <td>&nbsp;</td>
          <td class="stat">Cost</td>
          <td class="stat">Ranks</td>
          <td class="stat">Bonus</td>
        </tr>
        <tr>
          <td>Current:</td>
          <td class="stat">-</td>
          <td class="stat">{{currentSkill.Ranks}}</td>
          <td class="stat">{{$formatBonus(currentSkill.SkillBonus)}}</td>
        </tr>
        <tr>
          <td><button class="btn-gem btn-sm" :class="{'blue': skill.AddedRanks>=1}" @click="toggleRanks(1)">Normal</button></td>
          <td class="stat">{{skill.Cost}}</td>
          <td class="stat">{{skill.AddedRanks > 0 ? 1 : 0}}</td>
          <td class="stat">-</td>
        </tr>
        <tr>
          <td><button class="btn-gem btn-sm" :class="{'purple': skill.AddedRanks===2}" @click="toggleRanks(2)">Rapid</button></td>
          <td class="stat">{{skill.Cost * 2}}</td>
          <td class="stat">{{skill.AddedRanks > 1 ? 1 : 0}}</td>
          <td class="stat">-</td>
        </tr>
        <tr>
          <td>Level Up:</td>
          <td class="stat">{{totalCost}}</td>
          <td class="stat">{{skill.Ranks}}</td>
          <td class="stat">{{$formatBonus(skill.SkillBonus)}}</td>
        </tr>
      </table>
    </div>
    
    <div class="skill-description" v-if="showDescription">
      <AnnotationContainer category="error" :thin="true" v-if="skill.IsImportant">
        This is an important, commonly used Skill.
      </AnnotationContainer>
      {{skill.Description}}
      <div class="contested-skill" v-if="skill.SkillNameContested">
        Contested Skill: <span>{{skill.SkillNameContested}}</span>
      </div>
    </div>
  </div>

  <footer>
    <div>
      <a class="help-link" href="#" title="show description" v-if="skill.Description" @click.prevent="showDescription = !showDescription">
        <img src="/img/svg/question.svg" />
      </a> 
      &nbsp; 
    </div>
    <div>
      <button class="btn-gem red btn" title="Cancel" @click="$emit('close')">Cancel</button>
      <button class="btn-gem green btn" title="New Character" @click="$emit('save', skill)">Save</button>
    </div>
  </footer>
  
</FateFrame>
</template>

<script>
//-------------------------------------------------
import { getSkillBonus } from '../../../code/game/calculations'
//-------------------------------------------------
export default {
  name: "SkillForm",
  props: {
    payload: Object
  },
  computed: {
    showOrigial() { return this.skill.SkillName !== this.skill.OrigSkillName || this.skill.SkillCost !== this.skill.OrigSkillCost },
    showOriginalSkill() { return this.skill.SkillName !== this.skill.OrigSkillName },
    showOriginalCost() { return this.skill.Cost !== this.skill.OrigSkillCost },
    forgeState() { return this.$store.state.Forge.forgeState; }
  },
  data() {
    return {
      currentSkill: {},  // original / current state
      skill: {},
      totalCost: 0,
      showDescription: false
    }
  },
  watch: {
    payload(val) {
      if(val) {      
        this.showDescription = false;    
        this.currentSkill = this.$deepClone(this.payload.skill);
        this.skill = this.$deepClone(this.payload.skill);
        this.skill.isTemplate = 0;
        this.totalCost = 0;
        this.setRanks(this.skill.AddedRanks);
        this.skillBonusCheck();
      }
    }
  },
  methods: {

    toggleRanks(num) {
      let ranks = 0;
      switch(this.skill.AddedRanks) {
        case 0 : ranks = num; break;
        case 1 : ranks = num===1 ? 0 : num; break;
        case 2 : ranks = num===2 ? 1 : num; break;
      }
      this.setRanks(ranks);
    },

    setRanks(num) {
      let skill = this.$deepClone(this.skill);
      skill.AddedRanks = num;
      let total = (skill.AddedRanks * skill.Cost) + ((skill.AddedRanks-1) * skill.Cost);
      this.totalCost = total > 0 ? total : 0;
      skill.Ranks = skill.AddedRanks;
      skill.SkillBonus = getSkillBonus({ character: this.payload.character, skill: skill, skillGroup: this.payload.skillGroup });
      this.skill = skill;
    },

    skillBonusCheck() {
      
      let currentSkill = this.$deepClone(this.currentSkill);
      currentSkill.SkillBonus = getSkillBonus({ character: this.payload.character, skill: currentSkill, skillGroup: this.payload.skillGroup });
      this.currentSkill = currentSkill;

      let skill = this.$deepClone(this.skill);
      skill.SkillBonus = getSkillBonus({ character: this.payload.character, skill: skill, skillGroup: this.payload.skillGroup });
      this.skill = skill;
    }

  }
}
</script>


<style lang="scss" scoped>
.skill-form {

  header {
    padding: 5px;

    .skill-name-cost {
      display: flex;
      justify-content: space-between;
    }
    .original-skill {
      display: flex;
      justify-content: space-between;
      font-size: 10px;
      padding: 0 5px;
      color: #aaa;
    }
  }

  //---------------------------------------

  .form-body {
    padding: 5px 10px;

    .skill-bonus-defs {
      display: flex;
    }
    .skill-bonus-defs > div {
      flex: 1;
      padding: 0 3px;
    }

    .skill-cost-ledger {
      table {
        border-collapse: collapse;
        width: 100px;

        tr {
          td {

            text-align: center;
            width: 75px;
            font-size: 12px;
            border: solid 1px #777;
       
            &:first-child {
              width: auto;
              text-align: right;
              border-left: none;
              border-top: none;
              border-bottom: none;
              padding-right: 10px;
            }

            button {
              width: 60px;
            }
          }
          
        }
      }
      
    }
    //---------------------------------------
    .skill-description {
      padding: 10px;
      margin-bottom: 5px;
      border: solid 1px rgba(0,0,0,0.5);
      background-color: rgba(0,0,0,0.25);
      font-size: 14px;

      .contested-skill {
        margin-top: 15px;
      }
      .contested-skill > span {
        font-style: italic;
        color: rgb(179, 193, 255);
      }

    }
  }
  //---------------------------------------
  footer {
    display: flex;
    justify-content: space-between;
    padding: 5px;

    .help-link img {
      width: 22px;
      height: auto;
    }
  }
}
</style>