//-----------------------------------------------
import Dexie from 'dexie';
import models from '../models/g20CharacterModels'
import { characterSchema } from './Schemas'
//-----------------------------------------------
export default class CharacterDatabase {
  //----------------------------
  constructor() {
    this.db = null;
    this.init();
  }
  //----------------------------
  init() {
    this.db = new Dexie('g20Characters');
    this.db.version(1).stores(characterSchema);
  }
  //-----------------------------------------------
  //  DB Operations
  //-----------------------------------------------

  async listAll(tableName) {
    let list = await this.db[tableName].toArray();
    return list; 
  }

  async createCharacter(character) {
    let id = await this.db.characters.put({Name: character.Info.Name, Player_ID: character.Player_ID, Level: character.Level, CharacterData: character, Created: new Date().toISOString()});
    return id; 
  }

  async listCharacters() {
    let list = await this.db.characters.orderBy('Name').toArray();
    return list.map(item => new models.CharacterRecord(item)); 
  }

  async getCharacter({id, name}) {
    if(!id && !name) {
      throw "Query object must have an id or a name.";
    }
    let character = null;
    if(id) {
      character = await this.db.characters.get(id);
    } else {
      character = await this.db.characters.get({Name: name});
    }
    return new models.CharacterRecord(character);
  }

  async saveCharacter(characterRecord) {
    if(!characterRecord) {
      throw "Query object must have characterRecord.";
    }
    characterRecord.Updated = new Date().toISOString();
    await this.db.characters.put(characterRecord);    
  }

  
  async saveServerId(characterRecord, serverId) {
    if(!characterRecord || !serverId) {
      throw "Query object must have a characterRecord and a serverId.";
    }
    characterRecord.ServerId = serverId;
    characterRecord.Updated = new Date().toISOString();
    await this.db.characters.put(characterRecord);
  }


  async createSnapshot(record) {
    let id = await this.db.snapshots.put({Name: record.Name, Player_ID: record.Player_ID, ServerId: record.ServerId,  Level: record.Level, CharacterData: record.CharacterData, Created: new Date().toISOString()});
    return id; 
  }

  async getSnapshot(id) {
    let snapshot = await this.db.snapshots.get(id);
    return new models.CharacterRecord(snapshot);
  }

}