
//================================================================================
//  Canvas Imaging Tools
//    Tools for using 'canvas' object in resiszing images:
//
//    - loadImage(imageUrl) : domImage
//    - resizeImage(domImage, <width>, <height>) : canvas
//    - imageToCanvas(imageUrl, <width>, <height>) : canvas
//    - imageToDataURL(imageUrl, <width>, <height>, <quality>)  : dataUrl
//    - saveAsPng(canvas, <filename>) : download png file
//    - saveAsJpg(canvas, <filename>, <quality>) : download jpg file
//================================================================================

//----------------------------------------------------------
// Load Image:
//  Returns a promise that resolves to an domImage object
//----------------------------------------------------------
export function loadImage(imageUrl) {

  return new Promise((resolve, reject) => {
    //-----------------------------
    let domImage = new Image();
    domImage.addEventListener('load', () => {
      resolve(domImage);
    }, true);
    //-----------------------------
    if(typeof(imageUrl) === 'string') {
      domImage.src = imageUrl;
    } else {
      reject('image url is not valid.');
    }
    //-----------------------------
  });
}

//----------------------------------------------------------
// Image Resize:
//  Returns a promise that resolves a Canvas object
//  Takes an Image and resizes it to the target params
//----------------------------------------------------------
export function resizeImage(image, width, height) {
  return new Promise((resolve, reject) => {
    //-----------------------------  safety vlaves
    if(!image) { reject('image is ' + image); return; }
    if(image.width===0 || image.height===0) { reject('image dimensions are invalid'); return; }
    //-----------------------------    
    let aspectRatio = getAspectRatio(image);
    //-----------------------------
    if(!width && height) { width = height * aspectRatio.w }
    if(!height && width) { height = width * aspectRatio.h }
    if(!height && !width) {
      width = image.width;
      height = image.height;
    }
    //-----------------------------
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    //-----------------------------
    const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);    
    //----------------------------- 
    resolve(canvas);
    //-----------------------------     
  });
}

//----------------------------------------------------------
// Image To Canvas:
//  Returns a promise that resolves a Canvas object
//  Takes an Image and resizes it to the target params
//----------------------------------------------------------
export function imageToCanvas(imageUrl, width, height) {
  //----------------------------- 
  if(!width) { width = imageUrl.width }
  if(!height) { height = imageUrl.height }
  //----------------------------- 
  return loadImage(imageUrl).then(domImage => {
    return resizeImage(domImage, width, height);
  });
  //----------------------------- 
}

//----------------------------------------------------------
// Image To DataURL:
//  Returns a promise that resolves an DataURL object
//  Takes an Image and resizes it to the target params
//----------------------------------------------------------
export function imageToDataURL(imageUrl, width, height, quality) {
  //----------------------------- 
  if(!width) { width = imageUrl.width }
  if(!height) { height = imageUrl.height }
  //----------------------------- 
  return loadImage(imageUrl).then(domImage => {
    return resizeImage(domImage, width, height).then(canvas => {
      if(quality) {
        return canvas.toDataURL('image/jpeg', quality);
      } else {
        return canvas.toDataURL();
      }
    });
  });
  //----------------------------- 
}

//----------------------------------------------------------
//  Save As Png:
//    Takes a canvas object and downloads a png file
//----------------------------------------------------------
export function saveAsPng(canvas, filename = 'file.png') {
  canvasToPng(canvas).then((blob) => {
    saveFile(blob, filename);
  });
}

//----------------------------------------------------------
//  Save As Png:
//    Takes a canvas object and downloads a jpg file
//----------------------------------------------------------
//----------------------------------------------------------
export function saveAsJpg(canvas, filename = 'file.jpg', quality=0.8) {
  canvasToJpg(canvas, quality).then((blob) => {
    saveFile(blob, filename);
  });
}

//----------------------------------------------------------
//==========================================================
//----------------------------------------------------------
function getAspectRatio(image) {
  let aspect = { w:0, h:0 }
  aspect.w = image.width / image.height;
  aspect.h = image.height / image.width;
  return aspect;
}

//----------------------------------------------------------
function canvasToPng(canvas) {
  return new Promise((resolve, reject) => {
    if(!canvas) {reject('canvas is ' + canvas)}
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/png');
  });

}

//----------------------------------------------------------
function canvasToJpg(canvas, quality = 0.8) {
  return new Promise((resolve, reject) => {
    if(!canvas) {reject('canvas is ' + canvas)}
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/jpeg', quality);
  });

}

//----------------------------------------------------------
function saveFile(blob, filename) {
  //-----------------------------  create link
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");  
  link.href = blobUrl;
  link.download = filename;
  //-----------------------------  click and remove
  document.body.appendChild(link);
  link.dispatchEvent( new MouseEvent('click', { bubbles: true, cancelable: true, view: window }) );
  document.body.removeChild(link);
  //----------------------------- 
}

//----------------------------------------------------------
//----------------------------------------------------------
/*
REFERENCES:
https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement
https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toDataURL
https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
https://developer.mozilla.org/en-US/docs/Web/API/Blob
https://developer.mozilla.org/en-US/docs/Web/API/File
https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
https://dev.to/nombrekeff/download-file-from-blob-21ho
*/
//----------------------------------------------------------
//----------------------------------------------------------