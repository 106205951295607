<template>
<div class="fate-frame">
<slot></slot>
</div>
</template>

<script>
export default {
  name: "FateFrame"
}
</script>

<style lang="scss" scoped>

$artworkRoot: '/img/artwork/';

.fate-frame {
  border: solid 10px transparent;
  border-image-source: url($artworkRoot + 'border-fate.png');
  border-image-slice: 30%;
  border-image-width: auto;
  border-image-repeat: repeat;
  background: url($artworkRoot + 'texture-brown-cloth.png');
  background-size: 100%;
  color: #fff;
  text-shadow: 0 0 8px #000;
}

</style>