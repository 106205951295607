<template>
<div class="forge-progress">
  <div class="track">
    <div ref="fill" class="fill" :style="{'width': percent + '%'}"></div>
    <div ref="bead" class="bead" :style="{'left': beadPosition + 'px'}">{{step}}</div>
  </div>
</div>
</template>

<script>

export default {
  name: "ForgeProgress",
  props: {
    step: Number,
    steps: Number
  },
  computed: {
    percent() { return ( this.step / this.steps * 100) },
  },
  watch: {
    percent: {
      handler() {
        this.setBeadPosition();
      },
      immediate: true
    }
  },
  data() {
    return {
      beadPosition: 0
    }
  },
  mounted() {
    window.addEventListener('resize', this.setBeadPosition);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setBeadPosition);
  },
  methods: {
    setBeadPosition() {
      this.$nextTick(() => {
        this.beadPosition = this.$refs.fill.clientWidth -10;
      });
    }
  }
}
</script>

<style lang="scss" scoped>

$border-color: rgb(66, 52, 7);
$track-color: rgb(26, 26, 26);
$fill-color: rgb(209, 143, 0);
$bead-color: rgb(102, 73, 12);
$text-color: #fff;

.forge-progress {

  .track {
    position: relative;
    background-color: $track-color;
    border: solid 1px $border-color;

    width: 80%;
    height: 10px;
    margin:auto;

    .fill {
      height: 100%;
      background-color: $fill-color;
    }

    .bead {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -5px;
      background-color: $bead-color;
      border: solid 1px $fill-color;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      color: $text-color;
      font-size: 14px;
      line-height: 0;
    }
  }
}

</style>