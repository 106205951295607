<template>
<main class="concourse">

  <FxRainstorm></FxRainstorm>
  
  <header>
    <div class="title"> Characters &amp; Campaigns </div>
    <div>
      <button class="btn-capped-red btn-sm" @click="logout()">Logout</button>
    </div>
  </header>

  <div class="tools"> 
    <div>
      <Characters></Characters>
    </div>
    <div>
      <Notifications></Notifications>
      <Campaigns></Campaigns>
    </div>
  </div>
</main>

</template>

<script>
//-------------------------------------------------------
import { getProfile, clearProfile } from '../../code/app/playerProfile'
//-------------------------------------------------------
export default {
  name: "Concourse",

  computed: {
    ready() { return this.$store.state.Forge.ready; }
  },

  mounted() {

    let profile = getProfile();
    if(!profile) {
      this.$router.push({name: 'Login'});
    }

    if(!this.ready) { 
      this.$store.dispatch('App/initApp');
    }
  },

  methods: {
    logout() {
      clearProfile();
      this.$router.push({name: 'Login'});
    }
  }
}
</script>

<style lang="scss" scoped>
.concourse {
  
  width: 100%;
  height: 100%;
  font-family: 'Fondamento', cursive;

  header {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    z-index: 100;

    .title {
      font-size: 30px;
      line-height: 30px;
      color: #fff;
      text-shadow: 0px 0px 5px #000;
    }
  }
  
  .tools {
    padding: 10px;
  }

  .tools > section {
    margin: 10px 0;
  }
}


@media screen and (min-width: 640px) {
  .concourse {

    .tools {
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;
    }

    .tools > section {
      flex: 1;
      margin: 20px;
    }

  }
}

</style>