<template>
<span class="dropdown" :class="{'open': open}">
  <span class="editable-span" :class="{ 'empty': !text }"
      :style="style"
      v-text="text || 'None'"
      @focus="open=true"
      @blur="sendVal($event)"
      @keydown.enter="endEdit($event)"
      contenteditable></span>

  <ul class="dropdown-menu" @mouseleave="open=false">
    <li v-for="i in items" :key="i[nameProp]">
      <a @click="select(i)">{{i[nameProp]}}</a>
    </li>
  </ul>

</span>
</template>

<script>
export default {
  name: "EditableDropdown",
  props: {
    text: [String, Number],
    items: Array,
    nameProp: String,
    valueProp: String, //?
    numeric: { type: Boolean, default: false },
    allowEnter: { type: Boolean, default: false },
    emptyWidth: { type: String, default: "100%" },
    emptyHeight: { type: String, default: "10px" }
  },
  data() {
    return{
      open: false
    }
  },
  computed: {
    style() {
      return { 'min-width': this.emptyWidth, 'min-height': this.emptyHeight }
    }
  },
  methods: {
    sendVal($event) {
      let val = (this.numeric) ? parseInt($event.target.innerText) : $event.target.innerText.trim();
      if(!this.open) {
        this.$emit('input', val);
        this.open = false;
      } 
    },
    endEdit(evt) {
      if(!this.allowEnter) {
        this.$el.blur();
        this.open = false;
        evt.preventDefault();
      }
    },
    select(item) {
      this.open = false;
      let prop = this.valueProp ? this.valueProp : this.nameProp;
      this.$emit('input', item[prop]);
    }
  }
}
</script>