
//====================================================
// Manages All Aspects of the Character
//====================================================

import g20CharacterModels from '../../models/g20CharacterModels'
import * as calc from '../calculations'
import * as g20Utils from '../../utils/g20Utils'
import { getProfile } from '../../app/playerProfile'

//----------------------------------------------------------------------------

export function createNewCharacter(ledger) {

  let character = new g20CharacterModels.Character();
  character.Race = ledger.characterRace;
  character.Class = ledger.characterClass;
  character.SpellAbility = ledger.characterSpellAbility;
  character.Resists = ledger.characterResists;
  character.Attributes = ledger.characterAttributes;
  character.Backgrounds = ledger.characterBackgrounds;
  character.WeaponGroups = ledger.characterWeaponGroups;
  character.Fate = ledger.characterFate.startingFate - ledger.characterFate.spentFate;
  character.MaxFate = ledger.characterFate.maxFate;
  character.Info = ledger.characterInfo;
  character.SkillGroups = ledger.characterSkills;
  character.Wealth = calc.setInitialWealth(ledger.characterBackgrounds.Inheritance);
  character.CombatInfo = new g20CharacterModels.CombatInfo();
  
  let profile = getProfile();
  character.Player_ID = profile.Player_ID;

  console.log("new character complete?", character);
  
  return character;

}

//----------------------------------------------------------------------------

export function applyLedger(character, ledger) {
  
  character = new g20CharacterModels.Character(character);
  
  if(ledger.skillLedger?.blended) {
    setSkills(character, ledger.skillLedger);
  }
  if(ledger.spellLedger?.blended) {
    character.SpellLists = ledger.spellLedger.blended;
  }
  if(ledger.itemsLedger) {
    character.Items = ledger.itemsLedger;
    setCombatInfo(character);
  }

  if(ledger.statGainsLedger) {
    setStatGains(character, ledger.statGainsLedger);
  }

  if(ledger.levelBonusLedger) {
    setLevelBonuses(character, ledger.levelBonusLedger);
  }  

  // final calcs
  character.Level ++;
  character.Fate += character.Level;
  
  console.log("apply ledger complete?", character);

  return character;
}

//----------------------------------------------------------------------------
export function evalCharacter(character) {   
  
  setSkillBonuses(character);
  setCombatInfo(character);

  // check Vitals?

  return character;
}

//============================================================================  setters
//----------------------------------------------------------------------------
export function setSkills(character, skillLedger) {
  let keys = Object.keys(skillLedger.blended);
  keys.forEach(key => {
    let skillKeys = Object.keys(skillLedger.blended[key].Skill);
    skillKeys.forEach(skillKey => {
      skillLedger.blended[key].Skill[skillKey].AddedRanks = 0;
    });
  });  
  character.SkillGroups = skillLedger.blended;
}

//----------------------------------------------------------------------------  setSkillBonuses
export function setSkillBonuses(character) {  

  let sgKeys = Object.keys(character.SkillGroups);

  sgKeys.forEach(sgKey => {

    let skillGroup = character.SkillGroups[sgKey];
    let sKeys = Object.keys(skillGroup.Skill);    

    sKeys.forEach(sKey => {
      let skill = skillGroup.Skill[sKey];
      skill.SkillBonus = calc.getSkillBonus({character, skill, skillGroup});      
    });
  });
}

//----------------------------------------------------------------------------
export function setCombatInfo(character) {

  let combatInfo = new g20CharacterModels.CombatInfo();

  character.Items.forEach(item => {
    if(item.ItemType === 'Armor'  && item.IsEquipped) {
      combatInfo[item.ArmorLocation] = item;
      if(item.ArmorLocation === 'Shield') {
        combatInfo.ShieldBonus = item.ItemBonus;
      }
    }
  });

  character.CombatInfo = combatInfo;

  character.CombatInfo.DefensiveBonus = calc.getDefensiveBonus(character);
  character.CombatInfo.InitBonus = calc.getInitiativeBonus(character);

}

//----------------------------------------------------------------------------
export function setLevelBonuses(character, levelBonusLedger) {
  let lbKyes = Object.keys(levelBonusLedger.items);
  lbKyes.forEach(key => {
    character.SkillGroups[key].LevelBonus += levelBonusLedger.items[key].LevelBonus;
  });
}

//----------------------------------------------------------------------------
export function setStatGains(character, statGainsLedger) {
  let atKeys = Object.keys(statGainsLedger);
  atKeys.forEach(key => {    
    character.Attributes[key].Current += statGainsLedger[key].cur;
    character.Attributes[key].Potential += statGainsLedger[key].pot;
  });
}


//============================================================================  other

//----------------------------------------------------------------------------
export function getWeaponSkill(character, item) {
  if(item.ItemType !== 'Weapon') { return null }
  let weaponGroupIndex = character.WeaponGroups.findIndex(wg => wg.WeaponGroupDesc === item.WeaponSkill);
  for (const entry of Object.entries(character.SkillGroups.Weapons.Skill)) {
    if(entry[1].OrigSkillName === "WG" + (weaponGroupIndex + 1)) {
      let WeaponSkill = g20Utils.deepClone(entry[1]);
      WeaponSkill.SkillName = item.Name;
      WeaponSkill.ItemBonus = item.ItemBonus;
      WeaponSkill.SkillBonus = calc.getSkillBonus({character:character, skill:WeaponSkill, skillGroup: character.SkillGroups.Weapons});
      return WeaponSkill;
    }
  }
}